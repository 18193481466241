import { Col } from "antd";
import InstagramSvg from "assets/icon/icon-instagram.svg";
import FacebookSvg from "assets/images/facebook-purple.svg";
import TwitterSvg from "assets/images/twitter-purple.svg";
import { element, instanceOf, oneOfType, string } from "prop-types";
import React from "react";

const SocialNetwork = ({ img, link }) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={link}
    onClick={(e) => {
      e.preventDefault();
      if (link.includes("http")) {
        window.open(link);
      } else {
        window.open(`https://${link}`);
      }
    }}
  >
    {typeof img === "string" ? <img src={img} alt="facebook" className="iconNet" /> : { img }}
  </a>
);

SocialNetwork.propTypes = {
  img: oneOfType([string, element]).isRequired,
  link: string.isRequired,
};

const SocialNetworks = ({ onboarding }) => {
  const { agentSocialNetworks } = onboarding.success.profile;

  const instagram = agentSocialNetworks?.find(
    (socialNetwork) => socialNetwork.socialNetwork.key === "instagram"
  );

  const facebook = agentSocialNetworks?.find(
    (socialNetwork) => socialNetwork.socialNetwork.key === "facebook"
  );

  const twitter = agentSocialNetworks?.find(
    (socialNetwork) => socialNetwork.socialNetwork.key === "twitter"
  );

  const linkedIn = agentSocialNetworks?.find(
    (socialNetwork) => socialNetwork.socialNetwork.key === "linkedIn"
  );

  return (
    <Col className="informationAgent-contact">
      {instagram && (
        <SocialNetwork
          img={InstagramSvg}
          link={
            instagram.value.includes("instagram")
              ? instagram.value
              : `instagram.com/${instagram.value}`
          }
        />
      )}

      {facebook && (
        <SocialNetwork
          img={FacebookSvg}
          link={
            facebook.value.includes("facebook") ? facebook.value : `facebook.com/${facebook.value}`
          }
        />
      )}
      {twitter && (
        <SocialNetwork
          img={TwitterSvg}
          link={twitter.value.includes("twitter") ? twitter.value : `twitter.com/${twitter.value}`}
        />
      )}
      {linkedIn && (
        <SocialNetwork
          img={<i className="icon-linkedIn" aria-hidden="true" />}
          link={
            linkedIn.value.includes("linkedIn") ? linkedIn.value : `linkedIn.com/${linkedIn.value}`
          }
        />
      )}
    </Col>
  );
};

SocialNetworks.propTypes = {
  onboarding: instanceOf(Object).isRequired,
};

export default SocialNetworks;
