import { Button } from "antd";
import { bool, func, instanceOf, string } from "prop-types";
import React from "react";
import close from "../../../../../assets/images/close-m.svg";
import FormNewConection from "../FormNewConection/FormNewConection";

const FormConnect = ({
  selectedInsurance,
  setSelectedInsurance,
  setSection,
  setEdit,
  toEdit,
  showUser,
  showSecondPassword,
  mixPanel,
  user,
}) => (
  <div className="ConnectionsPIA__form">
    <div className="form">
      <div className="form-head">
        <div className="form-head--left">
          <p className="body-bold">Autorizar acceso a</p>
          <img src={selectedInsurance.image} alt="logo" className="image" />
        </div>
        <Button onClick={() => setSelectedInsurance(false)} className="button-transparent">
          <img src={close} alt="close" className="close" />
        </Button>
      </div>
      <FormNewConection
        user={user}
        mixPanel={mixPanel}
        toEdit={toEdit}
        selectedInsurance={selectedInsurance}
        setEdit={setEdit}
        setForm={setSelectedInsurance}
        setSection={setSection}
        showUser={showUser}
        showSecondPassword={showSecondPassword}
      />
    </div>
  </div>
);

FormConnect.propTypes = {
  selectedInsurance: instanceOf(Object).isRequired,
  setSelectedInsurance: func.isRequired,
  setSection: func.isRequired,
  setEdit: func.isRequired,
  toEdit: instanceOf(Object),
  showUser: bool.isRequired,
  showSecondPassword: bool.isRequired,
  mixPanel: instanceOf(Object),
  user: string,
};

FormConnect.defaultProps = {
  toEdit: null,
  mixPanel: null,
  user: null,
};

export default FormConnect;
