/* eslint-disable prefer-const */
import LayoutPrivate from "components/layout/private";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AgentActions from "services/agent/actions";
import OnboardingActions from "services/onboarding/actions";
import AuthActions from "services/auth/actions";
import ErrorPage from "./404/404";
import Account from "./account/account";
import EditAgentPage from "./editAgentPage/EditAgentPage";
import Home from "./home/Home";
import Leads from "./leads/Lead";
import Marketplace from "./marketplace/Marketplace";
import DashboardPia from "./pia/pia";
import SummaryPia from "./SummaryPia/SummaryPia";
import Notifications from "./Notifications/Notifications";
import Privacy from "./Privacy/Privacy";
import ConnectionsPIA from "./ConnectionsPIA/ConnectionsPIA";
import Reports from "./reports/Reports";
import ReportsPia from "./ReportsPia/ReportsPia";
import wellbeback from "./500/500";
import Commissions from "./Commissions/Commissions";
import PiaDashboard from "./piaDashboard/piaDashboard";
import * as Token from "../../common/storage/Token";

const Private = () => {
  const dispatch = useDispatch();
  const onboarding = useSelector((state) => state.onboarding);
  const agent = useSelector((state) => state.agent);

  useEffect(() => {
    if (!onboarding.loading.profile && !onboarding.success.profile && !onboarding.error.profile)
      dispatch(OnboardingActions.getProfile());

    if (onboarding.success.profile && onboarding.success.profile.agentSponsors?.length > 0)
      document.body.classList.add(onboarding.success.profile.agentSponsors[0].sponsor.key);
  }, [dispatch, onboarding]);

  useEffect(() => {
    let userToken = Token.decodeToken();
    const timestamp = userToken.exp * 1000;
    const dateToken = new Date(timestamp);
    const dateCurren = new Date();
    if (dateCurren > dateToken) dispatch(AuthActions.logout());
  }, [dispatch]);

  useEffect(() => {
    if (!agent.loading.menu && !agent.success.menu && !agent.error.menu) {
      dispatch(AgentActions.getMenu());
    }
  }, [dispatch, agent]);

  useEffect(() => {
    let userToken = Token.decodeToken();
    const timestamp = userToken.exp * 1000;
    const dateToken = new Date(timestamp);
    const dateCurren = new Date();
    if (dateCurren > dateToken) dispatch(AuthActions.logout());
  }, [dispatch]);

  const NoMatch = () => <ErrorPage />;

  return (
    <Router>
      <LayoutPrivate>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/404" component={ErrorPage} />
          <Route path="/500" component={wellbeback} />
          <Route exact path="/editPage" component={EditAgentPage} />
          <Route exact path="/leads" component={Leads} />
          <Route exact path="/reports" component={Reports} />
          <Route path="/marketplace" component={Marketplace} />
          <Route exact path="/pia" component={PiaDashboard} />
          <Route path="/pia/policies" component={DashboardPia} />
          <Route exact path="/account/account" component={Account} />
          <Route path="/pia/commissions" component={Commissions} />
          <Route path="/account/notification" component={Notifications} />
          <Route path="/account/privacy" component={Privacy} />
          <Route path="/account/pia" component={ConnectionsPIA} />
          <Route path="/pia/summary" component={SummaryPia} />
          <Route path="/pia/reportes" component={ReportsPia} />
          <Route component={NoMatch} />
        </Switch>
      </LayoutPrivate>
    </Router>
  );
};

export default Private;
