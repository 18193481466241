/* eslint-disable lines-between-class-members */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import Loader from "components/loader";
import React, { Component } from "react";
import Chart from "react-apexcharts";

class LongGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stroke: {
        curve: "smooth",
      },
    };
  }
  render() {
    return (
      <div className="chartPoint chartPoint--one LongGraph">
        <div className="row">
          {this.props.loading ? (
            <Loader />
          ) : (
            <div className="mixed-chart">
              {this.props.data && this.props.dataPoint ? (
                <Chart
                  options={this.props.isoptions}
                  series={this.props.dataPoint}
                  stroke={this.state.stroke}
                  type={this.props.type}
                  width={this.props.width}
                  className="chart-point"
                  height={this.props.height}
                />
              ) : (
                <Loader />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default LongGraph;
