export default {
  title: {
    column: "productLine.title",
    value: null,
  },
  name: {
    column: "person.name",
    value: null,
  },
  requestDate: {
    column: "lead.requestDate",
    value: "DESC",
  },
  origin: {
    column: "origin.name",
    value: null,
  },
};
