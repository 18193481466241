/* eslint-disable react/jsx-props-no-spreading */
import { Button } from "antd";
import { func, instanceOf, number } from "prop-types";
import React, { useEffect, useRef } from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import tutorialInfo from "common/utils/tutorialInfo";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Slider from "react-slick";
import image from "../../../../assets/images/image.jpg";
import { changeRoute } from "../../../../common/utils/changeRoute";

const Content = ({ view, setShowTutorial, t, handleChange, setActiveSlide2, activeSlide2 }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const sliderTutorial = useRef();
  const prevCountRef = useRef();
  const prevCount = prevCountRef.current;

  useEffect(() => {
    prevCountRef.current = view.steps.length;
    if (prevCount > view.steps.length) {
      setTimeout(() => sliderTutorial.current.slickGoTo(0), 100);
      return;
    }
    sliderTutorial.current.slickGoTo(0);
  }, [view, prevCount]);

  const handleNextStepTutorial = (item) => {
    setActiveSlide2(1);
    sliderTutorial.current.slickGoTo(0);
    for (let index = 0; index < tutorialInfo.length; index += 1) {
      handleChange(tutorialInfo[item]);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    afterChange: (current) => setActiveSlide2(current + 1),
  };
  return (
    <div className="content content-tutorial">
      <Slider
        {...settings}
        ref={(slider) => {
          sliderTutorial.current = slider;
        }}
      >
        {view &&
          view.steps?.map((item) => (
            <div key={item.id} className="content--item">
              <div className="content__img">
                <img src={item.image ? item.image : image} alt="imagen" className="img" />
              </div>
              <h2 className="title-name">{view.name}</h2>
              <div>
                <h3>{item.title}</h3>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
      </Slider>
      <div className="content__button">
        {activeSlide2 < view.steps.length && (
          <Button onClick={() => sliderTutorial.current.slickNext()} className="secundary">
            {t("button.nextStep")}
          </Button>
        )}
        {activeSlide2 === view.steps.length &&
          (view.key === "publicPage" ? (
            <Button
              onClick={() => {
                setShowTutorial();
                changeRoute("editPage", history, dispatch);
              }}
              className="secundary"
            >
              {t("button.goToMyProfile")}
            </Button>
          ) : (
            <Button onClick={() => handleNextStepTutorial(view.id)} className="secundary">
              {t("button.nextStep")}
            </Button>
          ))}
      </div>
    </div>
  );
};

Content.propTypes = {
  view: instanceOf(Object),
  activeSlide2: number.isRequired,
  setShowTutorial: func.isRequired,
  handleChange: func.isRequired,
  setActiveSlide2: func.isRequired,
  t: func.isRequired,
};

Content.defaultProps = {
  view: null,
};

export default Content;
