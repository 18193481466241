import { LeftOutlined } from "@ant-design/icons";
import { Button, Row, Select } from "antd";
import capitalize from "common/utils/capitalize";
import showTranslatedText from "common/utils/showTranslatedText";
import PropTypes from "prop-types";
import React from "react";
import { getI18n } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import leadsActions from "services/leads/actions";
import CloseSvg from "../../../../../../assets/images/arrow-green-left.svg";
import iconGrenn from "../../../../../../assets/images/arrow-small-green.svg";
import DownSvg from "../../../../../../assets/images/down-purple-dropdown.svg";
import hoja from "../../../../../../assets/images/hojas.png";
import iconDow from "../../../../../../assets/images/icon-dow-purple.svg";
import pdf from "../../../../../../assets/pdf/_pdf-prueba.pdf";
import { CLIENT_HOST } from "../../../../../../common/config/config";
import ShareButton from "../../../../../../components/ShareButton/ShareButton";

const { Option } = Select;

const KnowMore = ({ lead, close, product, t }) => {
  const dispatch = useDispatch();
  const { success } = useSelector((state) => state.onboarding);
  const hasEndpoint = () => (success.profile.endPoint ? `?agente=${success.profile.endPoint}` : "");

  return (
    <div className="product-modal">
      <div className="product-modal__header">
        <div className="product-modal__headerClose">
          <div className="res-close">
            <a
              className="res-close"
              onClick={(e) => {
                e.preventDefault();
                close();
              }}
              href="./"
            >
              <LeftOutlined />
              <h2>{t("label.ProductDetail")}</h2>
            </a>
          </div>
        </div>
        <div className="product-modal__headerWrapper">
          <div className="product-modal__headerWrapper__category">
            <a
              onClick={(e) => {
                e.preventDefault();
                close();
              }}
              href="./"
            >
              <img src={CloseSvg} alt="close" />
            </a>
            <img src={product?.icon} alt="icon-product" className="icon-product" />
            <h2 className="sub-title">Detalle de producto</h2>
          </div>
          <div className="application-res">
            <div className="circle">
              <img
                src="https://res.cloudinary.com/arepa-tec/image/upload/v1652889440/Blanco_iouvy0.svg"
                className="icon"
                alt="icon"
              />
            </div>
            <div className="content">
              <h6>
                Solicitud RFQ Seguro{" "}
                {showTranslatedText(lead?.lead?.desiredProduct?.title, getI18n().language)}
              </h6>
              <span>Recibida: {capitalize(lead?.lead?.requestDate)}</span>
              <p>Estado</p>
              <Select
                suffixIcon={<img src={DownSvg} className="down" alt="down" />}
                defaultValue={lead?.lead?.status[0]?.status.key}
                onChange={(value) => dispatch(leadsActions.changeStatus(+lead?.lead?.id, value))}
              >
                {lead.status.map((statusItem) => (
                  <Option key={statusItem.id} value={statusItem.key}>
                    {statusItem.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="card-img-res">
            <img src={product?.icon} alt="icon-product" className="icon-product" />
            <img src={product.background} alt="product-background" />
            <div className="gradient" />
            <ShareButton endpoint={`${CLIENT_HOST}producto/${product.endPoint}/${hasEndpoint()}`} />
          </div>
          <Button id="quote-button-modal" className="button button--secundary button-coti">
            {t("button.QuoteProduct")}
          </Button>
          <Row>
            <a className="button button-dow" href={pdf} download>
              <img src={iconDow} alt="icon-dow" className="icon-dow" />
              Download PDF
            </a>
            <Button className="button button-primary">Cotizar</Button>
          </Row>
        </div>
      </div>
      <div className="product-modal__summary">
        <div className="product-modal__summaryInfo">
          <h2>Resumen</h2>
          <a
            href={`${CLIENT_HOST}producto/${product.endPoint}/${hasEndpoint()}`}
            target="blank"
            className="primary"
          >
            Ver página de producto
            <img src={iconGrenn} alt="iconGrenn" className="iconGrenn" />
          </a>
          <p>{showTranslatedText(product.description, getI18n().language)}</p>
          <div className="product-modal__summaryInfo__items">
            {product.productDetail.productDetailAdditional.map((detail) => (
              <div className="product-modal__summaryInfo__itemsItem" key={detail.id}>
                <img src={hoja} alt="info" />
                <span>{detail.text}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="product-modal__summaryBenefits">
          <embed src={pdf} type="application/pdf" height={500} className="cont-pdf" />
        </div>
      </div>
    </div>
  );
};

KnowMore.propTypes = {
  close: PropTypes.func.isRequired,
  lead: PropTypes.instanceOf(Object).isRequired,
  product: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.instanceOf(Object).isRequired,
};

export default KnowMore;
