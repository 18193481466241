export const pr = {
  login_title: "Login",
};

const label = {};

const button = {};
const error = {};

const placeholder = {};

const alert = {};

export { label, button, error, alert, placeholder };
