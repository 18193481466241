/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import Chart from "react-apexcharts";

class ChartTower extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Net Profit",
          data: [44, 55, 57, 56],
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        colors: ["#7F14FC", "#7F14FC", "#7F14FC", "#7F14FC"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: ["Feb", "Mar", "Apr", "May"],
        },
        yaxis: {
          categories: ["0", "10", "20", "70"],
        },
      },
    };
  }

  render() {
    return (
      <div className="donut chartbar">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          width="400"
          height="180"
        />
      </div>
    );
  }
}

export default ChartTower;
