/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import { Col, Row, Spin, Checkbox } from "antd";
import linesSvg from "assets/icon/icon-lines.svg";
import showTranslatedText from "common/utils/showTranslatedText";
import React, { useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getI18n, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import OnboardingActions from "services/onboarding/actions";
import "./productLines.scss";

const onDragEnd = (result, dispatch) => {
  dispatch(OnboardingActions.orderProductLines(result.source, result.destination));
};

const ProductLines = () => {
  const dispatch = useDispatch();
  const onboarding = useSelector((state) => state.onboarding);
  const { t } = useTranslation();

  useEffect(() => {
    if (
      !onboarding.loading.productLines &&
      !onboarding.success.productLines &&
      !onboarding.error.productLines
    ) {
      dispatch(OnboardingActions.getProductLines());
    }
  }, [dispatch, onboarding]);

  const selectProductLine = (productLine) => {
    dispatch(OnboardingActions.selectProductLine(productLine));
  };

  const renderDroppables = () => {
    return Object.keys(onboarding.success.productLines).map((drop, index) => {
      const productLineList = onboarding.success.productLines[drop];
      return (
        <Droppable key={drop} droppableId={drop}>
          {(provided) => (
            <div className="wrapper" ref={provided.innerRef}>
              {index === 0 && <h6 style={{ marginTop: -25 }}>Más importante</h6>}
              {productLineList.map((productLine, i) => {
                return (
                  <Draggable key={productLine.id} draggableId={`${productLine.id}`} index={i}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`productLines__listProduct-line${
                          snapshot.isDragging || productLine.selected ? "--selected" : "--disabled"
                        }`}
                      >
                        <div
                          className={`productLines__listProduct-line${
                            snapshot.isDragging || productLine.selected
                              ? "--selected__view"
                              : "--disabled__view"
                          }`}
                        >
                          <Checkbox
                            checked={productLine.selected}
                            onChange={() => selectProductLine(productLine)}
                          />
                        </div>
                        <div
                          className={`productLines__listProduct-line${
                            snapshot.isDragging || productLine.selected
                              ? "--selected__product"
                              : "--disabled__product"
                          }`}
                        >
                          <div
                            className={`productLines__listProduct-line${
                              snapshot.isDragging || productLine.selected
                                ? "--selected__productImage"
                                : "--disabled__productImage"
                            }`}
                          >
                            <img
                              src={
                                snapshot.isDragging || productLine.selected
                                  ? productLine.iconWhite
                                  : productLine.icon
                              }
                              alt={productLine.title}
                            />
                          </div>
                          <div
                            className={`productLines__listProduct-line${
                              snapshot.isDragging || productLine.selected
                                ? "--selected__productText"
                                : "--disabled__productText"
                            }`}
                          >
                            <p>{showTranslatedText(productLine.title, getI18n().language)}</p>
                          </div>
                        </div>
                        <div
                          className={`productLines__listProduct-line${
                            snapshot.isDragging || productLine.selected
                              ? "--selected__lines"
                              : "--disabled__lines"
                          }`}
                        >
                          <img src={linesSvg} alt="lines" />
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
              {index === Object.keys(onboarding.success.productLines).length - 1 && (
                <h6>{t("label.LessImportant")}</h6>
              )}
            </div>
          )}
        </Droppable>
      );
    });
  };

  return (
    <div className="productLines">
      <div className="productLines__header">
        <Row className="productLines__title">
          <Col xs={24} sm={24} md={24} lg={24}>
            <h4> </h4>
          </Col>
        </Row>
        <Row className="productLines__subtitle">
          <Col xs={24} sm={24} md={24} lg={24}>
            <h4>
              Selecciona los ramos <br /> de seguro que vendes
            </h4>
            <p>Arrastre y ordene por prioridad</p>
          </Col>
        </Row>
      </div>
      {onboarding.loading.productLines && <Spin />}
      <Row className="productLines__content content-wrapper-res">
        {onboarding.success.productLines && (
          <DragDropContext onDragEnd={(result) => onDragEnd(result, dispatch)}>
            {renderDroppables()}
          </DragDropContext>
        )}
      </Row>
    </div>
  );
};

export default ProductLines;
