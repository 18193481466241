/* eslint-disable consistent-return */
import { UserOutlined } from "@ant-design/icons";
import PasswordSvg from "assets/icon/icon-password-gray.svg";
import { Checkbox, Col, Form, Input, message, Modal, Row } from "antd";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import PhonePng from "assets/images/profile/cel.png";
import EmailsSvg from "assets/images/profile/email.png";
import app from "common/firebase";
import { getSocialInfo, initGoogleAuthProvider } from "common/firebase/auth";
import Loader from "components/loader";
import AuthActions from "services/auth/actions";
import GoogleSvg from "assets/icon/icon-google-purple.svg";

const Signup = ({ setWhoIsActive, oAuthData }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [oauthError, setOauthError] = useState(false);
  const [tycError, setTycError] = useState(false);
  const [isOauth, setIsOauth] = useState();
  const auth = useSelector((state) => state.auth);

  const fillForm = useCallback(
    ({ name, email: newEmail }) => {
      form.setFieldsValue({
        name,
        email: newEmail,
        phone: "",
      });
    },
    [form]
  );

  useEffect(() => {
    if (!isOauth && oAuthData) {
      setIsOauth(oAuthData);
      fillForm(oAuthData);
    }
  }, [oAuthData, fillForm, isOauth]);

  useEffect(() => {
    if (!auth.success.signup) return;

    dispatch(AuthActions.setLogged(true));
    history.push("/");
  }, [auth.success.signup, dispatch, history, t, isOauth]);

  useEffect(() => {
    if (auth.error?.signup) message.error(t(`error.${auth.error.signup}`));

    if (auth.success?.signup) message.success(t("success.signup"));

    if (oauthError) message.error(t(`error.${oauthError}`));
  }, [auth, oauthError, t]);

  const socialSignup = async (provider, type) => {
    await app
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        const signupData = getSocialInfo(result.additionalUserInfo.profile, type);
        setIsOauth(signupData);
        fillForm(signupData);
      })
      .catch(() => {
        setOauthError(`oauth${type}`);
      });
  };

  const onFinish = (values) => {
    if (values.password !== values.secondPassword) {
      form.setFields([
        {
          name: "secondPassword",
          errors: ["Las contraseñas no coinciden"],
        },
      ]);

      return;
    }
    if (!values.remember) {
      setTycError(true);
      return message.error(t("alert.acceptTermsAndConditions"));
    }
    setTycError(false);
    setIsOauth(false);
    setOauthError(undefined);
    dispatch(AuthActions.signup({ ...isOauth, ...values }));
    return null;
  };

  return (
    <div className="signup">
      {auth.loading.signup && (
        <Modal
          className="modal-loader"
          visible
          centered
          maskStyle={{ backgroundColor: "#ffffff66" }}
        >
          <Loader />
        </Modal>
      )}
      <h1 className="signup__redesTitle">{t("label.createAccountWith")}</h1>
      <div className="card-login">
        <Row>
          <figure className="signup__redes">
            <div className="images">
              <div className="red" id="google-button-signup">
                <a
                  href="./"
                  onClick={(e) => {
                    e.preventDefault();
                    socialSignup(initGoogleAuthProvider(), "Google");
                  }}
                >
                  <img src={GoogleSvg} alt="google" className="google" />
                </a>
              </div>
            </div>
            <div className="signup__redesSeparator">{t("label.or")}</div>
          </figure>
        </Row>
        <div className="signup__form">
          <Form
            wrapperCol={{
              span: 24,
            }}
            form={form}
            name="signup"
            onFinish={onFinish}
          >
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: t("error.name"),
                },
                {
                  min: 5,
                  whitespace: true,
                  message: t("error.charactersNameMin"),
                },
                {
                  max: 50,
                  message: t("error.charactersNameMax"),
                },
                {
                  pattern: new RegExp(/^[a-zA-Záéíóú @~`!@#$%^&*()_=+\\\\';:"\\/?>.<,-]+$/i),
                  message: t("error.NotNumbers"),
                },
              ]}
            >
              <Input
                id="name-input-signup"
                prefix={<UserOutlined />}
                placeholder={t("placeholder.inputNameAndLastname")}
                className="input-custom"
              />
            </Form.Item>

            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: t("error.email"),
                  type: "email",
                },
                {
                  max: 200,
                  message: t("error.charactersEmail"),
                },
              ]}
            >
              <Input
                id="email-input-signup"
                disabled={!!isOauth}
                prefix={<img src={EmailsSvg} alt="icon" />}
                placeholder={t("placeholder.inputEmail")}
                className="input-custom"
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: t("error.password"),
                },
                {
                  max: 20,
                  min: 8,
                  message: t("error.charactersPassword"),
                },
              ]}
            >
              <Input
                id="email-input-signup"
                disabled={!!isOauth}
                prefix={<img src={PasswordSvg} alt="icon" />}
                placeholder={t("placeholder.inputPass")}
                className="input-custom"
                type="password"
              />
            </Form.Item>
            <Form.Item
              name="secondPassword"
              rules={[
                {
                  required: true,
                  message: t("error."),
                },
                {
                  max: 20,
                  min: 8,
                  message: t("error.charactersPassword"),
                },
              ]}
            >
              <Input
                id="pass2-input-signup"
                prefix={<img src={PasswordSvg} alt="icon" />}
                placeholder="Confirmar nueva contraseña"
                className="input-custom"
                type="password"
              />
            </Form.Item>

            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: t("error.phone"),
                },
                {
                  min: 10,
                  whitespace: true,
                  max: 50,
                  message: t("error.charactersPhone"),
                },
              ]}
            >
              <Input
                id="phone-input-signup"
                prefix={<img src={PhonePng} alt="icon" />}
                type="number"
                placeholder={t("placeholder.inputPhone")}
                className="input-custom input-custom--phone"
              />
            </Form.Item>
            <Row>
              <Col lg={24}>
                <Form.Item name="remember" className="checked" valuePropName="checked">
                  <Checkbox onClick={() => setTycError(false)} id="remember-checkbox-signup">
                    <a
                      href="https://blog.berrysafe.com/politicas-de-privacidad/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: tycError ? "red" : "#1890ff" }}
                    >
                      {t("button.acceptTheTyC")}
                    </a>
                  </Checkbox>
                </Form.Item>
                <button
                  type="submit"
                  id="signup-button-signup"
                  className="btn-custom btn-custom--secundary btn--login"
                  disabled={auth.loading.signup}
                >
                  {t("button.goSignUp")}
                </button>
                <button
                  type="button"
                  id="login-button-signup"
                  className="btn-custom btn-custom--tertiary btn--login-tertiary"
                  onClick={() => setWhoIsActive("login")}
                >
                  {t("button.login")}
                </button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

Signup.propTypes = {
  setWhoIsActive: PropTypes.func.isRequired,
  oAuthData: PropTypes.instanceOf(Object),
};
Signup.defaultProps = {
  oAuthData: null,
};

export default Signup;
