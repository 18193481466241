import { Card, Divider, message } from "antd";
import { bool, func } from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AgentActions from "../../../../../../../services/agent/actions";
import OnboardingActions from "../../../../../../../services/onboarding/actions";
import ProductLineSelection from "./components/ProductLineSelection/ProductLineSelection";

const Products = ({ saveProducts, setSaveProducts }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [someChange, setSomeChange] = useState();
  const agent = useSelector((state) => state.agent);

  useEffect(() => {
    if (
      !agent.loading.layoutProducts &&
      !agent.error.layoutProducts &&
      !agent.success.layoutProducts
    )
      dispatch(AgentActions.getLayoutProducts());

    if (agent.error.setSelectedLayout) {
      message.error(t("error.insuranceCarriersUpdated"));
      dispatch(AgentActions.clearResponseLayoutProducts());
      dispatch(OnboardingActions.getProfile(false));
    }

    if (agent.success.saveProductLines) {
      setSomeChange(false);
      dispatch(AgentActions.clearProductLinesResponse());
    }
  }, [agent, dispatch, t]);

  useEffect(() => {
    if (saveProducts && someChange) setSaveProducts(false);
  }, [saveProducts, setSaveProducts, someChange]);

  return (
    <section className="products-editing">
      <Card className="card-products">
        <div className="card-products__header">
          <div className="header__title">
            <h4>{t("label.branchs")}</h4>
            <Divider />
          </div>
        </div>
        <div className="card-products__wrapper">
          <ProductLineSelection agent={agent} setSomeChange={setSomeChange} />
        </div>
      </Card>
    </section>
  );
};

Products.propTypes = {
  setSaveProducts: func.isRequired,
  saveProducts: bool.isRequired,
};

export default Products;
