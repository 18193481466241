/* eslint-disable no-plusplus */
import { Button, Spin, Form, Input } from "antd";
import { func, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OnboardingActions from "../../../../../../../services/onboarding/actions";
import "./license.scss";

const License = ({ activeGoNext, selectedSponsor }) => {
  const dispatch = useDispatch();
  const [listDni, setListDni] = useState([]);
  const [isTabActive, setTabsActive] = useState(0);
  const onboarding = useSelector((state) => state.onboarding);

  const onFinish = () => {};

  const onFinishFailed = () => {};

  const handleSetListDni = (item) => {
    for (let index = 0; index < listDni.length; index++) {
      if (listDni[index] === item) {
        setListDni(listDni.filter((value) => value !== item));
        return;
      }
    }
    setListDni([...listDni, item]);
  };

  useEffect(() => {
    if (
      !onboarding.loading.license &&
      !onboarding.success.licenseTypes &&
      !onboarding.error.license
    ) {
      dispatch(OnboardingActions.getLicenses());
    }
  }, [dispatch, onboarding]);

  const setLicense = (license) => {
    activeGoNext();
    dispatch(OnboardingActions.setLicense(license.id, !license.isSelected));
  };

  return (
    <div className="license-responsive">
      <div className="license-responsive__text">
        <h4>Selecciona tu cédula de agente</h4>
        <p>Puedes seleccionar una o varias</p>
      </div>
      {selectedSponsor === "other" && listDni.length >= 1 && (
        <div className="tabs">
          <Button
            onClick={() => setTabsActive(0)}
            className={`step ${isTabActive === 0 && "step--active"}`}
          />
          <Button
            onClick={() => setTabsActive(1)}
            className={`step ${isTabActive === 1 && "step--active"}`}
          />
        </div>
      )}
      {isTabActive === 0 && (
        <div className="license-responsive__buttons animate__fadeInLeft  animate__animated">
          {onboarding.loading.license && <Spin />}

          {onboarding.success.licenseTypes &&
            onboarding.success.licenseTypes.map((license) => (
              <Button
                className={`licenseType${license.isSelected ? "--selected" : "--notSelected"}`}
                key={license.id}
                onClick={() => {
                  setLicense(license);
                  handleSetListDni(license);
                }}
                type="button"
              >
                {license.name}
              </Button>
            ))}
        </div>
      )}
      {isTabActive === 1 && selectedSponsor === "other" && listDni.length >= 1 && (
        <Form
          className="license__form animate__fadeInRight animate__animated"
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          {listDni?.map((license) => (
            <Form.Item
              name="username"
              key={`dni-${license.name}`}
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <div className="did-floating-label-content">
                <Input
                  onChange={(e) => {
                    dispatch(OnboardingActions.setLicense(license.id, true, e.target.value));
                  }}
                  className="did-floating-input"
                  type="text"
                  placeholder=""
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="did-floating-label">Número de cédula {license.name}</label>
              </div>
            </Form.Item>
          ))}
        </Form>
      )}
    </div>
  );
};

License.propTypes = {
  activeGoNext: func.isRequired,
  selectedSponsor: string.isRequired,
};

export default License;
