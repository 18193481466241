/* eslint-disable react/jsx-props-no-spreading */
import { Alert, Col, Form, Input, message, Row } from "antd";
import { func } from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Loader from "../../../../../../../components/loader";
import AuthActions from "../../../../../../../services/auth/actions";

const layout = {
  wrapperCol: {
    span: 24,
  },
};

const ChangeForgotPassword = ({ changeToLogin }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.success.changeForgotPassword) {
      message.success({
        content: t("alert.PasswordResetSuccessfully"),
        icon: (
          <svg
            className="icon-check-svg"
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.83877 5.6076L5.39204 9.16088L14.3405 0.212402L15.4703 1.34218L5.39204 11.4204L0.708984 6.73738L1.83877 5.6076Z"
              fill="#5D30DD"
            />
          </svg>
        ),
      });
      changeToLogin();
      history.replace("/");
    }
  }, [auth.success.changeForgotPassword, dispatch, changeToLogin, history, t]);

  const onFinish = (values) => dispatch(AuthActions.changeForgotPassword(values.password));

  const cancelChangePassword = () => {
    history.replace("/");
    changeToLogin();
  };

  return (
    <div className="changeForgotPassword">
      {auth.loading.changeForgotPassword ? (
        <div className="Loader">
          <Loader />
        </div>
      ) : (
        <div className="changeForgotPassword__content">
          <Row>
            <figure className="changeForgotPassword__header">
              <h1 className="changeForgotPassword__headerTitle">
                {t("label.changeForgotPasswordTitle")}
              </h1>

              <p className="changeForgotPassword__headerDescrip">
                {t("label.changeForgotPasswordSubtitle")}
              </p>
            </figure>
          </Row>
          <div className="changeForgotPassword__form">
            <Form {...layout} name="changeForgotPassword" onFinish={onFinish}>
              <div className="changeForgotPassword__form__item">
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t("error.password"),
                    },
                    {
                      whitespace: true,
                      min: 8,
                      max: 20,
                      message: t("error.charactersPassword"),
                    },
                  ]}
                >
                  <Input.Password
                    placeholder={t("placeholder.inputPassNew")}
                    className="input-custom"
                  />
                </Form.Item>
              </div>
              <Row>
                <Col lg={24}>
                  <button
                    className="btn-custom btn-custom--secundary"
                    disabled={auth.loading.changeForgotPassword}
                    type="submit"
                  >
                    {t("button.continue")}
                  </button>
                </Col>
              </Row>
              <Row>
                <Col lg={24}>
                  <button
                    type="button"
                    className="btn-custom btn-custom--white"
                    onClick={cancelChangePassword}
                  >
                    {t("button.cancel")}
                  </button>
                </Col>
              </Row>
            </Form>
            {auth.error && auth.error.changeForgotPassword && (
              <div className="alert">
                <Alert
                  message={t(`error.${auth.error.changeForgotPassword}`)}
                  type="error"
                  showIcon
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

ChangeForgotPassword.propTypes = {
  changeToLogin: func.isRequired,
};

export default ChangeForgotPassword;
