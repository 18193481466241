import { Button } from "antd";
import { func, instanceOf, oneOfType, string } from "prop-types";
import React from "react";

const Tips = ({ action, text }) => (
  <div className="button-complete">
    <p className="subtitle">{text}</p>
    <Button
      onClick={(e) => {
        e.preventDefault();
        action();
      }}
      className="button button-primary"
    >
      Completar requisitos
    </Button>
  </div>
);

Tips.propTypes = {
  action: func.isRequired,
  text: oneOfType([string, instanceOf(Object)]).isRequired,
};

export default Tips;
