import { Button, Checkbox, Modal } from "antd";
import { bool, func, string } from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import AgentActions from "services/agent/actions";

const ConfirmAlert = ({
  isVisible,
  onOk,
  onCancel,
  message,
  okClassName,
  cancelClassName,
  onAcceptClick,
  onDeniedClick,
  showAutoPublish,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <Modal
      title="Basic Modal"
      className="modal-warnning"
      visible={isVisible}
      onOk={onOk}
      onCancel={onCancel}
    >
      <div className="cont-modal">
        <div className="cont-modal--check">
          <h3>{message}</h3>
          {showAutoPublish && (
            <Checkbox onChange={() => dispatch(AgentActions.setAutoPublish())}>
              <span className="msj-check">Publicar cambios automáticamente en mi página web</span>
            </Checkbox>
          )}
        </div>
        <div className="btns">
          <Button className={okClassName} onClick={onAcceptClick}>
            {t("label.yes")}
          </Button>
          <Button className={cancelClassName} onClick={onDeniedClick}>
            {t("label.not")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmAlert.propTypes = {
  isVisible: bool.isRequired,
  onOk: func.isRequired,
  onCancel: func.isRequired,
  message: string.isRequired,
  okClassName: string.isRequired,
  cancelClassName: string.isRequired,
  onAcceptClick: func.isRequired,
  onDeniedClick: func.isRequired,
  showAutoPublish: bool,
};

ConfirmAlert.defaultProps = {
  showAutoPublish: false,
};

export default ConfirmAlert;
