import { createActions } from "redux-actions";

const { office } = createActions({
  OFFICE: {
    GET_COUNTRIES: () => {},
    GET_COUNTRIES_RESPONSE: (countries) => countries,
  },
});

export default office;
