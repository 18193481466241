/* eslint-disable consistent-return */
import { UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, message } from "antd";
import candado from "assets/images/candado.svg";
import piaFormLabels from "common/utils/piaFormLabels";
import { func, instanceOf, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PoliciesActions from "services/policies/actions";

const FormNewConection = ({ selectedInsurance, setSection, toEdit, setEdit, mixPanel, user }) => {
  const dispatch = useDispatch();
  const [tyc, setTyc] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const policies = useSelector((state) => state.policies);

  useEffect(() => {
    if (policies.success.newAccount) {
      if (toEdit) setEdit(false);
      setSection("ConnectionNew");
      dispatch(PoliciesActions.cleanRegisterAccount());
    }
    if (policies.error.newAccount)
      message.error(
        policies.error.newAccount.split(", ").map((error) => (
          <>
            {error}
            <br />
          </>
        ))
      );

    if (policies.success.updateAccount) {
      if (toEdit) setEdit(false);
      setSection("ConnectionNew");
      dispatch(PoliciesActions.cleanUpdateAccount());
    }
  }, [policies, toEdit, setEdit, setSection, dispatch]);

  const onFinish = (values) => {
    if (values?.password === values.confirmPassword) {
      setPasswordError(false);
      if (toEdit) {
        mixPanel.track("Pia, edit account", {
          account: {
            insurance: selectedInsurance.key,
            username: values.username,
          },
          user,
          status: "executed",
        });
        return dispatch(
          PoliciesActions.updateAccount(
            selectedInsurance.key,
            values.password,
            values.password2,
            values.usernameTwo,
            values.username,
            toEdit.reference
          )
        );
      }
      if (!tyc) return message.error("Debe aceptar los términos y condiciones");
      mixPanel.track("Pia, save account", {
        account: {
          insurance: selectedInsurance.key,
          username: values.username,
        },
        user,
        status: "executed",
      });
      return dispatch(
        PoliciesActions.registerAccount(
          selectedInsurance.key,
          values.password,
          values.password2,
          values.usernameTwo,
          values.username
        )
      );
    }
    setPasswordError(true);
  };
  return (
    <Form
      initialValues={
        toEdit
          ? {
              username: toEdit?.username,
              usernameTwo: toEdit?.agent_key,
            }
          : {}
      }
      name="basic"
      onFinish={onFinish}
      autoComplete="off"
    >
      {piaFormLabels[selectedInsurance.key]?.username && (
        <Form.Item
          name="username"
          rules={[{ required: true, message: "Por favor ingresa el nombre de usuario!" }]}
        >
          <Input
            placeholder={piaFormLabels[selectedInsurance.key]?.username}
            autoComplete="off"
            prefix={<UserOutlined />}
          />
        </Form.Item>
      )}
      {piaFormLabels[selectedInsurance.key]?.usernameTwo && (
        <Form.Item
          name="usernameTwo"
          rules={[
            { required: true, message: "Por favor ingresa el código de acceso!" },
            {
              pattern: new RegExp(/^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/),
              message: "El código de acceso debe ser numérico",
            },
          ]}
        >
          <Input
            placeholder={piaFormLabels[selectedInsurance.key]?.usernameTwo}
            prefix={<UserOutlined />}
            autoComplete="off"
          />
        </Form.Item>
      )}
      {piaFormLabels[selectedInsurance.key]?.password && (
        <Form.Item
          name="password"
          rules={[
            { required: true, message: "Por favor ingresa la contraseña!" },
            { max: 15, message: "El tamaño no puede exceder 15 caracteres" },
          ]}
        >
          <Input.Password
            placeholder={piaFormLabels[selectedInsurance.key]?.password}
            prefix={<img src={candado} alt="candado" />}
            autoComplete="off"
          />
        </Form.Item>
      )}
      {piaFormLabels[selectedInsurance.key]?.password && (
        <Form.Item
          name="confirmPassword"
          rules={[
            { required: true, message: "Por favor confirme la contraseña!" },
            { max: 15, message: "El tamaño no puede exceder 15 caracteres" },
          ]}
        >
          <Input.Password
            placeholder="Confirmar contraseña"
            prefix={<img src={candado} alt="candado" />}
            autoComplete="off"
          />
        </Form.Item>
      )}

      {piaFormLabels[selectedInsurance.key]?.password2 && (
        <Form.Item
          className="form-item-tooltip"
          name="password2"
          rules={[
            { message: "Por favor ingresa la segunda contraseña!" },
            { max: 15, message: "El tamaño no puede exceder 15 caracteres" },
          ]}
        >
          <Input.Password
            placeholder={piaFormLabels[selectedInsurance.key]?.password2}
            prefix={<img src={candado} alt="candado" />}
            autoComplete="off"
          />
        </Form.Item>
      )}
      {passwordError && <p className="ant-form-item-explain-error">Las contraseñas no coinciden</p>}
      {!toEdit && (
        <Form.Item name="remember" valuePropName="checked">
          <Checkbox onChange={() => setTyc(!tyc)}>
            <a
              href="https://blog.berrysafe.com/politicas-de-privacidad/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Acepto los T&C, y Politicas de privacidad
            </a>
          </Checkbox>
        </Form.Item>
      )}
      <Button className="button button-primary" type="primary" htmlType="submit">
        {!toEdit ? <span>Autorizar acceso</span> : <span>Guardar cambios</span>}
      </Button>
    </Form>
  );
};

FormNewConection.propTypes = {
  setSection: func.isRequired,
  toEdit: instanceOf(Object),
  setEdit: func,
  selectedInsurance: instanceOf(Object).isRequired,
  mixPanel: instanceOf(Object),
  user: string,
};

FormNewConection.defaultProps = {
  toEdit: null,
  setEdit: null,
  mixPanel: null,
  user: null,
};

export default FormNewConection;
