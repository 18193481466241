/* eslint-disable react/prop-types */
/* eslint-disable import/order */
/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import { INACTIVITYTIME } from "../config/config";
import AuthActions from "services/auth/actions";

const InactivityTimer = ({ dispatch }) => {
  let timeoutID;
  const logout = () => dispatch(AuthActions.logout());
  const resetTimer = () => {
    clearTimeout(timeoutID);
    startTimer();
  };

  const startTimer = () => {
    timeoutID = setTimeout(() => {
      logout();
    }, INACTIVITYTIME || 1800000);
  };

  useEffect(() => {
    document.addEventListener("mousemove", resetTimer);
    document.addEventListener("keydown", resetTimer);
    startTimer();
    return () => {
      clearTimeout(timeoutID);
      document.removeEventListener("mousemove", resetTimer);
      document.removeEventListener("keydown", resetTimer);
    };
  }, []);

  return <></>;
};

export default InactivityTimer;
