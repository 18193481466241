import { instanceOf, string } from "prop-types";
import React from "react";
import car from "../../../../../../../assets/images/car-gray.svg";
import inf from "../../../../../../../assets/images/i.svg";
import PiaField from "../PiaField";

const SectionRiskData = ({ searchedHighlight, policy, highlightDictionary }) => {
  const dummyTable = [
    {
      id: 1,
      title: "Sevicio",
      text: (policy.FleetList?.length > 0 && policy.FleetList[0].RiskService) || "",
      dictionary: highlightDictionary.service,
    },
    {
      id: 2,
      title: "Uso",
      text: (policy.FleetList?.length > 0 && policy.FleetList[0].RiskUse) || "",
      dictionary: highlightDictionary.use,
    },
    {
      id: 3,
      title: "Clave de vehículo",
      text: (policy.FleetList?.length > 0 && policy.FleetList[0].RiskKey) || "",
      dictionary: highlightDictionary.password,
    },
  ];

  return (
    <div className="SectionSummaryPia">
      {policy.General[0].Branch !== "AUTOS" || policy.FleetList?.length <= 0 ? (
        <div className="empty">
          <span className="h2">Por el momento no tenemos registro de datos riesgo</span>
        </div>
      ) : (
        <div className="SectionSummaryPia--table">
          <div className="SectionSummaryPia--table__column data-risk">
            <div className="box box-placa">
              <img src={car} alt="car" className="car" />
            </div>
            <div className="box box-placa">
              <div className="placa">
                <p
                  className={
                    highlightDictionary.plate.includes(searchedHighlight) ? "highlight" : ""
                  }
                >
                  Número de Placa
                </p>
                <div className="button-placa">
                  <PiaField text={policy.FleetList[0].RiskPlaca} type="" />
                </div>
              </div>
            </div>
          </div>

          <div className="SectionSummaryPia--table__column data-risk-column">
            <div className="column">
              <div className="column--top">
                <p
                  className={
                    highlightDictionary.serie.includes(searchedHighlight)
                      ? "highlight number-serie"
                      : "number-serie"
                  }
                >
                  Número de serie
                </p>
                <PiaField text={policy.FleetList[0].RiskSerie} type="" />
              </div>
            </div>
            <div className="column">
              <div className="column--top">
                <p
                  className={
                    highlightDictionary.motor.includes(searchedHighlight) ? "highlight" : ""
                  }
                >
                  Número de motor
                </p>
                <PiaField text={policy.FleetList[0].RiskNumberMotor} type="" />
              </div>
            </div>
          </div>

          <div className="warnning-head">
            <img src={inf} alt="inf" className="inf" />
            <h6 className="small-detail">Descripción del vehículo</h6>
          </div>
          <h3 className="body-regular">
            <PiaField text={policy.FleetList[0].RiskDescription} type="" />
          </h3>
          <div className="SectionSummaryPia--table__column">
            {dummyTable?.map((item) => (
              <div key={item.id} className="item">
                <div className="item-title">
                  <p className={item.dictionary.includes(searchedHighlight) ? "highlight" : ""}>
                    {item.title}
                  </p>
                </div>
                <div className="item-rta">
                  <PiaField text={item.text} type="" />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

SectionRiskData.propTypes = {
  searchedHighlight: string.isRequired,
  policy: instanceOf(Object).isRequired,
  highlightDictionary: instanceOf(Object).isRequired,
};

export default SectionRiskData;
