/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from "react";
import { Button, Tabs } from "antd";
import PropTypes, { object, bool, string, func } from "prop-types";
import Loader from "components/loader/index";
import arrowSvg from "assets/images/arrow-rigth-green-line.svg";
import leafPng from "assets/images/leaf-svg.svg";
import AutoSvg from "assets/images/pia-brands/auto.svg";
import { useHistory } from "react-router";
import DamageSvg from "assets/images/pia-brands/damage.svg";
import GmmSvg from "assets/images/pia-brands/gmm-icon.svg";
import VidaSvg from "assets/images/vida.svg";
import AutoGreenSvg from "assets/images/icons-line-product-green/Autos-green-svg.svg";
import DamageGreenSvg from "assets/images/pia-brands/damage-green.svg";
import GmmGreenSvg from "assets/images/pia-brands/gmm-icon-green.svg";
import VidaGreenSvg from "assets/images/vida-green.svg";
import { useDispatch } from "react-redux";
import PoliciesActions from "services/policies/actions";
import ButtonListFilters from "components/Atoms/ButtonListFilters/ButtonListFilters";
import CardInsurance from "./CardInsurance";
import Policies from "../../../pia/components/Policies/Policies";
// import { FILE_VIEWER_HOST } from "common/config/config";

const { TabPane } = Tabs;

const SectionPolicy = ({ policiesCard, policiesStore, styleDark, insurances }) => {
  const [FilterActive, setFilterActive] = useState(0);
  const [selectedExpiration, setSelectedExpiration] = useState("");
  const [selectedProductLines, setSelectedProductLines] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const onChange = (key) => {
    console.log(key);
  };
  const dummyInsures = [
    { id: 1, cant: 70, icon: AutoSvg, iconGrren: AutoGreenSvg, title: "GMM" },
    { id: 2, cant: 70, icon: DamageSvg, iconGrren: DamageGreenSvg, title: "Autos" },
    { id: 3, cant: 70, icon: VidaSvg, iconGrren: GmmGreenSvg, title: "Daños" },
    { id: 4, cant: 70, icon: GmmSvg, iconGrren: VidaGreenSvg, title: "Vida" },
  ];

  return (
    <section className="SectionPolicy">
      <div className="SectionPolicy__head">
        <h2 className="mobile-h2">Pólizas</h2>
        {!policiesStore.success.searched && insurances && (
          <Policies
            getLast10={() => dispatch(PoliciesActions.getLastPolicies())}
            styleMode={styleDark}
            insurances={insurances}
            accounts={policiesStore.success.accounts}
            dummyProduct={dummyInsures}
            selectedExpiration={selectedExpiration}
            setSelectedExpiration={setSelectedExpiration}
            filteredAccounts={policiesStore.filters.IdentificadorCuenta}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            selectedProductLines={selectedProductLines}
            setSelectedProductLines={setSelectedProductLines}
          />
        )}
      </div>
      <div className="list-filters">
        {policiesStore?.filters?.IdentificadorCuenta?.length >= 1 &&
          policiesStore?.filters?.IdentificadorCuenta.map((item) =>
            policiesStore?.success?.accounts?.map(
              (data) =>
                data.id === item && (
                  <ButtonListFilters
                    item={data}
                    type="account"
                    setSelectedProductLines={setSelectedProductLines}
                    setSelectedExpiration={setSelectedExpiration}
                    selectedProductLines={selectedProductLines}
                    key={`account-${item}`}
                  />
                )
            )
          )}
        {policiesStore?.filters?.Estado?.length >= 1 &&
          policiesStore?.filters?.Estado.map((item) => (
            <ButtonListFilters
              item={item}
              type="state"
              setSelectedProductLines={setSelectedProductLines}
              setSelectedExpiration={setSelectedExpiration}
              selectedProductLines={selectedProductLines}
              key={`state-${item}`}
            />
          ))}
        {policiesStore?.filters?.Ramos?.length >= 1 &&
          policiesStore?.filters?.Ramos.map((item) => (
            <ButtonListFilters
              item={item}
              type="branch"
              setSelectedProductLines={setSelectedProductLines}
              setSelectedExpiration={setSelectedExpiration}
              selectedProductLines={selectedProductLines}
              key={`branch-${item}`}
            />
          ))}
        {selectedExpiration !== "" && selectedExpiration > 0 && (
          <ButtonListFilters
            item={`Próx.${selectedExpiration}D`}
            type="expiration"
            setSelectedProductLines={setSelectedProductLines}
            setSelectedExpiration={setSelectedExpiration}
            selectedProductLines={selectedProductLines}
          />
        )}
      </div>
      {policiesStore?.loading?.policies ? (
        <Loader />
      ) : (
        <>
          <Tabs defaultActiveKey="1" onChange={onChange}>
            <div className="flex">
              <span className="small-detail">
                {policiesCard?.length} de {policiesCard?.length}
              </span>
              <Button className="button-transparent" onClick={() => history.push("/pia/policies")}>
                Ver todas
              </Button>
            </div>
            <TabPane tab={`Por renovar (${policiesCard ? policiesCard?.length : 0})`} key="1">
              {policiesCard?.length <= 0 && (
                <div className="empty">
                  <img src={leafPng} className="leaf" alt="leaf" />
                  <p className="sub-title">No se encontraron pólizas</p>
                </div>
              )}
              {policiesCard && (
                <>
                  {policiesCard?.map((item) => (
                    <CardInsurance
                      key={item.id}
                      item={item}
                      FilterActive={FilterActive}
                      setFilterActive={setFilterActive}
                      styleDark={styleDark}
                    />
                  ))}
                  {policiesStore?.loading?.insideLoader ? (
                    <Loader className="loader-small" />
                  ) : (
                    <div className="btn-center">
                      {policiesCard?.length >= 30 && (
                        <Button
                          onClick={() => dispatch(PoliciesActions.setFilters("NoPage", 0))}
                          className="button-seemore"
                        >
                          Ver más
                        </Button>
                      )}
                    </div>
                  )}
                </>
              )}
            </TabPane>
            <TabPane tab="Cotizadas (0)" key="2">
              <div className="empty">
                <img src={leafPng} className="leaf" alt="leaf" />
                <p className="sub-title">No se encontraron pólizas</p>
              </div>
            </TabPane>
            <TabPane tab="Emitidas (0)" key="3">
              <div className="empty">
                <img src={leafPng} className="leaf" alt="leaf" />
                <p className="sub-title">No se encontraron pólizas</p>
              </div>
            </TabPane>
            <TabPane tab="Pagadas (0)" key="4">
              <div className="empty">
                <img src={leafPng} className="leaf" alt="leaf" />
                <p className="sub-title">No se encontraron pólizas</p>
              </div>
            </TabPane>
          </Tabs>
          <div className="SectionPolicy__responsive">
            <div className="content-r">
              <div className="left">
                <p className="small-detail">Por renovar</p>
                <p className="sub-title">{policiesCard?.length <= 0 ? 0 : policiesCard?.length}</p>
              </div>
              <div className="rigth">
                <Button className="button-transparent">
                  <img src={arrowSvg} className="arrowSvg" alt="arrow" />
                </Button>
              </div>
            </div>
            <div className="wrap">
              {dummyInsures?.map((item) => (
                <div className="wrap-item">
                  <div className="circle">
                    <img className="img" src={styleDark ? item.iconGrren : item.icon} alt="icon" />
                  </div>
                  <span className="small-detail">{item.cant}%</span>
                </div>
              ))}
            </div>
            <div className="content-r">
              <div className="left">
                <p className="small-detail">Ofertas</p>
                <p className="sub-title">0</p>
              </div>
              <div className="rigth">
                <Button
                  className="button-transparent"
                  onClick={() => history.push("/pia/policies")}
                >
                  <img src={arrowSvg} className="arrowSvg" alt="arrow" />
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

SectionPolicy.propTypes = {
  policiesCard: PropTypes.instanceOf(object).isRequired,
  policiesStore: PropTypes.instanceOf(object).isRequired,
  insurances: PropTypes.instanceOf(object).isRequired,
  styleDark: bool.isRequired,
};

ButtonListFilters.propTypes = {
  item: string.isRequired,
  type: string.isRequired,
  setSelectedProductLines: func.isRequired,
  setSelectedExpiration: func.isRequired,
  selectedProductLines: PropTypes.instanceOf(object).isRequired,
};

export default SectionPolicy;
