/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import Chart from "react-apexcharts";

class TableLinePoint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          height: 350,
          type: "line",
        },
        stroke: {
          width: 7,
          curve: "smooth",
        },
        xaxis: {
          categories: ["Bimestral", "Mensual", "Trimestral", "Semestral", "Contado"],
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            gradientToColors: ["#7F14FC"],
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 0, 0, 0],
          },
        },
        markers: {
          size: 7,
          colors: ["#4B3A7C"],
          strokeColors: "#fff",
          strokeWidth: 2,
          hover: {
            size: 9,
          },
        },
        yaxis: {
          min: 0,
          max: 15,
          title: {
            text: "",
          },
        },
      },
      xaxis: {
        type: "datetime",
      },
      stroke: {
        curve: "smooth",
      },
      series: [
        {
          name: "Pagos",
          data: [0, 0, 0, 0, 0],
        },
      ],
    };
  }

  render() {
    return (
      <div className="chartPoint chartPoint--one">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={this.state.options}
              series={this.state.series}
              stroke={this.state.stroke}
              type="line"
              width={this.props.width}
              className="chart-point"
              height={this.props.height}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TableLinePoint;
