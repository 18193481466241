/* eslint-disable import/no-cycle */
import ApiConnection from "common/api/Api";
import parseError from "common/api/ErrorParser";
import * as Token from "common/storage/Token";
import { all, put, takeLatest } from "redux-saga/effects";
import AccountActions from "./actions";

function* updateEmail({ payload }) {
  const url = "/agent/update-email";
  const response = yield ApiConnection.post(url, { email: payload });
  if (!response.success)
    return yield put(AccountActions.updateEmailResponse(parseError(response), response));

  yield Token.save(response.detail, "@tokenAGENT");
  return yield put(AccountActions.updateEmailResponse());
}

function* updatePhone({ payload }) {
  const url = "/agent/update-contact";
  const response = yield ApiConnection.post(url, { phone: payload });
  if (!response.success)
    return yield put(AccountActions.updatePhoneResponse(parseError(response), response));

  return yield put(AccountActions.updatePhoneResponse());
}

function* setSchedule({ payload }) {
  const parsedSchedule = payload.schedule
    .map((scheduleRows) =>
      scheduleRows.days.map((day) => ({
        day,
        startTime: scheduleRows.startTime,
        endTime: scheduleRows.endTime,
      }))
    )
    .flatMap((day) => day);
  const url = "/schedule";
  const response = yield ApiConnection.post(url, { schedules: parsedSchedule });
  if (!response.success)
    return yield put(AccountActions.setScheduleResponse(parseError(response), response));
  yield put(AccountActions.getSchedule());
  return yield put(AccountActions.setScheduleResponse(payload.schedule));
}

function* getSchedule() {
  const url = "/schedule";
  const response = yield ApiConnection.get(url);
  if (response.success)
    return yield put(
      AccountActions.getScheduleResponse({
        schedules: response.detail,
      })
    );

  const err = new TypeError(response?.status ? response.message : "UNDEFINED_ERROR");
  return yield put(AccountActions.getScheduleResponse(err, response));
}

function* updateEndPoint({ payload }) {
  const url = "/agent/update-endpoint";
  const response = yield ApiConnection.post(url, { endPoint: payload });
  if (!response.success)
    return yield put(AccountActions.updateEndPointResponse(parseError(response), response));

  return yield put(AccountActions.updateEndPointResponse());
}

function* ActionWatcher() {
  yield takeLatest(AccountActions.updateEmail, updateEmail);
  yield takeLatest(AccountActions.updatePhone, updatePhone);
  yield takeLatest(AccountActions.updateEndPoint, updateEndPoint);
  yield takeLatest(AccountActions.setSchedule, setSchedule);
  yield takeLatest(AccountActions.getSchedule, getSchedule);
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
