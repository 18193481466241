/* eslint-disable no-unneeded-ternary */
import { Select } from "antd";
import { instanceOf } from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PoliciesActions from "services/policies/actions";
import clock from "assets/images/pia-brands/Espera.png";
import failed from "assets/images/pia-brands/failed.svg";
import like from "assets/images/pia-brands/Listo.png";
import warningGray from "assets/images/pia-brands/warningGray.svg";
import Loader from "components/loader";

const { Option } = Select;

const SelectChangeStatusPolicy = ({ policy }) => {
  const [isPolicySelected, setPolicySelected] = useState();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.policies);

  const handleChange = (value) => {
    if (value) {
      dispatch(PoliciesActions.updateStatusPolicy(isPolicySelected, value));
    }
  };
  return loading.updateStatusPolicy ? (
    <div className="loader-not-padding">
      <Loader className="loader-small" />
    </div>
  ) : (
    <div className="select__status--global">
      <Select
        value={policy.status ? policy.status : policy.General[0].StatusPolicy}
        onChange={handleChange}
        onClick={() =>
          setPolicySelected(policy.number ? policy.number : policy.General[0].NumPolicy)
        }
      >
        <Option value="En vigor">
          <div className="option-row-status">
            <img src={clock} alt="clock" className="clock" />
            <p className="body-bold vigor">En Vigor</p>
          </div>
        </Option>
        <Option value="Cotizada">
          <div className="option-row-status">
            <img src={warningGray} alt="clock" className="clock" />
            <p className="body-bold cancelada">Cotizada</p>
          </div>
        </Option>
        <Option value="Por cotizar">
          <div className="option-row-status">
            <img src={failed} alt="clock" className="clock" />
            <p className="body-bold vencida">Por Cotizar</p>
          </div>
        </Option>
        <Option value="Renovada">
          <div className="option-row-status">
            <img src={like} alt="clock" className="clock" />
            <p className="body-bold pagada">Renovada</p>
          </div>
        </Option>
      </Select>
    </div>
  );
};

SelectChangeStatusPolicy.propTypes = {
  policy: instanceOf(Object).isRequired,
};

export default SelectChangeStatusPolicy;
