/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
import { Card, message, Tooltip } from "antd";
import AlertSvg from "assets/icon/alert.svg";
import BigLikeSvg from "assets/icon/biglike.svg";
import ClockSvg from "assets/icon/clock.svg";
import PreviewSvg from "assets/images/preview.svg";
import { CLIENT_HOST } from "common/config/config";
import { changeRoute } from "common/utils/changeRoute";
import openMyPage from "common/utils/openMyPage";
import ModalPublishment from "components/Modals/Publishment/ModalPublishment";
import React, { useEffect, useState } from "react";
import { CircleSlider } from "react-circle-slider";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import levelSvg from "../../../../../../../assets/icon/icon-level.svg";
import starSvg from "../../../../../../../assets/icon/icon-star.svg";
import Loader from "../../../../../../../components/loader";
import AgentActions from "../../../../../../../services/agent/actions";
import EditMyPageActions from "../../services/actions";
import RankingCard from "./RankingCard";
import Tip from "./tip";

const dataDummy = [
  {
    title: "BerryMaster",
    description: "Has registrado ventas efectivas con Berrysafe",
  },
  {
    title: "BerryBright",
    description: "Ya tienes productos únicos Berrysafe en tu portafolio de servicios",
  },
  {
    title: "BerrySmart",
    description: "Completaste tu perfil exitosamente",
  },
  {
    title: "BerryNice",
    description: "Completaste un registro básico de datos en la plataforma",
  },
];

const Ranking = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [ranking, setRanking] = useState(0);
  const [publishModalData, setPublishModalData] = useState(undefined);

  const agent = useSelector((state) => state.agent);
  const onboarding = useSelector((state) => state.onboarding);

  useEffect(() => {
    if (!agent.success.tips && !agent.error.tips && !agent.loading.tips)
      dispatch(AgentActions.getTips());
  }, [agent, dispatch, t]);

  useEffect(() => {
    if (agent.success.tips) {
      const quantity = agent.success.tips.length + 2;
      let completed = 0;
      for (const tip of agent.success.tips) if (onboarding.success.profile[tip.column]) completed++;
      if (onboarding?.success?.profile?.agentProductLines?.length > 0) completed++;
      if (onboarding?.success?.profile?.agentProducts?.length > 0) completed++;

      const total = (100 / quantity) * completed;

      setRanking(total);
    }
  }, [onboarding.success.profile, agent.success.tips]);

  useEffect(() => {
    if (agent.success.publish) {
      const goodpublishData = {
        title: "¡Tu página se publicó exitosamente!",
        textContent: "Ya puedes revisar tu perfil",
        button: (
          <a
            href={`${CLIENT_HOST}agente/${onboarding.success.profile?.endPoint}`}
            onClick={() => {
              setPublishModalData(undefined);
            }}
            className="btn btn--succes link-dash"
          >
            Ver tu página
          </a>
        ),
        image: BigLikeSvg,
        isPublish: true,
      };
      setPublishModalData(goodpublishData);
      dispatch(AgentActions.clearPublish());
    }
    if (agent.error.publish) {
      message.error(t(`error.${agent.error.publish}`));
      dispatch(AgentActions.clearPublish());
    }
  }, [agent.success.publish, agent.error.publish, dispatch, t, onboarding]);

  if (agent.loading.tips) return <Loader />;

  const publishMyPage = () => {
    const tipState =
      agent.success.tips?.filter((tip) => !onboarding.success.profile[tip.column]).length > 0;

    let publishData;

    switch (onboarding.success.profile?.user.userType) {
      case "migrated":
        if (tipState) {
          publishData = {
            title: t("label.YourPageNeedsUpdated"),
            textContent: t("label.textMigrate"),
            button: (
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setPublishModalData(null);
                }}
                className="btn btn--succes link-dash"
              >
                {t("button.continue")}
              </a>
            ),
            image: AlertSvg,
          };
        } else {
          dispatch(AgentActions.publish());
        }
        return setPublishModalData(publishData);

      case "regular":
        if (onboarding.success.profile?.publishPermission !== "Active") {
          publishData = {
            title: t("label.titleRegular"),
            textContent: t("label.textRegular"),
            button: (
              <a
                href="/."
                onClick={(e) => {
                  e.preventDefault();
                  setPublishModalData(false);
                }}
                className="btn btn--succes link-dash"
              >
                {t("button.continue")}
              </a>
            ),
            image: ClockSvg,
          };
          return setPublishModalData(publishData);
        }
        if (tipState) {
          publishData = {
            title: t("label.titleRegular2"),
            textContent: t("label.textRegular2"),
            button: (
              <a
                href="/"
                onClick={() => {
                  setPublishModalData(null);
                }}
                className="btn btn--succes link-dash"
              >
                {t("label.GoToDashboard")}
              </a>
            ),
            image: AlertSvg,
          };
          return setPublishModalData(publishData);
        }
        return dispatch(AgentActions.publish());

      default:
        break;
    }
    return null;
  };

  return (
    <section className="ranking">
      <Card className="ranking-card">
        <div className="ranking-page-actions">
          {(onboarding.success.profile?.onBoarding || onboarding.omitForNow) && (
            <>
              {!onboarding.success.profile?.onBoarding ||
              ((!onboarding.success.profile?.agentProducts ||
                onboarding.success.profile?.agentProducts?.length) < 1 &&
                (!onboarding.success.profile?.agentProductLines ||
                  onboarding.success.profile?.agentProductLines?.length < 1)) ? (
                <Tooltip placement="topLeft" title={t("error.publishError")}>
                  <button
                    type="button"
                    id="edit-page-button-header"
                    onClick={() => {}}
                    className="btn-custom btn-custom--preview btn-custom--preview--disabled"
                    style={{ color: "#5d30dd" }}
                  >
                    <span>{t("label.previewMyPage")}</span>
                    <img src={PreviewSvg} className="preview-header" alt="preview" />
                  </button>
                </Tooltip>
              ) : (
                <button
                  type="button"
                  id="preview-button-header"
                  onClick={() => openMyPage(onboarding.success.profile?.endPoint)}
                  className="btn-custom btn-custom--preview"
                >
                  <img src={PreviewSvg} className="preview-header" alt="preview" />
                  <span>{t("label.previewMyPage")}</span>
                </button>
              )}
            </>
          )}
          {(onboarding.success.profile?.onBoarding || onboarding.omitForNow) && (
            <>
              <button
                type="button"
                id="publishMyPage-button-header"
                onClick={() => publishMyPage()}
                className="btn-custom btn-custom--secundary"
              >
                {t("label.publishMyPage")}
              </button>
            </>
          )}
        </div>
        {agent.success.tips?.filter((tip) => !onboarding.success.profile[tip.column]).length > 0 ? (
          <>
            <div className="ranking-card__complete">
              <div className="ranking-card__completeWrapper">
                <div className="ranking-card__completeWrapper__text">
                  {agent.success.tips && (
                    <h5>
                      {
                        agent.success.tips.find((tip) => !onboarding.success.profile[tip.column])
                          ?.name
                      }
                    </h5>
                  )}
                </div>
              </div>
              <div className="ranking-card__completeGo">
                <button
                  type="button"
                  onClick={() => dispatch(EditMyPageActions.changeEditStatus("myData"))}
                >
                  {t("label.complete")}
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            {onboarding.success.profile?.agentProducts?.length < 1 ? (
              <RankingCard
                desc="Selecciona al menos un producto del Marketplace Berrysafe"
                completeAction={() => changeRoute("marketplace", history, dispatch)}
                t={t}
              />
            ) : (
              <>
                {onboarding.success.profile?.agentProductLines?.length < 1 && (
                  <RankingCard
                    desc="Selecciona al menos un ramo para mostrar en tu perfil"
                    completeAction={() =>
                      window.scroll({
                        top: 100,
                        left: 100,
                        behavior: "smooth",
                      })
                    }
                    t={t}
                  />
                )}
              </>
            )}
          </>
        )}
        <div className="ranking-card__level" id="information">
          <div className="ranking-card__title">
            <h4>{t("label.myBerryRanking")}</h4>
          </div>
          <div className="ranking-card__levelWrapper">
            <div className="ranking-card__levelWrapper__ranking">
              <CircleSlider
                value={ranking}
                size={118}
                knobRadius={0}
                progressWidth={6}
                circleWidth={6}
                circleColor="#ffffff"
                tooltipColor="#4B3A7C"
                gradientColorFrom="#7B3BF9"
                gradientColorTo="#00DBD0"
                showTooltip
                tooltipSize={33}
                disabled
              />
            </div>
            <div className="ranking-card__levelWrapper__text">
              <h5>BerrySmart</h5>
              <div className="level">
                <img src={levelSvg} alt="" />
                <p>{t("label.YourCurrentLevel")}</p>
              </div>
              <span>{t("label.NextLevel")}: BerryMaster</span>
            </div>
          </div>
          <div className="ranking-card__levelInfo">
            <h5>{t("label.AgentLevels")} Berrysafe</h5>
            <p>{t("label.CompleteTheSteps")}</p>
          </div>
          <div className="ranking-card__levelTips">
            {dataDummy?.map((tips, index) => (
              <div className={index >= dataDummy.length - 1 ? "last-tip" : "tip"} key={tips.title}>
                <img src={starSvg} alt="" />
                <div className="tip__info">
                  <h6>{tips.title}</h6>
                  <p>{tips.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="ranking-card__items">
          {agent.success.tips?.filter((tip) => !onboarding.success.profile[tip.column]).length >
            0 && (
            <>
              {agent.success.tips
                ?.filter((tip) => !onboarding.success.profile[tip.column])
                ?.map((tip) => (
                  <Tip
                    key={`Tip-key-${tip.id}`}
                    tip={tip}
                    t={t}
                    action={() => dispatch(EditMyPageActions.changeEditStatus("myData"))}
                  />
                ))}
            </>
          )}
          {onboarding.success.profile?.agentProducts?.length < 1 && (
            <Tip
              tip={{
                name: "Agregar mis productos",
                id: "999",
              }}
              t={t}
              action={() => changeRoute("marketplace", history, dispatch)}
            />
          )}
        </div>
      </Card>
      {publishModalData && (
        <ModalPublishment
          image={publishModalData.image}
          setVisible={() => setPublishModalData(null)}
          visible
          title={publishModalData.title}
          textContent={publishModalData.textContent}
          button_succes={publishModalData.button}
          isPublish={publishModalData.isPublish || false}
        />
      )}
    </section>
  );
};

export default Ranking;
