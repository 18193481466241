import { createActions } from "redux-actions";

const { agent } = createActions({
  AGENT: {
    SAVE_MAIN_INFO: (
      name,
      tagline,
      instagram,
      facebook,
      twitter,
      description,
      image,
      linkedin,
      office
    ) => ({
      name,
      tagline,
      instagram,
      facebook,
      twitter,
      description,
      image,
      linkedin,
      office,
    }),
    SAVE_MAIN_INFO_RESPONSE: () => ({}),
    CLEAN_SAVE_MAIN_INFO_RESPONSE: () => ({}),

    ORDER_INSURANCE_CARRIERS: (source, destination) => ({
      source,
      destination,
    }),

    MODIFY_ORDER: (newInsurance) => newInsurance,

    GET_INSURANCE_CARRIERS: () => ({}),
    GET_INSURANCE_CARRIERS_RESPONSE: (insuranceCarriers) => insuranceCarriers,

    SET_PROFILE_PHOTO: (data) => data,
    SET_PROFILE_PHOTO_RESPONSE: () => ({}),

    GET_TIPS: () => ({}),
    GET_TIPS_RESPONSE: (tips) => tips,

    GET_LAYOUT_PRODUCTS: () => ({}),
    GET_LAYOUT_PRODUCTS_RESPONSE: (layoutProducts) => layoutProducts,

    SET_SELECTED_LAYOUT: (layoutKey) => layoutKey,
    SET_SELECTED_LAYOUT_RESPONSE: () => ({}),
    CLEAR_RESPONSE_LAYOUT_PRODUCTS: () => ({}),

    UPDATE_INSURANCE_CARRIERS: () => ({}),
    UPDATE_INSURANCE_CARRIERS_RESPONSE: () => ({}),

    SET_ACTUAL_INSURANCE_CARRIERS_ORDERED: (insuranceCarriers) => insuranceCarriers,

    CREATE_OFFICE: ({
      name,
      address,
      zipcode,
      city,
      country,
      areaLevel,
      latitude,
      longitude,
      contact,
    }) => ({
      name,
      address,
      zipcode,
      city,
      country,
      areaLevel,
      contact,
      latitude: `${latitude}`,
      longitude: `${longitude}`,
    }),
    CREATE_OFFICE_RESPONSE: () => ({}),
    CLEAR_CREATE_OFFICE: () => ({}),

    UPDATE_OFFICE: ({ id, name, address, zipcode, city, country, areaLevel, contact }) => ({
      id,
      name,
      address,
      zipcode,
      city,
      country,
      areaLevel,
      contact,
    }),
    UPDATE_OFFICE_RESPONSE: () => ({}),
    CLEAR_UPDATE_OFFICE: () => ({}),

    GET_MENU: () => ({}),
    GET_MENU_RESPONSE: (menu) => menu,

    SEND_GOOGLE_LOCATION: (location) => location,
    SEND_GOOGLE_LOCATION_RESPONSE: () => ({}),
    CLEAR_GOOGLE_LOCATION: () => ({}),

    SEND_GMB_VERIFICATION_CODE: (office) => office,
    SEND_GMB_VERIFICATION_CODE_RESPONSE: () => ({}),

    SELECT_PRODUCT_LINE: (productLine) => productLine.id,

    ORDER_PRODUCT_LINES: (source, destination) => ({ source, destination }),

    CHANGE_PRODUCT_LINE_STATE: (productLine) => productLine,

    GET_PRODUCT_LINES: () => ({}),
    GET_PRODUCT_LINES_RESPONSE: (productLines) => productLines,
    CLEAR_GET_PRODUCT_LINES_RESPONSE: () => ({}),

    SAVE_PRODUCT_LINES: () => ({}),
    SAVE_PRODUCT_LINES_RESPONSE: () => ({}),
    CLEAR_PRODUCT_LINES_RESPONSE: () => {},

    CLEAR_INSURANCE_CARRIER_UPDATED: () => {},

    CHANGE_SHOW: (office) => office,
    CHANGE_SHOW_RESPONSE: () => ({}),

    UPDATE_EMAIL: (email) => email,
    UPDATE_EMAIL_RESPONSE: () => ({}),
    CLEAR_UPDATE_EMAIL: () => ({}),

    UPDATE_PHONE: (phone) => phone,
    UPDATE_PHONE_RESPONSE: () => ({}),
    CLEAR_UPDATE_PHONE: () => ({}),

    PUBLISH: (endPoint) => endPoint,
    PUBLISH_RESPONSE: () => ({}),
    CLEAR_PUBLISH: () => ({}),

    DELETE_OFFICE: (id) => id,
    DELETE_OFFICE_RESPONSE: () => ({}),
    CLEAR_DELETE_OFFICE: () => ({}),

    CLEAR_CHANGE_SHOW: () => {},

    UPDATE_TUTORIAL_SHOWN: () => {},

    UPDATE_FIREBASE_TOKEN: (token) => token,

    SET_AUTO_INSURANCE: (insurance) => insurance,

    SET_AUTO_PUBLISH: () => ({}),

    SET_PUBLISH_DATA: (data) => data,
  },
});

export default agent;
