import { Modal } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import Loader from "../../../components/loader";
import EmailUpdate from "./components/EmailUpdate/EmailUpdate";
import EndPointUpdate from "./components/EndPointUpdate/EndPointUpdate";
import PasswordUpdate from "./components/PasswordUpdate/PasswordUpdate";
import PhoneUpdate from "./components/PhoneUpdate/PhoneUpdate";
import AgentAvailability from "./components/AgentAvailability/AgentAvailability";

const Account = () => {
  const onboarding = useSelector((state) => state.onboarding);
  const account = useSelector((state) => state.account);

  return (
    <div className="my-account">
      <div className="my-account__content">
        <Modal
          className="modal-loader"
          visible={onboarding.loading.profile || account.loading.schedules}
          centered
          maskStyle={{ backgroundColor: "#ffffff66" }}
        >
          <Loader />
        </Modal>
        <h1 className="my-account__content--title">Tu cuenta</h1>
        <EmailUpdate email={onboarding.success.profile?.user.email} />
        <PhoneUpdate phone={onboarding.success.profile?.user.person?.contacts[0]?.value} />
        <PasswordUpdate />
        <EndPointUpdate endPoint={onboarding.success.profile?.endPoint} />
        <AgentAvailability />
      </div>
    </div>
  );
};
export default Account;
