import { Button, Spin } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import OnboardingActions from "../../../../../../../services/onboarding/actions";
import "./insuranceCarrier.scss";

const InsuranceCarrier = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onboarding = useSelector((state) => state.onboarding);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    rows: 5,
  };

  useEffect(() => {
    if (
      !onboarding.loading.insuranceCarrier &&
      !onboarding.success.insuranceCarriers &&
      !onboarding.error.insuranceCarrier
    ) {
      dispatch(OnboardingActions.getInsuranceCarriers());
    }
  }, [dispatch, onboarding]);

  const setInsuranceCarrier = (license) => {
    dispatch(OnboardingActions.setInsuranceCarrier(license.id, !license.isSelected));
  };

  return (
    <div className="insuranceCarrier animate__fadeInLeft  animate__animated">
      <div className="insuranceCarrier__text">
        <h4>Selecciona las aseguradoras con las que trabajas</h4>
        <p>{t("label.selectOneOrMore")}</p>
      </div>
      <div className="insuranceCarrier__wrapper">
        {onboarding.loading.insuranceCarrier && <Spin />}
        {/* {onboarding.success.insuranceCarriers && onboarding.success.insuranceCarriers.map(insuranceCarrier =>
          (
            <Button
              className={`insuranceCarrier${insuranceCarrier.isSelected
                ? '--active' : '--disabled'}`}
              key={insuranceCarrier.id}
              onClick={() => setInsuranceCarrier(insuranceCarrier)}>
              {insuranceCarrier.name + ''}
            </Button>)
        )} */}
        <div className="insuranceCarrier__wrapperButtons">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Slider {...settings}>
            {onboarding.success.insuranceCarriers &&
              onboarding.success.insuranceCarriers.map((insuranceCarrier) => (
                <Button
                  className={`button-brand insuranceCarrier${
                    insuranceCarrier.isSelected ? "--active" : "--disabled"
                  }`}
                  key={insuranceCarrier.id}
                  onClick={() => setInsuranceCarrier(insuranceCarrier)}
                >
                  <img
                    alt={insuranceCarrier.key}
                    src={
                      insuranceCarrier.isSelected
                        ? insuranceCarrier.logoWhite
                        : insuranceCarrier.logo
                    }
                  />
                </Button>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default InsuranceCarrier;
