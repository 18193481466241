import promoacheWSvg from "assets/images/ache-white.svg";
import amasfacWSvg from "assets/images/amasfac-white.svg";
import fortisWSvg from "assets/images/fortis-white.svg";
import amasfacSvg from "assets/images/logo-amasfac.svg";
import fortisSvg from "assets/images/logo-fortis.svg";
import promoacheSvg from "assets/images/logo-promoache.svg";
import { func, string } from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import OnboardingActions from "services/onboarding/actions";
import "./belongAssociation.scss";

const BelongAssociation = ({ activeGoNext, setSelectedSponsor, selectedSponsor }) => {
  const dispatch = useDispatch();

  const setSponsor = (isSponsor) => {
    dispatch(OnboardingActions.setIsSponsor(isSponsor));
    activeGoNext();
    setSelectedSponsor(isSponsor);
  };

  const listSponsors = [
    { id: 1, name: "Amasfac", image: amasfacSvg, imageW: amasfacWSvg, key: "amasfac" },
    { id: 2, name: "Ache", image: promoacheSvg, imageW: promoacheWSvg, key: "ache" },
    { id: 3, name: "Fortis", image: fortisSvg, imageW: fortisWSvg, key: "fortis" },
    { id: 4, name: "Otro", key: "other" },
  ];

  return (
    <div className="belongAssociation">
      <div className="belongAssociation__text">
        <h4>¿Cómo llegaste a nosotros?</h4>
        <p>Si eliges otro, es ne cesario ingresar tu número de cedula en el próximo paso</p>
      </div>
      <div className="belongAssociation__buttons animate__fadeInLeft  animate__animated">
        {listSponsors?.map((item) => (
          <button
            key={item.id}
            className={selectedSponsor === item.key ? "active" : "disabled"}
            onClick={() => setSponsor(item.key)}
            type="button"
          >
            {item.image ? (
              <img
                src={selectedSponsor === item.key ? item.imageW : item.image}
                className="image"
                alt="sponsor"
              />
            ) : (
              item.name
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

BelongAssociation.propTypes = {
  activeGoNext: func.isRequired,
  selectedSponsor: string.isRequired,
  setSelectedSponsor: func.isRequired,
};

export default BelongAssociation;
