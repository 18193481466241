import { handleActions } from "redux-actions";

export const INITIAL_STATE = {
  loading: {
    leads: false,
    recentLeads: false,
    suggestedProducts: false,
    activities: false,
    deleteActivity: false,
    createActivity: false,
    notes: false,
    createNote: false,
    deleteNote: false,
    leadDetail: false,
    changeStatus: false,
    productLines: false,
    origins: false,
  },
  error: {
    leads: undefined,
    recentLeads: undefined,
    suggestedProducts: undefined,
    activities: undefined,
    deleteActivity: undefined,
    createActivity: undefined,
    notes: undefined,
    createNote: undefined,
    deleteNote: undefined,
    leadDetail: undefined,
    changeStatus: undefined,
    productLines: undefined,
    origins: undefined,
  },
  success: {
    leads: undefined,
    total: undefined,
    recentLeads: undefined,
    suggestedProducts: undefined,
    activities: undefined,
    deleteActivity: undefined,
    createActivity: undefined,
    notes: undefined,
    createNote: undefined,
    deleteNote: undefined,
    productLines: undefined,
    leadDetail: undefined,
    changeStatus: undefined,
    origins: undefined,
  },
};

const reducer = handleActions(
  {
    LEADS: {
      GET_MY_LEADS: (state) => ({
        ...state,
        loading: { ...state.loading, leads: true },
        error: { ...state.error, leads: undefined },
      }),
      GET_MY_LEADS_RESPONSE: {
        next(state, { payload: { leads, total } }) {
          return {
            ...state,
            loading: { ...state.loading, leads: false },
            success: { ...state.success, leads, total },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, leads: message },
            success: { ...state.success, leads: undefined },
            loading: { ...state.loading, leads: false },
          };
        },
      },

      GET_RECENT_LEADS: (state) => ({
        ...state,
        loading: { ...state.loading, recentLeads: true },
        error: { ...state.error, recentLeads: undefined },
      }),
      GET_RECENT_LEADS_RESPONSE: {
        next(state, { payload: { recentLeads } }) {
          return {
            ...state,
            loading: { ...state.loading, recentLeads: false },
            success: { ...state.success, recentLeads },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, recentLeads: message },
            success: { ...state.success, recentLeads: undefined },
            loading: { ...state.loading, recentLeads: false },
          };
        },
      },

      GET_SUGGESTED_PRODUCTS: (state) => ({
        ...state,
        loading: { ...state.loading, suggestedProducts: true },
        error: { ...state.error, suggestedProducts: undefined },
      }),
      GET_SUGGESTED_PRODUCTS_RESPONSE: {
        next(state, { payload: { suggestedProducts } }) {
          return {
            ...state,
            loading: { ...state.loading, suggestedProducts: false },
            success: { ...state.success, suggestedProducts },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, suggestedProducts: message },
            success: { ...state.success, suggestedProducts: undefined },
            loading: { ...state.loading, suggestedProducts: false },
          };
        },
      },

      GET_ACTIVITIES: (state) => ({
        ...state,
        loading: { ...state.loading, activities: true },
        error: { ...state.error, activities: undefined },
      }),
      GET_ACTIVITIES_RESPONSE: {
        next(state, { payload: { activities } }) {
          return {
            ...state,
            loading: { ...state.loading, activities: false },
            success: { ...state.success, activities },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, activities: message },
            success: { ...state.success, activities: undefined },
            loading: { ...state.loading, activities: false },
          };
        },
      },

      DELETE_ACTIVITY: (state) => ({
        ...state,
        loading: { ...state.loading, deleteActivity: true },
        error: { ...state.error, deleteActivity: undefined },
      }),

      DELETE_ACTIVITY_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, deleteActivity: false },
            success: { ...state.success, deleteActivity: true },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, deleteActivity: message },
            success: { ...state.success, deleteActivity: undefined },
            loading: { ...state.loading, deleteActivity: false },
          };
        },
      },

      CREATE_ACTIVITY: (state) => ({
        ...state,
        loading: { ...state.loading, createActivity: true },
        error: { ...state.error, createActivity: undefined },
      }),
      CREATE_ACTIVITY_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, createActivity: false },
            success: { ...state.success, createActivity: true },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, createActivity: message },
            success: { ...state.success, createActivity: undefined },
            loading: { ...state.loading, createActivity: false },
          };
        },
      },

      GET_NOTES: (state) => ({
        ...state,
        loading: { ...state.loading, notes: true },
        error: { ...state.error, notes: undefined },
      }),
      GET_NOTES_RESPONSE: {
        next(state, { payload: { notes } }) {
          return {
            ...state,
            loading: { ...state.loading, notes: false },
            success: { ...state.success, notes },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, notes: message },
            success: { ...state.success, notes: undefined },
            loading: { ...state.loading, notes: false },
          };
        },
      },

      CREATE_NOTE: (state) => ({
        ...state,
        loading: { ...state.loading, createNote: true },
        error: { ...state.error, createNote: undefined },
      }),
      CREATE_NOTE_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, createNote: false },
            success: { ...state.success, createNote: true },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, createNote: message },
            success: { ...state.success, createNote: undefined },
            loading: { ...state.loading, createNote: false },
          };
        },
      },
      DELETE_NOTE: (state) => ({
        ...state,
        loading: { ...state.loading, deleteActivity: true },
        error: { ...state.error, deleteActivity: undefined },
      }),
      DELETE_NOTE_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, deleteNote: false },
            success: { ...state.success, deleteNote: true },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, deleteNote: message },
            success: { ...state.success, deleteNote: undefined },
            loading: { ...state.loading, deleteNote: false },
          };
        },
      },
      GET_LEAD_DETAIL: (state) => ({
        ...state,
        loading: { ...state.loading, leadDetail: true },
        error: { ...state.error, leadDetail: undefined },
      }),
      GET_LEAD_DETAIL_RESPONSE: {
        next(state, { payload: { detail } }) {
          return {
            ...state,
            loading: { ...state.loading, leadDetail: false },
            success: { ...state.success, leadDetail: detail },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, leadDetail: message },
            success: { ...state.success, leadDetail: undefined },
            loading: { ...state.loading, leadDetail: false },
          };
        },
      },

      CHANGE_STATUS: (state) => ({
        ...state,
        loading: { ...state.loading, changeStatus: true },
        error: { ...state.error, changeStatus: undefined },
      }),
      CHANGE_STATUS_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, changeStatus: false },
            success: { ...state.success, changeStatus: true },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, changeStatus: message },
            success: { ...state.success, changeStatus: undefined },
            loading: { ...state.loading, changeStatus: false },
          };
        },
      },
      CLEAR_CHANGE_STATUS: (state) => ({
        ...state,
        success: { ...state.success, changeStatus: undefined },
      }),

      GET_PRODUCT_LINES: (state) => ({
        ...state,
        loading: { ...state.loading, productLines: true },
        error: { ...state.error, productLines: undefined },
      }),
      GET_PRODUCT_LINES_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, productLines: false },
            success: { ...state.success, productLines: payload },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, productLines: message },
            success: { ...state.success, productLines: undefined },
            loading: { ...state.loading, productLines: false },
          };
        },
      },
      GET_ORIGINS: (state) => ({
        ...state,
        loading: { ...state.loading, origins: true },
        error: { ...state.error, origins: undefined },
      }),
      GET_ORIGINS_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, origins: false },
            success: { ...state.success, origins: payload },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, origins: message },
            success: { ...state.success, origins: undefined },
            loading: { ...state.loading, origins: false },
          };
        },
      },
    },
  },
  INITIAL_STATE
);

export default reducer;
