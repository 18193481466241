import { Col, Row } from "antd";
import { func, instanceOf } from "prop-types";
import TweenOne from "rc-tween-one";
import React, { useState } from "react";
import Detail from "./activity/detail";
import Notes from "./activity/notes";

const animation = {
  duration: 300,
  opacity: 1,
  top: "10px",
  easy: "easeOutExpo",
};

function Activities({ lead, setActivityOpen, activity }) {
  const [reverse, setReverse] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const handlerHidden = () => {
    setReverse(true);
    setTimeout(() => setActivityOpen(false), 500);
  };

  return (
    <TweenOne animation={animation} className="activity" reverse={reverse}>
      <div className="activity-content">
        <Row style={{ minHeight: "100%" }}>
          <Col span={16} style={{ padding: "20px" }}>
            {activity ? (
              <Notes
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                activity={activity}
                hidden={handlerHidden}
              />
            ) : (
              <Notes
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                hidden={handlerHidden}
                activity={activity}
              />
            )}
          </Col>
          <Col span={8} className="activity-details">
            <Detail
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              activity={activity}
              lead={lead}
              hidden={handlerHidden}
            />
          </Col>
        </Row>
      </div>
    </TweenOne>
  );
}

Activities.propTypes = {
  lead: instanceOf(Object).isRequired,
  setActivityOpen: func.isRequired,
  activity: instanceOf(Object),
};

Activities.defaultProps = {
  activity: null,
};

export default Activities;
