import { Button, message } from "antd";
import Drawer from "react-bottom-drawer";
import EmailSvg from "assets/icon/icon-email.svg";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CLIENT_HOST } from "common/config/config";
import copy from "../../assets/icon/icon-copy.svg";
import face from "../../assets/icon/icon-facebook.svg";
import linkedin from "../../assets/icon/icon-linkedin.svg";
import share from "../../assets/icon/icon-share.svg";
import twiter from "../../assets/icon/icon-twiter.svg";
import shareProduct from "../../common/utils/shareProduct";

const info = () => {
  message.success("URL Copiada en el portapapeles");
};

const ShareButton = ({ email, endpoint }) => {
  const [shares, setShares] = useState(false);

  const onClose = () => {
    setShares(false);
  };
  useEffect(() => {
    if (shares) {
      document.body.style.overflowY = "auto";
    }
  }, [shares]);

  return (
    <div className="ShareButton">
      <Button className="secundary" onClick={() => setShares(!shares)}>
        <img alt="share" src={share} />
        <span className="span-btn">Compartirlo con tu cliente</span>
      </Button>
      {shares && (
        <div className="icons">
          <a
            href="./"
            onClick={(e) => {
              e.preventDefault();
              setShares(false);
              shareProduct(
                "https://www.facebook.com/sharer/sharer.php?u=",
                `${CLIENT_HOST}${endpoint}`
              );
            }}
            target="blank"
          >
            <img alt="face" src={face} />
          </a>
          <a
            href="./"
            onClick={(e) => {
              e.preventDefault();
              setShares(false);
              shareProduct("https://twitter.com/home?status=", `${CLIENT_HOST}${endpoint}`);
            }}
            target="blank"
          >
            <img alt="twiter" src={twiter} />
          </a>
          <a
            href="./"
            onClick={(e) => {
              e.preventDefault();
              setShares(false);
              shareProduct(
                "https://www.linkedin.com/shareArticle?mini=true&url=",
                `${CLIENT_HOST}${endpoint}`
              );
            }}
            target="blank"
          >
            <img alt="linkedin" src={linkedin} />
          </a>
          <a href={`mailto:${email}?&subject=&body=${CLIENT_HOST}${endpoint}`}>
            <img alt="email" src={EmailSvg} className="email" />
          </a>
          <a
            onClick={async (e) => {
              e.preventDefault();
              setShares(false);
              info();
              if (navigator.clipboard) {
                await navigator.clipboard.writeText(`${CLIENT_HOST}${endpoint}`);
              }
            }}
            href="./"
          >
            <img alt="copy" src={copy} />
          </a>
        </div>
      )}
      {shares && (
        <Drawer className="draw" isVisible={shares} onClose={onClose}>
          {/* <Button className="btn-close" onClick={() => setShares(!shares)} /> */}
          <div className="ShareButton-responsive">
            {/* <Button className="button-close" onClick={() => setShares(!shares)}>
              <div className="line" />
            </Button> */}
            <a
              href="./"
              onClick={(e) => {
                e.preventDefault();
                shareProduct(
                  "https://www.facebook.com/sharer/sharer.php?u=",
                  `${CLIENT_HOST}${endpoint}`
                );
              }}
              target="blank"
            >
              <img alt="face" src={face} />
              <span>Facebook</span>
            </a>
            <a
              href="./"
              onClick={(e) => {
                e.preventDefault();
                shareProduct("https://twitter.com/home?status=", `${CLIENT_HOST}${endpoint}`);
              }}
              target="blank"
            >
              <img alt="twiter" src={twiter} />
              <span>Twitter</span>
            </a>
            <a
              href="./"
              onClick={(e) => {
                e.preventDefault();
                shareProduct(
                  "https://www.linkedin.com/shareArticle?mini=true&url=",
                  `${CLIENT_HOST}${endpoint}`
                );
              }}
              target="blank"
            >
              <img alt="linkedin" src={linkedin} />
              <span>Linkedin</span>
            </a>
            <a href={`mailto:${email}?&subject=&body=${CLIENT_HOST}${endpoint}`}>
              <img alt="email" src={EmailSvg} />
              <span>Email</span>
            </a>
            <a
              onClick={async (e) => {
                e.preventDefault();
                info();
                if (navigator.clipboard) {
                  await navigator.clipboard.writeText(`${CLIENT_HOST}${endpoint}`);
                }
              }}
              href="./"
            >
              <img alt="copy" src={copy} />
              <span>Copiar URL</span>
            </a>
          </div>
        </Drawer>
      )}
    </div>
  );
};

ShareButton.propTypes = {
  email: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
};

export default ShareButton;
