/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Input, Pagination, Select, Table, Empty } from "antd";
import moment from "moment";
import PropTypes from "prop-types"; // ES6
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ActiveLeadsFilter from "../activeLeadsFilter/ActiveLeadsFilter";
import columns from "./components/columns";
import FilterModal from "./components/FilterModal";
import filterSvg from "../../../../assets/icon/filter.svg";
import searchSvg from "../../../../assets/icon/icon-search.svg";

const { Option } = Select;

const LeadTable = ({
  data,
  openDetail,
  setPage,
  setQuantity,
  quantity,
  search,
  isSearching,
  setFilter,
  total,
  sort,
  columnOrder,
  setShowLeadDetail,
  productLines,
  origins,
  filter,
  page,
}) => {
  const { t } = useTranslation();
  const [filterVisible, setFilterVisible] = useState("none");
  const [activeFilters, setActiveFilters] = useState([]);

  useEffect(() => {
    const newFiltersActive = filter.map((newFilter) => {
      switch (newFilter.type) {
        case "number":
          return { id: newFilter.id, name: newFilter.name };
        case "date":
          return {
            id: newFilter.id,
            name: `${moment(newFilter.value.start).format("MMMM D, YYYY")} a ${moment(
              newFilter.value.end
            ).format("MMMM D, YYYY")}`,
          };
        default:
          return { id: newFilter.id, name: newFilter.name };
      }
    });

    setActiveFilters(newFiltersActive);
  }, [filter]);

  return (
    <>
      <div className="actions-head">
        <div className="actions-head__select">
          <span className="actions-head__selectLabel">{t("label.see")}</span>
          <Select defaultValue="10" onChange={(value) => setQuantity(+value)}>
            <Option value="5">5</Option>
            <Option value="10">10</Option>
            <Option value="20">20</Option>
            <Option value="25">25</Option>
          </Select>
          {activeFilters.length > 0 && (
            <ActiveLeadsFilter
              setFilter={setFilter}
              activeFilters={activeFilters}
              filters={filter}
            />
          )}
        </div>
        <div className="actions-head__content">
          <div className="actions-head__search">
            {isSearching && (
              <Input
                onKeyDown={(e) => {
                  if (e.key === "Enter") search();
                }}
                className="actions-head__searchInput"
                placeholder={t("placeholder.LookFor")}
                id="inputSearch"
              />
            )}
            <figure onClick={() => search()}>
              <img className="filter-icon" aria-hidden="true" alt="filter" src={searchSvg} />
            </figure>
          </div>
          <div className="actions-head__filter">
            <button
              type="button"
              className="btn-active-button"
              onClick={(e) => {
                e.preventDefault();
                if (filterVisible === "none") return setFilterVisible("block");
                setFilterVisible("none");
                return null;
              }}
            >
              <figure>
                <img className="filter-icon" aria-hidden="true" alt="filter" src={filterSvg} />
              </figure>
            </button>
            <div className="menu-leads" style={{ display: filterVisible, zIndex: 1 }}>
              {productLines && origins && (
                <FilterModal
                  origins={origins}
                  closeModal={() => setFilterVisible("none")}
                  productLines={productLines}
                  setFilter={setFilter}
                  filter={filter}
                  tProp={t}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Table
        columns={columns(openDetail, columnOrder, sort, setShowLeadDetail)}
        dataSource={data}
        scroll={{ x: 1200 }}
        sticky
        pagination={false}
        className="table-leads"
      />
      {data?.length === 0 && (
        <div className="empty-table">
          <Empty
            imageStyle={{
              height: 60,
            }}
            description={
              <span className="text-emty">Todavía no tienes leads que ver en esta sección</span>
            }
          />
        </div>
      )}
      <Pagination
        defaultCurrent={1}
        onChange={(actualPage) => setPage(actualPage)}
        total={total}
        current={page}
        pageSize={quantity}
      />
    </>
  );
};

LeadTable.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  openDetail: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setQuantity: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  search: PropTypes.func.isRequired,
  isSearching: PropTypes.bool.isRequired,
  setFilter: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  sort: PropTypes.func.isRequired,
  setShowLeadDetail: PropTypes.func.isRequired,
  productLines: PropTypes.instanceOf(Array).isRequired,
  origins: PropTypes.instanceOf(Array).isRequired,
  filter: PropTypes.instanceOf(Array).isRequired,
  columnOrder: PropTypes.instanceOf(Object).isRequired,
  page: PropTypes.number.isRequired,
};

export default LeadTable;
