/* eslint-disable prefer-template */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unescaped-entities */
import { Button } from "antd";
import React from "react";
import arrowSvg from "assets/images/arrow-rigth-green-line.svg";
import PropTypes, { object } from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment";

const CardFeedback = ({ item }) => (
  <div className="cardFeedback">
    <img className="cardFeedback_icon" src={item.icon} alt="icon" />
    <div className="cardFeedback__content">
      <p className="body-bold">Antonio Hernandez</p>
      <p className="body-detail">#0000000001993</p>
      <div className="flex">
        <p className="small-detail">Ofertada por:</p>
        <div className="insurances">
          <div className="circle">
            <img
              src="https://res.cloudinary.com/arepa-tec/image/upload/v1677436637/Original_mnmtix.svg"
              alt="insuranse"
              className="circle-img"
            />
          </div>
          <div className="circle">
            <img
              src="https://res.cloudinary.com/arepa-tec/image/upload/v1677436637/icono_c14cdr.svg"
              alt="insuranse"
              className="circle-img"
            />
          </div>
          <div className="circle">
            <img
              src="https://res.cloudinary.com/arepa-tec/image/upload/v1677436637/icono_1_v8tvur.svg"
              alt="insuranse"
              className="circle-img"
            />
          </div>
        </div>
      </div>
    </div>
    <Button className="btn-arrow">
      <img src={arrowSvg} className="arrowSvg" alt="arrow" />
    </Button>
  </div>
);

const SectionFeedBack = () => {
  const dummyFeedback = [];
  const policies = useSelector((state) => state.policies);

  return (
    <section className="SectionFeedBack">
      <div className="column column-left">
        <div className="column-head">
          <h2 className="mobile-h2">Feedback</h2>
          <div className="tabs">
            <Button className="btn-tab">
              Pendiente <span className="number">(0)</span>
            </Button>
            <Button className="btn-tab">
              Enviado <span className="number">(0)</span>
            </Button>
          </div>
        </div>
        <div className="conetnt-cards">
          {dummyFeedback?.length <= 0 ? (
            <div className="empty">
              <p>Aquí verás las pólizas pendientes por dar feedback</p>
            </div>
          ) : (
            <>
              {dummyFeedback?.map((item) => (
                <CardFeedback key={item.id} item={item} />
              ))}
            </>
          )}
        </div>
        <div className="column-footer">
          <span className="small-detail">Mostrando 0</span>
          <Button className="btn-tab" disabled>
            Ver todas
          </Button>
        </div>
      </div>
      <div className="column column-rigth">
        <h2 className="mobile-h2">Ingresos por data</h2>
        <p className="small-detail small-detail--date">
          {moment(policies?.filtersCommissions?.FechaInicial[0]).format("MMM'YY")}-
          {moment(policies?.filtersCommissions?.FechaFinal[0]).format("MMM'YY")}
        </p>
        <h1 className="h1-gradient">$0</h1>
        <p className="small-detail">
          Próximo corte: <span>En {policies?.success?.commissions?.nextCloseDay} días</span>
        </p>
        <p className="small-detail">
          Ganancias potenciales este trimestre: <span>$0</span>
        </p>
      </div>
    </section>
  );
};

CardFeedback.propTypes = {
  item: PropTypes.instanceOf(object).isRequired,
};

export default SectionFeedBack;
