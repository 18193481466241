import { createActions } from "redux-actions";

const { marketplace } = createActions({
  MARKETPLACE: {
    GET_MINIMAL_MARKETPLACE: () => ({}),
    GET_MINIMAL_MARKETPLACE_RESPONSE: (products) => products,
    CLEAR_MINIMAL_MARKETPLACE_RESPONSE: () => ({}),

    SET_PRODUCT_STATUS: (productId) => productId,
    SET_PRODUCT_STATUS_RESPONSE: () => ({}),

    GET_INSURANCE_CARRIERS: () => ({}),
    GET_INSURANCE_CARRIERS_RESPONSE: (insuranceCarriers) => ({
      insuranceCarriers,
    }),

    GET_SPECIAL_PRODUCTS: () => ({}),
    GET_SPECIAL_PRODUCTS_RESPONSE: (specialProducts) => ({ specialProducts }),

    GET_ALL_PRODUCTS: () => ({}),
    GET_ALL_PRODUCTS_RESPONSE: (products) => ({ products }),

    GET_PRODUCTS_BY_INSURANCE_CARRIER: (insuranceCarrier) => ({
      insuranceCarrier,
    }),
    GET_PRODUCTS_BY_INSURANCE_CARRIER_RESPONSE: (insuranceCarrier, products) => ({
      insuranceCarrier,
      products,
    }),

    SET_AGENT_PRODUCT: (product) => ({ product }),
    SET_AGENT_PRODUCT_RESPONSE: (response) => ({ response }),
    CLEAR_AGENT_PRODUCT_RESPONSE: () => ({}),

    SET_INSURANCE_SELECT: (insurance) => ({ insurance }),

    GET_INSURANCE_LINES: () => ({}),
    GET_INSURANCE_LINES_RESPONSE: (list) => ({ list }),

    GET_PRODUCTS_BY_INSURANCE_LINE: (insuranceLine) => ({ insuranceLine }),
    GET_PRODUCTS_BY_INSURANCE_LINE_RESPONSE: (insuranceLine, products) => ({
      insuranceLine,
      products,
    }),

    GET_PRODUCT: (product) => ({ product }),
    GET_PRODUCT_RESPONSE: (product) => ({ product }),

    REQUEST_HELP_WITH_INSURANCE_CARRIER: () => ({}),
    REQUEST_HELP_WITH_INSURANCE_CARRIER_RESPONSE: () => ({}),
  },
});

export default marketplace;
