/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import { Button } from "antd";
import moment from "moment";
import { bool, func, instanceOf, string } from "prop-types";
import React from "react";
import "react-day-picker/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import PoliciesActions from "services/policies/actions";
import filterSvg from "assets/images/pia-brands/filter-p.svg";
import filterGreenSvg from "assets/images/pia-brands/insures/filter-gray.svg";
import filter from "../../../../../assets/images/menuw.svg";
import BranchSelector from "./components/BranchSelector";
import ExpirationFilter from "./components/ExpirationFilter";
import StatusSelector from "./components/StatusSelector";
import InsurersFilter from "./components/InsurersFilter";

const Policies = ({
  insurances,
  styleMode,
  accounts,
  getLast10,
  filteredAccounts,
  selectedExpiration,
  setSelectedExpiration,
  setSelectedStatus,
  selectedStatus,
  setSelectedProductLines,
  selectedProductLines,
}) => {
  const dispatch = useDispatch();
  // const [daysFilter, setDaysFilter] = useState({ from: null, to: null });
  const { filters, filtersOpened } = useSelector((state) => state.policies);

  const handleStatus = (value) => {
    const newStatus = [...selectedStatus];
    const statusIndex = newStatus.findIndex((status) => status === value);
    if (statusIndex > -1) newStatus.splice(statusIndex, 1);
    else newStatus.push(value);

    setSelectedStatus(newStatus);
    dispatch(PoliciesActions.setFilters("Estado", value));
  };

  const expirationFilter = [
    {
      title: "Próximos 30 días",
      key: "30",
      endDate: moment().add(30, "d").format("YYYY-MM-DD"),
    },
    {
      title: "Próximos 60 días",
      key: "60",
      endDate: moment().add(60, "d").format("YYYY-MM-DD"),
    },
    {
      title: "Próximos 90 días",
      key: "90",
      endDate: moment().add(90, "d").format("YYYY-MM-DD"),
    },
    {
      title: "Próximos 180 días",
      key: "180",
      endDate: moment().add(180, "d").format("YYYY-MM-DD"),
    },
  ];
  return (
    <div
      className={`Policies ${styleMode && "efect-darck Policies--darck"} ${
        filtersOpened && "Policies--open"
      }`}
    >
      <div className={`Policies__content ${filtersOpened && "Policies__content--open"}`}>
        <div className="Policies__content-head">
          <h2 className="h2">Pólizas</h2>
          <Button
            className="button-transparent"
            onClick={() => dispatch(PoliciesActions.modifyFiltersOpened())}
          >
            {styleMode ? (
              <img src={filterGreenSvg} alt="filterSvg" className="filter" />
            ) : (
              <img src={filterSvg} alt="filterSvg" className="filter" />
            )}
          </Button>
        </div>

        {filtersOpened && (
          <div className="Policies__content__filter animate__fadeIn animate__animated">
            <InsurersFilter
              insurances={insurances}
              accounts={accounts}
              styleMode={styleMode}
              filteredAccounts={filteredAccounts}
              getLast10={getLast10}
            />
            <BranchSelector
              dispatch={dispatch}
              filters={filters}
              selectedProductLines={selectedProductLines}
              setSelectedProductLines={setSelectedProductLines}
            />
            <StatusSelector
              dispatch={dispatch}
              filters={filters}
              handleStatus={handleStatus}
              setSelectedProductLines={setSelectedProductLines}
            />
            <ExpirationFilter
              dispatch={dispatch}
              expirationFilter={expirationFilter}
              selectedExpiration={selectedExpiration}
              setSelectedExpiration={setSelectedExpiration}
            />
            <Button
              className="button-see-less"
              onClick={() => dispatch(PoliciesActions.modifyFiltersOpened())}
            >
              Ver menos filtros
              <img src={filter} className="icon-filter" alt="icon-filter" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

Policies.propTypes = {
  insurances: instanceOf(Array).isRequired,
  accounts: instanceOf(Array).isRequired,
  styleMode: bool.isRequired,
  getLast10: func.isRequired,
  filteredAccounts: instanceOf(Array).isRequired,
  selectedExpiration: string.isRequired,
  setSelectedExpiration: func.isRequired,

  setSelectedStatus: func.isRequired,
  selectedStatus: instanceOf(Array).isRequired,
  setSelectedProductLines: func.isRequired,
  selectedProductLines: instanceOf(Array).isRequired,
};

export default Policies;
