import { instanceOf, string } from "prop-types";
import React, { useState } from "react";
import PiaField from "../PiaField";

const SectionSummaryPia = ({ searchedHighlight, policy, highlightDictionary }) => {
  const [summary] = useState([
    {
      id: 1,
      title: "Aseguradora",
      image:
        policy.General && policy.General.length > 0
          ? `https://fileviewer.berrysafe.com/images/insurance-carrier/pia/${policy.General[0].SlugNameInsured}.svg`
          : "",
      dictionary: highlightDictionary.insurance,
    },
    {
      id: 2,
      title: "Tipo de póliza",
      text: policy.General[0].TypePolicy,
      dictionary: highlightDictionary.policyType,
    },
    {
      id: 3,
      title: "Ramo",
      text: policy.General[0].Branch,
      dictionary: highlightDictionary.branch,
    },
    {
      id: 4,
      title: "Subramo",
      text:
        policy.General[0].SubBranch === "N/A"
          ? policy.General[0].Branch
          : policy.General[0].SubBranch,
      dictionary: highlightDictionary.subBranch,
    },
    {
      id: 5,
      title: "Inicio de Vigencia",
      type: "date",
      text: policy.General[0].ValidityFrom,
      dictionary: highlightDictionary.validity,
    },
    {
      id: 6,
      title: "Fecha de emisión",
      type: "date",
      text: policy.General[0].DateEmission,
      dictionary: highlightDictionary.emission,
    },
    {
      id: 7,
      title: "Fin de Vigencia",
      type: "date",
      text: policy.General[0].ValidityTo,
      dictionary: highlightDictionary.validity,
    },
    {
      id: 8,
      title: "Asegurado",
      text: policy.General[0].BenefeciaryName,
      dictionary: highlightDictionary.beneficiary,
    },
    {
      id: 9,
      title: "Agente",
      text: policy.General[0].AgentName,
      dictionary: highlightDictionary.agent,
    },
    {
      id: 10,
      title: "Oficina / Promotoría",
      text: policy.General[0].AgentOffice,
      dictionary: highlightDictionary.office,
    },
  ]);

  if (policy.General.length < 1)
    return (
      <div className="SectionSummaryPia">
        <div className="empty">
          <span className="h2">Esta póliza no reporta Resumen</span>
        </div>
      </div>
    );
  return (
    <div className="SectionSummaryPia">
      <div className="SectionSummaryPia--table">
        <div className="SectionSummaryPia--table__column">
          {summary?.map((item) => (
            <div className="item" key={item.id}>
              {item.text && item.text !== "N/A" && (
                <>
                  <div className="item-title">
                    <p className={item.dictionary.includes(searchedHighlight) ? "highlight" : ""}>
                      {item.title}
                    </p>
                  </div>
                  <div className="item-rta">
                    {!item.image && <PiaField text={item.text || ""} type={item.type || ""} />}
                  </div>
                </>
              )}
              {item.image && (
                <>
                  <div className="item-title">
                    <p className={item.dictionary.includes(searchedHighlight) ? "highlight" : ""}>
                      {item.title}
                    </p>
                  </div>
                  <div className="item-rta">
                    <img src={item.image} alt="aseguradora" className="image" />
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
        <div className="SectionSummaryPia--table__column">
          <h5 className="sub-title">Prima</h5>
          <div className="content-gray">
            <div className="content-gray--item">
              <p
                className={
                  highlightDictionary.currency.includes(searchedHighlight) ? "highlight" : ""
                }
              >
                Moneda
              </p>
              <h4 className="moneda">
                {policy.General[0].PremiumCurrency === "NACIONAL" ? (
                  "MXN"
                ) : (
                  <PiaField
                    text={
                      policy.General[0].PremiumCurrency === "" ||
                      policy.General[0].PremiumCurrency === "N/A"
                        ? "MXP"
                        : policy.General[0].PremiumCurrency
                    }
                    type=""
                  />
                )}
              </h4>
            </div>
            <div className="content-gray--item">
              <p
                className={
                  highlightDictionary.paymentMethod.includes(searchedHighlight) ? "highlight" : ""
                }
              >
                Forma de pago
              </p>
              <PiaField text={policy.General[0].PremiumWayPayment} type="" />
            </div>
            <div className="content-gray--item">
              <p
                className={highlightDictionary.rate.includes(searchedHighlight) ? "highlight" : ""}
              >
                Tarifa
              </p>
              {policy.General[0].PremiumAppliedRate === "N/A" ? (
                <span className="body-bold">$0</span>
              ) : (
                <PiaField text={policy?.General[0]?.PremiumAppliedRate || ""} type="" />
              )}
            </div>
            <div className="content-gray--item">
              <p
                className={
                  highlightDictionary.endorsement.includes(searchedHighlight) ? "highlight" : ""
                }
              >
                Endoso
              </p>
              <PiaField text={policy.General[0].Endorsement} type="" />
            </div>
          </div>
          <div className="content-lines">
            <div className="row">
              <p
                className={
                  highlightDictionary.comission.includes(searchedHighlight) ? "highlight" : ""
                }
              >
                Comisión
              </p>
              <PiaField text={policy.General[0].commission || ""} type="price" />
            </div>
            <div className="row">
              <p
                className={highlightDictionary.prime.includes(searchedHighlight) ? "highlight" : ""}
              >
                Prima neta
              </p>
              <PiaField
                text={
                  policy.General[0].PremiumPremiumNet
                    ? policy.General[0].PremiumPremiumNet.replace("MXN", "")
                    : ""
                }
                type="price"
              />
            </div>
            <div className="row">
              <p
                className={highlightDictionary.rate.includes(searchedHighlight) ? "highlight" : ""}
              >
                Tasa Financiada
              </p>

              {policy.General[0].PremiumRateF === "N/A" ? (
                <span className="body-bold">0</span>
              ) : (
                <PiaField text={policy.General[0].PremiumRateF || ""} type="price" />
              )}
            </div>
            <div className="row">
              <p
                className={
                  highlightDictionary.expenses.includes(searchedHighlight) ? "highlight" : ""
                }
              >
                Gastos de expedición
              </p>

              {policy.General[0].PremiumExpensesCreatedPolicy === "N/A" ? (
                <span className="body-bold">0</span>
              ) : (
                <PiaField
                  text={policy.General[0].PremiumExpensesCreatedPolicy || ""}
                  type="price"
                />
              )}
            </div>
          </div>
        </div>
        <div className="SectionSummaryPia--table__column">
          <div className="content-lines">
            <div className="row">
              <p className={highlightDictionary.tax.includes(searchedHighlight) ? "highlight" : ""}>
                IVA
              </p>
              <PiaField
                text={policy?.General[0]?.PremiumIva?.replace("MXN", "") || ""}
                type="price"
              />
            </div>
            <div className="row">
              <p
                className={
                  highlightDictionary.subtotal.includes(searchedHighlight) ? "highlight" : ""
                }
              >
                Subtotal
              </p>
              <PiaField
                text={policy.General[0].PremiumSubtotal?.replace("MXN", "") || ""}
                type="price"
              />
            </div>
            <div className="row">
              <p
                className={
                  highlightDictionary.discount.includes(searchedHighlight) ? "highlight" : ""
                }
              >
                Descuento
              </p>
              <PiaField text={policy.General[0].Discount || ""} type="price" />
            </div>
          </div>
        </div>
        <div className="content-total">
          <div className="total">
            <p className={highlightDictionary.total.includes(searchedHighlight) ? "highlight" : ""}>
              Prima total
            </p>
            <h2 className="h2">
              {policy.General[0].PremiumPremiumTotal >= 0 ? (
                <PiaField text={policy.General[0].PremiumPremiumTotal || ""} type="price" />
              ) : (
                "0"
              )}
            </h2>
          </div>
          <div className="total">
            <p
              className={
                [...highlightDictionary.total, ...highlightDictionary.endorsement].includes(
                  searchedHighlight
                )
                  ? "highlight"
                  : ""
              }
            >
              Prima total incluyendo endosos
            </p>
            <h2 className="h2">
              {policy.General[0].PremiumPremiumTotalWithEndorsement >= 0 ? (
                <PiaField
                  text={policy.General[0].PremiumPremiumTotalWithEndorsement || ""}
                  type="price"
                />
              ) : (
                "0"
              )}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionSummaryPia.propTypes = {
  searchedHighlight: string.isRequired,
  policy: instanceOf(Object).isRequired,
  highlightDictionary: instanceOf(Object).isRequired,
};

export default SectionSummaryPia;
