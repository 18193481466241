/* eslint-disable react/no-array-index-key */
import { Tabs } from "antd";
import { instanceOf, string } from "prop-types";
import React, { useState } from "react";
import PiaField from "../PiaField";

const { TabPane } = Tabs;

const SectionCoveragePia = ({ policy, searchedHighlight }) => {
  const [allDynamicCoverage] = useState(
    policy.CoverageList.map((coverage) => ({
      ...coverage,
      id: coverage.CoverageNumber,
      title: coverage.Risk,
      dictionary: [],
    }))
  );

  return (
    <div className="SectionSummaryPia">
      {policy.CoverageList.length < 1 && (
        <div className="empty">
          <span className="h2">Por el momento no tenemos registro de coberturas</span>
        </div>
      )}
      {policy.CoverageList.length > 0 && (
        <div className="SectionSummaryPia--table">
          <Tabs defaultActiveKey="dynamic-coverage-item-0">
            {allDynamicCoverage.map((coverage, i) => (
              <TabPane
                tab={
                  <div className="tab-head">
                    <h1>{coverage.title}</h1>
                  </div>
                }
                key={`dynamic-coverage-item-${i}`}
              >
                <div className="SectionSummaryPia--table__column">
                  <div className="item">
                    <div className="item-title">
                      <p
                        className={
                          coverage.dictionary.includes(searchedHighlight) ? "highlight" : ""
                        }
                      >
                        Suma
                      </p>
                    </div>
                    <div className="item-rta">
                      <PiaField text={coverage.AmountInsured} type="" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="item-title">
                      <p
                        className={
                          coverage.dictionary.includes(searchedHighlight) ? "highlight" : ""
                        }
                      >
                        Prima
                      </p>
                    </div>
                    <div className="item-rta">
                      <PiaField text={coverage.Premium} type="price" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="item-title">
                      <p
                        className={
                          coverage.dictionary.includes(searchedHighlight) ? "highlight" : ""
                        }
                      >
                        Deducible
                      </p>
                    </div>
                    <div className="item-rta">
                      <PiaField text={coverage.Deductible} type="" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="item-title">
                      <p
                        className={
                          coverage.dictionary.includes(searchedHighlight) ? "highlight" : ""
                        }
                      >
                        Coaseguro
                      </p>
                    </div>
                    <div className="item-rta">
                      <PiaField text={coverage.CoInsurance} type="" />
                    </div>
                  </div>
                </div>
              </TabPane>
            ))}
          </Tabs>
        </div>
      )}
    </div>
  );
};

SectionCoveragePia.propTypes = {
  policy: instanceOf(Object).isRequired,
  searchedHighlight: string.isRequired,
};
export default SectionCoveragePia;
