/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import { Button } from "antd";
import allSections from "common/utils/piaAllSections";
import initialHighlightDictionary from "common/utils/piaInitialHighlight";
import moment from "moment";
import { bool, instanceOf, string } from "prop-types";
import SelectChangeStatusPolicy from "components/Atoms/SelectChangeStatusPolicy/SelectChangeStatusPolicy";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import mover from "../../../../../assets/images/mover.svg";
import clock from "../../../../../assets/images/reloj.png";
import SectionCollectionPia from "./components/SectionCollectionPia/SectionCollectionPia";
import SectionCommissions from "./components/SectionCommissions/SectionCommissions";
import SectionCoveragePia from "./components/SectionCoveragePia/SectionCoveragePia";
import SectionRiskData from "./components/SectionRiskData/SectionRiskData";
import SectionSinisterPia from "./components/SectionSinisterPia/SectionSinisterPia";
import SectionStatesPia from "./components/SectionStatesPia/SectionStatesPia";
import SectionSummaryPia from "./components/SectionSummaryPia/SectionSummaryPia";

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getListStyle = () => ({
  padding: grid,
});

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  ...draggableStyle,
});

const PolicySummaryPia = ({ searchedHighlight, policy, isDarkMode }) => {
  const [highlightDictionary, setHighlightDictionary] = useState(initialHighlightDictionary);
  const [newHighlights, setNewHighlights] = useState(false);
  const [isMinimize, setMinimize] = useState([]);

  const [sections, setSections] = useState([]);

  const [secondSections, setSecondSections] = useState([]);

  const validateInput = (txt) => !!txt && txt.toLowerCase();

  useEffect(() => {
    if (!policy) return;

    if (policy?.EndorsementsList?.length < 1) allSections[2].hasData = false;

    if (policy?.General?.length < 1) allSections[1].hasData = false;

    if (policy?.General?.length < 1) allSections[0].hasData = false;

    const firstColumn = [];
    const secondColumn = [];

    allSections.sort((a, b) => (a === null) - (b === null) || +(a > b) || -(a < b));

    for (let i = 0; i < allSections.length; i++)
      if (!(i % 2)) firstColumn.push(allSections[i]);
      else secondColumn.push(allSections[i]);

    setSections(firstColumn);
    setSecondSections(secondColumn);
  }, [policy]);

  useEffect(() => {
    if (policy && !newHighlights) {
      const newHighlightDictionary = { ...initialHighlightDictionary };
      if (policy.General && policy.General.length > 0) {
        newHighlightDictionary.agent.push(validateInput(policy.General[0].AgentName));
        newHighlightDictionary.beneficiary.push(validateInput(policy.General[0].BenefeciaryName));
        newHighlightDictionary.tax.push(validateInput(policy.General[0].PremiumIva));
        newHighlightDictionary.branch.push(validateInput(policy.General[0].Branch));
        newHighlightDictionary.motor.push(validateInput(policy.General[0].RiskNumberMotor));
        newHighlightDictionary.insurance.push(validateInput(policy.General[0].SlugNameInsured));
        newHighlightDictionary.office.push(validateInput(policy.General[0].AgentOffice));
        newHighlightDictionary.password.push(validateInput(policy.General[0].RiskKey));
        newHighlightDictionary.plate.push(validateInput(policy.General[0].RiskPlaca));
        newHighlightDictionary.serie.push(validateInput(policy.General[0].RiskSerie));
        newHighlightDictionary.service.push(validateInput(policy.General[0].RiskService));
        newHighlightDictionary.plate.push(validateInput(policy.General[0].RiskDescription));
        newHighlightDictionary.plate.push(validateInput(policy.General[0].RiskNumberMotor));
        newHighlightDictionary.plate.push(validateInput(policy.General[0].RiskDescription));
        newHighlightDictionary.subBranch.push(validateInput(policy.General[0].SubBranch));
        newHighlightDictionary.subtotal.push(validateInput(policy.General[0].PremiumSubtotal));
        newHighlightDictionary.total.push(validateInput(policy.General[0].PremiumPremiumTotal));
        newHighlightDictionary.total.push(
          validateInput(policy.General[0].PremiumPremiumTotalWithEndorsement)
        );
        newHighlightDictionary.use.push(validateInput(policy.General[0].RiskUse));
        newHighlightDictionary.validity.push(
          validateInput(moment(policy.General[0].ValidityFrom).format("YY/MM/DD"))
        );
        newHighlightDictionary.validity.push(
          moment(policy.General[0].ValidityTo).format("YY/MM/DD")
        );
      }

      if (policy.CoverageList) {
        newHighlightDictionary.coInsurance.push(
          ...policy.CoverageList.map((coverage) => coverage.CoInsurance).filter((text) => !!text)
        );
        newHighlightDictionary.coverage.push(
          ...policy.CoverageList.map((coverage) => coverage.Premium).filter((text) => !!text)
        );
        newHighlightDictionary.deductible.push(
          ...policy.CoverageList.map((coverage) => coverage.Deductible).filter((text) => !!text)
        );
        newHighlightDictionary.net.push(
          ...policy.CoverageList.map((coverage) => coverage.Premium).filter((text) => !!text)
        );
        newHighlightDictionary.prime.push(
          ...policy.CoverageList.map((coverage) => coverage.Premium).filter((text) => !!text)
        );
        newHighlightDictionary.sum.push(
          ...policy.CoverageList.map((coverage) => coverage.AmountInsured).filter((text) => !!text)
        );
      }

      if (policy.EndorsementsList) {
        newHighlightDictionary.currency.push(
          ...policy.EndorsementsList.map((endorsement) => endorsement.PremiumCurrency).filter(
            (text) => !!text
          )
        );
        newHighlightDictionary.discount.push(
          ...policy.EndorsementsList.map((endorsement) => endorsement.PremiumDiscount).filter(
            (text) => !!text
          )
        );
        newHighlightDictionary.emission.push(
          ...policy.EndorsementsList.map((endorsement) => endorsement.DateEmission).filter(
            (text) => !!text
          )
        );
        newHighlightDictionary.endorsement.push(
          ...policy.EndorsementsList.map((endorsement) => endorsement.Number).filter(
            (text) => !!text
          )
        );
        newHighlightDictionary.expenses.push(
          ...policy.EndorsementsList.map(
            (endorsement) => endorsement.PremiumExpensesExpedition
          ).filter((text) => !!text)
        );
        newHighlightDictionary.rate.push(
          ...policy.EndorsementsList.map((endorsement) => endorsement.PremiumRate).filter(
            (text) => !!text
          )
        );
        newHighlightDictionary.subtotal.push(
          ...policy.EndorsementsList.map((endorsement) => endorsement.PremiumAmountParcial).filter(
            (text) => !!text
          )
        );
        newHighlightDictionary.tax.push(
          ...policy.EndorsementsList.map((endorsement) => endorsement.PremiumIva).filter(
            (text) => !!text
          )
        );
        newHighlightDictionary.total.push(
          ...policy.EndorsementsList.map((endorsement) => endorsement.PremiumAmountTotal).filter(
            (text) => !!text
          )
        );
        newHighlightDictionary.validity.push(
          ...policy.EndorsementsList.map((endorsement) => endorsement.EndValidityDate).filter(
            (text) => !!text
          )
        );
        newHighlightDictionary.validity.push(
          ...policy.EndorsementsList.map((endorsement) => endorsement.StartValidityDate).filter(
            (text) => !!text
          )
        );
      }

      if (policy.InsuranceClaimList) {
        newHighlightDictionary.cause.push(
          ...policy.InsuranceClaimList.map((sinister) => sinister.DetailCause).filter(
            (text) => !!text
          )
        );
        newHighlightDictionary.driver.push(
          ...policy.InsuranceClaimList.map((sinister) => sinister.DriverName).filter(
            (text) => !!text
          )
        );
        newHighlightDictionary.estimate.push(
          ...policy.InsuranceClaimList.map((sinister) => sinister.Estimate).filter((text) => !!text)
        );
        newHighlightDictionary.happenedDate.push(
          ...policy.InsuranceClaimList.map((sinister) => sinister.EventDay).filter((text) => !!text)
        );
        newHighlightDictionary.location.push(
          ...policy.InsuranceClaimList.map((sinister) => sinister.Location).filter((text) => !!text)
        );
        newHighlightDictionary.reportNumber.push(
          ...policy.InsuranceClaimList.map((sinister) => sinister.ReportNumber).filter(
            (text) => !!text
          )
        );
        newHighlightDictionary.sinisterNumber.push(
          ...policy.InsuranceClaimList.map((sinister) => sinister.ClaimNumber).filter(
            (text) => !!text
          )
        );
        newHighlightDictionary.subSection.push(
          ...policy.InsuranceClaimList.map((sinister) => sinister.SubSection).filter(
            (text) => !!text
          )
        );
        newHighlightDictionary.vehicle.push(
          ...policy.InsuranceClaimList.map((sinister) => sinister.DetailVehicle).filter(
            (text) => !!text
          )
        );
      }

      setHighlightDictionary({ ...newHighlightDictionary });
      setNewHighlights(true);
    }
  }, [policy, highlightDictionary, newHighlights]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (rawResult) => {
    const { source, destination } = rawResult;

    if (!destination) return;

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        source.droppableId === "droppable" ? sections : secondSections,
        source.index,
        destination.index
      );

      if (source.droppableId === "droppable2") {
        setSecondSections(items);
      } else {
        setSections(items);
      }
    } else {
      const result = move(
        source.droppableId === "droppable" ? sections : secondSections,
        destination.droppableId === "droppable" ? sections : secondSections,
        source,
        destination
      );

      setSections(result.droppable);
      setSecondSections(result.droppable2);
    }
  };

  const handleMinimizeFields = (item) => {
    item.isMinimizeT = true;
    setMinimize((isMinimize) => [...isMinimize, item]);
  };

  const handleOpenFields = (item) => {
    item.isMinimizeT = false;
    setMinimize((isMinimize) => isMinimize.filter((data) => data.id !== item.id));
  };
  const renderOrderedList = (selectedSections) =>
    selectedSections?.map((item, i) => (
      <Draggable key={item.id} draggableId={`draggable-${item.id}`} index={i}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={`PolicySummaryPia-card-content 
            ${isDarkMode && "PolicySummaryPia-card-content--dark-mode"}
            ${item.isMinimizeT === true && "PolicySummaryPia-card-content--none"}
            `}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            <div className="PolicySummary">
              <div className="PolicySummary--head">
                <img {...provided.dragHandleProps} src={mover} alt="mover" className="mover" />
                <Button className="btutton-transparent" onClick={() => handleMinimizeFields(item)}>
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.8523 1.14737C13.9374 1.23262 13.9891 1.34557 13.998 1.46568C14.0068 1.5858 13.9723 1.7051 13.9006 1.80191L13.8523 1.85824L9.67901 6.03225H13.4969C13.6187 6.0323 13.7363 6.07651 13.8279 6.15667C13.9195 6.23683 13.979 6.3475 13.9952 6.46816L13.9999 6.53523C14 6.65699 13.956 6.77466 13.8759 6.86642C13.7959 6.95817 13.6853 7.01778 13.5646 7.03418L13.4969 7.03887L8.43365 7.03753L8.42292 7.03619C8.37824 7.03285 8.33423 7.02337 8.29214 7.00802L8.22642 6.97717C8.15722 6.93999 8.09773 6.88704 8.05276 6.82262C8.00779 6.7582 7.9786 6.6841 7.96756 6.60631L7.96286 6.5359V1.50348C7.9629 1.37604 8.01131 1.25337 8.09831 1.16026C8.18531 1.06714 8.30442 1.01052 8.43156 1.00183C8.55869 0.993144 8.68439 1.03304 8.78325 1.11346C8.8821 1.19388 8.94675 1.30883 8.96412 1.43508L8.96881 1.50348V5.31937L13.1415 1.14737C13.1881 1.10066 13.2435 1.06359 13.3045 1.0383C13.3655 1.01302 13.4309 1 13.4969 1C13.5629 1 13.6283 1.01302 13.6893 1.0383C13.7503 1.06359 13.8057 1.10066 13.8523 1.14737ZM6.6263 8.88109V13.9135C6.6276 14.0419 6.57979 14.1658 6.49266 14.2601C6.40553 14.3543 6.28566 14.4117 6.15761 14.4204C6.02956 14.4292 5.90301 14.3886 5.80387 14.3071C5.70474 14.2256 5.64052 14.1092 5.62437 13.9819L5.61968 13.9135V10.0943L1.44701 14.2629C1.35726 14.3529 1.23677 14.4056 1.10977 14.4104C0.982759 14.4151 0.858653 14.3717 0.762401 14.2887C0.666148 14.2057 0.604887 14.0893 0.590933 13.963C0.576979 13.8366 0.611367 13.7097 0.687184 13.6077L0.73614 13.5514L4.90747 9.38407H1.09225C0.970702 9.38406 0.853271 9.34005 0.761671 9.26016C0.67007 9.18027 0.610496 9.06991 0.593966 8.9495L0.588601 8.88109C0.588601 8.7477 0.641593 8.61976 0.735919 8.52544C0.830245 8.43111 0.958178 8.37812 1.09157 8.37812L6.15752 8.37946L6.20715 8.3855L6.2722 8.40092L6.30775 8.41366L6.36542 8.44049L6.42511 8.47939C6.4626 8.50774 6.496 8.54114 6.52436 8.57864L6.56862 8.64973L6.59612 8.71277L6.61087 8.75971L6.62227 8.83549L6.6263 8.88109Z"
                      fill={isDarkMode ? "#35D8D0" : "#5D30DD"}
                      stroke={isDarkMode ? "#35D8D0" : "#5D30DD"}
                      strokeWidth="0.6"
                    />
                  </svg>
                </Button>
              </div>
              <div className="PolicySummary--title">
                <h1 className="h3">{item.title}</h1>
                {item.key === "Resumen" && <SelectChangeStatusPolicy policy={policy} />}
              </div>

              {item.key === "Resumen" && (
                <SectionSummaryPia
                  searchedHighlight={searchedHighlight}
                  highlightDictionary={highlightDictionary}
                  policy={policy}
                  className={item.key}
                />
              )}
              {item.key === "Endosos" && (
                <SectionStatesPia
                  searchedHighlight={searchedHighlight}
                  highlightDictionary={highlightDictionary}
                  policy={policy}
                  clock={clock}
                  className={item.key}
                />
              )}
              {item.key === "Coberturas" && (
                <SectionCoveragePia
                  searchedHighlight={searchedHighlight}
                  highlightDictionary={highlightDictionary}
                  policy={policy}
                  className={item.key}
                />
              )}
              {item.key === "Cobranza" && (
                <SectionCollectionPia
                  searchedHighlight={searchedHighlight}
                  highlightDictionary={highlightDictionary}
                  policy={policy}
                  clock={clock}
                />
              )}
              {item.key === "Siniestros" && (
                <SectionSinisterPia
                  searchedHighlight={searchedHighlight}
                  highlightDictionary={highlightDictionary}
                  policy={policy}
                  clock={clock}
                  className={item.key}
                />
              )}
              {item.key === "riesgo" && (
                <SectionRiskData
                  searchedHighlight={searchedHighlight}
                  highlightDictionary={highlightDictionary}
                  policy={policy}
                  className={item.key}
                />
              )}
              {item.key === "comisiones" && <SectionCommissions />}
            </div>
          </div>
        )}
      </Draggable>
    ));

  return (
    <>
      {isMinimize.length > 0 && (
        <div className={`cont-items-head-minimize ${isDarkMode && "content-dark-mode"}`}>
          {isMinimize?.map((item, index) => (
            <div key={index} className="sections-minimize">
              <Button className="btn-section" onClick={() => handleOpenFields(item)}>
                <span>{item.title}</span>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.8523 1.14737C13.9374 1.23262 13.9891 1.34557 13.998 1.46568C14.0068 1.5858 13.9723 1.7051 13.9006 1.80191L13.8523 1.85824L9.67901 6.03225H13.4969C13.6187 6.0323 13.7363 6.07651 13.8279 6.15667C13.9195 6.23683 13.979 6.3475 13.9952 6.46816L13.9999 6.53523C14 6.65699 13.956 6.77466 13.8759 6.86642C13.7959 6.95817 13.6853 7.01778 13.5646 7.03418L13.4969 7.03887L8.43365 7.03753L8.42292 7.03619C8.37824 7.03285 8.33423 7.02337 8.29214 7.00802L8.22642 6.97717C8.15722 6.93999 8.09773 6.88704 8.05276 6.82262C8.00779 6.7582 7.9786 6.6841 7.96756 6.60631L7.96286 6.5359V1.50348C7.9629 1.37604 8.01131 1.25337 8.09831 1.16026C8.18531 1.06714 8.30442 1.01052 8.43156 1.00183C8.55869 0.993144 8.68439 1.03304 8.78325 1.11346C8.8821 1.19388 8.94675 1.30883 8.96412 1.43508L8.96881 1.50348V5.31937L13.1415 1.14737C13.1881 1.10066 13.2435 1.06359 13.3045 1.0383C13.3655 1.01302 13.4309 1 13.4969 1C13.5629 1 13.6283 1.01302 13.6893 1.0383C13.7503 1.06359 13.8057 1.10066 13.8523 1.14737ZM6.6263 8.88109V13.9135C6.6276 14.0419 6.57979 14.1658 6.49266 14.2601C6.40553 14.3543 6.28566 14.4117 6.15761 14.4204C6.02956 14.4292 5.90301 14.3886 5.80387 14.3071C5.70474 14.2256 5.64052 14.1092 5.62437 13.9819L5.61968 13.9135V10.0943L1.44701 14.2629C1.35726 14.3529 1.23677 14.4056 1.10977 14.4104C0.982759 14.4151 0.858653 14.3717 0.762401 14.2887C0.666148 14.2057 0.604887 14.0893 0.590933 13.963C0.576979 13.8366 0.611367 13.7097 0.687184 13.6077L0.73614 13.5514L4.90747 9.38407H1.09225C0.970702 9.38406 0.853271 9.34005 0.761671 9.26016C0.67007 9.18027 0.610496 9.06991 0.593966 8.9495L0.588601 8.88109C0.588601 8.7477 0.641593 8.61976 0.735919 8.52544C0.830245 8.43111 0.958178 8.37812 1.09157 8.37812L6.15752 8.37946L6.20715 8.3855L6.2722 8.40092L6.30775 8.41366L6.36542 8.44049L6.42511 8.47939C6.4626 8.50774 6.496 8.54114 6.52436 8.57864L6.56862 8.64973L6.59612 8.71277L6.61087 8.75971L6.62227 8.83549L6.6263 8.88109Z"
                    fill={isDarkMode ? "#35D8D0" : "#5D30DD"}
                    stroke={isDarkMode ? "#35D8D0" : "#5D30DD"}
                    strokeWidth="0.6"
                  />
                </svg>
              </Button>
            </div>
          ))}
        </div>
      )}
      <div className="PolicySummaryPia">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable direction="vertical" droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                className="PolicySummaryPia--content"
              >
                {renderOrderedList(sections)}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable direction="vertical" droppableId="droppable2">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="PolicySummaryPia--content"
              >
                {renderOrderedList(secondSections)}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
};

PolicySummaryPia.propTypes = {
  policy: instanceOf(Object).isRequired,
  searchedHighlight: string.isRequired,
  isDarkMode: bool.isRequired,
};

export default PolicySummaryPia;
