/* eslint-disable no-plusplus */
import { Form, Input } from "antd";
import Loader from "components/loader";
import { func, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OnboardingActions from "services/onboarding/actions";
import "./license.scss";

const License = ({ activeGoNext, selectedSponsor }) => {
  const dispatch = useDispatch();
  const [listDni, setListDni] = useState([]);
  const onboarding = useSelector((state) => state.onboarding);

  const onFinish = () => {};

  const onFinishFailed = () => {};

  const handleSetListDni = (item) => {
    for (let index = 0; index < listDni.length; index++) {
      if (listDni[index] === item) {
        setListDni(listDni.filter((value) => value !== item));
        return;
      }
    }
    setListDni([...listDni, item]);
  };

  useEffect(() => {
    if (
      !onboarding.loading.license &&
      !onboarding.success.licenseTypes &&
      !onboarding.error.license
    ) {
      dispatch(OnboardingActions.getLicenses());
    }
  }, [dispatch, onboarding]);

  const setLicense = (license) => {
    activeGoNext();
    dispatch(OnboardingActions.setLicense(license.id, !license.isSelected));
  };

  return (
    <div className="license">
      <div className="license__text">
        <h4>Selecciona tu cédula de agente</h4>
        <p>Puedes seleccionar una o varias</p>
      </div>
      <div className="license__wrapper">
        <div className="license__buttons">
          {onboarding.loading.license && <Loader />}
          {onboarding.success.licenseTypes &&
            onboarding.success.licenseTypes.map((license) => (
              <button
                className={`licenseType${license.isSelected ? "--selected" : "--notSelected"}`}
                key={license.id}
                onClick={() => {
                  setLicense(license);
                  handleSetListDni(license);
                }}
                type="button"
              >
                {license.name}
              </button>
            ))}
        </div>
        {selectedSponsor === "other" && listDni.length >= 1 && (
          <Form
            className="license__form animate__fadeInRight animate__animated"
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            {listDni?.map((license) => (
              <Form.Item
                name="username"
                key={`dni-${license.name}`}
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <div className="did-floating-label-content">
                  <Input
                    onChange={(e) => {
                      dispatch(OnboardingActions.setLicense(license.id, true, e.target.value));
                    }}
                    className="did-floating-input"
                    type="text"
                    placeholder=""
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="did-floating-label">Número de cédula {license.name}</label>
                </div>
              </Form.Item>
            ))}
          </Form>
        )}
      </div>
    </div>
  );
};

License.propTypes = {
  activeGoNext: func.isRequired,
  selectedSponsor: string.isRequired,
};

export default License;
