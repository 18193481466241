import { func, string } from "prop-types";
import React from "react";

const RankingCard = ({ t, desc, completeAction }) => (
  <>
    <div className="ranking-card__complete">
      <div className="ranking-card__completeWrapper">
        <div className="ranking-card__completeWrapper__text">
          <h5>{desc}</h5>
        </div>
      </div>
      <div className="ranking-card__completeGo">
        <button type="button" onClick={completeAction}>
          {t("label.complete")}
        </button>
      </div>
    </div>
  </>
);

RankingCard.propTypes = {
  t: func.isRequired,
  desc: string.isRequired,
  completeAction: func.isRequired,
};

export default RankingCard;
