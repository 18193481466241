/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Input, message, Modal } from "antd";
import { string } from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import copy from "../../../../../assets/images/copy-green.png";
import edit from "../../../../../assets/images/edit.png";
import { CLIENT_HOST } from "../../../../../common/config/config";
import Loader from "../../../../../components/loader";
import accountActions from "../../../../../services/account/actions";
import OnboardingActions from "../../../../../services/onboarding/actions";

const EndPointUpdate = ({ endPoint }) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [parsedUrl, setParsedUrl] = useState({ parsed: false, value: "" });
  const [rawUrl, setRawUrl] = useState("");

  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);

  useEffect(() => {
    if (account.success.updateEndPoint) {
      message.success("Url actualizada");
      dispatch(OnboardingActions.getProfile());
    }

    if (account.error.updateEndPoint) message.error(t(`error.${account.error.updateEndPoint}`));

    if (account.error.updateEndPoint || account.success.updateEndPoint)
      dispatch(accountActions.clearUpdateEndPoint());
  }, [account, dispatch, t]);

  useEffect(() => {
    if (!parsedUrl.parsed && rawUrl !== "") {
      const newParsedUrl = rawUrl.replace(/ /g, "-").toLocaleLowerCase();
      if (newParsedUrl.match(/[^<a-zA-Z0-9> -]/g))
        message.error("La url no puede contener carácteres especiales");

      setRawUrl(rawUrl.replace(/[^<a-zA-Z0-9> -]/g, ""));
      setParsedUrl({
        value: newParsedUrl.replace(/[^<a-zA-Z0-9> -]/g, ""),
        parsed: true,
      });
    }
  }, [parsedUrl.parsed, rawUrl]);

  const updateEndPoint = () => {
    if (parsedUrl.value === endPoint)
      return message.error("La dirección ingresada es igual a la actual");
    dispatch(accountActions.updateEndPoint(parsedUrl.value));
    return setIsEditing(false);
  };

  return (
    <div className="my-account__content--section">
      <Modal
        className="modal-loader"
        visible={account.loading.updateEndPoint}
        centered
        maskStyle={{ backgroundColor: "#ffffff66" }}
      >
        <Loader />
      </Modal>
      <h4>{t("label.URLHandle")}</h4>
      {isEditing ? (
        <div className="center center-row center-row-res">
          <Input
            className="input-endPoint input-wahtsaap"
            value={rawUrl}
            onChange={(e) => {
              setRawUrl(e.target.value);
              setParsedUrl({ value: parsedUrl.value, parsed: false });
            }}
          />
          <label className="body-regular">https://berrysafe.com/agente/{parsedUrl.value}</label>
          <div className="btns-section-account">
            <Button onClick={() => setIsEditing(false)} className="button button--tertiary">
              {t("button.cancel")}
            </Button>
            <Button onClick={updateEndPoint} className="button button--secundary">
              {t("button.updateInsuranceCarriers")}
            </Button>
          </div>
        </div>
      ) : (
        <div className="center center-row">
          <Input
            disabled
            value={`https://berrysafe.com/agente/${endPoint}`}
            suffix={
              <a
                href="./"
                onClick={async (e) => {
                  e.preventDefault();
                  if (navigator.clipboard) {
                    await navigator.clipboard.writeText(`${CLIENT_HOST}agente/${endPoint}`);
                  }
                }}
              >
                <img alt="Copy" src={copy} />
              </a>
            }
          />
          <Button
            onClick={() => {
              setIsEditing(true);
              setParsedUrl({ parsed: true, value: endPoint });
            }}
            className="btn-edit"
          >
            <img src={edit} alt="editar" />
          </Button>
        </div>
      )}
    </div>
  );
};

EndPointUpdate.propTypes = {
  endPoint: string.isRequired,
};

export default EndPointUpdate;
