/* eslint-disable class-methods-use-this */
/* eslint-disable no-constant-condition */
import { API_PIA_URL } from "common/config/config";
// eslint-disable-next-line import/no-cycle
import store from "index";
import { ZendeskAPI } from "react-zendesk";
import authActions from "../../services/auth/actions";

export class Api {
  async post(url, data) {
    return Promise.race([
      fetch(`${API_PIA_URL}${url}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwibmFtZSI6Ik1hcmNvIiwibGFzdG5hbWUiOiJMb3BleiIsImlhdCI6MTYzMDg2OTA1M30.tzXBMAT2sBXuGZsUIyo1--FJ_-AZ2b8FEfpoBLh9q0g`,
        },
        body: JSON.stringify(data),
      }),
      new Promise((_, reject) =>
        // eslint-disable-next-line prefer-promise-reject-errors
        setTimeout(() => reject({ payload: { error: "TIMEOUT" } }), 28000)
      ),
    ])
      .then(async (response) => {
        if (response.status >= 300 || response.status < 200) ZendeskAPI("messenger", "open");

        if (response.status === 401) await store.dispatch(authActions.logout(true));

        if (response.status === 404) return { message: "SERVICE_UNAVALIABLE" };

        // eslint-disable-next-line no-return-await
        return await response.json();
      })
      .catch((err) => err);
  }

  async put(url, data) {
    return Promise.race([
      fetch(`${API_PIA_URL}${url}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwibmFtZSI6Ik1hcmNvIiwibGFzdG5hbWUiOiJMb3BleiIsImlhdCI6MTYzMDg2OTA1M30.tzXBMAT2sBXuGZsUIyo1--FJ_-AZ2b8FEfpoBLh9q0g`,
        },
        body: JSON.stringify(data),
      }),
      new Promise((_, reject) =>
        // eslint-disable-next-line prefer-promise-reject-errors
        setTimeout(() => reject({ payload: { error: "TIMEOUT" } }), 28000)
      ),
    ])
      .then(async (response) => {
        if (response.status === 401) await store.dispatch(authActions.logout(true));

        if (response.status === 404) return { message: "SERVICE_UNAVALIABLE" };

        // eslint-disable-next-line no-return-await
        return await response.json();
      })
      .catch((err) => err);
  }

  // eslint-disable-next-line class-methods-use-this
  async get(url, params) {
    const completeUrl = new URL(`${API_PIA_URL}${url}`);
    if (params)
      Object.keys(params).forEach((key) => completeUrl.searchParams.append(key, params[key]));
    return fetch(completeUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwibmFtZSI6Ik1hcmNvIiwibGFzdG5hbWUiOiJMb3BleiIsImlhdCI6MTYzMDg2OTA1M30.tzXBMAT2sBXuGZsUIyo1--FJ_-AZ2b8FEfpoBLh9q0g`,
      },
    })
      .then(async (res) => {
        const payload = await res.json();

        if ((res.status >= 300 || res.status < 200) && !url.includes("detail-policy"))
          ZendeskAPI("messenger", "open");

        if (res.status === 401) {
          await store.dispatch(authActions.logout(true));
          return res;
        }

        if (payload.status === 404) return { message: "SERVICE_UNAVALIABLE" };

        return payload;
      })
      .catch((err) => err);
  }

  // eslint-disable-next-line class-methods-use-this
  setStructureFormdata(data) {
    const dataBody = new FormData();
    Object.keys(data).map((key) => {
      if (!Array.isArray(data[key])) {
        const isFile = data[key] && data[key].size;
        const isJson = typeof data[key] === "object";

        dataBody.append(key, isFile || !isJson ? data[key] : JSON.stringify(data[key]));
      } else data[key].forEach((item) => dataBody.append(key, item));
      return key;
    });
    return dataBody;
  }
}

export default new Api();
