/* eslint-disable react/jsx-props-no-spreading */
import { Button, Slider } from "antd";
import AutoSvg from "assets/images/pia-brands/auto.svg";
import DamageSvg from "assets/images/pia-brands/damage.svg";
import GmmSvg from "assets/images/pia-brands/gmm-icon.svg";
import VidaSvg from "assets/images/vida.svg";
import React from "react";
import PoliciesActions from "services/policies/actions";
import RowSvg from "assets/images/pia-brands/row.svg";
import { func, instanceOf } from "prop-types";

const settingsCaroucelVertical = {
  dots: true,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  vertical: true,
  verticalSwiping: true,
  swipeToSlide: true,
};

const dummyProduct = [
  { id: 3, key: "gmm", title: "GMM", icon: GmmSvg },
  { id: 1, key: "autos", title: "Autos", icon: AutoSvg },
  { id: 4, key: "daños", title: "Daños", icon: DamageSvg },
  { id: 2, key: "vida", title: "Vida", icon: VidaSvg },
];

const BranchSelector = ({ selectedProductLines, setSelectedProductLines, dispatch, filters }) => (
  <div className="box-mini box-mini--border">
    <p className="sub-title">Ramo</p>
    <div className="buttons">
      <Slider {...settingsCaroucelVertical}>
        {dummyProduct?.map((productLineItem) => (
          <Button
            onClick={() => {
              const newProductLines = [...selectedProductLines];
              const productLineIndex = newProductLines.findIndex(
                (productLine) => productLine === productLineItem.key
              );
              if (productLineIndex > -1) newProductLines.splice(productLineIndex, 1);
              else newProductLines.push(productLineItem.key);

              setSelectedProductLines(newProductLines);
              dispatch(PoliciesActions.setFilters("Ramos", productLineItem.key));
            }}
            key={productLineItem.key}
            className={`button${
              filters.Ramos.find((productLine) => productLine === productLineItem.key)
                ? "--select"
                : ""
            }`}
          >
            <div className="left">
              <div className="image">
                <img src={productLineItem.icon} alt="product" className="icon-product" />
              </div>
              <span className="body-bold">{productLineItem.title}</span>
            </div>
            <img src={RowSvg} alt="flecha" className="icon-product" />
          </Button>
        ))}
      </Slider>
    </div>
  </div>
);

BranchSelector.propTypes = {
  selectedProductLines: instanceOf(Array).isRequired,
  setSelectedProductLines: func.isRequired,
  dispatch: func.isRequired,
  filters: instanceOf(Object).isRequired,
};

export default BranchSelector;
