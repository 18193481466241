import { Button, Checkbox } from "antd";
import DownSvg from "assets/images/menu-green.svg";
import { bool, func, instanceOf } from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PoliciesActions from "services/policies/actions";

const ButtonSelect = ({ accounts, styleMode, insurance, getLast10 }) => {
  const dispatch = useDispatch();
  const [isActive, setActive] = useState(false);
  const { filters } = useSelector((state) => state.policies);

  const onChange = (state, account) => {
    if (state === false && filters.IdentificadorCuenta.length === 1) {
      dispatch(PoliciesActions.setFilters("IdentificadorCuenta", account));
      getLast10();
    } else dispatch(PoliciesActions.setFilters("IdentificadorCuenta", account));
    setActive(!isActive);
  };

  return (
    <div className="content-select-multiple">
      <Button
        className={`select-multiple ${
          filters.IdentificadorCuenta?.some((filteredAccount) =>
            accounts?.some((account) => account.uuid === filteredAccount)
          ) && "select-multiple--selected"
        }`}
        onClick={() => setActive(!isActive)}
      >
        <img
          src={
            styleMode ||
            filters.IdentificadorCuenta?.some((filteredAccount) =>
              accounts?.some((account) => account.uuid === filteredAccount)
            )
              ? insurance?.whiteImage
              : insurance?.image
          }
          alt="aseguradora"
          className="icon"
        />
        <div className="circle">
          <div className="circle--content">
            <span>{accounts?.length}</span>
          </div>
        </div>
        <img src={DownSvg} alt="flecha" className="icon-donw" />
      </Button>
      {isActive && (
        <div className="absolute-content">
          {accounts?.map((account) => (
            <div
              key={account.uuid}
              className={`item ${
                filters.IdentificadorCuenta?.some(
                  (filteredAccount) => filteredAccount === account.uuid
                ) && "item--selected"
              }`}
            >
              <Checkbox
                disabled={account.status !== "connected"}
                checked={filters.IdentificadorCuenta?.some(
                  (filteredAccount) => filteredAccount === account.id
                )}
                onChange={(e) => onChange(e.target.checked, account.id)}
              >
                {account.username}
              </Checkbox>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

ButtonSelect.propTypes = {
  accounts: instanceOf(Array).isRequired,
  styleMode: bool.isRequired,
  insurance: instanceOf(Object).isRequired,
  getLast10: func.isRequired,
};

export default ButtonSelect;
