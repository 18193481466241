import { createActions } from "redux-actions";

const { leads: leadsActions } = createActions({
  LEADS: {
    GET_MY_LEADS: (page, quantity, filters, order, typeList) => ({
      page,
      quantity,
      filters,
      order,
      typeList,
    }),
    GET_MY_LEADS_RESPONSE: ({ leads, total }) => ({
      leads,
      total,
    }),

    GET_RECENT_LEADS: () => ({}),
    GET_RECENT_LEADS_RESPONSE: (recentLeads) => recentLeads,

    GET_SUGGESTED_PRODUCTS: (lead) => ({ lead }),
    GET_SUGGESTED_PRODUCTS_RESPONSE: (suggestedProducts) => ({
      suggestedProducts,
    }),

    GET_ACTIVITIES: (lead) => ({ lead }),
    GET_ACTIVITIES_RESPONSE: (activities) => ({ activities }),

    DELETE_ACTIVITY: (interaction, lead) => ({ interaction, lead }),
    DELETE_ACTIVITY_RESPONSE: (response) => ({ response }),

    CREATE_ACTIVITY: (values) => ({ values }),
    CREATE_ACTIVITY_RESPONSE: (response) => ({ response }),

    GET_NOTES: (interaction) => ({ interaction }),
    GET_NOTES_RESPONSE: (notes) => ({ notes }),

    CREATE_NOTE: (values) => ({ values }),
    CREATE_NOTE_RESPONSE: (response) => ({ response }),

    DELETE_NOTE: (note) => ({ note }),
    DELETE_NOTE_RESPONSE: (response) => ({ response }),

    GET_LEAD_DETAIL: (lead) => ({ lead }),
    GET_LEAD_DETAIL_RESPONSE: (detail) => detail,

    GET_PRODUCT_LINES: () => ({}),
    GET_PRODUCT_LINES_RESPONSE: (productLines) => productLines,

    GET_ORIGINS: () => ({}),
    GET_ORIGINS_RESPONSE: (origins) => origins,

    CHANGE_STATUS: (lead, status) => ({ lead, status }),
    CHANGE_STATUS_RESPONSE: () => ({}),
    CLEAR_CHANGE_STATUS: () => ({}),
  },
});

export default leadsActions;
