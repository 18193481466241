/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Col, Typography } from "antd";
import { func } from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import OnboardingActions from "../../../../../services/onboarding/actions";
import OfficeActions from "../../../../../services/office/actions";
import BelongAssociation from "./components/belongAssociation/BelongAssociation";
import Completed from "./components/completed/Completed";
import InsuranceCarrier from "./components/insuranceCarriers/InsuranceCarrier";
import License from "./components/license/License";
import Offices from "./components/offices/offices";
import ProductLines from "./components/productLines/ProductLines";
import "./onboarding.scss";

const { Text } = Typography;

const OnBoarding = ({ setOmit }) => {
  const [isMobile, setIsMobile] = useState();
  const dispatch = useDispatch();
  const onboarding = useSelector((state) => state.onboarding);
  const [step, setStep] = useState(0);
  const { profile } = useSelector((state) => state.auth);
  const office = useSelector((state) => state.office);
  const [viewedSteps, setViewedStep] = useState([]);
  const [canGoNext, setCanGoNext] = useState(false);
  const [nextAction, setNextAction] = useState();
  const [saveOffice, setSaveOffice] = useState();
  const [selectedSponsor, setSelectedSponsor] = useState("");
  const [officeView, setOfficeView] = useState(false);

  useEffect(() => {
    const width = window.innerWidth;
    setIsMobile(width < 1024);
  }, []);

  useEffect(() => {
    if (!office.loading.countries && !office.success.countries && !office.error.countries)
      dispatch(OfficeActions.getCountries());
  }, [office, dispatch]);

  useEffect(() => {
    if (
      !onboarding.loading.insuranceCarrier &&
      !onboarding.success.insuranceCarriers &&
      !onboarding.error.insuranceCarrier
    )
      dispatch(OnboardingActions.getInsuranceCarriers());
  }, [dispatch, onboarding]);

  useEffect(() => {
    if (
      !onboarding.loading.productLines &&
      !onboarding.success.productLines &&
      !onboarding.error.productLines
    )
      dispatch(OnboardingActions.getProductLines());
  }, [dispatch, onboarding]);

  const { t } = useTranslation();

  const stepWasChecked = (newStep) => viewedSteps.some((viewed) => newStep === viewed);

  const activeGoNext = () => setCanGoNext(true);

  const next = async () => {
    if (nextAction?.key === "saveOffice" && nextAction) {
      await nextAction.run();
      return;
    }

    if (nextAction?.key === "editOffice") {
      await nextAction.run();
      return;
    }

    if (nextAction?.key === "editFull") {
      setViewedStep([...viewedSteps, step]);
      setStep(step + 1);
      setCanGoNext(false);
      await nextAction.run();
      return;
    }

    if (nextAction?.key === "changeForm") {
      await nextAction.run();
      return;
    }

    if (nextAction?.key === "saveFull") await saveOffice.run();

    setViewedStep([...viewedSteps, step]);
    setStep(step + 1);
    setCanGoNext(false);
  };

  const setNextActive = () => {
    activeGoNext();
  };

  return (
    <div className="onBoarding">
      <div className="content">
        <Col className="onBoarding__navigation" xs={24} lg={10} xl={10}>
          <h3>{t("label.welcome")},</h3>
          <h3>{profile?.person.name}</h3>
          <p>{t("label.welcomeBody")}</p>
          <div className="onBoarding__navigation__navigator">
            <div
              className={step === 0 ? "step-navigation--active" : "step-navigation--disabled"}
              onClick={() => setStep(0)}
            >
              <label className="container">
                <input type="checkbox" onChange={() => {}} checked={stepWasChecked(0)} />
                <span className="checkmark" />
              </label>
              <Text>{t("label.sponsor")}</Text>
              <p>{stepWasChecked(1) && t("label.stepReady")}</p>
            </div>
            <div
              className={step === 1 ? "step-navigation--active" : "step-navigation--disabled"}
              onClick={() => setStep(1)}
            >
              <label className="container">
                <input type="checkbox" onChange={() => {}} checked={stepWasChecked(1)} />
                <span className="checkmark" />
              </label>
              <Text>{t("label.license")}</Text>
              <p>{stepWasChecked(0) && t("label.stepReady")}</p>
            </div>

            <div
              className={step === 2 ? "step-navigation--active" : "step-navigation--disabled"}
              onClick={() => setStep(2)}
            >
              <label className="container">
                <input type="checkbox" onChange={() => {}} checked={stepWasChecked(2)} />
                <span className="checkmark" />
              </label>
              <Text>{t("label.insuranceCarriers")}</Text>
              <p>{stepWasChecked(2) && t("label.stepReady")}</p>
            </div>
            <div
              className={step === 3 ? "step-navigation--active" : "step-navigation--disabled"}
              onClick={() => setStep(3)}
            >
              <label className="container">
                <input type="checkbox" onChange={() => {}} checked={stepWasChecked(3)} />
                <span className="checkmark" />
              </label>
              <Text>{t("label.productLines")}</Text>
              <p>{stepWasChecked(3) && t("label.stepReady")}</p>
            </div>
            <div
              className={step === 4 ? "step-navigation--active" : "step-navigation--disabled"}
              onClick={() => setStep(4)}
            >
              <label className="container">
                <input type="checkbox" onChange={() => {}} checked={stepWasChecked(4)} />
                <span className="checkmark" />
              </label>
              <Text>{t("label.offices")}</Text>
              <p>{stepWasChecked(4) && t("label.stepReady")}</p>
            </div>
            <Button onClick={() => setOmit(true)} type="link">
              <p>{t("label.omitForNow")}</p>
            </Button>
          </div>
        </Col>
        <Col xs={24} lg={14} xl={14} className="onBoarding__steps">
          {step < 5 && !isMobile && (
            <div className="onBoarding__steps__omitStep">
              <Button
                onClick={() => {
                  setStep(step + 1);
                }}
                type="link"
              >
                <p>{t("button.SkipStep")}</p>
              </Button>
            </div>
          )}
          <div
            className={
              step < 5 ? "onBoarding__steps__step" : "onBoarding__steps__step-without-skip"
            }
          >
            {step === 0 && (
              <BelongAssociation
                association={onboarding.success.profile?.association}
                activeGoNext={() => setNextActive(0)}
                selectedSponsor={selectedSponsor}
                setSelectedSponsor={setSelectedSponsor}
              />
            )}
            {step === 1 && (
              <License selectedSponsor={selectedSponsor} activeGoNext={() => setNextActive(1)} />
            )}
            {step === 2 && <InsuranceCarrier activeGoNext={() => setNextActive(2)} />}

            {step === 3 && <ProductLines activeGoNext={() => setNextActive(3)} />}

            {step === 4 && (
              <Offices
                office={office}
                offices={onboarding.officesToSave}
                setNextAction={setNextAction}
                nextAction={nextAction}
                setSaveOffice={setSaveOffice}
                saveOffice={saveOffice}
                insuranceCarriers={onboarding.success.insuranceCarriersSelected}
                productLinesSelected={onboarding.success.productLinesSelected}
                setOfficeView={setOfficeView}
              />
            )}

            {step === 5 && <Completed />}
          </div>
          {step < 5 && (
            <div className="onBoarding__steps__nextStep">
              {(nextAction?.key === "saveFull" || nextAction?.key === "editFull") && (
                <Button
                  className="primary"
                  onClick={async () => {
                    if (nextAction?.key === "saveFull") return saveOffice.run();
                    await nextAction.run();
                    return null;
                  }}
                >
                  {t("button.addOtherOffice")}
                </Button>
              )}
              {step < 5 && (
                <Button
                  disabled={
                    !canGoNext &&
                    !nextAction &&
                    !(onboarding.officesToSave.length > 0 && officeView === 0)
                  }
                  className="secundary"
                  onClick={next}
                >
                  {nextAction?.key === "saveFull" || nextAction?.key === "editFull"
                    ? t("button.saveAndContinue")
                    : t("button.nextStep")}
                </Button>
              )}
            </div>
          )}
        </Col>
      </div>
    </div>
  );
};

OnBoarding.propTypes = {
  setOmit: func.isRequired,
};

export default OnBoarding;
