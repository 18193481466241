import NotSaveSvg from "assets/icon/icon-not-save.svg";
import YesSaveSvg from "assets/icon/icon-save.svg";
import SaveSvg from "assets/icon/save.svg";
import { instanceOf } from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import EditMyPageActions from "scenes/Private/editAgentPage/components/mainAgentInfo/services/actions";

const SaveSection = ({ routes, onboarding }) => {
  const dispatch = useDispatch();
  const agent = useSelector((state) => state.agent);
  const isSomeLoading = Object.keys(agent.loading).find((data) => agent.loading[data]);

  if (
    routes.actualPosition === "editPage" &&
    onboarding?.success.profile?.onBoarding &&
    !onboarding?.omitForNow
  )
    return (
      <a
        href="#save"
        id="menu-button-header"
        className="custom-trigger-btn"
        onClick={(e) => {
          e.preventDefault();
          dispatch(EditMyPageActions.saveAll(true));
        }}
      >
        <div className="save">
          <img
            className={`save__${isSomeLoading ? "not-save" : "save"}`}
            src={isSomeLoading ? NotSaveSvg : YesSaveSvg}
            alt={isSomeLoading ? "No guardado" : "Guardado"}
          />
          <img src={SaveSvg} alt="SaveSvg" />
        </div>
      </a>
    );

  return <></>;
};

SaveSection.propTypes = {
  onboarding: instanceOf(Object).isRequired,
  routes: instanceOf(Object).isRequired,
};

export default SaveSection;
