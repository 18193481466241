import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { func } from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import background from "../../../../../../../../../assets/images/location.jpg";
import warning from "../../../../../../../../../assets/images/warning.png";

const GmbCodeError = ({ setView }) => {
  const { t } = useTranslation();

  return (
    <div className="GmbCodeError">
      <div className="GmbCodeError__content" style={{ backgroundImage: `url(${background})` }}>
        <h4>{t("label.MyBusiness")}</h4>
        <Button className="goBack" onClick={() => setView(4)}>
          <ArrowLeftOutlined />
        </Button>
        <div className="GmbCodeError--card">
          <div className="left">
            <h3>{t("label.gmbCodeTitle")}</h3>
            <p>{t("label.gmbCodeDescription")}</p>
            <Button onClick={setView} className="button--secondary">
              {t("button.sendAgain")}
            </Button>
          </div>
          <div className="right">
            <img alt="warning" src={warning} />
          </div>
        </div>
      </div>
    </div>
  );
};

GmbCodeError.propTypes = { setView: func.isRequired };

export default GmbCodeError;
