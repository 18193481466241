/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import { Row, Checkbox } from "antd";
import showTranslatedText from "common/utils/showTranslatedText";
import { func } from "prop-types";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getI18n, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import linesSvg from "../../../../../../../assets/icon/icon-lines.svg";
import Loader from "../../../../../../../components/loader";
import OnboardingActions from "../../../../../../../services/onboarding/actions";
import "./productLines.scss";

const ProductLines = ({ activeGoNext }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [blankFields, setBlankFields] = useState([0, 1, 2, 3]);

  const onboarding = useSelector((state) => state.onboarding);

  useEffect(() => {
    if (
      !onboarding.loading.productLines &&
      !onboarding.success.productLines &&
      !onboarding.error.productLines
    ) {
      dispatch(OnboardingActions.getProductLines());
    }
  }, [dispatch, onboarding]);

  useEffect(() => {
    if (onboarding.success.productLinesOrdered) {
      if (onboarding.success.productLinesOrdered.length === blankFields.length)
        setBlankFields([...blankFields, blankFields.length - 1]);

      if (
        onboarding.success.productLinesOrdered.length < blankFields.length &&
        blankFields.length > 4
      ) {
        const newBlankFields = blankFields;
        newBlankFields.pop();
        setBlankFields([...newBlankFields]);
      }
    }
  }, [onboarding, blankFields]);

  const onDragEnd = (result) => {
    dispatch(OnboardingActions.orderProductLines(result.source, result.destination));
    activeGoNext(true);
  };

  const selectProductLine = (productLine) => {
    dispatch(OnboardingActions.selectProductLine(productLine));
    activeGoNext(true);
  };

  const renderDroppables = () => {
    return Object.keys(onboarding.success.productLines).map((drop, index) => {
      const productLineList = onboarding.success.productLines[drop];
      return (
        <Droppable key={drop} droppableId={drop}>
          {(provided) => (
            <div className="wrapper" ref={provided.innerRef}>
              {index === 0 && <h6 style={{ marginTop: -25 }}>Más importante</h6>}
              {productLineList.map((productLine, i) => {
                return (
                  <Draggable key={productLine.id} draggableId={`${productLine.id}`} index={i}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`productLines__listProduct-line${
                          snapshot.isDragging || productLine.selected ? "--selected" : "--disabled"
                        }`}
                      >
                        <div
                          className={`productLines__listProduct-line${
                            snapshot.isDragging || productLine.selected
                              ? "--selected__view"
                              : "--disabled__view"
                          }`}
                        >
                          <Checkbox
                            checked={productLine.selected}
                            onChange={() => selectProductLine(productLine)}
                          />
                        </div>
                        <div
                          className={`productLines__listProduct-line${
                            snapshot.isDragging || productLine.selected
                              ? "--selected__product"
                              : "--disabled__product"
                          }`}
                        >
                          <div
                            className={`productLines__listProduct-line${
                              snapshot.isDragging || productLine.selected
                                ? "--selected__productImage"
                                : "--disabled__productImage"
                            }`}
                          >
                            <img
                              src={
                                snapshot.isDragging || productLine.selected
                                  ? productLine.iconWhite
                                  : productLine.icon
                              }
                              alt={productLine.title}
                            />
                          </div>
                          <div
                            className={`productLines__listProduct-line${
                              snapshot.isDragging || productLine.selected
                                ? "--selected__productText"
                                : "--disabled__productText"
                            }`}
                          >
                            <p>{showTranslatedText(productLine.title, getI18n().language)}</p>
                          </div>
                        </div>
                        <div
                          className={`productLines__listProduct-line${
                            snapshot.isDragging || productLine.selected
                              ? "--selected__lines"
                              : "--disabled__lines"
                          }`}
                        >
                          <img src={linesSvg} alt="lines" />
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
              {index === Object.keys(onboarding.success.productLines).length - 1 && (
                <h6>{t("label.LessImportant")}</h6>
              )}
            </div>
          )}
        </Droppable>
      );
    });
  };

  return (
    <div className="productLines">
      <div className="productLines__header">
        <h4>{t("label.titleProductLines")}</h4>
        <p>{t("label.subtitleProductLine")}</p>
      </div>
      {onboarding.loading.productLines && <Loader />}
      {onboarding.success.productLines && (
        <Row className="productLines__content">
          <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
            {renderDroppables()}
          </DragDropContext>
        </Row>
      )}
    </div>
  );
};

ProductLines.propTypes = {
  activeGoNext: func.isRequired,
};

export default ProductLines;
