import { Button, message, Spin } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import OnboardingActions from "../../../../../../../services/onboarding/actions";
import "./completed.scss";
import like from "../../../../../../../assets/images/like-svg.svg";

const OnBoardingComplete = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onboarding = useSelector((state) => state.onboarding);

  useEffect(() => {
    if (onboarding.success.complete)
      message.success({
        content: "Los datos se han registrado con éxito",
        icon: (
          <svg
            className="icon-check-svg"
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.83877 5.6076L5.39204 9.16088L14.3405 0.212402L15.4703 1.34218L5.39204 11.4204L0.708984 6.73738L1.83877 5.6076Z"
              fill="#5D30DD"
            />
          </svg>
        ),
      });

    if (onboarding.error.complete)
      message.error({
        content: "Los datos no se han registrado",
      });
  }, [onboarding]);

  useEffect(() => {
    if (onboarding.success.complete) dispatch(OnboardingActions.getProfile());
  }, [onboarding, dispatch]);

  const finish = () => {
    dispatch(OnboardingActions.saveOnboarding());
  };

  return (
    <div className="onBoardingComplete animate__fadeInLeft  animate__animated">
      <div className="onBoardingComplete__congrats">
        <img src={like} className="like" alt="like" />
        <h3>
          ¡Muchas gracias por <br />
          completar la información!
        </h3>
        <p>Puedes continuar personalizando tu perfil para avanzar tu ranking en Berrysafe.</p>
      </div>
      {onboarding.loading.complete && <Spin />}
      <div className="finish">
        <Button
          disabled={onboarding.loading.complete}
          loading={onboarding.loading.complete}
          onClick={() => finish()}
        >
          {t("button.goToProfile")}
        </Button>
      </div>
    </div>
  );
};

export default OnBoardingComplete;
