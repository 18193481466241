import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import clientBookReducer from "services/clientBook/reducer";
import editPageReducer from "../scenes/Private/editAgentPage/components/mainAgentInfo/services/reducer";
import accountReducer from "../services/account/reducer";
import agentReducer from "../services/agent/reducer";
import authReducer from "../services/auth/reducer";
import leadsReducer from "../services/leads/reducer";
import marketplaceReducer from "../services/marketplace/reducer";
import officeReducer from "../services/office/reducer";
import onboardingReducer from "../services/onboarding/reducer";
import reportsReducer from "../services/reports/reducer";
import routesReducer from "../services/routes/reducer";
import policiesReducer from "../services/policies/reducer";
import mixpanelReducer from "../services/mixpanel/reducer";

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    onboarding: onboardingReducer,
    routes: routesReducer,
    leads: leadsReducer,
    agent: agentReducer,
    marketplace: marketplaceReducer,
    reports: reportsReducer,
    editPage: editPageReducer,
    account: accountReducer,
    office: officeReducer,
    clientBook: clientBookReducer,
    policies: policiesReducer,
    mixpanel: mixpanelReducer,
  });

const rootReducer = (history) => (state, action) => {
  if (action.type === "AUTH/LOGOUT_RESPONSE") return appReducer(history)(undefined, action);
  return appReducer(history)(state, action);
};

export default rootReducer;
