/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes, { instanceOf, Object } from "prop-types";
import parseThousandNumber from "common/utils/parseThousandNumber";
import AutoSvg from "assets/images/pia-brands/auto.svg";
import DamageSvg from "assets/images/pia-brands/damage.svg";
import GmmSvg from "assets/images/pia-brands/gmm-icon.svg";
import VidaSvg from "assets/images/vida.svg";

const CardCommissions = ({ data, type }) => {
  return (
    <div className={`CardCommissions CardCommissions--${type} `}>
      <div className="CardCommissions__head">
        <div className="circle">
          {type === "branch" ? (
            <>
              {data.branch === "autos" && <img src={AutoSvg} alt="icon" className="icon" />}
              {data.branch === "daños" && <img src={DamageSvg} alt="icon" className="icon" />}
              {data.branch === "gmm" && <img src={GmmSvg} alt="icon" className="icon" />}
              {data.branch === "vida" && <img src={VidaSvg} alt="icon" className="icon" />}
              {data.branch === "salud" && <img src={VidaSvg} alt="icon" className="icon" />}
            </>
          ) : (
            <img src={data.logo} alt="icon" className="icon" />
          )}
        </div>
        <p className="sub-title">{data.branch}</p>
        <div className="rtl">
          <div className="item">
            <p className="small-detail">Emitidas</p>
            <p className="body-bold">{data.issued}</p>
          </div>
          <div className="item">
            <p className="small-detail">Por cobrar</p>
            <div className="flex">
              <p className="body-bold">{data.receivable}</p>
              <div className="divider" />
              <p className="body-bold">${data.receivable}K</p>
            </div>
          </div>
        </div>
      </div>
      <div className="CardCommissions__body">
        <div className="item">
          <span className="small-detail">{type === "branch" ? "Primas" : "Pagadas"}</span>
          <h1 className="h1-gradient">$ {parseThousandNumber(data.paid)}</h1>
        </div>
        <div className="item item-two">
          <span className="small-detail">Primas</span>
          <h1 className="h1-gradient">{data?.quantity}</h1>
        </div>
      </div>
    </div>
  );
};

CardCommissions.propTypes = {
  data: instanceOf(Object),
  type: PropTypes.string,
};

export default CardCommissions;
