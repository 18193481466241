import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Divider } from "antd";
import { changeRoute } from "common/utils/changeRoute";
import Loader from "components/loader";
import { bool, func, instanceOf } from "prop-types";
import arrow from "assets/images/arrow-rigth-green.svg";
import settings from "assets/images/settings-purple.svg";

const HeaderNotification = ({
  t,
  isLoading,
  unreadNotifications,
  allNotifications,
  setIsLoading,
  setUnreadNotifications,
  history,
  dispatch,
  notificationRef,
}) => {
  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  useEffect(() => {
    if (!notificationRef) return;
    unreadNotifications
      .filter((notification) => !notification.read)
      .map((notify) =>
        notificationRef
          .child(notify.key)
          .set({
            title: notify.title,
            text: notify.text,
            link: notify.link,
            read: true,
          })
          .catch(() => {})
      );
  }, [unreadNotifications, notificationRef]);

  return (
    <div className="without-notifications">
      {isLoading ? (
        <Loader className="loader-small" />
      ) : (
        <div className="without-notifications__header">
          <p>{t("label.notifications")}</p>
          <div>
            <img src={settings} alt="settings" className="settings" />
            <Link id="config-button-header" to="/account/notification">
              {t("label.SetUp")}
            </Link>
          </div>
        </div>
      )}
      <Divider />
      {unreadNotifications?.length <= 0 && (
        <div className="without-notifications__body">
          <p>No tienes notificaciones sin leer</p>
        </div>
      )}

      {unreadNotifications.map((notification) => (
        <div key={notification.key} className="item-notification">
          <div>
            <p className="item-notification--title body-bold">{notification.title}</p>
            <p className="item-notification--text body-regular">{notification.text}</p>
          </div>
          <Button
            onClick={() => {
              changeRoute(notification.link, history, dispatch);
            }}
            className="button button-secundary"
          >
            Ver más
          </Button>
        </div>
      ))}
      {unreadNotifications.length < allNotifications.length && (
        <Button
          className="button-see-more"
          onClick={async () => {
            setIsLoading(true);
            await timeout(1200);
            setIsLoading(false);
            setUnreadNotifications([...unreadNotifications, ...allNotifications]);
          }}
        >
          Ver todas las notificaciones
          <img src={arrow} alt="icon" className="icon" />
        </Button>
      )}
    </div>
  );
};

HeaderNotification.propTypes = {
  t: func.isRequired,
  isLoading: bool.isRequired,
  unreadNotifications: instanceOf(Array).isRequired,
  allNotifications: instanceOf(Array).isRequired,
  setIsLoading: func.isRequired,
  setUnreadNotifications: func.isRequired,
  history: instanceOf(Object).isRequired,
  dispatch: func.isRequired,
  notificationRef: instanceOf(Object),
};

HeaderNotification.defaultProps = {
  notificationRef: null,
};

export default HeaderNotification;
