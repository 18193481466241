/* eslint-disable react/jsx-props-no-spreading */
import { MailOutlined } from "@ant-design/icons";
import { Alert, Col, Form, Input, Row } from "antd";
import { func } from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../components/loader";
import AuthActions from "../../../../../services/auth/actions";

const layout = {
  wrapperCol: {
    span: 24,
  },
};

const ForgotPassword = ({ cancelForgotPassword, changeToValidateCode }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { t } = useTranslation();

  useEffect(() => {
    if (auth.success.forgotPassword) {
      changeToValidateCode();
      dispatch(AuthActions.cancelForgotPassword());
    }
  }, [auth.success.forgotPassword, changeToValidateCode, dispatch]);

  const onFinish = (values) => dispatch(AuthActions.forgotPassword(values));

  return (
    <div className="forgotPassword">
      {auth.loading.forgotPassword ? (
        <Loader />
      ) : (
        <div>
          <Row>
            <figure className="forgotPassword__redes">
              <h1 className="forgotPassword__redesTitle">{t("label.forgotPasswordTitle")}</h1>
              <p className="forgotPassword__redesDescrip">{t("label.forgotPasswordDescrip")}</p>
            </figure>
          </Row>
          <div className="forgotPassword__form">
            <Form {...layout} name="forgotPassword" onFinish={onFinish}>
              <div className="item">
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t("error.email"),
                      type: "email",
                    },
                  ]}
                >
                  <Input
                    id="email-input-forgotPassword"
                    prefix={<MailOutlined />}
                    placeholder={t("placeholder.inputEmail")}
                    className="input-custom"
                  />
                </Form.Item>
              </div>

              <Row>
                <Col lg={24}>
                  <button
                    id="continue-button-forgotPassword"
                    className="btn-custom btn-custom--secundary"
                    disabled={auth.loading.forgotPassword}
                    type="submit"
                  >
                    {t("button.continue")}
                  </button>
                </Col>
                <Col lg={24}>
                  <button
                    id="cancel-button-forgotPassword"
                    className="btn-custom btn-custom--white"
                    onClick={cancelForgotPassword}
                    type="button"
                  >
                    {t("button.cancel")}
                  </button>
                </Col>
              </Row>
            </Form>

            {auth.error && auth.error.forgotPassword && (
              <div className="alert">
                <Alert message={t(`error.${auth.error.forgotPassword}`)} type="error" showIcon />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

ForgotPassword.propTypes = {
  cancelForgotPassword: func.isRequired,
  changeToValidateCode: func.isRequired,
};

export default ForgotPassword;
