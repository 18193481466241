import { Button, Col, Form, Input, Row } from "antd";
import { func, instanceOf } from "prop-types";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

const VerifyAddress = ({ detailedAddress, goNext, setVerifiedAddress }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const setData = useCallback(() => {
    form.setFieldsValue({
      country: detailedAddress?.country,
      state: detailedAddress?.areaLevel,
      city: detailedAddress?.city,
      address: detailedAddress?.address,
      zipcode: detailedAddress?.postalCode,
    });
  }, [form, detailedAddress]);

  useEffect(() => {
    if (detailedAddress) setData();
  }, [detailedAddress, setData]);

  const onFinish = (values) => {
    setVerifiedAddress(values);
    goNext();
  };

  return (
    <div className="offices-editing__cardForms">
      <h3>{t("label.VerifyAddress")}</h3>
      <Form form={form} name="horizontal_login" layout="vertical" onFinish={onFinish}>
        <Row>
          <Col xs={12}>
            <Form.Item
              label={t("label.Country")}
              name="country"
              rules={[
                {
                  required: true,
                  message: t("error.YouEnterCountry"),
                },
              ]}
            >
              <Input disabled={detailedAddress.country} type="text" placeholder="México" />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label={t("label.state")}
              name="state"
              rules={[
                {
                  required: true,
                  message: t("error.YouEnterState"),
                },
              ]}
            >
              <Input disabled={detailedAddress.areaLevel} type="text" placeholder="Guadalajara" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              label={t("label.City")}
              name="city"
              rules={[
                {
                  required: true,
                  message: t("error.YouEnterCity"),
                },
              ]}
            >
              <Input disabled={detailedAddress.city} type="text" placeholder="Capital" />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label={t("label.PostalCode")}
              name="zipcode"
              rules={[
                {
                  required: true,
                  message: t("error.YouEnterCodePostal"),
                },
              ]}
            >
              <Input disabled={detailedAddress.postalCode} type="text" placeholder="00000" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={t("label.address")}
          name="address"
          rules={[
            {
              required: true,
              message: t("error.YouEnterAdress"),
            },
          ]}
        >
          <Input
            disabled={detailedAddress.address}
            style={{ width: "95%" }}
            type="text"
            placeholder="1230 Av. tepec"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("label.nextStep")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

VerifyAddress.propTypes = {
  detailedAddress: instanceOf(Object),
  goNext: func.isRequired,
  setVerifiedAddress: func.isRequired,
};

VerifyAddress.defaultProps = {
  detailedAddress: null,
};

export default VerifyAddress;
