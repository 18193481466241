import React from "react";
import LeadsActions from "services/leads/actions";
import PropTypes, { func } from "prop-types";
import { useDispatch } from "react-redux";
import { Select } from "antd";
import DownSvg from "../../../../../../assets/images/down-purple-dropdown.svg";

const { Option } = Select;

const ProductDescription = ({ product, selectedStatus, status, leadId, t }) => {
  const dispatch = useDispatch();
  return (
    <div className="sidebar-modal__product_description">
      {product.iconColor && <img alt="car_icon" src={product.iconColor} className="car-icon" />}
      <div className="sidebar-modal__requestInfo__state">
        <span>{t("label.state")}</span>
        <Select
          suffixIcon={<img src={DownSvg} className="down" alt="down" />}
          defaultValue={selectedStatus.key}
          style={{ width: 120 }}
          onChange={(value) => dispatch(LeadsActions.changeStatus(+leadId, value))}
        >
          {status.map((statusItem) => (
            <Option key={statusItem.id} value={statusItem.key}>
              {statusItem.name}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

ProductDescription.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  leadId: PropTypes.number.isRequired,
  status: PropTypes.instanceOf(Array).isRequired,
  selectedStatus: PropTypes.instanceOf(Object).isRequired,
  t: func.isRequired,
};

export default ProductDescription;
