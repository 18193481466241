import IconoHomePng from "../../assets/images/Tutorial/Dashboard/iconohome.png";
import IconoTutorialPng from "../../assets/images/Tutorial/Dashboard/iconotutorial.png";
import Sidebar1Png from "../../assets/images/Tutorial/Dashboard/Sidebar1.png";
import Sidebar2Png from "../../assets/images/Tutorial/Dashboard/Sidebar2.png";
import TuCuentaPng from "../../assets/images/Tutorial/Dashboard/tucuenta.png";
import Experiencia1Png from "../../assets/images/Tutorial/Editar_perfil/Experiencia1.png";
import Experiencia2Png from "../../assets/images/Tutorial/Editar_perfil/Experiencia2.png";
import Info1Png from "../../assets/images/Tutorial/Editar_perfil/Info1.png";
import Info2Png from "../../assets/images/Tutorial/Editar_perfil/Info2.png";
import PrevisualizarPng from "../../assets/images/Tutorial/Editar_perfil/Previsualizar.png";
import RequisitosPng from "../../assets/images/Tutorial/Editar_perfil/Requisitosparapublicar.png";
import Detalle1Png from "../../assets/images/Tutorial/Maneja_tus_leads/Detalle1.png";
import Historial2Png from "../../assets/images/Tutorial/Maneja_tus_leads/Historial2.png";
import Productos1Png from "../../assets/images/Tutorial/Maneja_tus_leads/Productos1.png";
import Productos2Png from "../../assets/images/Tutorial/Maneja_tus_leads/Productos2.png";
import TableLeads1Png from "../../assets/images/Tutorial/Maneja_tus_leads/Tabladeleads1.png";
import FiltrarporAseguradorasPng from "../../assets/images/Tutorial/Marketplace/filtrarporaseguradoras.png";
import NuevoProducto1Png from "../../assets/images/Tutorial/Marketplace/Nuevoproducto1.png";
import NuevoProducto2Png from "../../assets/images/Tutorial/Marketplace/Nuevoproducto2.png";
import ProductosmasvendidosPng from "../../assets/images/Tutorial/Marketplace/productosmasvendidos.png";
import VerporlineaPng from "../../assets/images/Tutorial/Marketplace/verporlinea.png";
import NuevaDireccion1Png from "../../assets/images/Tutorial/nueva-dirección/NuevaDireccion1.png";
import NuevaDireccion4 from "../../assets/images/Tutorial/nueva-dirección/NuevaDireccion4.png";
import AseguradorasPng from "../../assets/images/Tutorial/Onboarding/aseguradoras.png";
import Despacho3Png from "../../assets/images/Tutorial/Onboarding/despacho3.png";
import LicenciasPng from "../../assets/images/Tutorial/Onboarding/licencias.png";
import Lineas1Png from "../../assets/images/Tutorial/Onboarding/lineas1.png";
import Lineas2Png from "../../assets/images/Tutorial/Onboarding/lineas2.png";
import SponsorPng from "../../assets/images/Tutorial/Onboarding/Sponsor.png";

import Dashboard1 from "../../assets/images/Tutorial/Dashboard/Notificaciones Slice.png";
import manejaleads2 from "../../assets/images/Tutorial/Maneja_tus_leads/leads vendidos slice.png";
import agregadespachoslice from "../../assets/images/Tutorial/Editar_perfil/agrega despacho slice.png";
import Dashboard3 from "../../assets/images/Tutorial/Dashboard/opciones perfil header slice.png";

const tutorialInfo = [
  {
    id: 1,
    name: "Onboarding",
    key: "Onboarding",
    steps: [
      {
        id: 1,
        title: "Completa la información básica seleccionando tu tipo cédula",
        image: LicenciasPng,
      },
      {
        id: 2,
        title: "Selecciona las líneas de producto arrastrando según la importancia",
        image: Lineas1Png,
      },
      {
        id: 3,
        title: "Arrastra tus líneas de producto según la importancia",
        image: Lineas2Png,
      },
      {
        id: 4,
        title: "Indica si eres asociado AMASFAC",
        image: SponsorPng,
      },
      {
        id: 5,
        title: "Selecciona las aseguradoras con las que trabajas, puedes seleccionar más de una",
        image: AseguradorasPng,
      },
      {
        id: 6,
        title: "Agrega la información relacionada con la ubicación de tu(s) despacho(s).",
        image: agregadespachoslice,
      },
      {
        id: 7,
        title: "Selecciona las líneas de seguro y aseguradoras de este despacho.",
        image: Despacho3Png,
      },
    ],
  },
  {
    id: 2,
    name: "Editar perfil",
    key: "edit",
    steps: [
      {
        id: 1,
        title: "Edita tu perfil",
        image: Info1Png,
        text: "Clic al ícono de lapiz para editar tu información personal.",
      },
      {
        id: 2,
        title: "Completa los campos",
        image: Info2Png,
        text: "Agrega tu información personal, una pequeña descripción en tu perfil y no olvides agregar tus redes sociales.",
      },
      {
        id: 3,
        title: "Agrega tu experiencia",
        image: Experiencia1Png,
        text: "Clic al ícono de lápiz en el apartado de experiencia.",
      },
      {
        id: 4,
        title: "Agrega las aseguradoras con las que trabajas",
        image: Experiencia2Png,
        text: "Revisa y/o agrega la información relacionada con las aseguraduras que trabajas, arrastra los logos y listo.",
      },

      {
        id: 5,
        title: "Previsualiza tu página",
        image: PrevisualizarPng,
        text: "Clic al ícono de previsualizar que encuentras en la parte superior de la página para que puedas verificar la información antes de publicar tu página.",
      },
      {
        id: 6,
        title: "Asegúrate de llenar el 100% de tu información",
        image: RequisitosPng,
        text: "Verifica los requisitos mínimos que debes cumplir antes de publicar tu página.",
      },
      {
        id: 7,
        title: "Administra los datos de tus despacho",
        image: NuevaDireccion1Png,
        text: "Agrega la dirección de tu despacho dando clic en la edición de tu perfil a 'Agregar dirección’ y completa los campos",
      },
      {
        id: 8,
        title: "Verifica tu información",
        image: agregadespachoslice,
        text: "Completa los datos de contacto del despacho agregado, ingresa nombre y número de contacto y registra la dirección.",
      },
      {
        id: 9,
        title: "Elige el despacho visible para tu perfil",
        image: NuevaDireccion4,
        text: "Después de registrar la dirección, visualiza los despachos agregados y selecciona cuál quieres que sea visible en tu perfil,",
      },
    ],
  },
  {
    id: 3,
    name: "Dashboard",
    key: "induccion",
    steps: [
      {
        id: 1,
        title: "Visualiza el calendario en tu barra lateral ",
        image: Sidebar1Png,
        text: "Encuentra una barra lateral donde puedes visualizar el calendario y el acceso a tu dashboard.",
      },
      {
        id: 2,
        title: "Conoce las opciones de tu sidebar",
        image: Sidebar2Png,
        text: "En tu dashboard puedes acceder a Leads, Marketplace, P.I.A y cerrar sesión.",
      },
      {
        id: 3,
        title: "Regresa rápidamente al dashboard",
        image: IconoHomePng,
        text: "En la barra superior para ir a la página principal puedes dar clic en el ícono de casa.",
      },
      {
        id: 4,
        title: "Visualiza el tutorial cuando lo necesites",
        image: IconoTutorialPng,
        text: "Si quieres ver este tutorial en cualquier momento, solo da clic al ícono de interrogante que encuentras en la barrra superior, donde puedes desplegarlo cuántas veces quieras.",
      },
      {
        id: 5,
        title: "Visualiza tus notificaciones",
        image: Dashboard1,
        text: "En la barra superior, si das clic al ícono de campana, visualizarás las notificaciones que tengas relacionadas con tu página.",
      },
      {
        id: 6,
        title: "Administra el panel de control de tu cuenta",
        image: Dashboard3,
        text: "En la barra superior encuentras un ícono de agente o la foto de tu perfil si ya has asignado una, allí puedes acceder para editar tu perfil, configuraciones de tu cuenta, conexiones PIA y cerrar sesión.",
      },
      {
        id: 7,
        title: "Verifica cómo Administrar el panel de control de tu cuenta.",
        image: TuCuentaPng,
        text: "Al entrar a tu cuenta puedes editar tus datos principales de contacto y solicitar cambio de contraseña para acceder a Berrysafe",
      },
    ],
  },
  {
    id: 4,
    name: "Maneja tus leads",
    key: "negocio",
    steps: [
      {
        id: 1,
        title: "Administra tus clientes potenciales",
        image: TableLeads1Png,
        text: "En el Dashboard tendrás una sección de tus Leads y podrás visualizar el listado completo de clientes potenciales dando clic en ‘Ver más’.",
      },
      {
        id: 2,
        title: "Administra tus clientes",
        image: manejaleads2,
        text: "Filtra tus contactos por vendidos y pendientes logrando mantener un mayor control de tus ventas y clientes potenciales. ",
      },
      {
        id: 3,
        title: "Conoce en detalle cada uno de tus clientes",
        image: Detalle1Png,
        text: "al dar clic en detalle podrás obtener información importante como sus productos seleccionados e información personal del cliente valiosa para ti. ",
      },
      {
        id: 4,
        title: "Agrega información de tus clientes potenciales",
        image: Historial2Png,
        text: "Ingresa nueva actividad relacionada con el lead que recibiste, puedes especificar por qué medio hubo contacto, el detalle de la actividad y la fecha.",
      },
      {
        id: 5,
        title: "Visualiza el listado de productos del marketplace y compártelos con tu cliente.",
        image: Productos1Png,
        text: "Visualiza en el marketplace el listado de productos recomendados por Berrysafe para tu cliente.",
      },
      {
        id: 6,
        title: "Descarga la ficha técnica del producto ",
        image: Productos2Png,
        text: "Comparte con tu cliente potencial los beneficios y detalles del producto que quieres ofrecer. ",
      },
    ],
  },
  {
    id: 5,
    name: "Marketplace",
    key: "publicPage",
    steps: [
      {
        id: 1,
        title: "Conoce cómo puedes administrar los productos que ofreces a tus clientes.",
        image: ProductosmasvendidosPng,
        text: " Visualiza el listado de productos destacados por Berrysafe.",
      },
      {
        id: 2,
        title: "Busca los productos del marketplace por aseguradora",
        image: FiltrarporAseguradorasPng,
      },
      {
        id: 3,
        title: "Visualiza el listado de productos disponibles de acuerdo a la línea de seguro",
        image: VerporlineaPng,
        text: "selecciona en la barra lateral la línea de tu interés.",
      },
      {
        id: 4,
        title: "Activa cualquiera de los productos disponibles en el marketplace",
        image: NuevoProducto1Png,
        text: "Solo debes dar clic en el ícono de ojo para que sea visible en tu página.",
      },
      {
        id: 5,
        title: "Confirma que quieres hacer visible el producto",
        image: NuevoProducto2Png,
        text: "Asegúrate de trabajar con la aseguradora que ofrece el producto seleccionado ",
      },
    ],
  },
];

export default tutorialInfo;
