/* eslint-disable no-plusplus */
/* eslint-disable react/no-unescaped-entities */
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LongGraph from "components/Tablets/LongGraph";
import PoliciesActions from "services/policies/actions";
import moment from "moment";
import parseThousandNumber from "common/utils/parseThousandNumber";
import Loader from "components/loader";
import { useHistory } from "react-router";
import { bool } from "prop-types";
import arrowSvg from "assets/images/arrow-rigth-green-line.svg";

const SectionCommissions = ({ styleDark }) => {
  const [dataPoint, setDataPoint] = useState(undefined);
  const [isoptions, setoptions] = useState(undefined);
  const [optionSelected, setoptionSelected] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const policiesStore = useSelector((state) => state.policies);

  useEffect(() => {
    if (
      !policiesStore.loading.insurances &&
      !policiesStore.success.insurances &&
      !policiesStore.error.insurances
    )
      dispatch(PoliciesActions.getAllInsurances());
  }, [policiesStore, dispatch]);

  useEffect(() => {
    const arrayX = [];
    let arrayMonth = [];

    for (let index = 0; index < policiesStore?.success?.commissions?.chartData?.length; index++) {
      arrayMonth = [...arrayMonth, policiesStore?.success?.commissions?.chartData[index].month];
    }
    const newMonthsOrder = arrayMonth.sort();

    for (let index = 0; index < newMonthsOrder?.length; index++) {
      for (let j = 0; j < policiesStore?.success?.commissions?.chartData?.length; j++) {
        if (newMonthsOrder[index] === policiesStore?.success?.commissions.chartData[j]?.month) {
          arrayX.push(policiesStore?.success?.commissions.chartData[j].value);
        }
      }
    }

    const arrayX2 = [];
    for (let index = 0; index < arrayX.length; index++) {
      arrayX2.push(`$${arrayX[index]}`);
    }
    const Months = [];
    for (let index = 0; index < newMonthsOrder.length; index++) {
      Months.push(moment(newMonthsOrder[index]).format("MMM YYYY"));
    }

    setDataPoint([{ name: "pagos", data: arrayX, months: arrayMonth }]);
    if (styleDark) {
      setoptions({
        chart: {
          height: 300,
          type: "line",
        },
        stroke: {
          width: 3,
          curve: "smooth",
        },
        labels: arrayX2,
        xaxis: {
          categories: Months,
          title: {
            text: "Fechas",
          },
        },
        dataLabels: {
          enabled: false,
          labels: arrayX2,
          formatter: (value) =>
            new Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(value),
        },
        colors: ["#7F14FC", "#7F14FC", "#7F14FC", "#7F14FC"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            gradientToColors: ["#5D30DD"],
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 0, 0, 0],
          },
        },
        markers: {
          size: 7,
          colors: ["#35D8D0"],
          strokeColors: "#fff",
          strokeWidth: 1,
          hover: {
            size: 4,
          },
        },
        yaxis: {
          title: {
            text: "Mxn",
          },
          labels: {
            formatter: (value) =>
              new Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(value),
          },
        },
      });
    } else {
      setoptions({
        chart: {
          height: 300,
          type: "line",
        },
        stroke: {
          width: 3,
          curve: "smooth",
        },
        labels: arrayX2,
        xaxis: {
          categories: Months,
          title: {
            text: "Fechas",
          },
        },
        dataLabels: {
          enabled: false,
          labels: arrayX2,
          formatter: (value) =>
            new Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(value),
        },
        colors: ["#7F14FC", "#7F14FC", "#7F14FC", "#7F14FC"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            gradientToColors: ["#5D30DD"],
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 0, 0, 0],
          },
        },
        markers: {
          size: 7,
          colors: ["#4B3A7C"],
          strokeColors: "#fff",
          strokeWidth: 1,
          hover: {
            size: 4,
          },
        },
        yaxis: {
          title: {
            text: "Mxn",
          },
          labels: {
            formatter: (value) =>
              new Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(value),
          },
        },
      });
    }
  }, [policiesStore, styleDark]);

  useEffect(() => {
    dispatch(PoliciesActions.getCommissions());
  }, [dispatch]);
  const dummyMonth = [
    { id: 1, title: "24M", cantDays: 24 },
    { id: 2, title: "12M", cantDays: 12 },
    { id: 3, title: "6M", cantDays: 6 },
    { id: 4, title: "3M", cantDays: 3 },
  ];
  const changeFiltreCommissiones = (item) => {
    setoptionSelected(item.id);
    dispatch(
      PoliciesActions.setFiltersCommissions(
        "Fechas",
        moment().subtract(item.cantDays, "month").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      )
    );
  };

  return (
    <section className="SectionCommissions">
      <div className="box-left">
        <h2 className="mobile-h2">Comisiones</h2>
        <p className="small-detail small-detail--date">
          {moment(policiesStore?.filtersCommissions?.FechaInicial[0]).format("MMM'YY")}-
          {moment(policiesStore?.filtersCommissions?.FechaFinal[0]).format("MMM'YY")}
        </p>
        {policiesStore?.success?.commissions?.totalValue ? (
          <h1 className="h1-gradient">
            ${parseThousandNumber(policiesStore?.success?.commissions?.totalValue)}
          </h1>
        ) : (
          <Loader className="loader-small" />
        )}
        <p className="small-detail">
          Próximo corte: <span>En {policiesStore?.success?.commissions?.nextCloseDay} días</span>
        </p>
        <p className="body-regular">25% más que el mismo periodo de el año pasado</p>
        <Button className="button-transparent" onClick={() => history.push("/pia/commissions")}>
          Ver todas
        </Button>
      </div>
      <div className="box-r">
        <div className="content-filters">
          {dummyMonth?.map((item) => (
            <Button
              className={`btn ${item.id === optionSelected && "btn--active"}`}
              key={item.id}
              onClick={() => changeFiltreCommissiones(item)}
            >
              {item.title}
            </Button>
          ))}
        </div>
        <LongGraph
          width="98%"
          height={300}
          data={policiesStore?.success?.commissions?.chartData}
          dataPoint={dataPoint}
          isoptions={isoptions}
          loading={policiesStore?.loading?.filtersCommissions}
        />
      </div>
      <div className="SectionCommissions__responsive">
        <p className="sub-title">Feedback</p>
        <div className="content-r">
          <div className="left">
            <p className="small-detail">Pendiente</p>
            <p className="sub-title">0</p>
          </div>
          <div className="rigth">
            <Button className="button-transparent">
              <img src={arrowSvg} className="arrowSvg" alt="arrow" />
            </Button>
          </div>
        </div>
        <div className="medium">
          <p className="small-detail">Ganancias potenciales:</p>
          <p className="sub-title">$1,025</p>
        </div>
        <div className="content-r content-r-2">
          <div className="left">
            <p className="sub-title">Comisión</p>
            <p className="small-detail">Últimos 3M</p>
            <p className="sub-title sub-title--gradient">
              {policiesStore?.success?.commissions?.totalValue
                ? `$${parseThousandNumber(policiesStore?.success?.commissions?.totalValue)}`
                : "$0M"}
            </p>
          </div>
          <div className="rigth">
            <Button className="button-transparent" onClick={() => history.push("/pia/commissions")}>
              <img src={arrowSvg} className="arrowSvg" alt="arrow" />
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

SectionCommissions.propTypes = {
  styleDark: bool.isRequired,
};
export default SectionCommissions;
