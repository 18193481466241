/* eslint-disable no-use-before-define */
/* eslint-disable prefer-const */
/* eslint-disable no-else-return */
const parseNumberThousand = (inputNumber, type) => {
  try {
    const number = +inputNumber;
    if (!number) return number;
    const parsedNumber = number;
    if (Math.abs(parsedNumber) > 999999)
      return `${type === "price" ? "$" : ""}${(parsedNumber / 1000000).toFixed(1)}M`;
    if (Math.abs(parsedNumber) > 999)
      return `${type === "price" ? "$" : ""}${Math.floor(parsedNumber / 1000)}K`;
    return `${type === "price" ? "$" : ""}${
      type === "price" ? parsedNumber.toFixed(1) : splitPrice(parsedNumber)
    }`;
  } catch (error) {
    return inputNumber;
  }
};
function splitPrice(number) {
  const stringNumber = number.toString();
  const field = stringNumber.split(".");
  if (stringNumber.length >= 3) {
    let strindecimal = field[1];
    return `${field[0]}.${strindecimal[0]}${stringNumber[1]}`;
  } else {
    return number;
  }
}
export default parseNumberThousand;
