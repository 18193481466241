/* eslint-disable no-unused-expressions */
import { ArrowLeftOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, message, Modal } from "antd";
import CarGreenSvg from "assets/images/car-green.svg";
import CarPurpleSvg from "assets/images/icons-line-product-purple/autos-purple-svg.svg";
import eyeButton from "assets/images/eye-button.svg";
import luna from "assets/images/luna.svg";
import DamageGreenSvg from "assets/images/pia-brands/damage-green.svg";
import DamagePurpleSvg from "assets/images/icons-line-product-purple/damage-purple-svg.svg";
import GmmGreenSvg from "assets/images/pia-brands/gmm-icon-green.svg";
import sol from "assets/images/pia-brands/sol.svg";
import VidaGreenSvg from "assets/images/vida-green.svg";
import VidaPurpleSvg from "assets/images/icons-line-product-purple/live-purple-svg.svg";
import Loader from "components/loader";
import { bool, func, instanceOf } from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import PolicyActions from "services/policies/actions";
// import LoaderImg from "assets/images/loader.gif";

const branchImagesGreen = {
  gmm: GmmGreenSvg,
  autos: CarGreenSvg,
  daños: DamageGreenSvg,
  brio: DamageGreenSvg,
  vida: VidaGreenSvg,
};
const branchImagesPurple = {
  gmm: GmmGreenSvg,
  autos: CarPurpleSvg,
  daños: DamagePurpleSvg,
  brio: DamagePurpleSvg,
  vida: VidaPurpleSvg,
};

const capitalize = (str) => {
  if (!str) return "";
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
};

const HeaderSummaryPolicy = ({ setSearchedHighlight, policy, setDarkMode, isDarkMode }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const policiesStore = useSelector((state) => state.policies);
  useEffect(() => {
    if (policiesStore.success.coverUpload)
      message.success("La carátula se ha guardado exitosamente.");
    if (policiesStore.error.coverUpload) message.error(policiesStore.error.coverUpload);
    if (policiesStore.success.coverUpload || policiesStore.error.coverUpload)
      dispatch(PolicyActions.cleanUploadCoverResponse());
  }, [policiesStore, dispatch]);

  const getImage = (isDarkModes) =>
    isDarkModes
      ? branchImagesGreen[policy.General[0].Branch?.toLowerCase()]
      : branchImagesPurple[policy.General[0].Branch?.toLowerCase()];

  const handleDownloadCover = () => {
    if (policiesStore?.success?.cover) window.open(policiesStore?.success?.cover);
  };

  return (
    <div>
      <Modal
        className="modal-loader"
        visible={policiesStore.loading.coverUpload}
        centered
        maskStyle={{ backgroundColor: "#ffffff66" }}
      >
        <Loader />
      </Modal>
      <div className="SummarPoliza">
        <div className="nav">
          <a href="/pia/" className="link">
            Mi cartera
          </a>
          <a href="/pia/commissions" className="link link-op">
            Comisiones
          </a>
        </div>
        <div className="HeaderSummarPoliza">
          <Button
            onClick={() => {
              if (history.action !== "POP") return history.goBack();
              return history.replace("/pia/");
            }}
            className="HeaderSummarPoliza--head"
          >
            <ArrowLeftOutlined />
            <p className="text-back">Regresar a Dashboard PIAA</p>
          </Button>
          <div className="HeaderSummarPoliza--footer">
            <h1>Detalle de Póliza</h1>
            <div className="box-left">
              {policiesStore?.loading?.cover ? (
                <div />
              ) : (
                policy?.General?.length > 0 && (
                  <Button
                    onClick={() => handleDownloadCover()}
                    disabled={policiesStore?.error?.cover}
                    className="button-purple"
                  >
                    <img src={eyeButton} alt="eyeButton" className="eyeButton" />
                    Ver Carátula
                  </Button>
                )
              )}

              <Button onClick={() => setDarkMode(!isDarkMode)}>
                {isDarkMode ? <img src={sol} alt="sol" /> : <img src={luna} alt="luna" />}
              </Button>
            </div>
          </div>
        </div>
        <div className={`${isDarkMode ? "content-dark-mode" : "content-dark-mode--none"}`}>
          <div className="welcome--name">
            <div className="left">
              {getImage(isDarkMode) ? (
                <img src={getImage(isDarkMode)} alt="icon" className="image" />
              ) : (
                policy.General[0]?.Branch.includes("auto") && (
                  <img src={branchImagesPurple?.autos} alt="icon" className="image" />
                )
              )}
              {policy.General && policy.General.length > 0 && (
                <h1 className="h2">{capitalize(policy.General[0].BenefeciaryName)}</h1>
              )}
            </div>
            {policy.General && policy.General.length > 0 && (
              <h3 className="h3">#{policy.General[0].NumPolicy}</h3>
            )}
          </div>
          <Input
            size="large"
            placeholder="¿Qué dato estas buscando?"
            prefix={<SearchOutlined />}
            onChange={(e) =>
              setSearchedHighlight(
                e.target.value
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

HeaderSummaryPolicy.propTypes = {
  policy: instanceOf(Object).isRequired,
  setSearchedHighlight: func.isRequired,
  setDarkMode: func.isRequired,
  isDarkMode: bool.isRequired,
};

export default HeaderSummaryPolicy;
