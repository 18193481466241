import { Button, Modal } from "antd";
import ClockPng from "assets/images/clock.png";
import FilterSvg from "assets/images/Filtrar.svg";
import warning from "assets/images/pia-brands/warning.svg";
import Loader from "components/loader";
import ConfirmAlert from "components/Modals/ConfirmAlert/ConfirmAlert";
import React, { useEffect, useState } from "react";
import { MixpanelConsumer } from "react-mixpanel";
import { useDispatch, useSelector } from "react-redux";
import PoliciesActions from "services/policies/actions";
import AdditionalInsurances from "./ConnectionStatus/AdditionalInsurances";
import ConnectionStatus from "./ConnectionStatus/ConnectionStatus/ConnectionStatus";
import FormAnalisisConnection from "./ConnectionStatus/FormAnalisisConnection/FormAnalisisConnection";
import FormConnect from "./ConnectionStatus/FormConnect/FormConnect";

const ConnectionsPIA = () => {
  const dispatch = useDispatch();
  const [selectedInsurance, setSelectedInsurance] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState();
  const [actualSection, setActualSection] = useState("FormConnect");
  const [toEdit, setToEdit] = useState();
  const [isFilter, setFilter] = useState(false);
  const [isStepActive, setStepActive] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [visibleInsurances, setVisibleInsurances] = useState([]);
  const [whoIsHover, setWhoIsHover] = useState(null);
  const policies = useSelector((state) => state.policies);
  const { success } = useSelector((state) => state.onboarding);

  const dummyFilter = [
    { id: 1, name: "Ver todas", key: "all" },
    { id: 2, name: "Conectados", key: "conectado" },
    { id: 3, name: "En proceso", key: "proceso" },
    { id: 4, name: "Vencidos", key: "vencida" },
    { id: 5, name: "Desconectados", key: "desconectado" },
  ];

  const handleItemForm = (insurance) => {
    setSelectedInsurance(insurance);
    setStepActive(2);
    if (insurance.state === "conectado") return setActualSection("ConnectionAnalysis");
    if (insurance.state === "vencida") return setActualSection("ConnectionStatus");
    const hasPolicies = policies?.success.accounts?.some(
      (account) => account.insurance.key === insurance.key
    );
    if (!hasPolicies) return setActualSection("FormConnect");
    return setActualSection("ConnectionStatus");
  };

  useEffect(() => {
    if (!policies.loading.insurances && !policies.success.insurances && !policies.error.insurances)
      dispatch(PoliciesActions.getAllInsurances());
  }, [policies, dispatch]);

  useEffect(() => {
    if (
      !policies.loading.accounts &&
      !policies.success.accounts &&
      !policies.error.accounts &&
      policies.success.insurances
    )
      dispatch(PoliciesActions.getAllAccounts());
  }, [policies, dispatch]);

  useEffect(() => {
    if (policies?.success?.insurances) setVisibleInsurances(policies.success.insurances);
  }, [policies]);

  useEffect(() => {
    if (!selectedInsurance) setStepActive(1);
  }, [selectedInsurance]);

  const deleteAccount = (id) => {
    setAccountToDelete(id);
  };

  return (
    <MixpanelConsumer>
      {(mixpanel) => (
        <div className="ConnectionsPIA">
          <Modal
            className="modal-loader"
            visible={
              policies.loading.newAccount ||
              policies.loading.updateAccount ||
              policies.loading.deleteAccount ||
              policies.loading.insurances ||
              policies.loading.accounts
            }
            centered
            maskStyle={{ backgroundColor: "#ffffff66" }}
          >
            <Loader />
          </Modal>
          <ConfirmAlert
            onOk={() => {
              setAccountToDelete();
              dispatch(PoliciesActions.deleteAccount(accountToDelete));
            }}
            onCancel={() => {
              setAccountToDelete();
            }}
            message="¿Seguro que quieres eliminar cuenta?"
            okClassName="no"
            cancelClassName="yes"
            onAcceptClick={() => {
              setAccountToDelete();
              mixpanel.track("Pia, delete account", {
                insurance: accountToDelete,
                user: success?.profile?.user?.email,
                status: "executed",
              });
              dispatch(PoliciesActions.deleteAccount(accountToDelete));
            }}
            onDeniedClick={() => {
              setAccountToDelete(false);
            }}
            isVisible={!!accountToDelete}
          />
          <h3 className="h3">Conexiones PIAA</h3>
          <div className="content">
            <div className="ConnectionsPIA--head">
              <div className={`step ${isStepActive === 1 && "step--active"}`}>
                <div className="step--circle">
                  <span>1</span>
                </div>
                <p className="body-bold">Selecciona la aseguradora a gestionar</p>
                <Button className="btn btn--res" onClick={() => setFilter(!isFilter)}>
                  <img src={FilterSvg} alt="filter" className="filter" />
                  {isFilter && (
                    <div className="filter-menu">
                      {dummyFilter?.map((item) => (
                        <Button
                          onClick={() => setSelectedFilter(item.id)}
                          className={`button-menu ${
                            item.id === selectedFilter && "button--active"
                          }`}
                          key={item.id}
                        >
                          {item.name}
                        </Button>
                      ))}
                    </div>
                  )}
                </Button>
              </div>
              <div className="line-divider" />
              <div className={`step ${isStepActive === 2 && "step--active"}`}>
                <div className="step--circle">
                  <span>2</span>
                </div>
                <p className="body-bold">Ingresa tus credenciales y conéctate a PIAA</p>
              </div>
            </div>
            <div className="content content-row">
              <div
                className={`ConnectionsPIA__body ${
                  selectedInsurance && "ConnectionsPIA__body--active"
                }`}
              >
                <div className="section-filter">
                  <h4>Filtros:</h4>
                  <div className="cont-btns">
                    {policies?.success?.insurances !== null &&
                      dummyFilter?.map((filterState) => (
                        <Button
                          onClick={() => {
                            if (filterState.key === "all")
                              return setVisibleInsurances(policies.success.insurances);

                            const filteredInsurances = [...policies.success.insurances];

                            setVisibleInsurances(
                              filteredInsurances.filter(
                                (insurance) => insurance.state === filterState.key
                              )
                            );
                            return setSelectedFilter(filterState.key);
                          }}
                          key={filterState.key}
                          className={`button-filter ${
                            filterState.key === selectedFilter && "button-filter--active"
                          }`}
                        >
                          {filterState.name}{" "}
                          {`(${
                            filterState.key === "all"
                              ? policies?.success?.insurances.length
                              : policies.success.insurances.filter(
                                  (insurance) => insurance.state === filterState.key
                                ).length
                          })`}
                        </Button>
                      ))}
                  </div>
                </div>
                {visibleInsurances?.map((item) => (
                  <Button
                    onClick={() => {
                      handleItemForm(item);
                      mixpanel.track("Pia, select insurance", {
                        Insurance: item.key,
                        User: success?.profile?.user?.email,
                        status: "executed",
                      });
                    }}
                    key={item.key}
                    className={`button-pia ${
                      item.key === selectedInsurance.key && "button-pia--active"
                    }`}
                    onMouseEnter={() => setWhoIsHover(item.key)}
                    onMouseLeave={() => setWhoIsHover(null)}
                  >
                    <div className="cont cont-top">
                      {item.state === "vencida" && (
                        <img src={warning} alt="warning" className="warning" />
                      )}

                      {item.key === selectedInsurance.key || whoIsHover === item.key ? (
                        <img src={item.logoWhite} alt="brand" className="img-brand" />
                      ) : (
                        <>
                          {item.state === "conectado" || item.state === "vencida" ? (
                            <img
                              src={item.logoColor}
                              alt="brand"
                              className="img-brand img-brand--color"
                            />
                          ) : (
                            <img
                              src={item.logo}
                              alt="brand"
                              className="img-brand img-brand--gray"
                            />
                          )}
                        </>
                      )}
                    </div>
                    <div className="cont cont-bottom">
                      {item.state === "proceso" && (
                        <img src={ClockPng} alt="En proceso" className="warning-name" />
                      )}

                      <span
                        className={`
                    ${item.state === "vencida" && "yellow"} 
                    ${item.state === "desconectado" && "gray"} 
                    ${item.state === "proceso" && "yellow"} 
                    ${item.state === "conectado" && "conect"}`}
                      >
                        {item.state === "vencida" && "Contraseña vencida"}
                        {item.state === "error" && "Inconsistencias en el portal"}
                        {item.state === "proceso" && "En proceso"}
                        {item.state !== "proceso" && item.state !== "vencida" && item.state}
                      </span>
                    </div>
                  </Button>
                ))}
              </div>
              {selectedInsurance && (
                <article className="form-container animate__animated animate__fadeInRight">
                  {selectedInsurance.state === "desconectado" && (
                    <>
                      {actualSection === "FormConnect" && (
                        <FormConnect
                          mixPanel={mixpanel}
                          user={success?.profile?.user?.email}
                          showUser={
                            selectedInsurance.key === "qualitas" || selectedInsurance.key === "gnp"
                          }
                          showSecondPassword={selectedInsurance.key === "gnp"}
                          setEdit={setToEdit}
                          selectedInsurance={selectedInsurance}
                          setSelectedInsurance={setSelectedInsurance}
                          setSection={setActualSection}
                          toEdit={toEdit}
                        />
                      )}
                      {actualSection === "ConnectionAnalysis" && (
                        <ConnectionStatus
                          deleteAccount={deleteAccount}
                          setEdit={setToEdit}
                          selectedInsunrace={selectedInsurance}
                          setSelectedInsurance={setSelectedInsurance}
                          setSection={setActualSection}
                        />
                      )}
                      {actualSection === "ConnectionNew" && (
                        <FormAnalisisConnection
                          setEdit={setToEdit}
                          selectedInsunrace={selectedInsurance}
                          selectedInsurance={selectedInsurance}
                          setSelectedInsurance={setSelectedInsurance}
                          setSection={setActualSection}
                        />
                      )}
                      {actualSection === "ConnectionStatus" && (
                        <ConnectionStatus
                          deleteAccount={deleteAccount}
                          isSection={actualSection}
                          accounts={policies?.success.accounts?.filter(
                            (account) => account.insurance.key === selectedInsurance.key
                          )}
                          selectedInsunrace={selectedInsurance}
                          setEdit={setToEdit}
                          setSelectedInsurance={setSelectedInsurance}
                          setSection={setActualSection}
                        />
                      )}
                    </>
                  )}
                  {selectedInsurance.state === "conectado" && (
                    <>
                      {actualSection === "FormConnect" && (
                        <FormConnect
                          mixPanel={mixpanel}
                          user={success?.profile?.user?.email}
                          showUser={
                            selectedInsurance.key === "qualitas" || selectedInsurance.key === "gnp"
                          }
                          showSecondPassword={selectedInsurance.key === "gnp"}
                          toEdit={toEdit}
                          setEdit={setToEdit}
                          selectedInsurance={selectedInsurance}
                          setSelectedInsurance={setSelectedInsurance}
                          setSection={setActualSection}
                        />
                      )}
                      {actualSection === "ConnectionAnalysis" && (
                        <ConnectionStatus
                          isSection={actualSection}
                          deleteAccount={deleteAccount}
                          accounts={policies?.success.accounts?.filter(
                            (account) => account.insurance.key === selectedInsurance.key
                          )}
                          selectedInsunrace={selectedInsurance}
                          setEdit={setToEdit}
                          setSelectedInsurance={setSelectedInsurance}
                          setSection={setActualSection}
                        />
                      )}
                      {actualSection === "ConnectionNew" && (
                        <FormAnalisisConnection
                          setEdit={setToEdit}
                          selectedInsunrace={selectedInsurance}
                          selectedInsurance={selectedInsurance}
                          setSelectedInsurance={setSelectedInsurance}
                          setSection={setActualSection}
                        />
                      )}
                      {actualSection === "ConnectionStatus" && (
                        <ConnectionStatus
                          deleteAccount={deleteAccount}
                          isSection={actualSection}
                          accounts={policies?.success.accounts?.filter(
                            (account) => account.insurance.key === selectedInsurance.key
                          )}
                          selectedInsunrace={selectedInsurance}
                          setEdit={setToEdit}
                          setSelectedInsurance={setSelectedInsurance}
                          setSection={setActualSection}
                        />
                      )}
                    </>
                  )}
                  {(selectedInsurance.state === "vencida" ||
                    selectedInsurance.state === "error") && (
                    <>
                      {actualSection === "FormConnect" && (
                        <FormConnect
                          mixPanel={mixpanel}
                          user={success?.profile?.user?.email}
                          showUser={
                            selectedInsurance.key === "qualitas" || selectedInsurance.key === "gnp"
                          }
                          showSecondPassword={selectedInsurance.key === "gnp"}
                          setEdit={setToEdit}
                          selectedInsurance={selectedInsurance}
                          setSelectedInsurance={setSelectedInsurance}
                          setSection={setActualSection}
                          toEdit={toEdit}
                        />
                      )}
                      {actualSection === "ConnectionAnalysis" && (
                        <FormAnalisisConnection
                          setEdit={setToEdit}
                          selectedInsunrace={selectedInsurance}
                          selectedInsurance={selectedInsurance}
                          setSelectedInsurance={setSelectedInsurance}
                          setSection={setActualSection}
                        />
                      )}
                      {actualSection === "ConnectionNew" && (
                        <FormAnalisisConnection
                          setEdit={setToEdit}
                          selectedInsunrace={selectedInsurance}
                          selectedInsurance={selectedInsurance}
                          setSelectedInsurance={setSelectedInsurance}
                          setSection={setActualSection}
                        />
                      )}
                      {actualSection === "ConnectionStatus" && (
                        <ConnectionStatus
                          deleteAccount={deleteAccount}
                          isSection={actualSection}
                          accounts={policies?.success.accounts?.filter(
                            (account) => account.insurance.key === selectedInsurance.key
                          )}
                          selectedInsunrace={selectedInsurance}
                          setEdit={setToEdit}
                          setSelectedInsurance={setSelectedInsurance}
                          setSection={setActualSection}
                        />
                      )}
                    </>
                  )}
                  {selectedInsurance.state === "proceso" && (
                    <>
                      {actualSection === "FormConnect" && (
                        <FormConnect
                          mixPanel={mixpanel}
                          user={success?.profile?.user?.email}
                          showUser={
                            selectedInsurance.key === "qualitas" || selectedInsurance.key === "gnp"
                          }
                          showSecondPassword={selectedInsurance.key === "gnp"}
                          setEdit={setToEdit}
                          selectedInsurance={selectedInsurance}
                          setSelectedInsurance={setSelectedInsurance}
                          setSection={setActualSection}
                          toEdit={toEdit}
                        />
                      )}
                      {actualSection === "ConnectionAnalysis" && (
                        <FormAnalisisConnection
                          setEdit={setToEdit}
                          selectedInsunrace={selectedInsurance}
                          selectedInsurance={selectedInsurance}
                          setSelectedInsurance={setSelectedInsurance}
                          setSection={setActualSection}
                        />
                      )}
                      {actualSection === "ConnectionNew" && (
                        <FormAnalisisConnection
                          setEdit={setToEdit}
                          selectedInsunrace={selectedInsurance}
                          selectedInsurance={selectedInsurance}
                          setSelectedInsurance={setSelectedInsurance}
                          setSection={setActualSection}
                        />
                      )}
                      {actualSection === "ConnectionStatus" && (
                        <ConnectionStatus
                          deleteAccount={deleteAccount}
                          isSection={actualSection}
                          accounts={policies?.success.accounts?.filter(
                            (account) => account.insurance.key === selectedInsurance.key
                          )}
                          selectedInsunrace={selectedInsurance}
                          setEdit={setToEdit}
                          setSelectedInsurance={setSelectedInsurance}
                          setSection={setActualSection}
                        />
                      )}
                    </>
                  )}
                </article>
              )}
            </div>
            <AdditionalInsurances />
          </div>
        </div>
      )}
    </MixpanelConsumer>
  );
};

export default ConnectionsPIA;
