/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { func, instanceOf } from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import Address from "../AddressForm/AddressForm";
import Office from "../OfficeForm/OfficeForm";

const OfficeForms = ({
  setInAdditionAddress,
  inEditionAddress,
  newAddress,
  setAddressForm,
  inAdditionAddress,
  setOfficeForm,
}) => {
  const { t } = useTranslation();

  return (
    <div className="add-offices">
      <div className="add-offices__tabs">
        <h4 id="tab1" className="tab--active">
          {inEditionAddress
            ? t("label.subtitleEditAddressOffices")
            : t("label.subtitleAddressOffices")}
        </h4>
      </div>
      <Address
        newAddress={newAddress}
        inEditionAddress={inEditionAddress}
        setAddressForm={setAddressForm}
      />

      <div className="add-offices__tabs">
        <h4 id="tab2" className="tab--active">
          <span>*</span>
          {inEditionAddress
            ? t("label.subtitleEditInformationOffices")
            : t("label.subtitleInformationOffices")}
        </h4>
      </div>
      <Office
        inAdditionAddress={inAdditionAddress}
        inEditionAddress={inEditionAddress}
        setOfficeForm={setOfficeForm}
        setInAdditionAddress={setInAdditionAddress}
      />
    </div>
  );
};

OfficeForms.propTypes = {
  setInAdditionAddress: func.isRequired,
  inEditionAddress: instanceOf(Object),
  newAddress: instanceOf(Object),
  setAddressForm: func.isRequired,
  inAdditionAddress: instanceOf(Object),
  setOfficeForm: func.isRequired,
};

OfficeForms.defaultProps = {
  inEditionAddress: null,
  newAddress: null,
  inAdditionAddress: null,
};

export default OfficeForms;
