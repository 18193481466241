import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import AuthActions from "../../../services/auth/actions";
import "./checkEmail.scss";

const CheckEmail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const goToDashboard = () => {
    history.replace("/");
    dispatch(AuthActions.setLogged(true));
  };

  return (
    <div className="checkEmail">
      <div className="checkEmail__card">
        <div className="checkEmail__cardWrapper">
          <h3 className="checkEmail__cardWrapperTitle">{t("label.checkYourEmail")}</h3>

          <p className="checkEmail__cardWrapperContent">{t("label.checkYourEmailParagraph")}</p>

          <div className="checkEmail__cardWrapperFooter">
            <Button onClick={() => goToDashboard()}>{t("button.goToBerrysafe")}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckEmail;
