import { instanceOf, string } from "prop-types";
import React from "react";
import TypeOneMedium from "./components/TypeOneMedium/TypeOneMedium";
import TypeQuarter from "./components/TypeQuarter/TypeQuarter";

const CardProduct = ({ type, cards, dummyCardsRecommendation }) => (
  <div className="card-product">
    {type === "1/2" && <TypeOneMedium cards={cards} />}
    {type === "1/4" && (
      <TypeQuarter cards={cards} dummyCardsRecommendation={dummyCardsRecommendation} />
    )}
  </div>
);

CardProduct.propTypes = {
  type: string.isRequired,
  cards: instanceOf(Array).isRequired,
  dummyCardsRecommendation: instanceOf(Array).isRequired,
};

export default CardProduct;
