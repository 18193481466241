import { Button, message, Modal } from "antd";
import { bool, func } from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import OfficeActions from "services/office/actions";
import AgentActions from "../../../../../../../services/agent/actions";
import OnboardingActions from "../../../../../../../services/onboarding/actions";
import EditMyPageActions from "../../services/actions";
import AddOffice from "./components/AddOffice/AddOffice";
import AddOfficeManually from "./components/AddOfficeManually/AddOfficeManually";
import EditOffice from "./components/EditOffice/EditOffice";
import GmbCodeError from "./components/GmbCodeError/GmbCodeError";
import MyOffices from "./components/MyOffices/MyOffices";
import OfficeSaved from "./components/OfficeThankYouPage/OfficeThankYouPage";
import SetGmbCode from "./components/SetGmbCode/SetGmbCode";

const Offices = ({ saveOffice, setSaveOffice }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [view, setView] = useState(undefined);
  const [action, setAction] = useState();
  const [selectedOffice, setSelectedOffice] = useState(undefined);
  const [officeToSetCode, setOfficeToSetCode] = useState(undefined);
  const [sureDelete, setSureDelete] = useState(false);
  const [officeToDelete, setOfficeToDelete] = useState(undefined);

  const onboarding = useSelector((state) => state.onboarding);
  const agent = useSelector((state) => state.agent);
  const office = useSelector((state) => state.office);

  const defaultView = useCallback(
    () => (onboarding.success.profile.offices.length === 0 ? 1 : 0),
    [onboarding]
  );

  useEffect(() => {
    if (!office.loading.countries && !office.success.countries && !office.error.countries)
      dispatch(OfficeActions.getCountries());
  }, [dispatch, office]);

  useEffect(() => {
    if (agent.error.sendGmbVerificationCode) setView(6);
  }, [agent, setView, defaultView]);

  useEffect(() => {
    if (onboarding.success.profile) setView(defaultView());
  }, [onboarding, setView, defaultView]);

  useEffect(() => {
    if (agent.success.createOffice) {
      setAction("create");
      setView(4);
    }

    if (agent.success.updateOffice) {
      setAction("update");
      setView(4);
    }
  }, [agent, setView, defaultView, dispatch]);

  useEffect(() => {
    if (saveOffice) {
      setSaveOffice(false);
      dispatch(EditMyPageActions.changeEditStatus(undefined));
    }
  }, [saveOffice, setSaveOffice, dispatch]);

  useEffect(() => {
    if (agent.success.deleteOffice) {
      message.success("La oficina se ha eliminado exitosamente");
      dispatch(AgentActions.clearDeleteOffice());
      setSureDelete(false);
    }
  }, [agent, dispatch]);

  const finishSave = () => {
    if (action === "create") {
      setView(defaultView());
      dispatch(AgentActions.clearCreateOffice());
    }

    if (action === "update") {
      dispatch(OnboardingActions.updateOfficeToProfile(selectedOffice));
      dispatch(AgentActions.clearUpdateOffice());
      setSelectedOffice(undefined);
    }
  };

  const editOffice = (officeToEdit) => {
    setSelectedOffice(officeToEdit);
    setView(3);
  };

  const alertDropOffice = (officeToDrop) => {
    setSureDelete(true);
    setOfficeToDelete(officeToDrop);
  };

  return (
    <>
      <Modal
        title="Basic Modal"
        className="modal-warnning"
        visible={sureDelete}
        onOk={() => setSureDelete(false)}
        onCancel={() => setSureDelete(false)}
        closable={false}
      >
        <div className="cont-modal">
          <h3>¿{t("label.deleteOffice")}?</h3>
          <div>
            <Button
              onClick={() => dispatch(AgentActions.deleteOffice(officeToDelete.id))}
              className="yes"
            >
              {t("label.yes")}
            </Button>
            <Button onClick={() => {}} className="no">
              {t("label.not")}
            </Button>
          </div>
        </div>
      </Modal>

      {view === 0 && (
        <MyOffices
          goToAdd={() => {
            setView(1);
            dispatch(EditMyPageActions.changeEditStatus("office"));
          }}
          edit={(editedOffice) => {
            editOffice(editedOffice);
          }}
          drop={alertDropOffice}
          goToSetCode={(officeToSet) => {
            setOfficeToSetCode(officeToSet);
            setView(5);
          }}
        />
      )}
      {view === 1 && (
        <AddOffice
          availableCountries={office.success.countries ?? []}
          setAddManually={() => setView(2)}
          saveOffice={() => {}}
        />
      )}
      {view === 2 && (
        <AddOfficeManually
          office={selectedOffice}
          goBack={() => setView(defaultView())}
          saveOffice={() => {}}
        />
      )}
      {view === 3 && (
        <EditOffice
          selectedOffice={selectedOffice}
          goBack={() => setView(defaultView())}
          editOffice={() => {}}
          setSelectedOffice={setSelectedOffice}
        />
      )}
      {view === 4 && <OfficeSaved action={action} finish={finishSave} />}
      {view === 5 && <SetGmbCode selectedOffice={officeToSetCode} setView={() => setView(0)} />}
      {view === 6 && <GmbCodeError setView={() => setView(5)} />}
    </>
  );
};

Offices.propTypes = {
  saveOffice: bool.isRequired,
  setSaveOffice: func.isRequired,
};

export default Offices;
