/* eslint-disable import/no-cycle */
import { all, fork } from "redux-saga/effects";
import AccountSaga from "../services/account/saga";
import AgentSaga from "../services/agent/saga";
import AuthSaga from "../services/auth/saga";
import LeadsSaga from "../services/leads/saga";
import MarketplaceSaga from "../services/marketplace/saga";
import OnboardingSaga from "../services/onboarding/saga";
import ReportSaga from "../services/reports/saga";
import OfficeSaga from "../services/office/saga";
import ClientBookSaga from "../services/clientBook/saga";
import PoliciesSaga from "../services/policies/saga";
import MixpanelSaga from "../services/mixpanel/saga";

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(OnboardingSaga),
    fork(LeadsSaga),
    fork(AgentSaga),
    fork(MarketplaceSaga),
    fork(ReportSaga),
    fork(AccountSaga),
    fork(OfficeSaga),
    fork(ClientBookSaga),
    fork(PoliciesSaga),
    fork(MixpanelSaga),
  ]);
}
