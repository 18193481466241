/* eslint-disable spaced-comment */
/* eslint-disable react/forbid-prop-types */
import React from "react";
import arrowSvg from "assets/images/arrow-rigth-green-line.svg";
//import arrow2Svg from "assets/images/arrow-rigth-green.svg";
//import leafCheckSvg from "assets/images/leaf-check.svg";
import AutoSvg from "assets/images/pia-brands/auto.svg";
import DamageSvg from "assets/images/pia-brands/damage.svg";
import GmmSvg from "assets/images/pia-brands/gmm-icon.svg";
import VidaSvg from "assets/images/vida.svg";
import AutoGreenSvg from "assets/images/icons-line-product-green/Autos-green-svg.svg";
import DamageGreenSvg from "assets/images/pia-brands/damage-green.svg";
import GmmGreenSvg from "assets/images/pia-brands/gmm-icon-green.svg";
import VidaGreenSvg from "assets/images/vida-green.svg";
import { object, bool } from "prop-types";
import { Popover } from "antd";
import { Link } from "react-router-dom";

const CardInsurance = ({ item, styleDark }) => {
  function capitalize(str) {
    const lower = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lower.slice(1);
  }
  const content = (
    <div>
      <p className="body-bold">{capitalize(item.title)}</p>
    </div>
  );
  return (
    <div className="CardInsurance">
      <div className="image">
        <img className="CardInsurance_icon" src={item.image} alt="icon" />
      </div>
      <div className="CardInsurance__content">
        <div className="CardInsurance__content__head">
          {/* <p>{`${FILE_VIEWER_HOST}/images/insurance-carrier/pia/${item.ramo}-white.svg`}</p> */}
          {styleDark ? (
            <>
              {item.ramo === "autos" && <img className="icon" src={AutoGreenSvg} alt="icon" />}
              {item.ramo === "daños" && <img className="icon" src={DamageGreenSvg} alt="icon" />}
              {item.ramo === "vida" && <img className="icon" src={VidaGreenSvg} alt="icon" />}
              {item.ramo === "GMM" && <img className="icon" src={GmmGreenSvg} alt="icon" />}
            </>
          ) : (
            <>
              {item.ramo === "autos" && <img className="icon" src={AutoSvg} alt="icon" />}
              {item.ramo === "daños" && <img className="icon" src={DamageSvg} alt="icon" />}
              {item.ramo === "vida" && <img className="icon" src={VidaSvg} alt="icon" />}
              {item.ramo === "GMM" && <img className="icon" src={GmmSvg} alt="icon" />}
            </>
          )}
          <Popover content={content}>
            <p className="body-bold">{capitalize(item.title)}</p>
          </Popover>
        </div>
        <p className="small-detail">{item.expiration <= 0 ? "Vencido hace" : "Vence en"}</p>
        <p className="body-detail">
          {item.expiration <= 0 ? `${item.expiration * -1} días` : `${item.expiration} días`}
        </p>
      </div>
      <div className="cont">
        {/* <p className="small-detail">Cotiza con</p>
        <Button className="circle">
          <p className="body-detail">{item?.insurersOffering?.length}</p>
        </Button>
        <div className="filter-absolute">
          <Button className="btn-filter">
            <div>
              <div className="cilrcle">
                <img
                  src="https://res.cloudinary.com/arepa-tec/image/upload/v1677436637/icono_1_v8tvur.svg"
                  alt="icon"
                  className="icon"
                />
              </div>
              <span>Cotizar con GNP</span>
            </div>
            <img src={arrow2Svg} className="arrow" alt="arrow" />
          </Button>
          <Button className="btn-filter">
            <div>
              <div className="cilrcle">
                <img
                  src="https://res.cloudinary.com/arepa-tec/image/upload/v1677436637/Original_mnmtix.svg"
                  alt="icon"
                  className="icon"
                />
              </div>
              <span>Cotizar con GNP</span>
            </div>
            <img src={leafCheckSvg} className="arrow" alt="arrow" />
          </Button>
          <Button className="btn-filter">
            <div>
              <div className="cilrcle">
                <img
                  src="https://res.cloudinary.com/arepa-tec/image/upload/v1677436637/icono_c14cdr.svg"
                  alt="icon"
                  className="icon"
                />
              </div>
              <span>Cotizar con GNP</span>
            </div>
            <img src={arrow2Svg} className="arrow" alt="arrow" />
          </Button>
        </div>*/}
      </div>
      <Link to={`/pia/summary?id=${item.id}`} className="btn-arrow">
        <img src={arrowSvg} className="arrowSvg" alt="arrow" />
      </Link>
    </div>
  );
};

CardInsurance.propTypes = {
  item: object.isRequired,
  styleDark: bool.isRequired,
};

export default CardInsurance;
