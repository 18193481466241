import { Button } from "antd";
import showTranslatedText from "common/utils/showTranslatedText";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { getI18n, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import msj from "../../../../../assets/images/contact/whatsapp.png";
import HospitalGroup from "./components/HospitalGroupDescription";
import KnowMore from "./components/KnowMore";
import RecommendedProducts from "./components/Recommended";

const ContainerLead = ({ lead }) => {
  const { t } = useTranslation();
  const [showKnowMore, setshowKnowMore] = useState(false);
  const {
    success: { leadDetail, suggestedProducts },
  } = useSelector((state) => state.leads);
  const onboarding = useSelector((state) => state.onboarding);
  const [product, setProduct] = useState(undefined);

  const readMore = (selectedProduct) => {
    const parsedProduct = selectedProduct;
    parsedProduct.productDetail.productDetailAdditional =
      selectedProduct.productDetail.productDetailAdditional.map((additional) => {
        const span = document.createElement("span");
        span.innerHTML = showTranslatedText(additional.text, getI18n()?.language);
        return { ...additional, text: span.textContent || span.innerText };
      });
    setProduct(selectedProduct);
    setshowKnowMore(true);
  };

  const getMail = () =>
    leadDetail.lead.person?.contacts.find((item) => item.type === "Phone")?.value;

  return (
    <div className="container-modal">
      {showKnowMore ? (
        <KnowMore t={t} lead={leadDetail} product={product} close={() => setshowKnowMore(false)} />
      ) : (
        <>
          <div className="container-modal__msj">
            <h3>{t("label.Message")}</h3>
            <h6 className="container-modal__requestDate">{lead?.description}</h6>
            <div className="divider" />
            <h4>
              {t("label.SureOf")}{" "}
              {leadDetail.lead?.desiredProduct
                ? showTranslatedText(leadDetail.lead.desiredProduct?.title, getI18n().language)
                : showTranslatedText(lead.productLine?.title, getI18n().language)}
            </h4>

            <Button target="_blank" href={`https://wa.me/52${getMail()}`}>
              <img src={msj} alt="whatsapp" className="whatsapp" />
              Responder
            </Button>
          </div>
          {leadDetail.lead.hospitalGroup ? (
            <HospitalGroup hospitalGroup={leadDetail.lead.hospitalGroup} t={t} />
          ) : (
            suggestedProducts && (
              <RecommendedProducts
                hasSponsor={
                  onboarding.success.profile.agentSponsors
                    ? !!onboarding.success.profile.agentSponsors[0]
                    : false
                }
                leadDetail={leadDetail}
                readMore={readMore}
                t={t}
                suggestedProducts={suggestedProducts}
              />
            )
          )}
        </>
      )}
    </div>
  );
};

ContainerLead.propTypes = {
  lead: PropTypes.instanceOf(Object).isRequired,
};

export default ContainerLead;
