import { Tabs } from "antd";
import { instanceOf, string } from "prop-types";
import React from "react";
import PiaField from "../PiaField";

const { TabPane } = Tabs;

const SectionStatesPia = ({ clock, policy, highlightDictionary, searchedHighlight }) => {
  return (
    <div className="SectionSummaryPia">
      {policy.EndorsementsList.length < 1 && (
        <div className="empty">
          <span className="h2">Por el momento no tenemos registro de Endosos</span>
        </div>
      )}
      {policy.EndorsementsList.length > 0 && (
        <div className="SectionSummaryPia--table">
          <Tabs defaultActiveKey="1">
            {policy.EndorsementsList.map((endorsement, index) => (
              <TabPane
                tab={
                  <div className="tab-head">
                    <h1>Endoso {index + 1}</h1>
                    <img src={clock} alt="clock" />
                  </div>
                }
                key={endorsement.Number}
              >
                <div className="SectionSummaryPia--table__column">
                  <div className="item">
                    <div className="item-title">
                      <p
                        className={
                          highlightDictionary.endorsement.includes(searchedHighlight)
                            ? "highlight"
                            : ""
                        }
                      >
                        Número de endoso
                      </p>
                    </div>
                    <div className="item-rta">
                      <PiaField text={endorsement.Number} type="" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="item-title">
                      <p
                        className={
                          highlightDictionary.validity.includes(searchedHighlight)
                            ? "highlight"
                            : ""
                        }
                      >
                        Fin de vigencia
                      </p>
                    </div>
                    <div className="item-rta">
                      <PiaField text={endorsement.EndValidityDate} type="date" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="item-title">
                      <p
                        className={
                          highlightDictionary.validity.includes(searchedHighlight)
                            ? "highlight"
                            : ""
                        }
                      >
                        Inicio de vigencia
                      </p>
                    </div>
                    <div className="item-rta">
                      <PiaField text={endorsement.StartValidityDate} type="date" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="item-title">
                      <p
                        className={
                          highlightDictionary.emission.includes(searchedHighlight)
                            ? "highlight"
                            : ""
                        }
                      >
                        Fecha de emisión
                      </p>
                    </div>
                    <div className="item-rta">
                      <PiaField text={endorsement.DateEmission} type="date" />
                    </div>
                  </div>
                </div>
                <div className="SectionSummaryPia--table__column">
                  <h5 className="sub-title">Prima</h5>
                  <div className="content-gray">
                    <div className="content-gray--item">
                      <p
                        className={
                          highlightDictionary.currency.includes(searchedHighlight)
                            ? "highlight"
                            : ""
                        }
                      >
                        Moneda
                      </p>
                      <PiaField text={endorsement.PremiumCurrency} type="" />
                    </div>
                    <div className="content-gray--item">
                      <p
                        className={
                          highlightDictionary.paymentMethod.includes(searchedHighlight)
                            ? "highlight"
                            : ""
                        }
                      >
                        Forma de pago
                      </p>
                      <PiaField text={endorsement.PremiumWayPayment} type="" />
                    </div>
                    <div className="content-gray--item">
                      <p
                        className={
                          highlightDictionary.rate.includes(searchedHighlight) ? "highlight" : ""
                        }
                      >
                        Tarifa
                      </p>
                      <PiaField text={endorsement.PremiumRate} type="price" />
                    </div>
                  </div>
                  <div className="content-lines">
                    <div className="row">
                      <p
                        className={
                          highlightDictionary.net.includes(searchedHighlight) ? "highlight" : ""
                        }
                      >
                        Prima neta
                      </p>
                      <PiaField text={endorsement.PremiumNet} type="price" />
                    </div>
                    <div className="row">
                      <p
                        className={
                          highlightDictionary.rate.includes(searchedHighlight) ? "highlight" : ""
                        }
                      >
                        Tasa Financiada
                      </p>
                      <PiaField text={endorsement.PremiumRateFin} type="price" />
                    </div>
                    <div className="row">
                      <p
                        className={
                          highlightDictionary.expenses.includes(searchedHighlight)
                            ? "highlight"
                            : ""
                        }
                      >
                        Gastos de expedición
                      </p>
                      <PiaField text={endorsement.PremiumExpensesExpedition} type="price" />
                    </div>
                  </div>
                </div>
                <div className="SectionSummaryPia--table__column">
                  <div className="content-lines">
                    <div className="row">
                      <p
                        className={
                          highlightDictionary.tax.includes(searchedHighlight) ? "highlight" : ""
                        }
                      >
                        IVA
                      </p>
                      <PiaField text={endorsement.PremiumIva} type="price" />
                    </div>
                    <div className="row">
                      <p
                        className={
                          highlightDictionary.subtotal.includes(searchedHighlight)
                            ? "highlight"
                            : ""
                        }
                      >
                        Subtotal
                      </p>
                      <PiaField text={endorsement.PremiumAmountParcial} type="price" />
                    </div>
                    <div className="row">
                      <p
                        className={
                          highlightDictionary.discount.includes(searchedHighlight)
                            ? "highlight"
                            : ""
                        }
                      >
                        Descuento
                      </p>
                      <PiaField text={endorsement.PremiumDiscount} type="price" />
                    </div>
                  </div>
                </div>
                <div className="content-total">
                  <div className="total">
                    <p
                      className={
                        [...highlightDictionary.prime, ...highlightDictionary.total].includes(
                          searchedHighlight
                        )
                          ? "highlight"
                          : ""
                      }
                    >
                      Prima total
                    </p>
                    <h2 className="h2">
                      {" "}
                      <PiaField text={endorsement.PremiumAmountTotal} type="price" />
                    </h2>
                  </div>
                </div>
              </TabPane>
            ))}
          </Tabs>
        </div>
      )}
    </div>
  );
};

SectionStatesPia.propTypes = {
  clock: string.isRequired,
  policy: instanceOf(Object).isRequired,
  highlightDictionary: instanceOf(Object).isRequired,
  searchedHighlight: string.isRequired,
};

export default SectionStatesPia;
