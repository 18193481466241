import NotificationsSvg from "../../assets/icon/icon-notifications.svg";
import SettingsSvg from "../../assets/icon/icon-settings.svg";
import ProfileSvg from "../../assets/icon/profile.svg";
import Home from "../../scenes/Private/home/Home";
import Marketplace from "../../scenes/Private/marketplace/Marketplace";
import Leads from "../../scenes/Private/leads/Lead";
import EditAgentPage from "../../scenes/Private/editAgentPage/EditAgentPage";
import Account from "../../scenes/Private/account/account";
import Reports from "../../scenes/Private/reports/Reports";
import DashboardPia from "../../scenes/Private/pia/pia";

const menu = [
  {
    name: "ErrorPage",
    key: "404",
    notVisible: true,
    icon: ProfileSvg,
    path: "",
    menu: [],
  },
  {
    name: "Home",
    key: "",
    notVisible: true,
    icon: ProfileSvg,
    path: "",
    menu: [],
    items: [
      {
        name: "",
        path: "",
        key: "",
        component: "",
        default: true,
      },
      {
        name: "P.I.A.A",
        path: "pia",
        key: "pia",
        component: DashboardPia,
      },
      {
        name: "Reportes",
        path: "reports",
        key: "reports",
        component: Reports,
      },
      {
        name: "Leads",
        path: "leads",
        key: "Leads",
        component: Leads,
      },
      {
        name: "Marketplace",
        path: "marketplace",
        key: "marketplace",
        component: Marketplace,
      },
    ],
  },
  {
    name: "P.I.A.A",
    key: "pia",
    notVisible: true,
    path: "pia",
    items: [
      { name: "Resumen", path: "", key: "", default: true, notVisible: true },
      {
        name: "Resumen",
        path: "pia",
        key: "",
        default: true,
      },
      {
        name: "Póliza",
        path: "pia/summary",
        key: "summary",
      },
      {
        name: "Comisiones",
        path: "pia/commissions",
        key: "commissions",
      },
      {
        name: "Policies",
        path: "pia/policies",
        key: "policies",
        component: DashboardPia,
      },
    ],
  },
  {
    name: "Leads",
    key: "leads",
    notVisible: true,
    icon: ProfileSvg,
    path: "leads",
    menu: [],
    items: [
      {
        name: "",
        path: "",
        key: "",
        component: Home,
        default: true,
      },
    ],
  },
  {
    name: "Mi página",
    key: "editPage",
    path: "editPage",
    notVisible: true,
    items: [
      {
        name: "",
        path: "",
        key: "",
        component: EditAgentPage,
        default: true,
      },
      {
        name: "Mis datos",
        path: "",
        key: "myData",
        component: EditAgentPage,
        default: true,
        internalNavigation: true,
      },
      {
        name: "Aseguradoras",
        path: "",
        key: "experience",
        component: EditAgentPage,
        default: true,
        internalNavigation: true,
      },
      {
        name: "Productos",
        path: "",
        key: "products",
        component: EditAgentPage,
        default: true,
        internalNavigation: true,
      },
      {
        name: "Mi negocio",
        path: "",
        key: "myBusiness",
        component: EditAgentPage,
        default: true,
        internalNavigation: true,
      },
    ],
  },
  {
    name: "Reportes",
    key: "reports",
    notVisible: true,
    icon: ProfileSvg,
    path: "reports",
    menu: [],
    items: [
      {
        name: "",
        path: "",
        key: "",
        component: Home,
        default: true,
      },
    ],
  },
  {
    name: "Marketplace",
    path: "marketplace",
    key: "marketplace",
    component: Marketplace,
    notVisible: true,
    dynamicItems: "productLines",
    items: [
      {
        name: "",
        path: "",
        key: "",
        component: "",
        default: true,
      },
    ],
  },
  {
    name: "Configuración",
    path: "account",
    key: "account",
    component: Account,
    notVisible: true,
    items: [
      {
        name: "Tu cuenta",
        path: "account",
        key: "account",
        default: true,
      },
      {
        name: "Notificaciones",
        path: "account/notification",
        key: "notifications",
      },
      {
        name: "Privacidad",
        path: "account/privacy",
        key: "privacy",
      },
      {
        name: "Conexiones PIAA",
        path: "account/pia",
        key: "pia",
      },
    ],
  },
  {
    name: "Notificaciones",
    key: "notifications",
    icon: NotificationsSvg,
    path: "notifications",
    menu: [],
    items: [],
  },
  {
    name: "Ajustes",
    key: "settings",
    icon: SettingsSvg,
    path: "settings",
    menu: [
      {
        name: "Mi cuenta",
        key: "myAccount",
        path: "account",
      },
      {
        name: "Notificaciones",
        key: "notifications",
        path: "account/notification",
      },
      {
        name: "Privacidad",
        key: "privacy",
        path: "account/privacy",
      },
      {
        name: "Conexiones PIAA",
        key: "conecctions",
        path: "account/pia",
      },
    ],
    items: [],
  },
  {
    name: "Usuario",
    key: "user",
    icon: ProfileSvg,
    path: "user",
    menu: [
      {
        name: "Editar mi perfil",
        key: "editMyProfile",
      },
      {
        name: "Tips",
        key: "tips",
      },
      {
        name: "Leads",
        key: "leads",
      },
    ],
    items: [],
  },
];

export default menu;
