/* eslint-disable react/jsx-props-no-spreading */
import { instanceOf } from "prop-types";
import React from "react";
import Slider from "react-slick";
import Card from "../Card/Card";

const TypeQuarter = ({ cards, dummyCardsRecommendation }) => {
  const settingsCards = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 764,
        slidesToShow: 1,
      },
    ],
  };
  const settingsRecommendation = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 764,
        slidesToShow: 1.2,
      },
    ],
  };
  return (
    <div className="TypeQuarter">
      <div className="TypeQuarter__view">
        <Slider {...settingsCards}>
          {cards?.map((item) => (
            <div key={item.id} className="caroucel-item">
              <Card item={item} type="1/4" />
            </div>
          ))}
        </Slider>
      </div>
      <div className="TypeQuarter__recommendation">
        <Slider {...settingsRecommendation}>
          {dummyCardsRecommendation?.map((item) => (
            <div key={dummyCardsRecommendation} className="caroucel-item">
              <Card item={item} type="1/4" />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

TypeQuarter.propTypes = {
  cards: instanceOf(Array).isRequired,
  dummyCardsRecommendation: instanceOf(Array).isRequired,
};

export default TypeQuarter;
