import { createActions } from "redux-actions";

const { mixpanel } = createActions({
  MIXPANEL: {
    INIT: (mixpanelInstance) => mixpanelInstance,
    TRACK: (name, data) => ({ name, data }),
  },
});

export default mixpanel;
