import { Button } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../../../components/loader";
import OnboardingActions from "../../../../../../../services/onboarding/actions";
import "./completed.scss";

const OnBoardingComplete = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onboarding = useSelector((state) => state.onboarding);

  useEffect(() => {
    if (onboarding.success.complete) dispatch(OnboardingActions.getProfile());
  }, [onboarding, dispatch]);

  const finish = () => {
    dispatch(OnboardingActions.saveOnboarding());
  };

  return (
    <div className="onBoardingComplete">
      <div className="onBoardingComplete__congrats">
        <h3>{t("label.congratsOnboarding")}</h3>
        <p>{t("label.continueToProfile")}</p>
      </div>
      {onboarding.loading.complete && <Loader />}
      <div className="finish">
        <Button
          disabled={onboarding.loading.complete}
          loading={onboarding.loading.complete}
          onClick={() => finish()}
        >
          {t("button.goToProfile")}
        </Button>
      </div>
    </div>
  );
};

export default OnBoardingComplete;
