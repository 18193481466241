/* eslint-disable react/jsx-no-bind */
import { Collapse } from "antd";
import React, { useState } from "react";
import down from "../../../assets/images/collapse.svg";
import CustomizeReport from "./components/CustomizeReport/CustomizeReport";
import HeaderReportsPia from "./components/HeaderReportsPia/HeaderReportsPia";
import ReportsSummary from "./components/ReportsSummary/ReportsSummary";

const { Panel } = Collapse;

const ReportsPia = () => {
  const [isTabActive, setTabActive] = useState("1");
  function callback(key) {
    setTabActive(key);
  }
  return (
    <div className="content-ReportsPia">
      <div className="ReportsPia">
        <HeaderReportsPia />
        <Collapse
          defaultActiveKey={["1"]}
          activeKey={[isTabActive]}
          expandIcon={() => <img src={down} alt="down" className="down" />}
          accordion
          onChange={callback}
        >
          <Panel header="1. Personalizar reporte" key="1">
            <CustomizeReport setTabActive={setTabActive} />
          </Panel>
          <Panel header="2. Ver Reporte y resumen" key="2">
            <ReportsSummary />
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default ReportsPia;
