import { handleActions } from "redux-actions";

export const INITIAL_STATE = {
  loading: {
    minimalMetrics: false,
  },
  error: {
    minimalMetrics: undefined,
  },
  success: {
    minimalMetrics: undefined,
  },
};

const reducer = handleActions(
  {
    CHARTS: {
      GET_MINIMAL_METRICS: (state) => ({
        ...state,
        loading: { ...state.loading, minimalMetrics: true },
        error: { ...state.error, minimalMetrics: undefined },
      }),
      GET_MINIMAL_METRICS_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, minimalMetrics: false },
            success: { minimalMetrics: payload.metrics },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, minimalMetrics: message },
            success: { ...state.success, minimalMetrics: undefined },
            loading: { ...state.loading, minimalMetrics: false },
          };
        },
      },
    },
  },
  INITIAL_STATE
);

export default reducer;
