import { Button, Card, Dropdown, message } from "antd";
import EllipsisSvg from "assets/icon/ellipsis.svg";
import { func } from "prop-types";
import React, { useEffect } from "react";
import { GoogleLogin } from "react-google-login";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import verification from "../../../../../../../../../assets/icon/icon-verification.svg";
import iconEyeClose from "../../../../../../../../../assets/icon/icon-eyeclose.svg";
import iconEyeOpen from "../../../../../../../../../assets/icon/icon-eyeO-white.svg";
import AgentActions from "../../../../../../../../../services/agent/actions";
import ONobardingActions from "../../../../../../../../../services/onboarding/actions";

const MyOffices = ({ goToAdd, edit, drop, goToSetCode }) => {
  const onboarding = useSelector((state) => state.onboarding);
  const agent = useSelector((state) => state.agent);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    if (agent.success.changeShow) {
      message.success("Has cambiado la visibilidad exitosamente");
      dispatch(AgentActions.clearChangeShow());
      dispatch(ONobardingActions.getProfile(false));
    }
    if (agent.error.changeShow) {
      message.error();
      dispatch(AgentActions.clearChangeShow("No se ha podido cambiar la visibilidad exitosamente"));
    }
  }, [agent, dispatch]);

  useEffect(() => {
    if (agent.success.officeSent) {
      message.success({
        content: "Hemos enviado tu información a Google mi negocio",
        icon: (
          <svg
            className="icon-check-svg"
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.83877 5.6076L5.39204 9.16088L14.3405 0.212402L15.4703 1.34218L5.39204 11.4204L0.708984 6.73738L1.83877 5.6076Z"
              fill="#5D30DD"
            />
          </svg>
        ),
      });
      dispatch(AgentActions.clearGoogleLocation());
    }
    if (agent.error.officeSent) {
      message.success({
        content: `No hemos podido enviar tu información a Google mi negocio. ${t(
          `error.${agent.error.officeSent}`
        )}`,
        icon: (
          <svg
            className="icon-check-svg"
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.83877 5.6076L5.39204 9.16088L14.3405 0.212402L15.4703 1.34218L5.39204 11.4204L0.708984 6.73738L1.83877 5.6076Z"
              fill="#5D30DD"
            />
          </svg>
        ),
      });
      dispatch(AgentActions.clearGoogleLocation());
    }
  }, [agent, dispatch, t]);

  const sendOfficeToGmb = (values, office) => {
    if (!office) return;
    dispatch(
      AgentActions.sendGoogleLocation({
        accesToken: values.accessToken,
        ...office,
      })
    );
  };

  const options = (office) => (
    <div className="menu-edit">
      <a
        onClick={(e) => {
          e.preventDefault();
          edit(office);
        }}
        href="./"
      >
        {t("button.edit")}
      </a>
      <a
        onClick={(e) => {
          e.preventDefault();
          drop(office);
        }}
        href="./"
      >
        {t("button.Erase")}
      </a>
    </div>
  );

  return (
    <section className="offices">
      <Card className="offices__card">
        <div className="offices__cardHeader">
          <h4>{t("label.myBusiness")}</h4>
        </div>
        <div className="button">
          <Button onClick={goToAdd}>{t("button.addOffices")}</Button>
        </div>
        <div className="offices__cardOffices">
          {onboarding.success.profile.offices?.map((office) => (
            <div className="offices__cardOffice" key={office.id}>
              <div className="offices__cardOffice__edit">
                <Dropdown overlay={options(office)} trigger={["click"]} placement="bottomRight">
                  <a
                    href="./"
                    className="custom-trigger-btn"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <img alt="avatar" src={EllipsisSvg} />
                  </a>
                </Dropdown>
              </div>
              <div className="offices__cardOffice__info">
                <h3>{office.name}</h3>
                <div className="offices__cardOffice__infoAddress">
                  <p>{office.address}</p>
                  <p>{office.areaLevel}</p>
                  <p>
                    {office.zipcode}, {office.city}
                  </p>
                  <p>{office.country}</p>
                </div>
              </div>
              <div className="offices__cardOffice__verification">
                <div className="offices__cardOffice__verification__state">
                  <img src={verification} alt="verification" />
                  <span className="text">
                    {office.verified === "Verified" && "Verificado"}
                    {office.verified === "Inactive" && "No verificado"}
                    {office.verified === "Processing" && "En proceso"}
                  </span>
                </div>
                <div className="offices__cardOffice__verification__action">
                  {office.verified === "Inactive" && (
                    <GoogleLogin
                      clientId="1028744019107-1vart3v5185at3cmqaba84mldm3ts2ng.apps.googleusercontent.com"
                      buttonText="Enviar"
                      scope="https://www.googleapis.com/auth/business.manage"
                      onSuccess={(values) => sendOfficeToGmb(values, office)}
                      onFailure={sendOfficeToGmb}
                      render={(renderProps) => (
                        <a
                          href="./"
                          className="validate"
                          onClick={(e) => {
                            e.preventDefault();
                            renderProps.onClick();
                          }}
                        >
                          {t("button.send")}
                        </a>
                      )}
                      cookiePolicy="single_host_origin"
                    />
                  )}
                  {office.verified === "Processing" && (
                    <a
                      href="./"
                      className="validate"
                      onClick={(e) => {
                        e.preventDefault();
                        goToSetCode(office);
                      }}
                    >
                      {t("button.validate")}
                    </a>
                  )}
                </div>
              </div>
              <div className="offices__cardOffice__predetermined">
                <div
                  className={`offices__cardOffice__predetermined--${
                    office.show === "Active" ? "visible" : "notVisible"
                  }`}
                >
                  <p>
                    {office.show === "Active"
                      ? t("label.addressIsVisible")
                      : t("label.addressNotIsVisible")}
                  </p>
                  <a
                    href="./"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(AgentActions.changeShow(office.id));
                    }}
                  >
                    {office.show === "Active" ? (
                      <img
                        src={iconEyeOpen}
                        alt="iconEyeClose"
                        className="icon-invisible icon-eyes"
                        aria-hidden="true"
                      />
                    ) : (
                      <img
                        src={iconEyeClose}
                        alt="iconEyeClose"
                        className="icon-invisible icon-eyes"
                        aria-hidden="true"
                      />
                    )}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Card>
    </section>
  );
};

MyOffices.propTypes = {
  goToAdd: func.isRequired,
  edit: func.isRequired,
  drop: func.isRequired,
  goToSetCode: func.isRequired,
};

export default MyOffices;
