import { Button } from "antd";
import statusList from "common/utils/policiesStatusList";
import { func, instanceOf } from "prop-types";
import React from "react";
import PoliciesActions from "services/policies/actions";

const StatusSelector = ({ dispatch, setSelectedProductLines, handleStatus, filters }) => (
  <div className="box-mini box-mini--min">
    <div className="box-head">
      <p className="sub-title">Estatus</p>
      <Button
        onClick={() => {
          dispatch(PoliciesActions.setFilters("clean_Estado", []));
          setSelectedProductLines([]);
        }}
        className="desSelect"
      >
        Deseleccionar
      </Button>
    </div>
    <div className="content-status">
      {statusList?.map((item) => (
        <Button
          onClick={() => handleStatus(item.title)}
          key={item.id}
          className={`box ${
            filters.Estado.find((status) => status === item.title) && "box--active"
          }`}
        >
          <img src={item.icon} alt="estado" className="icon" />
          <span className={`${item.status}`}>{item.title}</span>
        </Button>
      ))}
    </div>
  </div>
);

StatusSelector.propTypes = {
  dispatch: func.isRequired,
  setSelectedProductLines: func.isRequired,
  handleStatus: func.isRequired,
  filters: instanceOf(Object).isRequired,
};

export default StatusSelector;
