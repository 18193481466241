/* eslint-disable react/jsx-no-bind */
import { Button, Select, Table } from "antd";
import React from "react";
import car from "../../../../../assets/icon/car-gradient.svg";
import down from "../../../../../assets/images/down-purple.svg";
import ChartTower from "../../../reports/ChartTower/ChartTower";
import TableCake from "../../../reports/TableCake/TableCake";
import TableLinePoint from "../../../reports/TableLinePoint/TableLinePoint";
import TableOne from "../../../reports/tableOne/tableOne";

const { Option } = Select;

const ReportsSummary = () => {
  function handleChange(value) {
    // eslint-disable-next-line no-console
    console.log(`selected ${value}`);
  }

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
    {
      key: "3",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
  ];

  return (
    <div className="ReportsSummary">
      <div className="ReportsSummary--content">
        <div className="ReportsSummary--content--head">
          <h3 className="h3">Reporte PIAA</h3>
          <div className="constnt--select">
            <p className="body-regular">Tiempo seleccionado:</p>
            <Select
              suffixIcon={<img src={down} alt="down" className="down" />}
              defaultValue="lucy"
              onChange={handleChange}
            >
              <Option value="jack">2 mes</Option>
              <Option value="lucy">1 mes</Option>
            </Select>
          </div>
        </div>
        <div className="table-metricks-medium">
          <div className="box-table">
            <TableOne />
          </div>
          <div className="box-number">
            <p className="body-bold">Ventas cerradas</p>
            <h2 className="number">93</h2>
          </div>
          <div className="box-number box-number-small">
            <p className="body-bold">Top ramo</p>
            <img src={car} alt="metricks" className="metricks" />
            <h3 className="sub-title">Autos</h3>
            <h4 className="body-regular">47.2%</h4>
          </div>
        </div>
        <div className="table-metricks-body">
          <div className="content-chart">
            <div className="item-select">
              <p className="body-bold">Ramo</p>
              <Select className="select" defaultValue="1" onChange={handleChange}>
                <Option value="1">1 año</Option>
                <Option value="2">2 años</Option>
                <Option value="3">3 años</Option>
              </Select>
            </div>
            <TableCake />
          </div>
          <div className="content-chart">
            <div className="item-select">
              <p className="body-bold">Top 5 Aseguradoras en primas (MXP)</p>
              <Select className="select" defaultValue="1" onChange={handleChange}>
                <Option value="1">1 año</Option>
                <Option value="2">2 años</Option>
                <Option value="3">3 años</Option>
              </Select>
            </div>
            <ChartTower />
          </div>
          <div className="content-chart">
            <div className="item-select">
              <p className="body-bold">Pago</p>
              <Select className="select" defaultValue="1" onChange={handleChange}>
                <Option value="1">1 año</Option>
                <Option value="2">2 años</Option>
                <Option value="3">3 años</Option>
              </Select>
            </div>
            <TableLinePoint width="90%" height="180" />
            <div className="tower">
              <p className="body-bold">Moneda</p>
              <div className="tower-table">
                <div className="up">
                  <p>MXP</p>
                  <span>85.5%</span>
                </div>
                <div className="down">
                  <p>USD</p>
                  <span>13.5%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ReportsSummary--table">
        <h3 className="h3">Detalle por Ramo</h3>
        <Table dataSource={dataSource} columns={columns} />
        <div className="generate-report">
          <Button className="button button-tertiary">Descargar PDF</Button>
          <Button className="button button-primary">Generar Reporte</Button>
        </div>
      </div>
    </div>
  );
};

export default ReportsSummary;
