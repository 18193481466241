import { Button, Col, Divider, Form, Input, Row } from "antd";
import mail from "assets/icon/icon-mail.svg";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ProductDescription from "./components/ProductDescription";
import ProductLineDescription from "./components/ProductLineDescription";
import phonePurple from "../../../../../assets/images/phone-purple.svg";
import emailPurple from "../../../../../assets/images/email-purple.svg";
import WhatsappPurple from "../../../../../assets/images/Whatsapp-purple.svg";

const SidebarLead = ({ setPageCurrent, pageCurrent, lead, detail }) => {
  const { t } = useTranslation();

  const leadCurrentContact = useSelector(
    (state) => state.leads.success.leadDetail.lead.person.contacts
  );

  const contact = leadCurrentContact.reduce((acc, el) => ({ ...acc, [el.type]: el }), {});

  return (
    <div className="sidebar-modal">
      <div className="sidebar-modal__profile">
        <div className="sidebar-modal__profile--left">
          <div className="sidebar-modal__profileInfo">
            <span className="sidebar-modal__profileLetter">{lead?.person.name[0]} </span>
          </div>
          <div className="name">
            <p>{lead?.person.name}</p>
            <Button onClick={() => setPageCurrent("history")} type="link">
              Ver {t("label.Record")}
            </Button>
          </div>
        </div>
        <div className="rigth">
          {lead?.person?.contacts?.map((item) =>
            item.type === "Email" ? (
              <a key={`Contact-key-${item.value}`} href={`mailto:${item.value}`}>
                <img src={emailPurple} alt="icon" className="icon icon-email" />
              </a>
            ) : (
              <div key={`Contact-key-${item.value}`}>
                <a href={`tel:${item.value}`}>
                  <img src={phonePurple} alt="icon" className="icon icon-phone" />
                </a>
                <a href={`https://wa.me/52${item.value}`}>
                  <img src={WhatsappPurple} alt="icon" className="icon icon-whatsaap" />
                </a>
              </div>
            )
          )}
        </div>
      </div>
      {detail.lead.desiredProduct ? (
        <ProductDescription
          leadId={+lead.id}
          product={detail.lead.desiredProduct}
          selectedStatus={lead.status[0].status}
          status={detail.status}
          t={t}
        />
      ) : (
        <ProductLineDescription
          t={t}
          leadId={+lead.id}
          productLine={lead.productLine}
          selectedStatus={lead.status[0].status}
          status={detail.status}
        />
      )}
      <div className="sidebar-modal__form">
        <Divider />
        <Form name="horizontal_login" layout="inline">
          <Row>
            <Col span={16}>
              <Form.Item label="E-mail">
                <Input placeholder="E-mail" value={contact.Email?.value} disabled />
              </Form.Item>
            </Col>
            <Col style={{ display: "flex" }} className="sidebar-modal__formButtons" span={8}>
              <a href={`mailto:${contact.Email?.value}`} className="primary">
                <img src={mail} alt="mail" />
              </a>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24}>
              <Form.Item label="Teléfono">
                <Input placeholder="Teléfono" disabled value={contact.Phone?.value} />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24}>
              <Form.Item label="Origen">
                <Input placeholder="Origen" disabled value={lead.origin.name} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="sidebar-modal__buttons">
        <Button
          className={`button-solicitud ${pageCurrent === "policy" && "active"}`}
          block
          onClick={() => setPageCurrent("policy")}
        >
          {t("label.Policy")}
        </Button>
        <Divider />
        <Button
          className={`button-solicitud ${pageCurrent === "products" && "active"}`}
          block
          onClick={() => setPageCurrent("products")}
        >
          {t("label.Request")}
        </Button>
        <Divider />
        <Button
          className={pageCurrent === "history" && "active"}
          onClick={() => setPageCurrent("history")}
          style={{ borderRadius: "0 0 20px 20px" }}
          block
        >
          {t("label.Record")}
        </Button>
      </div>
    </div>
  );
};

SidebarLead.propTypes = {
  setPageCurrent: PropTypes.func.isRequired,
  pageCurrent: PropTypes.string.isRequired,
  lead: PropTypes.instanceOf(Object).isRequired,
  detail: PropTypes.instanceOf(Object).isRequired,
};

export default SidebarLead;
