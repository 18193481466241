/* eslint-disable react/jsx-props-no-spreading */
import { instanceOf } from "prop-types";
import React from "react";
import Slider from "react-slick";
import Card from "../Card/Card";

const TypeOneMedium = ({ cards }) => {
  const settings = {
    dots: true,
    infinite: cards.length > 1,
    speed: 4000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 764,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1.1,
          autoplay: false,
          autoplaySpeed: 500,
          speed: 500,
        },
      },
    ],
  };
  return (
    <div className="TypeOneMedium">
      <div className="TypeOneMedium__content">
        <Slider {...settings}>
          {cards?.map((item) => (
            <div key={item.id} className="caroucel-item">
              <Card
                item={{
                  agency:
                    item.insuranceCarrierProducts &&
                    item.insuranceCarrierProducts[0]?.insuranceCarrier,
                  ...item,
                }}
                type="1/2"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

TypeOneMedium.propTypes = {
  cards: instanceOf(Array).isRequired,
};

export default TypeOneMedium;
