import IconHelp from "assets/icon/icon-help.svg";
import IconHome from "assets/icon/icon-home.svg";
import Logo from "assets/images/berry-admin.svg";
import { func } from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import OnboardingActions from "../../../services/onboarding/actions";

const BrandHeader = ({ innerChangeRoute }) => {
  const dispatch = useDispatch();
  return (
    <>
      <a
        href="./"
        id="home-button-header"
        className="logo-berry-admin"
        onClick={(e) => {
          e.preventDefault();
          innerChangeRoute("");
        }}
      >
        <img src={Logo} alt="logo" />
      </a>
      <a
        href="./"
        id="home-button-header"
        onClick={(e) => {
          e.preventDefault();
          innerChangeRoute("");
        }}
      >
        <img src={IconHome} className="icon-home-head" alt="icon-home" />
      </a>
      <a
        href="./"
        className="icon-home--header"
        id="tutorial-button-header"
        onClick={(e) => {
          e.preventDefault();
          dispatch(OnboardingActions.setShowTutorial(true));
        }}
      >
        <img src={IconHelp} alt="icon-help" className="icon-help" />
      </a>
    </>
  );
};

BrandHeader.propTypes = {
  innerChangeRoute: func.isRequired,
};

export default BrandHeader;
