/* eslint-disable import/no-cycle */
import ApiConnection from "common/api/Api";
import { all, put, takeLatest } from "redux-saga/effects";
import parseError from "../../common/api/ErrorParser";
import leadsActions from "./actions";

function* getMyLeads({ payload }) {
  const url = "/lead/list-my-leads";
  const response = yield ApiConnection.post(url, {
    ...payload,
    quantity: `${payload.quantity}`,
    page: `${payload.page}`,
  });

  if (!response.success)
    return yield put(leadsActions.getMyLeadsResponse(parseError(response), response));

  return yield put(
    leadsActions.getMyLeadsResponse({
      leads: response.payload.leads.map((lead, i) => ({ ...lead, key: i })),
      total: response.payload.total,
    })
  );
}

function* getLeadDetail({ payload }) {
  const url = "/lead/detail";
  const response = yield ApiConnection.get(url, { id: payload.lead });
  if (!response.success)
    return yield put(leadsActions.getLeadDetailResponse(parseError(response), response));
  return yield put(
    leadsActions.getLeadDetailResponse({
      detail: response.detail,
    })
  );
}

function* getRecentLeads({ payload }) {
  const url = "/lead/list-recent-leads";
  const response = yield ApiConnection.get(url, payload);
  if (response.success)
    return yield put(
      leadsActions.getRecentLeadsResponse({
        recentLeads: response.detail.map((lead, i) => ({ ...lead, key: i })),
      })
    );

  return yield put(leadsActions.getRecentLeadsResponse(parseError(response), response));
}

function* getSuggestedProducts({ payload }) {
  const url = "/lead/suggested-products";
  const response = yield ApiConnection.post(url, payload);
  if (response.success) {
    return yield put(leadsActions.getSuggestedProductsResponse(response.detail));
  }

  return yield put(leadsActions.getSuggestedProductsResponse(parseError(response), response));
}

function* getActivities({ payload }) {
  const url = "/interaction";
  const response = yield ApiConnection.get(url, payload);
  if (response.success) return yield put(leadsActions.getActivitiesResponse(response.detail));
  return yield put(leadsActions.getActivitiesResponse(parseError(response), response));
}

function* deleteActivity({ payload: { interaction, lead } }) {
  const url = "/interaction/inactive";
  const response = yield ApiConnection.post(url, { interaction });
  if (response.success) {
    yield put(leadsActions.getActivities(lead));
    return yield put(leadsActions.deleteActivityResponse(response));
  }

  return yield put(leadsActions.deleteActivityResponse(parseError(response), response));
}

function* createActivity({ payload: { values } }) {
  const url = "/interaction";
  const response = yield ApiConnection.post(url, values);
  if (response.success) {
    yield put(leadsActions.getActivities(values.lead));
    return yield put(leadsActions.createActivityResponse(response));
  }
  return yield put(leadsActions.createActivityResponse(parseError(response), response));
}

function* getNotes({ payload }) {
  const url = "/note";
  const response = yield ApiConnection.get(url, payload);
  if (response.success) return yield put(leadsActions.getNotesResponse(response.detail));

  return yield put(leadsActions.getNotesResponse(parseError(response), response));
}

function* createNote({ payload: { values } }) {
  const url = "/note";
  const response = yield ApiConnection.post(url, values);
  if (response.success) {
    yield put(leadsActions.getNotes(values.interaction));
    return yield put(leadsActions.createNoteResponse(response));
  }

  return yield put(leadsActions.createNoteResponse(parseError(response), response));
}

function* deleteNote({ payload: { note } }) {
  const url = "/note/delete";
  const response = yield ApiConnection.post(url, { note });
  if (response.success) {
    yield put(leadsActions.getNotes(note));
    return yield put(leadsActions.deleteNoteResponse(response));
  }

  return yield put(leadsActions.deleteNotesResponse(parseError(response), response));
}

function* changeStatus({ payload }) {
  const url = "/lead/set-status";
  const response = yield ApiConnection.post(url, payload);
  if (!response.success)
    return yield put(leadsActions.changeStatusResponse(parseError(response), response));

  return yield put(leadsActions.changeStatusResponse());
}

function* getProductLines() {
  const url = "/product-line";
  const response = yield ApiConnection.get(url);
  if (response.status !== "SUCCESS")
    return yield put(leadsActions.getProductLinesResponse(parseError(response), response));

  return yield put(leadsActions.getProductLinesResponse(response.payload));
}

function* getOrigins() {
  const url = "/origin";
  const response = yield ApiConnection.get(url);
  if (response.success) return yield put(leadsActions.getOriginsResponse(response.detail));

  return yield put(leadsActions.getOriginsResponse(parseError(response), response));
}

function* ActionWatcher() {
  yield takeLatest(leadsActions.getMyLeads, getMyLeads);
  yield takeLatest(leadsActions.getRecentLeads, getRecentLeads);
  yield takeLatest(leadsActions.getSuggestedProducts, getSuggestedProducts);
  yield takeLatest(leadsActions.getActivities, getActivities);
  yield takeLatest(leadsActions.deleteActivity, deleteActivity);
  yield takeLatest(leadsActions.createActivity, createActivity);
  yield takeLatest(leadsActions.getNotes, getNotes);
  yield takeLatest(leadsActions.createNote, createNote);
  yield takeLatest(leadsActions.deleteNote, deleteNote);
  yield takeLatest(leadsActions.getLeadDetail, getLeadDetail);
  yield takeLatest(leadsActions.changeStatus, changeStatus);
  yield takeLatest(leadsActions.getProductLines, getProductLines);
  yield takeLatest(leadsActions.getOrigins, getOrigins);
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
