import React, { useState } from "react";
import TweenOne from "rc-tween-one";
import { Button } from "antd";
import PropTypes from "prop-types";
import back from "../../../../../../assets/images/flecha.svg";
import IconSubmitNotes from "../../../../../../assets/icon/submit-notes.svg";

const animation = {
  duration: 300,
  opacity: 1,
  top: "10px",
  easy: "easeOutExpo",
};

const EditActivity = ({ setEditNoteOpen }) => {
  const [reverse, setReverse] = useState(false);
  const handlerHidden = () => {
    setReverse(true);
    setTimeout(() => setEditNoteOpen(false), 500);
  };
  const dummyMsj = [
    {
      id: 1,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque interdum neque, sed velit. Augue suspendisse ultricies feugiat pellentesque sit.",
      hour: "10:05am",
    },
    {
      id: 2,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque interdum neque, sed velit. Augue suspendisse ultricies feugiat pellentesque sit.",
      hour: "12:05am",
    },
    {
      id: 3,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque interdum neque, sed velit. Augue suspendisse ultricies feugiat pellentesque sit.",
      hour: "12:05am",
    },
    {
      id: 4,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque interdum neque, sed velit. Augue suspendisse ultricies feugiat pellentesque sit.",
      hour: "12:05am",
    },
    {
      id: 5,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque interdum neque, sed velit. Augue suspendisse ultricies feugiat pellentesque sit.",
      hour: "12:05am",
    },
    {
      id: 6,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque interdum neque, sed velit. Augue suspendisse ultricies feugiat pellentesque sit.",
      hour: "12:05am",
    },
  ];
  return (
    <TweenOne animation={animation} className="activity" reverse={reverse}>
      <div className="activity-content">
        <div className="title-new-interaction">
          <Button onClick={() => handlerHidden()} className="back">
            <img src={back} alt="back" />
          </Button>
          <h2>Nueva interacción</h2>
        </div>
        <div className="content-msj">
          {dummyMsj?.map((item) => (
            <div className="msj" key={item.id}>
              <p>{item.text}</p>
              <span>{item.hour}</span>
            </div>
          ))}
          <div className="activity-message">
            <textarea id="detail-note" className="detail-note" placeholder="Detalle de la nota" />
            <Button className="activity-notesButton">
              <img src={IconSubmitNotes} alt="" />
            </Button>
          </div>
        </div>
      </div>
    </TweenOne>
  );
};

EditActivity.propTypes = {
  setEditNoteOpen: PropTypes.func.isRequired,
};

export default EditActivity;
