/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import Chart from "react-apexcharts";

class TableCake extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [14, 12, 15, 6],
      options: {
        chart: {
          width: 480,
          type: "donut",
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#7F14FC", "#1C0E42", "#340AAB", "#D9D2E9"],
        labels: ["Team A", "Team B", "Team C", "Team D"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  }

  render() {
    return (
      <div className="donut">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="donut"
          width="100%"
          height="150"
        />
      </div>
    );
  }
}

export default TableCake;
