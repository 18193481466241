/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import MarketplaceActions from "../../../services/marketplace/actions";
import Category from "./category/category";
import Home from "./home/home";

const Marketplace = () => {
  const dispatch = useDispatch();
  const { insuranceCarriers, specialProducts, products, loading, error } = useSelector(
    (state) => state.marketplace
  );

  useEffect(() => {
    if (!insuranceCarriers && !loading.getInsuranceCarriers && !error.getInsuranceCarriers)
      dispatch(MarketplaceActions.getInsuranceCarriers());
    if (!specialProducts && !loading.getSpecialProducts && !error.getSpecialProducts)
      dispatch(MarketplaceActions.getSpecialProducts());
    if (!products.all && !loading.getAllProducts && !error.getAllProducts)
      dispatch(MarketplaceActions.getAllProducts());
  }, [dispatch, insuranceCarriers, specialProducts, products, error, loading]);

  return (
    <Switch>
      <Route
        path="/marketplace/:category"
        render={(props) => <Category category={props.match.params.category} />}
      />
      <Route exact path="/marketplace" component={Home} />
    </Switch>
  );
};

export default Marketplace;
