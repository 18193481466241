/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
import { all, put, select, takeLatest } from "redux-saga/effects";
import Api from "../../common/api/Api";
import { chunk } from "../../common/utils/chunk";
import AgentActions from "../agent/actions";
import OnboardingActions from "./actions";

function* getProfile() {
  const url = "/agent";

  const response = yield Api.get(url);

  if (response.status === "SUCCESS") {
    response.payload.offices = response.payload.offices.sort((office, secondOffice) => {
      if (office.id < secondOffice.id) return 1;

      if (office.id > secondOffice.id) return -1;
      return 0;
    });
    yield put(AgentActions.getProductLines());
    return yield put(
      OnboardingActions.getProfileResponse({
        profile: {
          ...response.payload,
          onBoarding: response.payload.onBoarding === "Active",
          agentSocialNetworks:
            response.payload.agentSocialNetworks.length > 0
              ? response.payload.agentSocialNetworks
              : undefined,
        },
      })
    );
  }
  const err = new TypeError(response?.status ? response.message : "UNDEFINED_ERROR");
  return yield put(OnboardingActions.getProfileResponse(err, response));
}

function* getLicenses() {
  const url = "/license";

  const response = yield Api.get(url);

  if (response.status === "SUCCESS") {
    return yield put(
      OnboardingActions.getLicensesResponse({
        licenses: response.payload,
      })
    );
  }
  const err = new TypeError(response?.status ? response.message : "UNDEFINED_ERROR");
  return yield put(OnboardingActions.getLicensesResponse(err, response));
}

function* getInsuranceCarriers() {
  const url = "/insurance-carrier";

  const response = yield Api.get(url);

  if (response.status === "SUCCESS") {
    return yield put(
      OnboardingActions.getInsuranceCarriersResponse({
        insuranceCarriers: response.payload,
      })
    );
  }
  const err = new TypeError(response?.status ? response.message : "UNDEFINED_ERROR");
  return yield put(OnboardingActions.getInsuranceCarriersResponse(err, response));
}

function* getProductLines() {
  const url = "/product-line";

  const response = yield Api.get(url);
  if (response.status === "SUCCESS") {
    const productLines = chunk(response.payload, 6);
    const productLinesLists = {};
    for (const i in productLines) {
      productLinesLists[`list${i}`] = productLines[i].map((productLine) => ({
        ...productLine,
        selected: false,
      }));
    }
    return yield put(
      OnboardingActions.getProductLinesResponse({
        productLinesLists,
      })
    );
  }
  const err = new TypeError(response?.status ? response.message : "UNDEFINED_ERROR");
  return yield put(OnboardingActions.getProductLinesResponse(err, response));
}

function parseOnboarding(onboarding, officesToSave) {
  const data = {
    sponsor: onboarding.sponsor === "other" ? "" : onboarding.sponsor,
    licenses: [],
    insurers: [],
    productLines: [],
    offices: [],
  };
  if (onboarding.licenseTypes)
    onboarding.licenseTypes.map((license) => {
      if (license.isSelected)
        data.licenses.push({ license: +license.id, licenseNumber: license.licenseNumber });
      return license;
    });

  if (onboarding.insuranceCarriers)
    onboarding.insuranceCarriers.map((insuranceCarrier) => {
      if (insuranceCarrier.isSelected) data.insurers.push(+insuranceCarrier.id);
      return insuranceCarrier;
    });

  if (onboarding.productLinesSelected)
    onboarding.productLinesSelected.map((productLine, i) => {
      data.productLines.push({
        id: +productLine.id,
        level: i + 1,
      });
      return productLine;
    });
  if (officesToSave)
    officesToSave.map((office) => {
      data.offices.push({
        ...office,
        productLines: office.productLines?.map((productLine) => +productLine.id),
        insuranceCarriers: office.insuranceCarriers?.map(
          (insuranceCarrier) => +insuranceCarrier.id
        ),
      });
      return office;
    });
  return data;
}

function* saveOnboarding() {
  const url = "/agent/complete-profile";
  const getOnboarding = (state) => state.onboarding;
  const { success: onboarding, officesToSave } = yield select(getOnboarding);

  const data = parseOnboarding(onboarding, officesToSave);
  const response = yield Api.post(url, data);
  if (response.status === "SUCCESS") {
    yield put(AgentActions.clearGetProductLinesResponse());
    yield put(OnboardingActions.getProfile());
    return yield put(OnboardingActions.saveOnboardingResponse());
  }

  const err = new TypeError(response?.status ? response.message : "UNDEFINED_ERROR");
  return yield put(OnboardingActions.saveOnboardingResponse(err, response));
}

function* ActionWatcher() {
  yield takeLatest(OnboardingActions.getLicenses, getLicenses);
  yield takeLatest(OnboardingActions.getProfile, getProfile);
  yield takeLatest(OnboardingActions.getInsuranceCarriers, getInsuranceCarriers);
  yield takeLatest(OnboardingActions.getProductLines, getProductLines);
  yield takeLatest(OnboardingActions.saveOnboarding, saveOnboarding);
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
