/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import { Card, message, Modal } from "antd";
import ConfirmAlert from "components/Modals/ConfirmAlert/ConfirmAlert";
import { bool, func } from "prop-types";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import insurance from "assets/icon/insurance.svg";
import Loader from "components/loader";
import AgentActions from "services/agent/actions";
import OnboardingActions from "services/onboarding/actions";
import MarketplaceActions from "services/marketplace/actions";
import SliderInsuranceCarriers from "./SliderInsuranceCarriers/SliderInsuranceCarriers";
import EditSvgW from "../../../../../../../assets/icon/icon-edit-white.svg";

const ExperienceEditing = ({ stopEditing, saveExperience, setSaveExperience }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const agent = useSelector((state) => state.agent);
  const [placeholders, setPlaceholders] = useState([0, 1, 2, 3, 4, 5, 6]);
  const [insuranceCarrierConfirm, setInsuranceCarrierConfirm] = useState(false);
  const [selectedInsuranceCarrierConfirm, setSelectedInsuranceCarrierConfirm] = useState(undefined);

  useEffect(() => {
    if (
      !agent.success.insuranceCarriers &&
      !agent.error.insuranceCarriers &&
      !agent.loading.insuranceCarriers &&
      agent.success.insuranceCarriersOrdered
    )
      dispatch(AgentActions.getInsuranceCarriers());

    if (agent.success.updateInsuranceCarriers) {
      message.success({
        content: t("alert.insuranceCarriersUpdated"),
        icon: (
          <svg
            className="icon-check-svg"
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.83877 5.6076L5.39204 9.16088L14.3405 0.212402L15.4703 1.34218L5.39204 11.4204L0.708984 6.73738L1.83877 5.6076Z"
              fill="#5D30DD"
            />
          </svg>
        ),
      });
      dispatch(AgentActions.clearInsuranceCarrierUpdated());

      stopEditing();
      dispatch(OnboardingActions.getProfile(false));
      dispatch(MarketplaceActions.clearMinimalMarketplaceResponse());
    }

    if (agent.error.updateInsuranceCarriers) message.error(t("error.insuranceCarriersUpdated"));
  }, [agent, dispatch, t, stopEditing]);

  useEffect(() => {
    const placeholderSize = placeholders.length;
    const times = agent.success.insuranceCarriersOrdered?.length + 1 - placeholderSize;
    if (agent.success.insuranceCarriersOrdered?.length >= placeholderSize - 1) {
      for (let i = 0; i < times; i += 1) placeholders.push(placeholders[placeholderSize - 1] + 1);

      setPlaceholders(placeholders);
    }
  }, [agent.success, placeholders]);

  const saveInsuranceCarriers = () => {
    if (agent.autoInsurance) dispatch(AgentActions.setAutoInsurance(undefined));
    dispatch(AgentActions.updateInsuranceCarriers());
  };

  useEffect(() => {
    if (saveExperience) {
      setSaveExperience(false);
      dispatch(AgentActions.updateInsuranceCarriers());
    }
  }, [saveExperience, setSaveExperience, dispatch]);

  const renderOrderedList = () => {
    const {
      success: { insuranceCarriersOrdered },
    } = agent;
    return placeholders.map((placeholder, i) => {
      if (insuranceCarriersOrdered && insuranceCarriersOrdered[i])
        return (
          <Draggable key={`order${i}`} draggableId={`order${i}`} index={i}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={`experience-editing__cardContext__content__listInsuranceCarriers ${
                  insuranceCarriersOrdered[i].auto ? "auto" : ""
                }`}
              >
                <img alt={insuranceCarriersOrdered[i].key} src={insuranceCarriersOrdered[i].logo} />
              </div>
            )}
          </Draggable>
        );

      return (
        <div
          key={`placeholder${i}`}
          className="experience-editing__cardContext__wrapper__droppableItem"
        >
          <div className="item" />
        </div>
      );
    });
  };

  const moveInsuranceCarrier = (result) => {
    dispatch(AgentActions.orderInsuranceCarriers(result.source, result.destination));
    if (
      result.source.droppableId !== result.destination.droppableId &&
      result.destination.droppableId === "insuranceCarriersOrdered"
    ) {
      placeholders.push(0);
    }
    setPlaceholders(placeholders);
  };

  const onDragEnd = (result) => {
    if (result.destination && result.source) {
      if (result.destination.droppableId === "insuranceCarriers") {
        setSelectedInsuranceCarrierConfirm(result);
        return setInsuranceCarrierConfirm(true);
      }
      moveInsuranceCarrier(result, dispatch);
    }
    return null;
  };

  return (
    <section className="experience-editing">
      <Card className="experience-editing__card">
        <div className="experience-editing__card__title">
          <img src={insurance} className="insurance" alt="insurance" />
          <h3>{t("label.insuranceCarriers")}</h3>
          <a
            href="./"
            onClick={(e) => {
              e.preventDefault();
              stopEditing();
            }}
          >
            <img src={EditSvgW} className="edit" alt="true" />
          </a>
        </div>
        <ConfirmAlert
          onOk={() => {
            setInsuranceCarrierConfirm(false);
          }}
          onCancel={() => {
            setInsuranceCarrierConfirm(false);
          }}
          message={`¿Seguro que quieres eliminar esta aseguradora? Se van a desactivar los productos relacionados con ${
            agent.success.insuranceCarriersOrdered &&
            selectedInsuranceCarrierConfirm &&
            agent.success.insuranceCarriersOrdered[selectedInsuranceCarrierConfirm.source.index]
              ?.name
          }`}
          okClassName="yes"
          cancelClassName="no"
          onAcceptClick={() => {
            setInsuranceCarrierConfirm(false);
            moveInsuranceCarrier(selectedInsuranceCarrierConfirm, dispatch);
          }}
          onDeniedClick={() => {
            setInsuranceCarrierConfirm(false);
          }}
          isVisible={insuranceCarrierConfirm}
        />

        {!agent.success.insuranceCarriers ? (
          <Modal
            className="modal-loader"
            visible
            centered
            maskStyle={{ backgroundColor: "#ffffff66" }}
          >
            <Loader />
          </Modal>
        ) : (
          <div className="experience-editing__cardContext">
            <DragDropContext onDragEnd={(result) => onDragEnd(result, dispatch)}>
              <div className="experience-editing__cardContext__wrapper">
                <h4>{t("label.yourSelection")}</h4>
                <Droppable direction="horizontal" droppableId="insuranceCarriersOrdered">
                  {(provided, snapshot) => (
                    <div
                      key="insuranceCarriersOrdered"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="experience-editing__cardContext__wrapper__droppable"
                      style={{
                        background: snapshot.isDraggingOver ? "lightblue" : "white",
                      }}
                    >
                      {renderOrderedList()}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
              <div className="experience-editing__cardContext__content">
                <SliderInsuranceCarriers insurances={agent?.success?.insuranceCarriers} />
                <h4>{t("label.selectAndDrag")}</h4>
                <Droppable direction="horizontal" droppableId="insuranceCarriers">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      key="insuranceCarriers"
                      {...provided.droppableProps}
                      className="experience-editing__cardContext__content__list"
                      style={{
                        background: snapshot.isDraggingOver ? "lightblue" : "white",
                      }}
                    >
                      {agent.success.insuranceCarriers?.map((insuranceCarrier, i) => (
                        <Draggable
                          key={insuranceCarrier.id}
                          draggableId={insuranceCarrier.id}
                          index={i}
                        >
                          {(draggableProvided, draggableSnapshot) => (
                            <div
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                              className={`experience-editing__cardContext__content__listInsuranceCarriers${
                                draggableSnapshot.isDragging ? "--selected" : ""
                              }`}
                              key={insuranceCarrier.id}
                            >
                              <img
                                alt={insuranceCarrier.key}
                                src={
                                  draggableSnapshot.isDragging
                                    ? insuranceCarrier.logoWhite
                                    : insuranceCarrier.logo
                                }
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </DragDropContext>
          </div>
        )}
        <div className="experience-editing__cardButtons">
          <button type="button" onClick={stopEditing} className="button button--tertiary">
            {t("label.omitChanges")}
          </button>
          <button type="button" className="filled" onClick={saveInsuranceCarriers}>
            {t("button.updateInsuranceCarriers")}
          </button>
        </div>
      </Card>
    </section>
  );
};

ExperienceEditing.propTypes = {
  stopEditing: func.isRequired,
  setSaveExperience: func.isRequired,
  saveExperience: bool.isRequired,
};

export default ExperienceEditing;
