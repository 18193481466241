import { handleActions } from "redux-actions";

export const INITIAL_STATE = {
  instance: null,
};

const reducer = handleActions(
  {
    MIXPANEL: {
      INIT: (state, { payload }) => ({
        ...state,
        instance: payload,
      }),
    },
  },
  INITIAL_STATE
);

export default reducer;
