/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import tutorialInfo from "common/utils/tutorialInfo";
import { func, instanceOf } from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import AgentActions from "services/agent/actions";
import closeSvg from "../../assets/icon/close-white.svg";
import MenuTutorialPng from "../../assets/images/menu-tutorial.png";

import Content from "./components/content/content";
import Sidebar from "./components/sidebar/sidebar";
import Welcome from "./components/welcome/welcome";

const Tutorial = ({ setShowTutorial, profile }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeSlide2, setActiveSlide2] = useState(1);
  const [step, setStep] = useState(0);
  const [slider, setSlider] = useState(false);

  const [view, setView] = useState("");
  const [warnning, setWarnning] = useState(false);

  const [indexCaroucel, setIndexCaroucel] = useState(1);

  const handleChange = (item) => {
    setView(item);
    if (item.id === 1 && indexCaroucel < 2) setIndexCaroucel(1);
    if (item.id === 2 && indexCaroucel < 20) setIndexCaroucel(25);
    if (item.id === 3 && indexCaroucel < 40) setIndexCaroucel(50);
    if (item.id === 4 && indexCaroucel < 60) setIndexCaroucel(75);
    if (item.id === 5 && indexCaroucel < 80) setIndexCaroucel(88);
  };

  const handleSlider = (indexCaroucelProp) => {
    setIndexCaroucel(indexCaroucelProp);
  };

  useEffect(() => {
    if (view === "") setView(tutorialInfo[0]);
  }, [view]);

  useEffect(() => {
    if (view.key === "Onboarding" && activeSlide2 === 1) setIndexCaroucel(1);
    if (view.key === "Onboarding" && activeSlide2 === 2) setIndexCaroucel(6);
    if (view.key === "Onboarding" && activeSlide2 === 3) setIndexCaroucel(9);
    if (view.key === "Onboarding" && activeSlide2 === 4) setIndexCaroucel(12);
    if (view.key === "Onboarding" && activeSlide2 === 5) setIndexCaroucel(15);
    if (view.key === "Onboarding" && activeSlide2 === 6) setIndexCaroucel(18);
    if (view.key === "Onboarding" && activeSlide2 === 7) setIndexCaroucel(22);
    if (view.key === "edit" && activeSlide2 === 1) setIndexCaroucel(25);
    if (view.key === "edit" && activeSlide2 === 2) setIndexCaroucel(27);
    if (view.key === "edit" && activeSlide2 === 3) setIndexCaroucel(30);
    if (view.key === "edit" && activeSlide2 === 4) setIndexCaroucel(33);
    if (view.key === "edit" && activeSlide2 === 5) setIndexCaroucel(36);
    if (view.key === "edit" && activeSlide2 === 6) setIndexCaroucel(40);
    if (view.key === "edit" && activeSlide2 === 7) setIndexCaroucel(43);
    if (view.key === "edit" && activeSlide2 === 8) setIndexCaroucel(45);
    if (view.key === "edit" && activeSlide2 === 9) setIndexCaroucel(50);
    if (view.key === "induccion" && activeSlide2 === 1) setIndexCaroucel(53);
    if (view.key === "induccion" && activeSlide2 === 2) setIndexCaroucel(56);
    if (view.key === "induccion" && activeSlide2 === 3) setIndexCaroucel(59);
    if (view.key === "induccion" && activeSlide2 === 4) setIndexCaroucel(62);
    if (view.key === "induccion" && activeSlide2 === 5) setIndexCaroucel(65);
    if (view.key === "induccion" && activeSlide2 === 6) setIndexCaroucel(68);
    if (view.key === "induccion" && activeSlide2 === 7) setIndexCaroucel(71);
    if (view.key === "negocio" && activeSlide2 === 1) setIndexCaroucel(75);
    if (view.key === "negocio" && activeSlide2 === 2) setIndexCaroucel(78);
    if (view.key === "negocio" && activeSlide2 === 3) setIndexCaroucel(81);
    if (view.key === "negocio" && activeSlide2 === 4) setIndexCaroucel(83);
    if (view.key === "negocio" && activeSlide2 === 5) setIndexCaroucel(85);
    if (view.key === "negocio" && activeSlide2 === 6) setIndexCaroucel(88);
    if (view.key === "publicPage" && activeSlide2 === 1) setIndexCaroucel(88);
    if (view.key === "publicPage" && activeSlide2 === 2) setIndexCaroucel(91);
    if (view.key === "publicPage" && activeSlide2 === 3) setIndexCaroucel(93);
    if (view.key === "publicPage" && activeSlide2 === 4) setIndexCaroucel(96);
    if (view.key === "publicPage" && activeSlide2 === 5) setIndexCaroucel(100);
  }, [activeSlide2, view]);

  useEffect(() => {
    dispatch(AgentActions.updateTutorialShown());
  }, [profile, dispatch]);

  return (
    <div className="tutorial">
      {step === 0 && (
        <Welcome setShowTutorial={setShowTutorial} profile={profile} setStep={() => setStep(1)} />
      )}
      {step === 1 && (
        <>
          <div className="tutorial__header">
            {warnning && (
              <div className="warnning">
                <div>
                  <h4>¿{t("label.skipTutorial")}?</h4>
                  <p>
                    Puedes volver a ver el tutorial dando clic en el ícono{" "}
                    <QuestionCircleOutlined /> en la parte superior de tu dashboard.
                  </p>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      setShowTutorial();
                      setStep(0);
                      setWarnning(false);
                    }}
                    className="yes"
                  >
                    {t("label.yes")}
                  </Button>
                  <Button onClick={() => setWarnning(false)} className="no">
                    {t("label.not")}
                  </Button>
                </div>
              </div>
            )}
            <div onClick={() => setSlider(!slider)} className="button-menu">
              <img src={MenuTutorialPng} alt="menu-tutorial" />
              <p>
                {t("label.Induction")} {indexCaroucel}%
              </p>
            </div>
            <Button onClick={() => setWarnning(true)} type="link">
              {t("label.omitForNow")}
              <img src={closeSvg} alt="" />
            </Button>
          </div>
          <div className="tutorial__wrapper">
            <div className="tutorial__sidebar">
              <Sidebar
                indexCaroucel={indexCaroucel}
                setIndexCaroucel={setIndexCaroucel}
                handleChange={handleChange}
                handleSlider={handleSlider}
                slider={slider}
                view={view}
                dummyItemsTutorial={tutorialInfo}
                setView={setView}
              />
            </div>
            <div className="tutorial__content">
              <Content
                t={t}
                activeSlide2={activeSlide2}
                setActiveSlide2={setActiveSlide2}
                handleChange={handleChange}
                setShowTutorial={setShowTutorial}
                view={view}
                setView={setView}
                dummyItemsTutorial={tutorialInfo}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

Tutorial.propTypes = { setShowTutorial: func.isRequired, profile: instanceOf(Object) };

Tutorial.defaultProps = {
  profile: null,
};

export default Tutorial;
