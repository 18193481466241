import { Tooltip } from "antd";
import moment from "moment";
import { bool, instanceOf } from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import PoliciesActions from "services/policies/actions";
import RoutesActions from "services/routes/actions";
import SelectChangeStatusPolicy from "components/Atoms/SelectChangeStatusPolicy/SelectChangeStatusPolicy";
import PiaField from "scenes/Private/SummaryPia/components/PolicySummaryPia/components/PiaField";

const DetailProduct = ({ policy, isDarkMode }) => {
  const dispatch = useDispatch();
  function capitalize(str) {
    const lower = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lower.slice(1);
  }

  return (
    <div className="DetailProduct">
      <div className="DetailProduct__head">
        <div className="box">
          {policy?.icon && <img src={policy.icon} alt="icon" className="image" />}
          <h2 className="body-bold">{capitalize(policy.title)}</h2>
        </div>
        {policy.HasDetail === 1 ? (
          <Link
            to={`/pia/summary?id=${policy.id}`}
            className="button button--primary"
            onClick={() => {
              dispatch(RoutesActions.setActualRoute("pia/summary"));
              dispatch(PoliciesActions.cleanDetailResponse());
            }}
          >
            Ver Detalle
          </Link>
        ) : (
          <Tooltip title="Te notificaremos via correo electrónico cuando la data este completa.">
            <Link to="/pia" className="link-disabled button button--disabled">
              Ver Detalle
            </Link>
          </Tooltip>
        )}
      </div>
      <div className="DetailProduct__content">
        <div className="row">
          <div className="column">
            <p className="title">Número de póliza</p>
            <p className="body-bold">#{policy.number}</p>
          </div>
          <div className="column">
            {policy.ramo && policy.ramo !== "n/a" && (
              <>
                <p className="title">Ramo</p>
                <p className="body-bold body-bold--ramo">{policy.ramo}</p>
              </>
            )}
          </div>
          <div className="column">
            <p className="title">Estatus</p>
            <SelectChangeStatusPolicy policy={policy} />
          </div>
          <div className="column">
            <div className="column">
              <p className="title">Fecha de vencimiento</p>
              <p className="body-bold">
                {policy.fecha_vencimiento !== "ND"
                  ? moment(policy.fecha_vencimiento).format("DD/MM/YYYY")
                  : policy.fecha_vencimiento}
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="column">
            <p className="title">Aseguradora</p>
            <img
              src={isDarkMode ? policy.whiteImage : policy.image}
              alt={`Aseguradora-${isDarkMode ? policy.whiteImage : policy.image}`}
              className="img"
            />
          </div>
          <div className="column">
            {policy.pago && (
              <>
                <p className="title">Forma de pago</p>
                <p className="body-bold">{policy.pago}</p>
              </>
            )}
          </div>
          <div className="column">
            <p className="title">Fecha de emisión</p>
            <p className="body-bold">
              {policy.fecha !== "ND" ? moment(policy.fecha).format("DD/MM/YYYY") : policy.fecha}
            </p>
          </div>
          <div className="column">
            <p className="title">Comisiones</p>
            <p className="body-bold">
              <PiaField text={policy.commissionValue || ""} type="price" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

DetailProduct.propTypes = { isDarkMode: bool, policy: instanceOf(Object).isRequired };
DetailProduct.defaultProps = { isDarkMode: false };

export default DetailProduct;
