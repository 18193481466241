/* eslint-disable prefer-const */
const ramos = [
  { name: "autos", ramo: "Autos" },
  { name: "vida", ramo: "Vida" },
  { name: "salud", ramo: "Salud" },
  { name: "daños", ramo: "Daños" },
  { name: "fianza", ramo: "Fianzas" },
  { name: "salud", ramo: "gastos medicos individual" },
  { name: "salud", ramo: "gastos medicos colectivo" },
  { name: "vida", ramo: "vida individual" },
  { name: "autos", ramo: "camiones flotilla" },
  { name: "fianza", ramo: "proteccion a bienes familiares" },
];

const topRamo = (name) => {
  console.log(name);
  let result = ramos.filter((item) => item.ramo.toLowerCase() === name.toLowerCase());
  console.log(result);
  return result[0]?.name;
};

export default topRamo;
