import React from "react";
import backBerry from "../../../assets/images/pia-view.png";
import Header from "../../../components/header/Header";

const ErrorPage = () => {
  return (
    <div className="page-404">
      <Header setUnreadNotifications={() => {}} />
      <div className="error_page">
        <div className="error_page__contain">
          <h1 className="h1-color-gradient-berry">¡Ya volvemos!</h1>
          <p>En este momento estamos realizando una actualización en el sitio</p>
          <span className="text-span">
            Si requieres ayuda inmediata por favor escríbenos a <br />
            <a href="mailto:info@berrysafe.com">info@berrysafe.com</a>
          </span>
        </div>
        <div className="coetent-img">
          <img src={backBerry} alt="backBerry" className="logo-pia" />
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
