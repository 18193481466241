/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Button, Dropdown } from "antd";
import UserSvg from "assets/icon/profile-inverse.svg";
import starSvg from "assets/images/star-white.svg";
import equipajeSvg from "assets/images/equipaje-white.svg";
import graphSvg from "assets/images/grafica-white.svg";
import Drawer from "react-bottom-drawer";
import { func, instanceOf } from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import piaSvg from "assets/images/Pia-svg.svg";
import { useSelector } from "react-redux";
import piaMenu from "components/header/components/PiaMenuConection";
import PoliciesActions from "services/policies/actions";
import MenuResponsive from "./MenuResponsive";

const FooterNavBar = ({ history, onboarding, dispatch, t, logout }) => {
  const [isVisible, setVisible] = useState(false);
  const styleDark = useSelector((state) => state.policies.styleDark);

  const handleCloseModal = () => {
    document.body.style.overflowY = "auto";
    setVisible(false);
  };

  const onClose = () => {
    setVisible(false);
  };
  const policiesStore = useSelector((state) => state.policies);
  useEffect(() => {
    if (
      !policiesStore.loading.insuranceAccountStatus &&
      !policiesStore.success.insuranceAccountStatus &&
      !policiesStore.error.insuranceAccountStatus
    )
      dispatch(PoliciesActions.getInsuranceAccountStatus());
  }, [policiesStore, dispatch]);

  return (
    <div className="nav-bar-footer-res">
      <Link to="/pia/commissions">
        <img src={graphSvg} alt="graph" />
      </Link>
      <Link to="/pia/policies">
        <img src={equipajeSvg} alt="graph" />
      </Link>
      <Link to="">
        <img src={starSvg} alt="graph" />
      </Link>
      <Dropdown
        overlay={piaMenu(policiesStore?.success?.insuranceAccountStatus)}
        trigger={["click"]}
        overlayClassName="header__dropdown-pia-menu"
      >
        <a
          href="./"
          id="menu-button-footer"
          className="custom-trigger-btn"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <div className="alert" />
          <img src={piaSvg} className="piaSvg" alt="pia" />
        </a>
      </Dropdown>

      <Drawer isVisible={isVisible} onClose={onClose}>
        <MenuResponsive
          dispatch={dispatch}
          history={history}
          profile={onboarding.success.profile}
          t={t}
          handleCloseModal={handleCloseModal}
          logout={logout}
          styleDark={styleDark}
        />
      </Drawer>
      <Button
        id="editPage-button-slider"
        className="user"
        onClick={() => {
          setVisible(true);
        }}
      >
        <div className="border-status">
          <img
            className="user-img"
            alt="settings"
            src={
              onboarding.success.profile
                ? onboarding.success.profile.profilePhoto !== ""
                  ? onboarding.success.profile.profilePhoto
                  : UserSvg
                : UserSvg
            }
          />
        </div>
      </Button>
    </div>
  );
};

FooterNavBar.propTypes = {
  history: instanceOf(Object).isRequired,
  onboarding: instanceOf(Object).isRequired,
  dispatch: func.isRequired,
  logout: func.isRequired,
  t: func.isRequired,
};

export default FooterNavBar;
