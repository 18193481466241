import React from "react";
import PropTypes, { func } from "prop-types";
import { Button } from "antd";

const HospitalGroup = ({ hospitalGroup, t }) => (
  <div className="HospitalGroup">
    <p className="title">{t("label.HospitalGroup")}</p>
    <img src={hospitalGroup.icon} alt="Grupo hospitalario" />
    <div>
      <h3>
        {t("label.Hospital")} {hospitalGroup.name}
      </h3>
      <p className="text">{hospitalGroup.description}</p>
    </div>
    <Button
      className="button button--primary"
      onClick={() => {
        window.open(hospitalGroup.link);
      }}
    >
      {t("label.LearnMore")}
    </Button>
  </div>
);

HospitalGroup.propTypes = {
  hospitalGroup: PropTypes.instanceOf(Object).isRequired,
  t: func.isRequired,
};

export default HospitalGroup;
