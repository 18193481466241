import { RightOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Input, Menu, Modal, Row, Table } from "antd";
import Loader from "components/loader";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ReactComponent as IconMail } from "../../../../../assets/icon/icon-mail.svg";
import { ReactComponent as IconPhone } from "../../../../../assets/icon/icon-phone.svg";
import Pencil from "../../../../../assets/icon/pencil-create.svg";
import { ReactComponent as IconWs } from "../../../../../assets/icon/ws-berry.svg";
import MailPurpleSvg from "../../../../../assets/images/email-purple.svg";
import CarPurpleSvg from "../../../../../assets/images/insurance/car-purple.png";
import phonePurple from "../../../../../assets/images/phone-purple.svg";
import points from "../../../../../assets/images/points.png";
import WhatsappPurple from "../../../../../assets/images/Whatsapp-purple.svg";
import LeadsActions from "../../../../../services/leads/actions";
import Activity from "./activity";
import EditActivityPage from "./editActivity/editActivity";

const { Search } = Input;
const mediums = {
  email: <IconMail fill="red" stroke="green" />,
  whatsapp: <IconWs />,
  phone: <IconPhone fill="red" stroke="green" />,
};

const columns = (
  lead,
  setActivity,
  setActivityOpen,
  showModal,
  handleOk,
  handleCancel,
  visible
) => [
  {
    title: "Actividad",
    dataIndex: "activity",
    key: "activity",
    className: "activity-colum",
    render: (text, record) => (
      <div>
        {mediums[record.medium.icon]}
        <span>{text}</span>
      </div>
    ),
  },
  {
    title: "Fecha",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text, record) => (
      <Row>
        <span>{moment(text).format("MMM DD, YYYY - hh:mm a", "es")}</span>
        <Dropdown
          overlay={
            <MenuHistory
              activity={record}
              showModal={showModal}
              handleOk={handleOk}
              handleCancel={handleCancel}
              visible={visible}
              lead={lead}
              setActivityOpen={setActivityOpen}
              setActivity={setActivity}
            />
          }
          placement="bottomRight"
        >
          <Link to="./" className="container-modal__historyTableAction">
            <i /> <i /> <i className="last" />
          </Link>
        </Dropdown>
      </Row>
    ),
  },
];

const MenuHistory = ({
  activity,
  lead,
  setActivity,
  setActivityOpen,
  showModal,
  handleOk,
  handleCancel,
  visible,
  editHistory,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const deleteActivity = (selectedActivity) => {
    dispatch(LeadsActions.deleteActivity(+selectedActivity.id, +lead.id));
  };

  const addHistory = (selectedActivity) => {
    setActivity(selectedActivity);
    setActivityOpen(true);
  };

  return (
    <Menu className="container-modal__historyTableDropdown">
      <Menu.Item key="add" className="item">
        <Button onClick={() => addHistory(activity)}>{t("button.AddNote")}</Button>
      </Menu.Item>
      <Menu.Item key="add" className="item">
        <Button onClick={() => editHistory(activity)}>Editar</Button>
      </Menu.Item>
      <Menu.Item key="delete" className="item">
        <Button type="primary" onClick={showModal}>
          {t("button.delete")}
        </Button>
        <Modal
          title="Basic Modal"
          className="modal-warnning-delete"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>{t("label.DeleteHistory")}</p>
          <div className="buttons-modal">
            <Button className="no" onClick={handleCancel}>
              {t("label.not")}
            </Button>
            <Button
              className="yes"
              onClick={() => {
                deleteActivity(activity);
                handleCancel();
              }}
            >
              {t("label.yes")}
            </Button>
          </div>
        </Modal>
      </Menu.Item>
    </Menu>
  );
};

const ContainerLead = ({ lead }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { success, loading } = useSelector((state) => state.leads);
  const [activityOpen, setActivityOpen] = useState(false);
  const [editNoteOpen, setEditNoteOpen] = useState(false);
  const [activity, setActivity] = useState(null);
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const editHistory = (selectedActivity) => {
    setActivity(selectedActivity);
    setEditNoteOpen(true);
  };

  useEffect(() => {
    dispatch(LeadsActions.getActivities(lead.id));
  }, [dispatch, lead]);

  useEffect(() => {
    if (success.createActivity) setActivityOpen(false);
  }, [success.createActivity]);

  return (
    <div className="container-modal">
      <div className="container-modal__history">
        <Row align="middle" justify="space-between" className="container-modal__historyTopBar">
          <Col className="col-padding">
            <h3 className="container-modal__historyTitle">{t("label.historyContact")}</h3>
          </Col>
          <Col>
            <div className="icon-edit">
              <span
                className="container-modal__historyPencil"
                onClick={() => {
                  setActivity(null);
                  setActivityOpen(true);
                }}
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
              >
                <img src={Pencil} alt="pencil" />
              </span>
            </div>
          </Col>
        </Row>
        <div className="container-modal__historyContent">
          {loading.activities && <Loader />}
          <div className="card-item">
            <div>
              <Search placeholder="Buscar" enterButton />
            </div>
            {success.activities?.map((item) => (
              <div key={item.id} className="item">
                <Button onClick={() => editHistory(activity)} className="card-item-history">
                  <div className="icon">
                    <img src={lead.productLine.icon || CarPurpleSvg} alt="car_purple" />
                  </div>

                  {item.medium.icon === "whatsapp" && (
                    <img src={WhatsappPurple} className="icon-purple" alt="icon-whatsaap" />
                  )}
                  {item.medium.icon === "email" && (
                    <img src={MailPurpleSvg} alt="icon-email" className="icon-purple" />
                  )}
                  {item.medium.icon === "phone" && (
                    <img src={phonePurple} alt="icon-phone" className="icon-purple" />
                  )}

                  <div className="card-text">
                    <h4>{item.activity}</h4>
                    <p>{item.date}</p>
                  </div>
                  <RightOutlined />
                </Button>
                <div className="select">
                  <Dropdown
                    overlay={
                      <MenuHistory
                        showModal={showModal}
                        handleOk={handleOk}
                        handleCancel={handleCancel}
                        visible={visible}
                        lead={lead}
                        setActivityOpen={setActivityOpen}
                        setEditNoteOpen={setEditNoteOpen}
                        editHistory={editHistory}
                        editNoteOpen={editNoteOpen}
                        setActivity={setActivity}
                        activity={activity}
                      />
                    }
                    trigger={["click", "contextMenu"]}
                    onVisibleChange={(state) => {
                      if (state) setActivity(item);
                    }}
                  >
                    <Button className="ant-dropdown-link button-points">
                      <img src={points} alt="points" className="points" />
                    </Button>
                  </Dropdown>
                </div>
              </div>
            ))}
          </div>
          <Table
            className="container-modal__historyTable"
            pagination={false}
            dataSource={success.activities}
            columns={columns(
              lead,
              setActivity,
              setActivityOpen,
              showModal,
              handleOk,
              handleCancel,
              visible
            )}
            rowKey="id"
          />
          {success.activities && success.activities.length <= 0 && (
            <div className="message--empty">{t("label.textHistory")}</div>
          )}
          {activityOpen && (
            <Activity lead={lead} activity={activity} setActivityOpen={setActivityOpen} />
          )}
          {editNoteOpen && (
            <EditActivityPage lead={lead} activity={activity} setEditNoteOpen={setEditNoteOpen} />
          )}
        </div>
      </div>
    </div>
  );
};

MenuHistory.propTypes = {
  activity: PropTypes.instanceOf(Object),
  lead: PropTypes.instanceOf(Object).isRequired,
  setActivity: PropTypes.func.isRequired,
  setActivityOpen: PropTypes.func.isRequired,
  editHistory: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

MenuHistory.defaultProps = {
  activity: null,
};

ContainerLead.propTypes = {
  lead: PropTypes.instanceOf(Object).isRequired,
};

export default ContainerLead;
