/* eslint-disable camelcase */
import { Button, Modal } from "antd";
import { element, instanceOf, string } from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import IconEyeClose from "../../../assets/icon/eye-outline.svg";
import IconEye from "../../../assets/icon/icon-eye.svg";
import { changeRoute } from "../../../common/utils/changeRoute";
import MarketplaceActions from "../../../services/marketplace/actions";
import ConfirmAlert from "../ConfirmAlert/ConfirmAlert";

const ModalGenericEditable = ({ button_succes, title, image, product }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [insuranceCarrier, setInsuranceCarrier] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [selectProduct, setSelectProduct] = useState();

  const {
    success: { profile },
  } = useSelector((state) => state.onboarding);

  const handleSetAgentProduct = (productProp) => {
    setModalConfirm(true);
    setSelectProduct(productProp);
  };

  const confirmInsurance = () => {
    if (product.mine) return handleSetAgentProduct(product);
    const exist = profile?.insuranceCarrierAgents.find((insuranceCarrierAgent) =>
      product.insuranceCarrierProducts.find(
        (pInsurance) =>
          +pInsurance.insuranceCarrier.id === +insuranceCarrierAgent.insuranceCarrier.id
      )
    );
    if (exist || product.insuranceCarrierProducts.length === 0)
      return handleSetAgentProduct(product);

    setVisible(true);
    return setInsuranceCarrier(product.insuranceCarrierProducts[0].insuranceCarrier.name);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <div className="modal-meneric-editable">
      <ConfirmAlert
        onOk={() => dispatch(MarketplaceActions.setAgentProduct(selectProduct.id))}
        onCancel={() => {
          setModalConfirm(false);
          setSelectProduct(undefined);
        }}
        message={`¿Seguro que quieres ${
          selectProduct?.mine ? "eliminar " : "agregar "
        } este producto?`}
        okClassName={selectProduct?.mine ? "yes" : "no"}
        cancelClassName={!selectProduct?.mine ? "yes" : "no"}
        onAcceptClick={() => {
          dispatch(MarketplaceActions.setAgentProduct(selectProduct.id));
          setModalConfirm(false);
        }}
        onDeniedClick={() => {
          setModalConfirm(false);
          setSelectProduct(undefined);
        }}
        isVisible={modalConfirm}
        showAutoPublish
      />
      <Button id="open-modal-button-card" className="open_modal" onClick={confirmInsurance}>
        <img src={product.mine ? IconEye : IconEyeClose} className="eyes" alt="eyes" />
      </Button>
      <Modal
        className="modal-meneric-editable__modal"
        title="Basic Modal"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="box-left">
          <h1 className="title">{title}</h1>
          <div>
            <p className="text">
              Todavía esta aseguradora no está en tu perfíl, por eso no puedes cotizarla todavía. Si
              quieres ofrecer productos de {insuranceCarrier}, podemos ayudarte a obtener permisos y
              registrarte más rápido haciendo clíc en ”Agregar a mi perfíl”
            </p>
            <p className="text">
              *Si ya estás registrado a {insuranceCarrier}, porfavor actualiza tu{" "}
              <a
                href="./"
                className="link"
                onClick={(e) => {
                  e.preventDefault();
                  changeRoute("editPage", history, dispatch);
                }}
              >
                {t("button.insuranceSection")}
              </a>
            </p>
          </div>
          <div className="cont-btn">
            {button_succes}
            <Button onClick={handleCancel} className="btn btn--cancel">
              {t("button.cancel")}
            </Button>
          </div>
        </div>
        <div className="box-right">
          <img alt={image} src={image} />
        </div>
      </Modal>
    </div>
  );
};

ModalGenericEditable.propTypes = {
  button_succes: element.isRequired,
  title: string.isRequired,
  image: string.isRequired,
  product: instanceOf(Object).isRequired,
};

export default ModalGenericEditable;
