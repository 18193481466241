const allSections = [
  { id: 1, title: "Resumen", key: "Resumen", hasData: true },
  {
    id: 4,
    title: "Endosos",
    key: "Endosos",
    hasData: true,
  },
  {
    id: 5,
    title: "Cobranza",
    key: "Cobranza",
    hasData: true,
  },
];

export default allSections;
