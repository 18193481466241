import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loader";
import OnboardingActions from "../../../services/onboarding/actions";
import MainAgentInfo from "./components/mainAgentInfo/MainAgentInfo";
import OnBoarding from "./components/onboarding/Onboarding";
import OnBoardingResponsive from "./responsive/onboarding";

function EditAgentPage() {
  const dispatch = useDispatch();

  const [someEdited, setSomeEdited] = useState(false);
  const onboarding = useSelector((state) => state.onboarding);
  const router = useSelector((state) => state.router);

  const { omitForNow } = onboarding;

  useEffect(() => {
    if (router.action === "POP") dispatch(OnboardingActions.setOmitForNow(false));
  }, [router, dispatch]);

  useEffect(() => {
    if (!onboarding.loading.profile && !onboarding.success.profile && !onboarding.error.profile) {
      dispatch(OnboardingActions.getProfile());
    }
  }, [dispatch, onboarding]);

  if (onboarding.loading.profile)
    return (
      <Modal className="modal-loader" visible centered maskStyle={{ backgroundColor: "#ffffff66" }}>
        <Loader />
      </Modal>
    );

  if (!onboarding.success.profile?.onBoarding && !omitForNow)
    return (
      <div className="editAgentPage">
        <div className="container">
          <div className="editAgentPage__onboardingWrapper--desktop">
            {onboarding.success.profile && !onboarding.success.profile.onBoarding && !omitForNow && (
              <OnBoarding
                setOmit={() => {
                  dispatch(OnboardingActions.setOmitForNow(true));
                }}
              />
            )}
          </div>
        </div>
        <div className="editAgentPage__onboardingWrapper--mobile">
          {onboarding.success.profile && !onboarding.success.profile.onBoarding && !omitForNow && (
            <OnBoardingResponsive
              setOmit={() => {
                dispatch(OnboardingActions.setOmitForNow(true));
              }}
            />
          )}
        </div>
      </div>
    );

  return (
    <div className="editAgentPage">
      <div className="editAgentPage__mainAgentInfoWrapper">
        <MainAgentInfo someEdited={someEdited} setEdited={() => setSomeEdited(true)} />
      </div>
    </div>
  );
}

export default EditAgentPage;
