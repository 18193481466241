import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Menu, Tabs } from "antd";
import close from "assets/icon/close.svg";
import showTranslatedText from "common/utils/showTranslatedText";
import PropTypes, { func } from "prop-types";
import React from "react";
import { getI18n } from "react-i18next";
import SimpleReactCalendar from "simple-react-calendar";
import { v4 as uuidv4 } from "uuid";

const { TabPane } = Tabs;

const modifyFilters = (filter, setFilter, value, column, type) => {
  const isYetFiltered = filter.some(
    (filterIterable) => filterIterable.column === column && filterIterable.value === value.value
  );
  let newFilters = [];
  if (isYetFiltered) {
    newFilters = filter.filter((filterIterable) => filterIterable.column !== column);
  } else {
    newFilters = filter.filter(
      (filterIterable) => filterIterable.column !== column && filterIterable.value !== value
    );
    newFilters.push({
      id: uuidv4(),
      column,
      value: value.value,
      name: value.label,
      type,
    });
  }
  setFilter(newFilters);
};

const FilterModal = ({ productLines, setFilter, filter, closeModal, origins, tProp }) => (
  <div className="filter">
    <Menu>
      <div className="filter__title">
        <h4>{tProp("label.filter")}</h4>
        <Button onClick={closeModal} type="link">
          <img src={close} alt="close" />
        </Button>
      </div>
      <div>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Línea" key="1" className="filter__buttons">
            <div className="filter__buttonsWrapper">
              {productLines.map((productLine) => (
                <Button
                  key={productLine.id}
                  type="link"
                  onClick={() =>
                    modifyFilters(
                      filter,
                      setFilter,
                      {
                        value: productLine.id,
                        label: showTranslatedText(productLine.title, getI18n().language),
                      },
                      "productLine.id",
                      "number"
                    )
                  }
                >
                  {showTranslatedText(productLine.title, getI18n().language)}
                </Button>
              ))}
            </div>
          </TabPane>
          <TabPane tab="Origen" key="2" className="filter__buttons">
            <div className="filter__buttonsWrapper">
              {origins.map((origin) => (
                <Button
                  type="link"
                  key={origin.id}
                  onClick={() =>
                    modifyFilters(
                      filter,
                      setFilter,
                      { value: origin.key, label: origin.name },
                      "origin.key",
                      "varchar"
                    )
                  }
                >
                  {origin.name}
                </Button>
              ))}
            </div>
          </TabPane>
          <TabPane tab="Fecha" key="3" className="filter__day">
            <div className="filter__dayWrapper">
              <SimpleReactCalendar
                mode="range"
                headerNextArrow={<RightOutlined />}
                headerPrevArrow={<LeftOutlined />}
                onSelect={(data) => {
                  modifyFilters(
                    filter,
                    setFilter,
                    { value: data, label: "" },
                    "lead.requestDate",
                    "date"
                  );
                }}
                activeMonth={new Date()}
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </Menu>
  </div>
);

FilterModal.propTypes = {
  productLines: PropTypes.instanceOf(Array).isRequired,
  origins: PropTypes.instanceOf(Array).isRequired,
  setFilter: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(Array).isRequired,
  closeModal: PropTypes.func.isRequired,
  tProp: func.isRequired,
};

export default FilterModal;
