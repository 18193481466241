/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import showTranslatedText from "common/utils/showTranslatedText";
import { func, instanceOf } from "prop-types";
import ConfirmAlert from "components/Modals/ConfirmAlert/ConfirmAlert";
import { Button, Checkbox } from "antd";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getI18n, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import AgentActions from "services/agent/actions";
import eyeClose from "../../../../../../../../../assets/images/eye-close-purple.svg";
import eyeOpen from "../../../../../../../../../assets/images/eye-open-purple.svg";

const ProductLineSelection = ({ agent, setSomeChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showProductLineAlert, setShowProductLineAlert] = useState(false);
  const [selectedProductLine, setSelectedProductLine] = useState();

  const onDragEnd = (result) => {
    setSomeChange(true);
    dispatch(AgentActions.orderProductLines(result.source, result.destination));
  };

  const selectProductLine = (productLine) => {
    if (productLine.selected) {
      setSelectedProductLine(productLine);
      return setShowProductLineAlert(true);
    }
    setSomeChange(true);
    return dispatch(AgentActions.selectProductLine(productLine));
  };

  return (
    <div className="card-products__lines">
      <ConfirmAlert
        onOk={() => {
          setSomeChange(true);
          setSelectedProductLine(undefined);
          setShowProductLineAlert(false);
        }}
        onCancel={() => {
          setSomeChange(true);
          setSelectedProductLine(undefined);
          setShowProductLineAlert(false);
        }}
        message={`¿${t("alert.sure_to_delete")}?`}
        okClassName="yes"
        cancelClassName="no"
        onAcceptClick={() => {
          dispatch(AgentActions.selectProductLine(selectedProductLine));
          setSelectedProductLine(undefined);
          setShowProductLineAlert(false);
          setSomeChange(true);
        }}
        onDeniedClick={() => {
          setSelectedProductLine(undefined);
          setShowProductLineAlert(false);
        }}
        isVisible={showProductLineAlert}
      />

      <div className="product-lines__header">
        <h4 className="title-des">
          Seleccione una o múltiples y arrastre de acuerdo a la importancia
        </h4>
        <div className="text-responsive">
          <p className="small-detail">Hacer visible una o múltiples</p>
          <p className="important">Más importante</p>
        </div>
      </div>
      <div>
        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
          <Droppable key={0} droppableId="list0" direction="horizontal">
            {(provided) => (
              <div className="product-lines__product-line" ref={provided.innerRef}>
                {agent.success.productLines &&
                  agent.success.productLines.map((productLine, i) => (
                    <Draggable key={productLine.id} draggableId={`${productLine.id}`} index={i}>
                      {(providedDrag, snapshot) => (
                        <div
                          ref={providedDrag.innerRef}
                          {...providedDrag.draggableProps}
                          {...providedDrag.dragHandleProps}
                          className={`product-line product-line${
                            snapshot.isDragging || productLine.selected
                              ? "--selected"
                              : "--disabled"
                          }`}
                        >
                          <div
                            style={{
                              borderTopLeftRadius: i === 0 ? 5 : 0,
                            }}
                            className={`product-line${
                              snapshot.isDragging || productLine.selected
                                ? "--selected__view"
                                : "--disabled__view"
                            }`}
                          >
                            <Checkbox
                              checked={productLine.selected}
                              onChange={() => selectProductLine(productLine)}
                            />
                          </div>
                          <div
                            className={`product-line${
                              snapshot.isDragging || productLine.selected
                                ? "--selected__product"
                                : "--disabled__product"
                            }`}
                          >
                            <div
                              className={`product-line${
                                snapshot.isDragging || productLine.selected
                                  ? "--selected__productImage"
                                  : "--disabled__productImage"
                              }`}
                            >
                              <img
                                src={
                                  snapshot.isDragging || productLine.selected
                                    ? productLine.icon
                                    : productLine.icon
                                }
                                alt={showTranslatedText(productLine.title, getI18n().language)}
                              />
                            </div>
                            <div
                              className={`product-line${
                                snapshot.isDragging || productLine.selected
                                  ? "--selected__productText"
                                  : "--disabled__productText"
                              }`}
                            >
                              <p>{showTranslatedText(productLine.title, getI18n().language)}</p>
                            </div>
                          </div>
                          <div
                            style={{
                              borderTopRightRadius: i === 0 ? 5 : 0,
                            }}
                            className={`product-line${
                              snapshot.isDragging || productLine.selected
                                ? "--selected__lines"
                                : "--disabled__lines"
                            }`}
                          >
                            {/* <img src={linesSvg} alt="lines" /> */}
                            <i className="icon-menu" aria-hidden="true" />
                          </div>
                          <Button
                            onClick={() => selectProductLine(productLine)}
                            className={`button button-${
                              snapshot.isDragging || productLine.selected ? "purple" : "white"
                            }`}
                          >
                            <img
                              src={snapshot.isDragging || productLine.selected ? eyeOpen : eyeClose}
                              alt="eye"
                              className="eyeIcon"
                            />
                            {snapshot.isDragging || productLine.selected ? "Visible" : "Invisible"}
                          </Button>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

ProductLineSelection.propTypes = {
  agent: instanceOf(Object).isRequired,
  setSomeChange: func.isRequired,
};

export default ProductLineSelection;
