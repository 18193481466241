import { Select } from "antd";
import showTranslatedText from "common/utils/showTranslatedText";
import PropTypes, { func } from "prop-types";
import React from "react";
import { getI18n } from "react-i18next";
import { useDispatch } from "react-redux";
import LeadsActions from "services/leads/actions";
import DownSvg from "../../../../../../assets/images/down-purple-dropdown.svg";

const { Option } = Select;

const ProductLineDescription = ({ productLine, selectedStatus, status, leadId, t }) => {
  const dispatch = useDispatch();

  return (
    <div className="sidebar-modal__request">
      <div className="category">
        <img src={productLine?.iconWhite} alt="icon" className="icon" />
      </div>
      <div className="sidebar-modal__requestInfo">
        <h4>
          {t("label.SureOf")} {showTranslatedText(productLine.title, getI18n().language)}
        </h4>
        <div className="sidebar-modal__requestInfo__state">
          <span>{t("label.state")}</span>
          <Select
            suffixIcon={<img src={DownSvg} className="down" alt="down" />}
            defaultValue={selectedStatus.key}
            style={{ width: 120 }}
            onChange={(value) => dispatch(LeadsActions.changeStatus(+leadId, value))}
          >
            {status.map((statusItem) => (
              <Option key={statusItem.id} value={statusItem.key}>
                {statusItem.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

ProductLineDescription.propTypes = {
  productLine: PropTypes.instanceOf(Object).isRequired,
  leadId: PropTypes.number.isRequired,
  status: PropTypes.instanceOf(Array).isRequired,
  selectedStatus: PropTypes.instanceOf(Object).isRequired,
  t: func.isRequired,
};

export default ProductLineDescription;
