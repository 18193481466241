/* eslint-disable no-plusplus */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable-next-line */
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import DonutGraph from "components/Tablets/DonutGraph";
import { func, instanceOf, objectOf } from "prop-types";
import parseThousandNumber from "common/utils/parseThousandNumber";
import moment from "moment";

const CardBranch = ({
  branches,
  isBranchesQuantity,
  setBranchesQuantity,
  setBranchesPaid,
  isBranchesPaid,
  isDateBranches,
}) => {
  const [isTabActive, setTabActive] = useState(1);
  const [isSeries, setSeries] = useState([]);
  const [isoptions, setoptions] = useState({});
  const [isListItem, setListItem] = useState([]);
  // eslint-disable-next-line
  useEffect(() => {
    let arrayNames = [];
    let array1 = [];
    let array2 = [];
    if (branches?.length >= 1) {
      for (let index = 0; index < branches?.length; index++) {
        array1 = [...array1, Math.trunc(branches[index].paid)];
        array2 = [...array2, branches[index].quantity];
        arrayNames = [...arrayNames, branches[index].branch];
      }
    }
    setBranchesPaid(array1);
    setBranchesQuantity(array2);
    setoptions({
      chart: {
        width: 480,
        type: "donut",
      },
      colors: [
        "#5D30DD",
        "#35D8D0",
        "#FFBB0D",
        "#EB5757",
        "#7F14Fc",
        "#4b3a7c",
        "#16be40",
        "#25452d",
        "#794f1f",
      ],
      labels: arrayNames,
      dataLabels: {
        enabled: true,
        formatter(val, opts) {
          return `$${parseThousandNumber(opts.w.config.series[opts.seriesIndex])}`;
        },
      },
      tooltip: {
        y: {
          formatter(value) {
            return `$${parseThousandNumber(value)}`;
          },
        },
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
        },
      },
    });
    setListItem([]);
  }, [branches, setBranchesPaid, setBranchesQuantity, setListItem]);

  useEffect(() => {
    if (isTabActive === 1) {
      setSeries(isBranchesPaid);
    } else if (isTabActive === 2) {
      setSeries(isBranchesQuantity);
    }
  }, [isTabActive, isBranchesPaid, isBranchesQuantity]);
  return (
    <div className="CardBranch">
      <div className="CardBranch__head">
        <h2 className="h2">Ramos</h2>
        <p className="small-detail">
          {moment(isDateBranches?.FechaInicial[0]).format("MMM'YY")}-
          {moment(isDateBranches?.FechaFinal[0]).format("MMM'YY")}
        </p>
      </div>
      <div className="tabs">
        <Button
          className={`btn-tab ${isTabActive === 1 && "btn-tab--active"}`}
          onClick={() => setTabActive(1)}
        >
          Prima
        </Button>
        <Button
          className={`btn-tab ${isTabActive === 2 && "btn-tab--active"}`}
          onClick={() => setTabActive(2)}
        >
          Cantidad
        </Button>
      </div>
      <DonutGraph
        isoptions={isoptions}
        series={isSeries}
        isListItem={isListItem}
        setListItem={setListItem}
      />
    </div>
  );
};

CardBranch.propTypes = {
  branches: objectOf.isRequired,
  isDateBranches: objectOf.isRequired,
  isBranchesQuantity: instanceOf(Array).isRequired,
  isBranchesPaid: instanceOf(Array).isRequired,
  setBranchesPaid: func.isRequired,
  setBranchesQuantity: func.isRequired,
};

export default CardBranch;
