import { Button, Col, Row, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OnboardingActions from "services/onboarding/actions";
import leaf from "../../../../../assets/images/leaf-svg.svg";
import OnboardingSteps from "./components";
import "./onboarding.scss";

const { Text } = Typography;

const OnBoardingResponsive = () => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const [onboardingStarted, setOnboardingStarted] = useState(false);

  return (
    <div className="onBoarding-responsive">
      {onboardingStarted && <OnboardingSteps />}
      {!onboardingStarted && (
        <Row>
          <Col className="onBoarding-responsive__navigation" xs={24} lg={10} xl={10}>
            <div className="onBoarding-responsive__navigation__navigator">
              <h3>{t("label.welcome")},</h3>
              <h3 className="name">{profile.person.name}</h3>
              <p>{t("label.welcomeBody")}</p>
              <div className="step-items">
                <div className="step-items__item" />
                <img src={leaf} alt="leaf" className="leaf" />
                <Text>Sponsor</Text>
              </div>
              <div className="step-items">
                <div className="step-items__item" />
                <img src={leaf} alt="leaf" className="leaf" />
                <Text>{t("label.License")}</Text>
              </div>
              <div className="step-items">
                <div className="step-items__item" />
                <img src={leaf} alt="leaf" className="leaf" />
                <Text>{t("label.Insurers")}</Text>
              </div>
              <div className="step-items">
                <div className="step-items__item" />
                <img src={leaf} alt="leaf" className="leaf" />
                <Text>{t("label.productLines")}</Text>
              </div>
            </div>
            <div className="onBoarding-responsive__navigation__start">
              <h1>{t("label.StartHere")}</h1>
              <Button
                onClick={() => {
                  setOnboardingStarted(true);
                  dispatch(OnboardingActions.setStep(0));
                }}
              >
                {t("label.StartWizard")}
              </Button>
              <Link
                className="link"
                to="/"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(OnboardingActions.setOmitForNow(true));
                }}
              >
                {t("label.OmitirWizard")}
              </Link>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default OnBoardingResponsive;
