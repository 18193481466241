/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Divider, Slider } from "antd";
import { bool, func, instanceOf, number } from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

const Sidebar = ({
  view,
  dummyItemsTutorial,
  slider,
  indexCaroucel,
  handleChange,
  handleSlider,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`sidebar sidebar--tuto ${slider && "sidebar--open"}`}>
      <div className="sidebar__header">
        <h4>{t("label.Tutorial")}</h4>
        <Slider
          step={1}
          disabled
          onChange={handleSlider}
          tooltipVisible={false}
          value={indexCaroucel}
        />
        <p>
          {indexCaroucel}% <span>{t("label.completed")}</span>
        </p>
      </div>
      <Divider />
      <div className="sidebar__menu">
        <h6>Crea tu página</h6>
        <div className="item">
          {dummyItemsTutorial?.map((item) => (
            <div
              key={item.id}
              onClick={() => handleChange(item)}
              className={`item--${view.key === item.key ? "active" : "disabled"}`}
            >
              <div />
              <p>{item.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  view: instanceOf(Object),
  dummyItemsTutorial: instanceOf(Array).isRequired,
  slider: bool.isRequired,
  indexCaroucel: number.isRequired,
  handleChange: func.isRequired,
  handleSlider: func.isRequired,
};

Sidebar.defaultProps = {
  view: null,
};

export default Sidebar;
