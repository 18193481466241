import { handleActions } from "redux-actions";

export const INITIAL_STATE = {
  loading: {
    updateEmail: false,
    schedule: false,
    schedules: false,
    updatePhone: false,
    updateEndPoint: false,
  },
  error: {
    updateEmail: undefined,
    schedule: undefined,
    schedules: undefined,
    updatePhone: undefined,
    updateEndPoint: undefined,
  },
  success: {
    updateEmail: undefined,
    schedule: undefined,
    schedules: undefined,
    updatePhone: undefined,
    updateEndPoint: undefined,
  },
};

const reducer = handleActions(
  {
    ACCOUNT_ACTIONS: {
      UPDATE_EMAIL: (state) => ({
        ...state,
        loading: { ...state.loading, updateEmail: true },
        error: { ...state.error, updateEmail: undefined },
      }),
      UPDATE_EMAIL_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, updateEmail: false },
            success: {
              ...state.success,
              updateEmail: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, updateEmail: message },
            success: { ...state.success, updateEmail: undefined },
            loading: { ...state.loading, updateEmail: false },
          };
        },
      },
      CLEAR_UPDATE_EMAIL: (state) => ({
        ...state,
        success: {
          ...state.success,
          updateEmail: undefined,
        },
        error: {
          ...state.error,
          updateEmail: undefined,
        },
      }),

      UPDATE_PHONE: (state) => ({
        ...state,
        loading: { ...state.loading, updatePhone: true },
        error: { ...state.error, updatePhone: undefined },
      }),
      UPDATE_PHONE_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, updatePhone: false },
            success: {
              ...state.success,
              updatePhone: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, updatePhone: message },
            success: { ...state.success, updatePhone: undefined },
            loading: { ...state.loading, updatePhone: false },
          };
        },
      },
      SET_SCHEDULE: (state, { payload }) => ({
        ...state,
        success: {
          ...state.success,
          schedule: { ...state.success.schedule, schedule: payload },
        },
      }),
      SET_SCHEDULE_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, schedule: false },
            success: {
              ...state.success,
              schedule: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, schedule: message },
            success: { ...state.success, schedule: undefined },
            loading: { ...state.loading, schedule: false },
          };
        },
      },
      GET_SCHEDULE: (state) => ({
        ...state,
        loading: { ...state.loading, schedules: true },
        error: { ...state.error, schedules: undefined },
      }),
      GET_SCHEDULE_RESPONSE: {
        next(state, { payload: { schedules } }) {
          return {
            ...state,
            loading: {
              ...state.loading,
              schedules: false,
            },
            success: {
              ...state.success,
              schedules: schedules.schedules,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, schedules: message },
            success: { ...state.success, schedules: undefined },
            loading: { ...state.loading, schedules: false },
          };
        },
      },
      CLEAR_UPDATE_PHONE: (state) => ({
        ...state,
        success: {
          ...state.success,
          updatePhone: undefined,
        },
        error: {
          ...state.error,
          updatePhone: undefined,
        },
      }),

      UPDATE_END_POINT: (state) => ({
        ...state,
        loading: { ...state.loading, updateEndPoint: true },
        error: { ...state.error, updateEndPoint: undefined },
      }),
      UPDATE_END_POINT_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, updateEndPoint: false },
            success: {
              ...state.success,
              updateEndPoint: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, updateEndPoint: message },
            success: { ...state.success, updateEndPoint: undefined },
            loading: { ...state.loading, updateEndPoint: false },
          };
        },
      },
      CLEAR_UPDATE_END_POINT: (state) => ({
        ...state,
        success: {
          ...state.success,
          updateEndPoint: undefined,
        },
        error: {
          ...state.error,
          updateEndPoint: undefined,
        },
      }),
    },
  },
  INITIAL_STATE
);

export default reducer;
