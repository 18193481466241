/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
import { CLIENT_HOST } from "common/config/config";
import { all, put, select, takeLatest } from "redux-saga/effects";
import ApiConnection from "../../common/api/Api";
import parseError from "../../common/api/ErrorParser";
import * as Token from "../../common/storage/Token";
import toBase64 from "../../common/utils/toBase64";
import AuthActions from "../auth/actions";
import OnboardingActions from "../onboarding/actions";
import AgentActions from "./actions";

function* saveMainInfo({ payload }) {
  const url = "/agent/modify-main-info";
  const data = {
    ...payload,
    socialNetworks: [],
  };

  delete data.image;

  if (payload.instagram) data.socialNetworks.push({ key: "instagram", value: payload.instagram });

  if (payload.twitter) data.socialNetworks.push({ key: "twitter", value: payload.twitter });

  if (payload.facebook) data.socialNetworks.push({ key: "facebook", value: payload.facebook });

  if (payload.linkedin) data.socialNetworks.push({ key: "linkedin", value: payload.linkedin });

  const response = yield ApiConnection.post(url, data);
  if (response.success) return yield put(AgentActions.saveMainInfoResponse());

  let err;
  if (response.message) err = new TypeError(response.status);
  else if (response.message) err = new TypeError(response.message[0]);
  else err = new TypeError("ERROR_EDIT_AGENT_INFO");

  return yield put(AgentActions.saveMainInfoResponse(err, response));
}

function* sendGoogleLocation({ payload }) {
  const { profile } = yield select((state) => state.onboarding.success);

  const locationFormatted = {};
  locationFormatted.storeCode = "";
  locationFormatted.languageCode = "en-mx";
  locationFormatted.locationName = payload.name;
  locationFormatted.primaryPhone = payload.contact;
  locationFormatted.address = {};
  locationFormatted.address.addressLines = [payload.address];
  locationFormatted.address.locality = payload.city;
  locationFormatted.address.postalCode = payload.zipcode;
  locationFormatted.address.administrativeArea = payload.areaLevel;
  locationFormatted.address.regionCode = "MX";
  locationFormatted.websiteUrl = `berrysafe.com/${profile.endPoint}`;
  locationFormatted.primaryCategory = {};
  locationFormatted.primaryCategory.categoryId = "gcid:insurance_company";
  locationFormatted.id = payload.id;

  const url = "/office/validate-google";

  const response = yield ApiConnection.post(url, {
    code: payload.accesToken,
    office: locationFormatted,
  });

  if (response.success) {
    yield put(OnboardingActions.setVerifiedOfficeStatus(payload.id, "Processing"));
    return yield put(AgentActions.sendGoogleLocationResponse(payload.id));
  }

  return yield put(AgentActions.sendGoogleLocationResponse(parseError(response), response));
}

function* getTips() {
  const url = "/tips";

  const response = yield ApiConnection.get(url);

  if (response.success) {
    return yield put(
      AgentActions.getTipsResponse({
        tips: response.payload,
      })
    );
  }
  return yield put(AgentActions.getTipsResponse(parseError(response), response));
}

function* getMenu() {
  const url = "/user/menus";

  const response = yield ApiConnection.get(url);

  if (response.success) return yield put(AgentActions.getMenuResponse(response.detail));

  return yield put(AgentActions.getMenuResponse(parseError(response), response));
}

function* getLayoutProducts() {
  const url = "/layout-products";

  const response = yield ApiConnection.get(url);

  if (response.success)
    return yield put(
      AgentActions.getLayoutProductsResponse({
        layoutProducts: response.detail,
      })
    );

  return yield put(AgentActions.getLayoutProductsResponse(parseError(response), response));
}

function* setSelectedLayout({ payload }) {
  const url = "/agent/set-layout";

  const response = yield ApiConnection.post(url, { layout: payload });

  if (response.success)
    return yield put(
      AgentActions.setSelectedLayoutResponse({
        layoutProducts: response.detail,
      })
    );

  return yield put(AgentActions.setSelectedLayoutResponse(parseError(response), response));
}

function* changeShow({ payload }) {
  const url = "/office/change-show";

  const response = yield ApiConnection.post(url, { id: `${payload}` });

  if (response.success)
    return yield put(
      AgentActions.changeShowResponse({
        layoutProducts: response.detail,
      })
    );

  return yield put(AgentActions.changeShowResponse(parseError(response), response));
}

function* getInsuranceCarriers() {
  const url = "/insurance-carrier";

  const response = yield ApiConnection.get(url);

  if (response.status === "SUCCESS")
    return yield put(
      AgentActions.getInsuranceCarriersResponse({
        insuranceCarriers: response.payload,
      })
    );

  const err = new TypeError(response?.status ? response.message : "UNDEFINED_ERROR");
  return yield put(AgentActions.getInsuranceCarriersResponse(err, response));
}

function* updateInsuranceCarriers() {
  const url = "/agent/update-insurance-carriers";

  const {
    success: { insuranceCarriersOrdered },
  } = yield select((state) => state.agent);

  const requestData = {
    insuranceCarriers: insuranceCarriersOrdered.map((insuranceCarrier) => insuranceCarrier.id),
  };
  if (!insuranceCarriersOrdered) return "error";

  const response = yield ApiConnection.post(url, requestData);
  if (response.success) {
    yield put(AgentActions.setActualInsuranceCarriersOrdered(undefined));
    return yield put(AgentActions.updateInsuranceCarriersResponse());
  }

  const err = parseError(response);
  return yield put(AgentActions.updateInsuranceCarriersResponse(err, response));
}

function* setProfilePhoto({ payload }) {
  const url = "/agent/set-profile-photo";
  const response = yield ApiConnection.post(url, payload, true);
  if (!response.success) yield put(AgentActions.setProfilePhotoResponse(parseError(response)));

  yield put(AgentActions.setProfilePhotoResponse());
  yield put(OnboardingActions.setProfilePhoto(yield toBase64(payload.profile)));
}

function* createOffice({ payload }) {
  const url = "/office";
  let { contact } = payload;
  if (payload.contact) contact = `${payload.contact}`;
  const response = yield ApiConnection.post(url, { ...payload, contact });
  if (!response.success)
    return yield put(AgentActions.createOfficeResponse(parseError(response), response));

  const { profile } = yield select((state) => state.onboarding.success);

  if (profile) {
    const { offices } = profile;
    offices.unshift(response.detail);
    yield put(OnboardingActions.setAllProfileOffices(offices));
  }

  return yield put(AgentActions.createOfficeResponse());
}

function* updateOffice({ payload }) {
  const url = "/office/update";

  const response = yield ApiConnection.post(url, { ...payload, id: +payload.id });
  if (response.success) return yield put(AgentActions.updateOfficeResponse());

  const err = parseError(response);
  return yield put(AgentActions.updateOfficeResponse(err, response));
}

function* sendGmbVerificationCode({ payload }) {
  const url = "/office/send-verification";
  const response = yield ApiConnection.post(url, payload);
  if (response.success) return yield put(AgentActions.sendGmbVerificationCodeResponse());

  const err = parseError(response);
  return yield put(AgentActions.sendGmbVerificationCodeResponse(err, response));
}

function* getProductLines() {
  const url = "/product-line";

  const response = yield ApiConnection.get(url);

  if (response.status !== "SUCCESS") {
    const err = new TypeError(response?.status ? response.message : "UNDEFINED_ERROR");
    return yield put(AgentActions.getProductLinesResponse(err, response));
  }

  const {
    success: { profile },
  } = yield select((state) => state.onboarding);

  let productLines = [];
  if (profile?.agentProductLines) {
    productLines = profile.agentProductLines.map((agentProductline) => ({
      ...agentProductline.productLine,
      selected: true,
    }));
  }

  const additionalProducts = response.payload.filter(
    (productLine) =>
      !productLines.find((existingProductLine) => existingProductLine.id === productLine.id)
  );

  productLines.push(...additionalProducts);

  return yield put(
    AgentActions.getProductLinesResponse({
      productLinesLists: productLines,
    })
  );
}

function* saveProductLines() {
  const {
    success: { productLines },
  } = yield select((state) => state.agent);
  const selectedProductLines = productLines
    .filter((productLine) => productLine.selected)
    .map((productLine, i) => ({ id: productLine.id, level: i + 1 }));

  const url = "/agent/save-product-lines";
  const response = yield ApiConnection.post(url, { productLines: selectedProductLines });
  if (response.status === "SUCCESS") {
    yield put(OnboardingActions.getProfile(false));
    return yield put(AgentActions.saveProductLinesResponse());
  }

  return yield put(AgentActions.saveProductLinesResponse(parseError(response), response));
}

function* updateEmail({ payload }) {
  const url = "/agent/update-email";
  const response = yield ApiConnection.post(url, { email: payload });
  if (!response.success)
    return yield put(AgentActions.updateEmailResponse(parseError(response), response));

  yield Token.save(response.detail, "@tokenAGENT");
  return yield put(AgentActions.updateEmailResponse());
}

function* updatePhone({ payload }) {
  const url = "/agent/update-contact";
  const response = yield ApiConnection.post(url, { phone: payload });
  if (!response.success)
    return yield put(AgentActions.updatePhoneResponse(parseError(response), response));

  return yield put(AgentActions.updatePhoneResponse());
}

function* publish() {
  const url = "/agent/publish";
  const response = yield ApiConnection.get(url);
  if (!response.success)
    return yield put(AgentActions.publishResponse(parseError(response), response));

  const onboarding = yield select((state) => state.onboarding);

  try {
    yield fetch(`${CLIENT_HOST}agente/${onboarding.success.profile?.endPoint}`);
  } catch (error) {
    console.log(error);
  }

  yield Token.save(response.detail, `@tokenAGENT`);

  yield put(AgentActions.publishResponse());
  return yield put(
    AuthActions.loginResponse({
      token: response.detail,
      profile: yield Token.decodeToken(),
    })
  );
}

function* deleteOffice({ payload }) {
  const url = "/office/delete";
  const response = yield ApiConnection.post(url, {
    id: +payload,
  });
  if (!response.success)
    return yield put(AgentActions.deleteOfficeResponse(parseError(response), response));

  yield put(OnboardingActions.removeOffice(payload));
  return yield put(AgentActions.deleteOfficeResponse());
}

function* updateTutorialShown() {
  const url = "/agent/tutorial";
  const response = yield ApiConnection.put(url);
  if (response.success) {
    yield Token.save(response.detail, `@tokenAGENT`);
    return yield put(
      AuthActions.loginResponse({
        token: response.detail,
        profile: yield Token.decodeToken(),
      })
    );
  }
  return {};
}

function* updateFirebaseToken({ payload }) {
  const url = "/agent/firebase-token";
  yield ApiConnection.put(url, { token: payload });
}

function* selectProductLine() {
  yield select((state) => state.agent.success.productLines);
  yield put(AgentActions.saveProductLines());
}

function* ActionWatcher() {
  yield takeLatest(AgentActions.saveMainInfo, saveMainInfo);
  yield takeLatest(AgentActions.updateInsuranceCarriers, updateInsuranceCarriers);
  yield takeLatest(AgentActions.getInsuranceCarriers, getInsuranceCarriers);
  yield takeLatest(AgentActions.getTips, getTips);
  yield takeLatest(AgentActions.getLayoutProducts, getLayoutProducts);
  yield takeLatest(AgentActions.setSelectedLayout, setSelectedLayout);
  yield takeLatest(AgentActions.setProfilePhoto, setProfilePhoto);
  yield takeLatest(AgentActions.createOffice, createOffice);
  yield takeLatest(AgentActions.saveProductLines, saveProductLines);
  yield takeLatest(AgentActions.updateOffice, updateOffice);
  yield takeLatest(AgentActions.getMenu, getMenu);
  yield takeLatest(AgentActions.sendGoogleLocation, sendGoogleLocation);
  yield takeLatest(AgentActions.getProductLines, getProductLines);
  yield takeLatest(AgentActions.changeShow, changeShow);
  yield takeLatest(AgentActions.updateEmail, updateEmail);
  yield takeLatest(AgentActions.updatePhone, updatePhone);
  yield takeLatest(AgentActions.sendGmbVerificationCode, sendGmbVerificationCode);
  yield takeLatest(AgentActions.publish, publish);
  yield takeLatest(AgentActions.deleteOffice, deleteOffice);
  yield takeLatest(AgentActions.updateTutorialShown, updateTutorialShown);
  yield takeLatest(AgentActions.updateFirebaseToken, updateFirebaseToken);
  yield takeLatest(AgentActions.selectProductLine, selectProductLine);
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
