import { createActions } from "redux-actions";

const { accountActions } = createActions({
  ACCOUNT_ACTIONS: {
    UPDATE_EMAIL: (email) => email,
    UPDATE_EMAIL_RESPONSE: () => ({}),
    CLEAR_UPDATE_EMAIL: () => ({}),

    UPDATE_PHONE: (phone) => phone,
    UPDATE_PHONE_RESPONSE: () => ({}),
    CLEAR_UPDATE_PHONE: () => ({}),

    UPDATE_END_POINT: (endPoint) => endPoint,
    UPDATE_END_POINT_RESPONSE: () => ({}),
    CLEAR_UPDATE_END_POINT: () => ({}),

    CLEAR_CHANGE_SHOW: () => {},

    SET_SCHEDULE: (schedule) => ({ schedule }),
    SET_SCHEDULE_RESPONSE: () => ({}),

    GET_SCHEDULE: () => ({}),
    GET_SCHEDULE_RESPONSE: (schedules) => ({ schedules }),
  },
});

export default accountActions;
