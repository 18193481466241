/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
import { all, select, takeLatest } from "redux-saga/effects";
import MixPanelActions from "./actions";

function* track({ payload }) {
  const mixpanel = yield select((state) => state.mixpanel.instance);
  if (!mixpanel) return;

  mixpanel.track(payload.name, {
    ...payload.data,
  });
}

function* ActionWatcher() {
  yield takeLatest(MixPanelActions.track, track);
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
