import { string } from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import LoaderImg from "assets/images/loader.gif";

const Loader = ({ width, className }) => {
  const { t } = useTranslation();
  return (
    <div className={`loader ${className}`}>
      <img style={{ width }} src={LoaderImg} className="loader__img" alt="" />
      <p>{t("label.textLoader")}</p>
    </div>
  );
};

Loader.propTypes = {
  width: string,
};

Loader.propTypes = {
  className: string,
};

Loader.defaultProps = {
  width: "0",
  className: "0",
};

export default Loader;
