/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/no-array-index-key */
import Loader from "components/loader";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import AutoSvg from "assets/images/pia-brands/auto.svg";
import DamageSvg from "assets/images/pia-brands/damage.svg";
import GmmSvg from "assets/images/pia-brands/gmm-icon.svg";
import VidaSvg from "assets/images/vida.svg";
import PoliciesActions from "services/policies/actions";
import ButtonListFilters from "components/Atoms/ButtonListFilters/ButtonListFilters";
import DetailProduct from "../components/DetailProduct/DetailProduct";
import Policies from "../components/Policies/Policies";
import HeaderFeedBack from "../../piaDashboard/components/HeaderFeedBack/HeaderFeedBack";

const branchImages = {
  gmm: GmmSvg,
  autos: AutoSvg,
  daños: DamageSvg,
  vida: VidaSvg,
};

const Home = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [policiesCard, setPoliciesCard] = useState();
  const [insurances, setInsurances] = useState();
  const [selectedExpiration, setSelectedExpiration] = useState("");
  const [selectedProductLines, setSelectedProductLines] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const policiesStore = useSelector((state) => state.policies);
  const styleDark = useSelector((state) => state.policies.styleDark);

  useEffect(() => {
    history.listen((location, action) => {
      if (action === "POP")
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 25);
    });
  }, [history]);

  useEffect(() => {
    if (policiesStore.success.accounts)
      if (policiesStore.success.accounts.length === 0) history.replace("/account/pia");
  }, [policiesStore, history]);

  useEffect(() => {
    if (
      !policiesStore.loading.insurances &&
      !policiesStore.success.insurances &&
      !policiesStore.error.insurances
    )
      dispatch(PoliciesActions.getAllInsurances());
  }, [policiesStore, dispatch]);

  useEffect(() => {
    if (
      !policiesStore.loading.policies &&
      !policiesStore.success.policies &&
      !policiesStore.error.policies
    )
      dispatch(PoliciesActions.getLastPolicies());
  }, [policiesStore, dispatch]);

  useEffect(() => {
    if (
      !policiesStore.loading.comissionsTotal &&
      !policiesStore.success.comissionsTotal &&
      !policiesStore.error.comissionsTotal
    )
      dispatch(PoliciesActions.getAllComissions());
  }, [policiesStore, dispatch]);

  useEffect(() => {
    if (
      !policiesStore.loading.accounts &&
      !policiesStore.success.accounts &&
      !policiesStore.error.accounts &&
      policiesStore.success.insurances
    )
      dispatch(PoliciesActions.getAllAccounts());
    if (policiesStore.success.accounts && policiesStore.success.insurances && !insurances) {
      const filteredInsurances = policiesStore.success.insurances
        .filter(
          (insurance) =>
            policiesStore.success.accounts.filter(
              (account) => account.insurance.key === insurance.key
            ).length > 0
        )
        .map((insurance) => ({
          ...insurance,
          available:
            policiesStore.success.accounts.filter(
              (account) => account.insurance.key === insurance.key && account.status === "connected"
            ).length > 0,
        }));
      setInsurances(filteredInsurances);
    }

    if (policiesStore.success.policies && policiesStore.success.insurances) {
      const mappedPolicies = policiesStore.success.policies?.map((policy) => ({
        id: policy.IdentifierPolicy,
        title: policy?.BenefeciaryName || "",
        number: policy.NumberPolicy,
        icon: branchImages[policy.Branch?.toLowerCase()],
        image: policiesStore.success.insurances.find(
          (insurance) => insurance.key === policy.SlugNameInsured
        )?.image,
        whiteImage: policiesStore.success.insurances.find(
          (insurance) => insurance.key === policy.SlugNameInsured
        )?.whiteImage,
        ramo: policy.Branch?.toLowerCase(),
        pago: policy.WayPayment,
        status: policy.StatusPolicy,
        fecha: policy.DateEmission,
        fecha_limite: policy.DateLimitPayment,
        fecha_vencimiento: policy.DateExpired,
        HasDetail: policy.HasDetail,
        commissionValue: policy.commissionValue,
      }));
      setPoliciesCard(mappedPolicies);
    }
    if (policiesStore.success.lastPolicies) {
      const mappedPolicies = policiesStore.success.lastPolicies?.map((policy) => ({
        id: policy.IdentifierPolicy,
        title: policy?.BenefeciaryName || "",
        number: policy.NumberPolicy,
        icon: branchImages[policy.Branch?.toLowerCase()],
        image: policiesStore.success.insurances.find(
          (insurance) => insurance.key === policy.SlugNameInsured
        )?.image,
        whiteImage: policiesStore.success.insurances.find(
          (insurance) => insurance.key === policy.SlugNameInsured
        )?.whiteImage,
        ramo: policy.Branch?.toLowerCase(),
        pago: policy.WayPayment,
        status: policy.StatusPolicy,
        fecha: policy.DateEmission,
        fecha_limite: policy.DateLimitPayment,
        fecha_vencimiento: policy.DateExpired,
        HasDetail: policy.HasDetail,
        commissionValue: policy.commissionValue,
      }));
      setPoliciesCard(mappedPolicies);
    }
  }, [policiesStore, dispatch, insurances]);

  const dummyProduct = [
    { id: 3, title: "GMM", icon: GmmSvg },
    { id: 1, title: "Autos", icon: AutoSvg },
    { id: 4, title: "Daños", icon: DamageSvg },
    { id: 2, title: "Vida", icon: VidaSvg },
  ];
  return (
    <div className={`dashboard-pia ${styleDark && "dashboard-pia--dark"}`}>
      <div className="container">
        <HeaderFeedBack styleDark={styleDark} />
        {insurances && (
          <Policies
            getLast10={() => dispatch(PoliciesActions.getLastPolicies())}
            styleMode={styleDark}
            insurances={insurances}
            accounts={policiesStore.success.accounts}
            dummyProduct={dummyProduct}
            selectedExpiration={selectedExpiration}
            setSelectedExpiration={setSelectedExpiration}
            filteredAccounts={policiesStore.filters.IdentificadorCuenta}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            selectedProductLines={selectedProductLines}
            setSelectedProductLines={setSelectedProductLines}
          />
        )}

        <div className="list-filters">
          {policiesStore?.filters?.IdentificadorCuenta?.length >= 1 &&
            policiesStore?.filters?.IdentificadorCuenta.map((item) =>
              policiesStore?.success?.accounts?.map(
                (data) =>
                  data.id === item && (
                    <ButtonListFilters
                      item={data}
                      type="account"
                      setSelectedProductLines={setSelectedProductLines}
                      setSelectedExpiration={setSelectedExpiration}
                      selectedProductLines={selectedProductLines}
                      key={`account-${item}`}
                    />
                  )
              )
            )}
          {policiesStore?.filters?.Estado?.length >= 1 &&
            policiesStore?.filters?.Estado.map((item) => (
              <ButtonListFilters
                item={item}
                type="state"
                setSelectedProductLines={setSelectedProductLines}
                setSelectedExpiration={setSelectedExpiration}
                selectedProductLines={selectedProductLines}
                key={`state-${item}`}
              />
            ))}
          {policiesStore?.filters?.Ramos?.length >= 1 &&
            policiesStore?.filters?.Ramos.map((item) => (
              <ButtonListFilters
                item={item}
                type="branch"
                setSelectedProductLines={setSelectedProductLines}
                setSelectedExpiration={setSelectedExpiration}
                selectedProductLines={selectedProductLines}
                key={`branch-${item}`}
              />
            ))}
          {selectedExpiration !== "" && selectedExpiration > 0 && (
            <ButtonListFilters
              item={`Próx.${selectedExpiration}D`}
              type="expiration"
              setSelectedProductLines={setSelectedProductLines}
              setSelectedExpiration={setSelectedExpiration}
              selectedProductLines={selectedProductLines}
            />
          )}
        </div>

        {policiesStore?.loading?.policies ? (
          <Loader />
        ) : (
          policiesCard && (
            <div className={`${styleDark && "efect-darck efect-darck--top"}`}>
              <InfiniteScroll
                dataLength={policiesStore?.success?.policies?.length}
                next={() => dispatch(PoliciesActions.setFilters("NoPage", 0))}
                hasMore={true}
                loader={<Loader />}
              >
                {policiesCard?.map((item, index) => (
                  <DetailProduct isDarkMode={styleDark} key={index} policy={item} />
                ))}
              </InfiniteScroll>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Home;
