import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { changeRoute } from "../../../common/utils/changeRoute";

const Privacy = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div className="section-notifications">
      <h1>{t("label.privacy")}</h1>
      <h3>{t("label.textWeAreWorking")}</h3>
      <p>{t("label.messageNotifications")}.</p>
      <Button
        onClick={() => changeRoute("", history, dispatch)}
        className="btn-custom btn-custom--secundary"
      >
        {t("label.GoToDashboard")}
      </Button>
    </div>
  );
};

export default Privacy;
