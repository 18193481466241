import React from "react";
import { useTranslation } from "react-i18next";
import BackLoginPng from "../../../assets/images/back-login.png";
import Header from "../../../components/header/Header";

const ErrorPage = () => {
  const { t } = useTranslation();
  return (
    <div className="page-404">
      <Header setUnreadNotifications={() => {}} />
      <div className="error_page">
        <div className="error_page__contain">
          <h1 className="h1-color-gradient-berry">Error 404</h1>
          <p>{t("error.text404")}</p>
          <a href="./" className="button button--secundary">
            {t("label.GoToDashboard")}
          </a>
        </div>
        <div className="coetent-img">
          <img src={BackLoginPng} alt="logo" className="logo" />
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
