import { Button, Col, Form, Input, Row } from "antd";
import { func, instanceOf } from "prop-types";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const VerifyOfficeData = ({ office, saveOffice }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const setOffice = useCallback(() => {
    form.setFieldsValue({
      name: office?.name,
      contact: office?.contact,
    });
  }, [form, office]);

  useEffect(() => {
    if (office) setOffice();
  }, [office, setOffice]);

  const onboarding = useSelector((state) => state.onboarding);

  const onFinish = (values) => saveOffice(values);

  return (
    <div className="offices-editing__cardForms">
      <h3>{t("label.VerifyInformation")}</h3>
      <Form form={form} name="horizontal_login" layout="vertical" onFinish={onFinish}>
        <Form.Item
          label={t("label.OfficeName")}
          name="name"
          rules={[
            {
              required: true,
              message: t("error.YouEnterOfficeName"),
            },
          ]}
        >
          <Input style={{ width: "95%" }} type="text" placeholder={t("label.OfficeName")} />
        </Form.Item>
        <Row>
          <Col xs={12}>
            <Form.Item
              label={t("label.ContactNumber")}
              className="input-type-number"
              name="contact"
              rules={[
                {
                  required: true,
                  message: t("error.phone"),
                },
                {
                  whitespace: true,
                  max: 15,
                  message: t("error.charactersContactMax"),
                },
                {
                  whitespace: true,
                  min: 10,
                  message: t("error.charactersContactMin"),
                },
              ]}
            >
              <Input type="number" placeholder="300-113-1234" />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label="Página web URL"
              name="url"
              initialValue={`https://berrysafe.com/${onboarding.success.profile?.endPoint}`}
            >
              <Input type="url" placeholder="https://berrysafe.com/juan" disabled />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {office ? t("button.updateAddress") : t("button.registerAddress")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

VerifyOfficeData.propTypes = { office: instanceOf(Object).isRequired, saveOffice: func.isRequired };

export default VerifyOfficeData;
