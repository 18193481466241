import { postcodeValidator } from "postcode-validator";
import { func, instanceOf } from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import OnboardingActions from "../../../../../../../services/onboarding/actions";
import SearchPlace from "../../../mainAgentInfo/components/offices/components/AddOffice/components/SearchPlace/SearchPlace";
import AddedOffices from "./components/AddedOffices/AddedOffices";
import InsuranceCarrierSelection from "./components/InsuranceCarrier/InsuranceCarrierSelection";
import OfficeForms from "./components/OfficesForms/OfficeForms";
import ProductLineSelection from "./components/ProductLine/ProductLineSelection";

const Offices = ({
  office,
  offices,
  setSaveOffice,
  saveOffice,
  setNextAction,
  nextAction,
  productLinesSelected,
  insuranceCarriers,
  setOfficeView,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [view, setView] = useState(0);
  const [inAdditionAddress, setInAdditionAddress] = useState(undefined);
  const [addressForm, setAddressForm] = useState();
  const [officeForm, setOfficeForm] = useState();
  const [newAddress, setNewAddress] = useState();
  const [inEditionAddress, setInEditionAddress] = useState();
  const [sideBarCollapsed, setsideBarCollapsed] = useState(false);
  const [searchByGoogle, setSearchByGoogle] = useState(true);

  const officeChanged = (office1, office2) => {
    if (office2?.insuranceCarriers === undefined && office2?.productLines === undefined)
      return false;

    if (
      office2?.insuranceCarriers?.some(
        (e) => !office1?.insuranceCarriers?.some((o) => e.id === o.id)
      )
    )
      return true;

    if (office2?.productLines?.some((e) => !office1?.productLines?.some((o) => e.id === o.id)))
      return true;

    return false;
  };

  useEffect(() => {
    if (saveOffice && officeChanged(saveOffice.data, newAddress))
      setSaveOffice({
        data: newAddress,
        run: async () => {
          setSearchByGoogle(true);
          setNewAddress(undefined);
          setView(0);
          setOfficeView(0);
          await officeForm.resetFields();
          await addressForm.resetFields();
          setOfficeForm(undefined);
          setAddressForm(undefined);
          setNextAction(undefined);
        },
      });
  }, [
    newAddress,
    addressForm,
    dispatch,
    officeForm,
    saveOffice,
    setSaveOffice,
    setNextAction,
    setOfficeView,
  ]);

  useEffect(() => {
    if (nextAction?.key === "editFull" && officeChanged(saveOffice.data, newAddress))
      setNextAction({
        key: "editFull",
        run: async () => {
          setView(0);
          setOfficeView(0);
          await officeForm.resetFields();
          await addressForm.resetFields();
          setNewAddress(undefined);
          setOfficeForm(undefined);
          setAddressForm(undefined);
          setInEditionAddress(undefined);
          setNextAction(undefined);
        },
      });
  }, [
    addressForm,
    dispatch,
    inEditionAddress,
    newAddress,
    nextAction,
    officeForm,
    saveOffice,
    setNextAction,
    setOfficeView,
  ]);

  useEffect(() => {
    if (
      view === 0 &&
      officeForm &&
      nextAction?.key !== "saveOffice" &&
      nextAction?.key !== "editOffice"
    ) {
      if (!inEditionAddress) {
        setNextAction({
          key: "saveOffice",
          run: async () => {
            let fullAddress = {};
            try {
              const addressValues = await addressForm.validateFields();

              const isValid = postcodeValidator(`${addressValues.zipcode}`, "MX");
              if (!isValid) throw new Error();

              if (inEditionAddress)
                fullAddress = {
                  latitude: newAddress?.latitude,
                  longitude: newAddress?.longitude,
                  ...inEditionAddress,
                  office: { ...inEditionAddress.office, ...addressValues },
                };
              else
                fullAddress = {
                  latitude: newAddress?.latitude,
                  longitude: newAddress?.longitude,
                  ...addressValues,
                };
            } catch (error) {
              return addressForm.setFields([
                { name: "zipcode", errors: ["Debe ser un código postal de México"] },
              ]);
            }
            try {
              const officeValues = await officeForm.validateFields();
              fullAddress = { ...fullAddress, ...officeValues };
              setNewAddress(fullAddress);
              dispatch(OnboardingActions.addOfficeToSave(fullAddress));
              setView(1);
              setOfficeView(1);
            } catch (error) {
              return error;
            }
            return null;
          },
        });
      } else {
        setNextAction({
          key: "editOffice",
          run: async () => {
            let fullAddress = {};
            try {
              const addressValues = await addressForm.validateFields();

              const isValid = postcodeValidator(`${addressValues.zipcode}`, "MX");
              if (!isValid) throw new Error();

              if (inEditionAddress)
                fullAddress = {
                  ...inEditionAddress.office,
                  ...addressValues,
                };
              else fullAddress = { ...addressValues };
            } catch (error) {
              return addressForm.setFields([
                { name: "zipcode", errors: ["Debe ser un código postal de México"] },
              ]);
            }
            try {
              const values = await officeForm.validateFields();
              fullAddress = { ...inEditionAddress, office: { ...fullAddress, ...values } };
              setInEditionAddress(fullAddress);
              const payload = {
                inEditionAddress,
                newAddress: { ...fullAddress.office },
              };
              dispatch(OnboardingActions.updateOfficeToSave(payload));
              setView(1);
              setOfficeView(1);
            } catch (error) {
              return {};
            }
            return null;
          },
        });
      }
    }
  }, [
    officeForm,
    nextAction,
    setNextAction,
    setView,
    newAddress,
    view,
    setOfficeView,
    dispatch,
    inEditionAddress,
    addressForm,
  ]);

  useEffect(() => {
    if (view === 1 && (nextAction?.key === "saveOffice" || nextAction?.key === "editOffice")) {
      if (!inEditionAddress) {
        setNextAction({
          key: "saveFull",
        });
        setSaveOffice({
          data: newAddress,
          run: async () => {
            dispatch(OnboardingActions.completeOffice(newAddress));
            setView(0);
            setOfficeView(0);
            await officeForm.resetFields();
            await addressForm.resetFields();
            setOfficeForm(undefined);
            setAddressForm(undefined);
            setInEditionAddress(undefined);
            setNextAction(undefined);
          },
        });
      } else {
        setNextAction({
          key: "editFull",
          run: async () => {
            setView(0);
            setOfficeView(0);
            await officeForm.resetFields();
            await addressForm.resetFields();
            setOfficeForm(undefined);
            setAddressForm(undefined);
            setInEditionAddress(undefined);
            setNextAction(undefined);
          },
        });
      }
    }
  }, [
    officeForm,
    dispatch,
    setNextAction,
    view,
    newAddress,
    nextAction,
    setSaveOffice,
    addressForm,
    inEditionAddress,
    setOfficeView,
  ]);

  const selectProductLine = (productLine) => {
    const { productLines } = inEditionAddress ? inEditionAddress.office : newAddress;

    if (!productLines && inEditionAddress) {
      OnboardingActions.updateOfficeToSave({
        inEditionAddress,
        newAddress: {
          ...inEditionAddress.office,
          productLines: [productLine],
        },
      });
      return setInEditionAddress({
        ...inEditionAddress,
        office: {
          ...inEditionAddress.office,
          productLines: [productLine],
        },
      });
    }

    if (!productLines) {
      dispatch(
        OnboardingActions.completeOffice({
          ...newAddress,
          productLines: [productLine],
        })
      );
      return setNewAddress({ ...newAddress, productLines: [productLine] });
    }

    const exists = productLines.findIndex(
      (productLineIterable) => productLineIterable.id === productLine.id
    );

    if (exists > -1) productLines.splice(exists, 1);
    else productLines.push(productLine);

    if (inEditionAddress) {
      OnboardingActions.updateOfficeToSave({
        inEditionAddress,
        newAddress: {
          ...inEditionAddress.office,
          productLines,
        },
      });

      return setInEditionAddress({
        ...inEditionAddress,
        office: { ...inEditionAddress.office, productLines },
      });
    }

    dispatch(OnboardingActions.completeOffice({ ...newAddress, productLines }));
    return setNewAddress({ ...newAddress, productLines });
  };

  const selectInsuranceCarrier = (insuranceCarrier) => {
    const { insuranceCarriers: newInsuranceCarriers } = inEditionAddress
      ? inEditionAddress.office
      : newAddress;

    if (!newInsuranceCarriers && inEditionAddress) {
      dispatch(
        OnboardingActions.updateOfficeToSave({
          inEditionAddress,
          newAddress: {
            ...inEditionAddress.office,
            insuranceCarriers: [insuranceCarrier],
          },
        })
      );
      return setInEditionAddress({
        ...inEditionAddress,
        office: {
          ...inEditionAddress.office,
          insuranceCarriers: [insuranceCarrier],
        },
      });
    }

    if (!newInsuranceCarriers) {
      dispatch(
        OnboardingActions.completeOffice({
          ...newAddress,
          insuranceCarriers: [insuranceCarrier],
        })
      );
      return setNewAddress({
        ...newAddress,
        insuranceCarriers: [insuranceCarrier],
      });
    }

    const exists = newInsuranceCarriers.findIndex(
      (insuranceCarriersIterable) => insuranceCarriersIterable.id === insuranceCarrier.id
    );

    if (exists > -1) newInsuranceCarriers.splice(exists, 1);
    else newInsuranceCarriers.push(insuranceCarrier);

    if (inEditionAddress) {
      dispatch(
        OnboardingActions.updateOfficeToSave({
          inEditionAddress,
          newAddress: { ...inEditionAddress.office, insuranceCarriers: newInsuranceCarriers },
        })
      );

      return setInEditionAddress({
        ...inEditionAddress,
        office: { ...inEditionAddress.office, insuranceCarriers: newInsuranceCarriers },
      });
    }

    dispatch(
      OnboardingActions.completeOffice({ ...newAddress, insuranceCarriers: newInsuranceCarriers })
    );
    return setNewAddress({ ...newAddress, insuranceCarriers: newInsuranceCarriers });
  };

  const renderSideBarClassName = () => {
    if (sideBarCollapsed) return "open";

    if (offices.length > 0) return "close";

    return "invisible";
  };

  return (
    <section className="onboarding-offices">
      <div className="onboarding-offices__offices">
        {offices.length > 0 && (
          <AddedOffices
            setInEditionAddress={setInEditionAddress}
            sideBarCollapsed={sideBarCollapsed}
            setsideBarCollapsed={setsideBarCollapsed}
            offices={offices}
            canEdit={view === 0}
          />
        )}
      </div>
      <div className={`onboarding-offices__add--offices-${renderSideBarClassName()}`}>
        <h3>
          {inEditionAddress ? t("label.titleEditYourOffices") : t("label.titleAddYourOffices")}
        </h3>
        <p>{t("label.importantInformationOffice")}</p>

        {view === 0 && (
          <>
            {searchByGoogle && !inEditionAddress ? (
              <SearchPlace
                setAddManually={() => setSearchByGoogle(false)}
                goToAddOffice={() => setSearchByGoogle(false)}
                setDetailedAddress={(values) => {
                  if (inEditionAddress) return;
                  setNewAddress({
                    ...values,
                    zipcode: values.postalCode,
                  });
                }}
                availableCountries={office.success.countries ?? []}
              />
            ) : (
              <OfficeForms
                inAdditionAddress={inAdditionAddress}
                inEditionAddress={inEditionAddress}
                newAddress={newAddress}
                setAddressForm={setAddressForm}
                setInAdditionAddress={setInAdditionAddress}
                setOfficeForm={setOfficeForm}
              />
            )}
          </>
        )}
        {view === 1 && (
          <div className="offices-products">
            <h4>{t("label.assignProductLines")}</h4>
            <ProductLineSelection
              inEditionAddress={inEditionAddress}
              productLinesSelected={productLinesSelected}
              selectProductLine={selectProductLine}
              newAddress={newAddress}
            />

            <h4>{t("label.assignInsurance")}</h4>
            <InsuranceCarrierSelection
              inEditionAddress={inEditionAddress}
              selectInsuranceCarrier={selectInsuranceCarrier}
              insuranceCarriers={insuranceCarriers}
              newAddress={newAddress}
            />
          </div>
        )}
      </div>
    </section>
  );
};

Offices.propTypes = {
  office: instanceOf(Object).isRequired,
  offices: instanceOf(Array).isRequired,
  setSaveOffice: func.isRequired,
  saveOffice: instanceOf(Object),
  setNextAction: func.isRequired,
  nextAction: instanceOf(Object),
  productLinesSelected: instanceOf(Array).isRequired,
  insuranceCarriers: instanceOf(Array).isRequired,
  setOfficeView: func.isRequired,
};

Offices.defaultProps = {
  saveOffice: null,
  nextAction: null,
};

export default Offices;
