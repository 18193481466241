/* eslint-disable no-nested-ternary */
import { Button, Col, Dropdown, Layout, Menu, Row } from "antd";
import { func, instanceOf } from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import IconAvatar from "assets/icon/profile-inverse.svg";
import Logout from "assets/images/logout-purple.svg";
import menuIcon from "assets/images/menu-white.svg";
import notification from "assets/images/notification-empty.svg";
import MyMenu from "common/menu";
import { changeRoute } from "common/utils/changeRoute";
import AuthActions from "services/auth/actions";
import RoutesActions from "services/routes/actions";
import BrandHeader from "./components/BrandHeader";
import HeaderNotification from "./components/HeaderNotification";
import NavigationMenu from "./components/NavigationMenu";
import OmitSection from "./components/OmitSection";
import SaveSection from "./components/SaveSection";
import InactivityTimer from "../../common/utils/InactivityTimer";

const { Header: HeaderAnt } = Layout;

const userMenu = (t, logout, history, dispatch, onboarding) => (
  <Menu>
    <Menu.Item
      key="profile"
      className="header__dropdown-custom__item header__dropdown-custom__item--hasAfter user-item"
    >
      <p className="body-bold">{onboarding?.success?.profile?.user?.person?.name}</p>
      <p className="header__dropdown-custom__itemState--label">{t("label.onlineStatus")}</p>
      <p className="header__dropdown-custom__itemState">
        <span className="header__dropdown-custom__itemState__circle" />
        {t("label.inLine")}
      </p>
    </Menu.Item>
    <Menu.Item key="editProfile" className="header__dropdown-custom__item">
      <Button
        type="link"
        id="edit-page-button-header"
        block
        onClick={() => changeRoute("editPage", history, dispatch)}
      >
        Mi página
      </Button>
    </Menu.Item>
    <Menu.Item key="manage-connections" className="header__dropdown-custom__item">
      <Button
        type="link"
        id="edit-page-button-header"
        block
        onClick={() => changeRoute("account/pia", history, dispatch)}
      >
        Gestionar aseguradoras
      </Button>
    </Menu.Item>
    <Menu.Item key="account" className="header__dropdown-custom__item">
      <Button
        type="link"
        id="edit-page-button-header"
        block
        onClick={() => changeRoute("account", history, dispatch)}
      >
        Cuenta
      </Button>
    </Menu.Item>
    <Menu.Item
      key="Logout"
      className="header__dropdown-custom__item header__dropdown-custom__item--logOut"
    >
      <Button
        type="link"
        id="logout-button-header"
        block
        onClick={(e) => {
          e.preventDefault();
          history.replace("/");
          logout();
        }}
      >
        <img className="header-logout" src={Logout} alt="logout" />
        {t("label.logOut")}
      </Button>
    </Menu.Item>
  </Menu>
);

const Header = ({
  notificationRef,
  unreadNotifications,
  allNotifications,
  setUnreadNotifications,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const logout = () => dispatch(AuthActions.logout());
  const routes = useSelector((state) => state.routes);
  const onboarding = useSelector((state) => state.onboarding);

  const [isLoading, setIsLoading] = useState(false);

  const innerChangeRoute = (path) => {
    history.replace(`/${path}`);
    dispatch(RoutesActions.setActualRoute(path));
  };

  useEffect(() => {
    const actualMenu = history.location.pathname.split("/")[1];
    if (routes.actualPosition === undefined) {
      return;
    }

    if (history.location.pathname.split("/").length > 2) return;

    const isARoute = MyMenu.find((menu) => menu.key === actualMenu);
    if (isARoute === undefined) {
      return;
    }

    const defaultSubmenu = `${actualMenu}/${
      MyMenu.find((menu) => menu.key === actualMenu).items.find((item) => item.default)?.path
    }`;

    if (defaultSubmenu !== "") history.push(defaultSubmenu);
  }, [routes, dispatch, history]);
  const showMenu = (key) => {
    const subMenu = MyMenu.find((menu) => menu.key === key);

    if (key === "notifications" && unreadNotifications)
      return (
        <HeaderNotification
          t={t}
          allNotifications={allNotifications}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          unreadNotifications={unreadNotifications}
          setUnreadNotifications={setUnreadNotifications}
          dispatch={dispatch}
          history={history}
          notificationRef={notificationRef}
        />
      );

    return (
      <Menu>
        {subMenu.menu.map((submenu) => (
          <Menu.Item key={submenu.path} className="header__dropdown-custom__item">
            <Button
              id="menu-button-header"
              type="link"
              block
              onClick={() => innerChangeRoute(submenu.path)}
            >
              {submenu.name}
            </Button>
          </Menu.Item>
        ))}
      </Menu>
    );
  };
  const renderMenu = (onboardingStore) =>
    MyMenu.filter((menu) => !menu.notVisible).map((menu) => {
      if (menu.key === "") return <div key={menu.key} />;
      if (menu.key === "user")
        return (
          <Dropdown
            key={menu.key}
            overlay={userMenu(t, logout, history, dispatch, onboarding)}
            trigger={["click"]}
            placement="bottomRight"
            overlayClassName="header__dropdown-custom"
          >
            <a
              href="./"
              id="menu-button-header"
              className="custom-trigger-btn button-menu-avatar"
              onClick={(e) => {
                e.preventDefault();
                if (menu.menu === undefined) innerChangeRoute(menu.path);
              }}
            >
              <img
                className="avatar"
                alt="avatar"
                src={
                  onboardingStore.success.profile
                    ? onboardingStore.success.profile.profilePhoto !== ""
                      ? onboardingStore.success.profile.profilePhoto
                      : IconAvatar
                    : IconAvatar
                }
              />
              <img className="menu" alt="menu" src={menuIcon} />
            </a>
          </Dropdown>
        );
      return (
        <Dropdown
          key={menu.key}
          overlay={menu.menu ? showMenu(menu.key) : <></>}
          trigger={["click"]}
          placement="bottomRight"
          overlayClassName="header__dropdown-custom"
        >
          <Link
            to="./"
            id="menu-button-header"
            className={`custom-trigger-btn custom--notifications custom-trigger-btn--${menu.key}`}
            onClick={(e) => {
              e.preventDefault();
              if (menu.menu === undefined) innerChangeRoute(menu.path);
            }}
          >
            <img src={notification} alt={menu.name} />
            {menu.key === "notifications" && unreadNotifications?.length > 0 && (
              <div className="circle-notify">
                <span className="number-notify">{unreadNotifications?.length}</span>
              </div>
            )}
          </Link>
        </Dropdown>
      );
    });

  return (
    <div>
      <div className="padding-header" />
      <InactivityTimer dispatch={dispatch} />
      <HeaderAnt className="header">
        <Row className="header__container margin header-sidemenu">
          <Col xs={4} sm={6} md={8} lg={8} className="header__brand">
            <BrandHeader innerChangeRoute={innerChangeRoute} />
          </Col>
          <Col className="header__actions" xs={20} sm={18} md={16} lg={16}>
            <NavigationMenu dispatch={dispatch} />
            <SaveSection onboarding={onboarding} routes={routes} />
            {renderMenu(onboarding)}
          </Col>
        </Row>

        <OmitSection onboarding={onboarding} />
      </HeaderAnt>
    </div>
  );
};

Header.propTypes = {
  notificationRef: instanceOf(Object),
  unreadNotifications: instanceOf(Array),
  allNotifications: instanceOf(Array),
  setUnreadNotifications: func.isRequired,
};

Header.defaultProps = {
  notificationRef: null,
  unreadNotifications: null,
  allNotifications: [],
};

export default Header;
