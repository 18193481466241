import { Button, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import pass from "../../../../../assets/images/profile/pass.png";
import AuthActions from "../../../../../services/auth/actions";

const PasswordUpdate = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();
  const [passwordData, setPassworData] = useState({
    lastPassword: "",
    newPassword: "",
    repeatPassword: "",
  });

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.success.updatedPassword) {
      message.success("Contraseña actualizada exitosamente");
      dispatch(AuthActions.clearUpdatePassword());
      setIsEditing(false);
    }

    if (auth.error.updatedPassword) {
      message.error("La contraseña actual es incorrecta");
      dispatch(AuthActions.clearUpdatePassword());
    }
  }, [auth, dispatch]);

  const validatePasswordFields = () => {
    if (
      passwordData.newPassword === "" ||
      passwordData.repeatPassword === "" ||
      passwordData.lastPassword === ""
    )
      return message.error("Debes ingresar todos los campos");

    if (passwordData.newPassword !== passwordData.repeatPassword)
      return message.error("Las contraseñas no coinciden");

    return dispatch(
      AuthActions.updatePassword({
        lastPassword: passwordData.lastPassword,
        newPassword: passwordData.newPassword,
      })
    );
  };

  return (
    <div
      className={`my-account__content--section ${
        isEditing && "my-account__content--section--pass"
      }`}
    >
      <h4>Contraseña</h4>
      <div className="center">
        <Button
          className={`send ${isEditing && "send--inactive"}`}
          onClick={() => setIsEditing(true)}
        >
          <img src={pass} alt="pass" />
          {t("label.ChangePassword")}
        </Button>
        <div className={`form-password ${isEditing && "form-password--active"}`}>
          <div className="inputs">
            <Input.Password
              className="input-password"
              prefix={<img src={pass} alt="pass" />}
              placeholder="Contraseña actual"
              onChange={(e) =>
                setPassworData({
                  ...passwordData,
                  lastPassword: e.target.value,
                })
              }
            />
            <Input.Password
              className="input-password"
              prefix={<img src={pass} alt="pass" />}
              placeholder="Nueva contraseña"
              onChange={(e) =>
                setPassworData({
                  ...passwordData,
                  newPassword: e.target.value,
                })
              }
            />
            <Input.Password
              className="input-password"
              prefix={<img src={pass} alt="pass" />}
              placeholder="Confirmar nueva contraseña"
              onChange={(e) =>
                setPassworData({
                  ...passwordData,
                  repeatPassword: e.target.value,
                })
              }
            />
          </div>
          <div className="btns">
            <Button onClick={() => setIsEditing(false)} className="button button--tertiary">
              {t("button.cancel")}
            </Button>
            <Button
              onClick={() => {
                validatePasswordFields();
              }}
              className="button button--secundary"
            >
              {t("button.updateInsuranceCarriers")}
            </Button>
          </div>
        </div>
      </div>
      <div />
    </div>
  );
};

export default PasswordUpdate;
