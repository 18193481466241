import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./home/home";

const DashboardPia = () => (
  <Switch>
    <Route exact path="/pia/policies" component={Home} />
  </Switch>
);

export default DashboardPia;
