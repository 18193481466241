/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button } from "antd";
import { func, instanceOf } from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import close from "../../../../assets/icon/icon-close.svg";
import berry from "../../../../assets/images/berry.png";

const Welcome = ({ setShowTutorial, profile, setStep }) => {
  const { t } = useTranslation();

  return (
    <div className="welcome">
      <div className="welcome__go">
        <div className="titles">
          <h4>¡{t("label.welcome")}</h4>
          <h4 className="name">{profile?.user.person.name.split(" ")[0]}!</h4>
        </div>
        <p>{t("label.textWolcome")}</p>
        <Button onClick={setStep} className="secundary">
          {t("button.watchTutorial")}
        </Button>
      </div>
      <div className="welcome__image">
        <div className="welcome__imageClose">
          <img alt="close" src={close} onClick={() => setShowTutorial(false)} />
        </div>
        <img className="berry" alt="berry" src={berry} />
      </div>
    </div>
  );
};

Welcome.propTypes = {
  setShowTutorial: func.isRequired,
  profile: instanceOf(Object),
  setStep: func.isRequired,
};

Welcome.defaultProps = {
  profile: null,
};

export default Welcome;
