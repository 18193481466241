import selectectablesProps from "common/config/defaultClientColumns";
import moment from "moment";
import { handleActions } from "redux-actions";

const validateClients = (data) => {
  const clientKeys = data.filter((item) => item.name === "Póliza" || item.name === "Correo");
  const rowsQuantity = clientKeys[0]?.rows.length;
  const clientDict = {};
  const messages = [];
  for (let i = 0; i < rowsQuantity; i += 1) {
    if (clientDict[`${clientKeys[0].rows[i].name}-${clientKeys[1].rows[i].name}`])
      messages.push(
        `El cliente ${clientKeys[0].rows[i].name} está duplicado con la poliza ${clientKeys[1].rows[i].name}`
      );
    else clientDict[`${clientKeys[0].rows[i].name}-${clientKeys[1].rows[i].name}`] = 1;
  }

  return messages || undefined;
};

export const INITIAL_STATE = {
  columnRelations: [],
  data: [],
  visibleData: [],
  loading: {
    uploadBook: false,
  },
  error: {
    uploadBook: false,
    dataErrors: undefined,
  },
  success: {
    uploadBook: false,
  },
};

const reducer = handleActions(
  {
    CLIENT_BOOK_ACTIONS: {
      SET_COLUMNS: (state, { payload: rows }) => {
        if (!rows || rows.length === 0) return { ...state, columnRelations: [] };
        let columnsWithRows = rows[0].map((column, i) => ({
          id: i,
          name: column,
          rows: [],
          assigned: !!selectectablesProps.find((props) => props.name === column),
          property: selectectablesProps.find((props) => props.name === column)?.name,
        }));
        rows.shift();
        rows.map((row) =>
          row.map((columnValue, i) => {
            if (columnValue instanceof Date)
              return columnsWithRows[i].rows.push({
                name: moment(columnValue).format("DD/MM/YYYY"),
                id: i,
              });
            if (typeof columnValue === "number")
              return columnsWithRows[i].rows.push({ name: `${columnValue}`, id: i });

            return columnsWithRows[i].rows.push({ name: columnValue, id: i });
          })
        );
        const newData = columnsWithRows;

        columnsWithRows = columnsWithRows.map((column) => {
          if (column.rows.length > 4) return { ...column, rows: column.rows.slice(0, 4) };
          return column;
        });

        return {
          ...state,
          columnRelations: columnsWithRows,
          data: newData,
        };
      },

      SET_RELATION: (state, { payload: { property, columnId } }) => {
        const columns = state.columnRelations;
        const newData = state.data;

        const wasAssigned = columns.findIndex((column) => column.property === property);
        if (wasAssigned > -1) {
          newData[wasAssigned].assigned = false;
          newData[wasAssigned].property = null;
          columns[wasAssigned].assigned = false;
          columns[wasAssigned].property = null;
        }

        columns[columnId].assigned = true;
        columns[columnId].property = property;
        newData[columnId].assigned = true;
        newData[columnId].property = property;

        return {
          ...state,
          columnRelations: columns,
          data: newData,
        };
      },

      UPLOAD_BOOK: (state) => {
        const messages = validateClients(state.data);
        return {
          ...state,
          loading: { ...state.loading, uploadBook: true },
          error: {
            ...state.error,
            uploadBook: undefined,
            dataErrors: messages,
          },
        };
      },
      UPLOAD_BOOK_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, uploadBook: false },
            success: {
              ...state.success,
              uploadBook: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, uploadBook: message },
            success: { ...state.success, uploadBook: undefined },
            loading: { ...state.loading, uploadBook: false },
          };
        },
      },
      CLEAR_UPLOAD_BOOK: (state) => ({
        ...state,
        success: {
          ...state.success,
          uploadBook: undefined,
        },
        error: {
          ...state.error,
          uploadBook: undefined,
        },
      }),

      UPDATE_END_POINT: (state) => ({
        ...state,
        loading: { ...state.loading, updateEndPoint: true },
        error: { ...state.error, updateEndPoint: undefined },
      }),
      UPDATE_END_POINT_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, updateEndPoint: false },
            success: {
              ...state.success,
              updateEndPoint: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, updateEndPoint: message },
            success: { ...state.success, updateEndPoint: undefined },
            loading: { ...state.loading, updateEndPoint: false },
          };
        },
      },
      CLEAR_UPDATE_END_POINT: (state) => ({
        ...state,
        success: {
          ...state.success,
          updateEndPoint: undefined,
        },
        error: {
          ...state.error,
          updateEndPoint: undefined,
        },
      }),
    },
  },
  INITIAL_STATE
);

export default reducer;
