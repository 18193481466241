/* eslint-disable no-param-reassign */
import { handleActions } from "redux-actions";

export const INITIAL_STATE = {
  loading: {
    minimalMarketplace: false,
    setProductStatus: false,
    getInsuranceCarriers: false,
    getSpecialProducts: false,
    getProductsByInsuranceCarrier: false,
    getProductsByInsuranceLine: false,
    getInsuranceLines: false,
    setAgentProduct: false,
    getAllProducts: false,
    getProduct: false,
    requestHelp: false,
  },
  error: {
    minimalMarketplace: undefined,
    setProductStatus: undefined,
    getInsuranceCarriers: undefined,
    getSpecialProducts: undefined,
    getProductsByInsuranceCarrier: undefined,
    getProductsByInsuranceLine: undefined,
    getInsuranceLines: undefined,
    setAgentProduct: undefined,
    getAllProducts: undefined,
    getProduct: false,
    requestHelp: false,
  },
  success: {
    minimalMarketplace: undefined,
    setProductStatus: undefined,
    getInsuranceCarriers: undefined,
    getSpecialProducts: undefined,
    getProductsByInsuranceCarrier: undefined,
    getProductsByInsuranceLine: undefined,
    getInsuranceLines: undefined,
    setAgentProduct: undefined,
    getAllProducts: undefined,
    getProduct: undefined,
    requestHelp: undefined,
  },
  products: {
    selected: "all",
  },
  insuranceLines: {},
};

const reducer = handleActions(
  {
    MARKETPLACE: {
      GET_MINIMAL_MARKETPLACE: (state) => ({
        ...state,
        loading: { ...state.loading, minimalMarketplace: true },
        error: { ...state.error, minimalMarketplace: undefined },
      }),
      GET_MINIMAL_MARKETPLACE_RESPONSE: {
        next(state, { payload: { marketplace } }) {
          return {
            ...state,
            loading: { ...state.loading, minimalMarketplace: false },
            success: { ...state.success, minimalMarketplace: marketplace },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, minimalMarketplace: message },
            success: { ...state.success, minimalMarketplace: undefined },
            loading: { ...state.loading, minimalMarketplace: false },
          };
        },
      },
      CLEAR_MINIMAL_MARKETPLACE_RESPONSE: (state) => ({ ...state, ...INITIAL_STATE }),

      REQUEST_HELP_WITH_INSURANCE_CARRIER: (state) => ({
        ...state,
        loading: { ...state.loading, requestHelp: true },
        error: { ...state.error, requestHelp: undefined },
      }),
      REQUEST_HELP_WITH_INSURANCE_CARRIER_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, requestHelp: false },
            success: { ...state.success, requestHelp: true },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, requestHelp: message },
            success: { ...state.success, requestHelp: undefined },
            loading: { ...state.loading, requestHelp: false },
          };
        },
      },

      SET_PRODUCT_STATUS: (state) => ({
        ...state,
        loading: { ...state.loading, setProductStatus: true },
        error: { ...state.error, setProductStatus: undefined },
      }),
      SET_PRODUCT_STATUS_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, setProductStatus: false },
            success: { ...state.success, setProductStatus: true },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, setProductStatus: message },
            success: { ...state.success, setProductStatus: undefined },
            loading: { ...state.loading, setProductStatus: false },
          };
        },
      },

      GET_INSURANCE_CARRIERS: (state) => {
        return {
          ...state,
          loading: { ...state.loading, getInsuranceCarriers: true },
          error: { ...state.error, getInsuranceCarriers: undefined },
        };
      },
      GET_INSURANCE_CARRIERS_RESPONSE: {
        next(state, { payload: { insuranceCarriers } }) {
          return {
            ...state,
            insuranceCarriers,
            loading: { ...state.loading, getInsuranceCarriers: false },
            success: { ...state.success, getInsuranceCarriers: true },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            loading: { ...state.loading, getInsuranceCarriers: false },
            success: { ...state.success, getInsuranceCarriers: undefined },
            error: { ...state.error, getInsuranceCarriers: message },
          };
        },
      },

      GET_SPECIAL_PRODUCTS: (state) => {
        return {
          ...state,
          loading: { ...state.loading, getSpecialProducts: true },
          error: { ...state.error, getSpecialProducts: undefined },
        };
      },
      GET_SPECIAL_PRODUCTS_RESPONSE: {
        next(state, { payload: { specialProducts } }) {
          return {
            ...state,
            specialProducts,
            loading: { ...state.loading, getSpecialProducts: false },
            success: { ...state.success, getSpecialProducts: true },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            loading: { ...state.loading, getSpecialProducts: false },
            success: { ...state.success, getSpecialProducts: undefined },
            error: { ...state.error, getSpecialProducts: message },
          };
        },
      },

      GET_ALL_PRODUCTS: (state) => {
        return {
          ...state,
          loading: { ...state.loading, getAllProducts: true },
          error: { ...state.error, getAllProducts: undefined },
        };
      },
      GET_ALL_PRODUCTS_RESPONSE: {
        next(state, { payload: { products } }) {
          return {
            ...state,
            products: { ...state.products, all: products },
            loading: { ...state.loading, getAllProducts: false },
            success: { ...state.success, getAllProducts: true },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            loading: { ...state.loading, getAllProducts: false },
            success: { ...state.success, getAllProducts: undefined },
            error: { ...state.error, getAllProducts: message },
          };
        },
      },

      GET_PRODUCTS_BY_INSURANCE_CARRIER: (state) => {
        return {
          ...state,
          products: { ...state.products },
          loading: { ...state.loading, getProductsByInsuranceCarrier: true },
          error: { ...state.error, getProductsByInsuranceCarrier: undefined },
        };
      },
      GET_PRODUCTS_BY_INSURANCE_CARRIER_RESPONSE: {
        next(state, { payload: { insuranceCarrier, products } }) {
          if (!Array.isArray(products)) products = [];
          return {
            ...state,
            products: { ...state.products, [insuranceCarrier]: products },
            loading: { ...state.loading, getProductsByInsuranceCarrier: false },
            success: { ...state.success, getProductsByInsuranceCarrier: true },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            loading: { ...state.loading, getProductsByInsuranceCarrier: false },
            success: {
              ...state.success,
              getProductsByInsuranceCarrier: undefined,
            },
            error: { ...state.error, getProductsByInsuranceCarrier: message },
          };
        },
      },

      SET_AGENT_PRODUCT: (state) => {
        return {
          ...state,
          loading: { ...state.loading, setAgentProduct: true },
          error: { ...state.error, setAgentProduct: undefined },
        };
      },
      SET_AGENT_PRODUCT_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, setAgentProduct: false },
            success: {
              ...state.success,
              minimalMarketplace: undefined,
              setAgentProduct: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            loading: { ...state.loading, setAgentProduct: false },
            success: { ...state.success, setAgentProduct: undefined },
            error: { ...state.error, setAgentProduct: message },
          };
        },
      },
      CLEAR_AGENT_PRODUCT_RESPONSE: (state) => {
        return {
          ...state,
          success: { setAgentProduct: false },
        };
      },

      SET_INSURANCE_SELECT: (state, { payload: { insurance } }) => {
        return {
          ...state,
          products: { ...state.products, selected: insurance },
        };
      },

      GET_INSURANCE_LINES: (state) => {
        return {
          ...state,
          loading: { ...state.loading, getInsuranceLines: true },
          error: { ...state.error, getInsuranceLines: undefined },
        };
      },
      GET_INSURANCE_LINES_RESPONSE: {
        next(state, { payload: { list } }) {
          return {
            ...state,
            insuranceLines: { ...state.insuranceLines, list },
            loading: { ...state.loading, getInsuranceLines: false },
            success: { ...state.success, getInsuranceLines: true },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            loading: { ...state.loading, getInsuranceLines: false },
            success: { ...state.success, getInsuranceLines: undefined },
            error: { ...state.error, getInsuranceLines: message },
          };
        },
      },

      GET_PRODUCTS_BY_INSURANCE_LINE: (state) => {
        return {
          ...state,
          loading: { ...state.loading, getProductsByInsuranceLine: true },
          error: { ...state.error, getProductsByInsuranceLine: undefined },
        };
      },
      GET_PRODUCTS_BY_INSURANCE_LINE_RESPONSE: {
        next(state, { payload: { insuranceLine, products } }) {
          return {
            ...state,
            insuranceLines: {
              ...state.insuranceLines,
              [insuranceLine]: products,
            },
            loading: { ...state.loading, getProductsByInsuranceLine: false },
            success: { ...state.success, getProductsByInsuranceLine: true },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            loading: { ...state.loading, getProductsByInsuranceLine: false },
            success: {
              ...state.success,
              getProductsByInsuranceLine: undefined,
            },
            error: { ...state.error, getProductsByInsuranceLine: message },
          };
        },
      },

      GET_PRODUCT: (state) => {
        return {
          ...state,
          loading: { ...state.loading, getProduct: true },
          error: { ...state.error, getProduct: undefined },
        };
      },
      GET_PRODUCT_RESPONSE: {
        next(state, { payload: { product } }) {
          return {
            ...state,
            product,
            loading: { ...state.loading, getProduct: false },
            success: { ...state.success, getProduct: true },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            ...state,
            loading: { ...state.loading, getProduct: false },
            success: { ...state.success, getProduct: undefined },
            error: { ...state.error, getProduct: message },
          };
        },
      },
    },
  },
  INITIAL_STATE
);

export default reducer;
