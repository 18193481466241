/* eslint-disable no-param-reassign */
import { Button } from "antd";
import edit from "assets/images/edit.png";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccountActions from "services/account/actions";
import FormSchedule from "./FormSchedule";

const AgentAvailability = () => {
  const dispatch = useDispatch();
  const account = useSelector((store) => store.account);
  const [scheduleRows, setScheduleRows] = useState([
    { id: 1, name: "form1", days: [], startTime: null, endTime: null },
  ]);

  useEffect(() => {
    dispatch(AccountActions.getSchedule());
  }, [dispatch]);

  useEffect(() => {
    if (account.success?.schedules) {
      const scheduleGroups = account.success.schedules.reduce((r, a) => {
        r[`${a.startTime}-${a.endTime}`] = r[`${a.startTime}-${a.endTime}`] || [];
        r[`${a.startTime}-${a.endTime}`].push(a);
        return r;
      }, Object.create(null));
      const actualRows = Object.keys(scheduleGroups).map((keys, i) => ({
        id: i + 1,
        name: `form${i + 1}`,
        days: scheduleGroups[keys].map((schedule) => schedule.day),
        startTime: scheduleGroups[keys][0].startTime,
        endTime: scheduleGroups[keys][0].endTime,
      }));
      setScheduleRows(actualRows);
    }
  }, [account.success]);

  const saveScheduled = () => {
    dispatch(AccountActions.setSchedule(scheduleRows));
  };

  return (
    <div className="my-account__content--section my-account__content--section--end">
      <div className="section-start">
        <h4>Horario de atención</h4>
        <div className="content">
          {scheduleRows?.map((schedule) => (
            <FormSchedule
              key={schedule.id}
              scheduleId={schedule.id}
              setScheduleRows={setScheduleRows}
              scheduleRows={scheduleRows}
            />
          ))}
          <div>
            <Button
              onClick={() => {
                const localScheduleRows = [...scheduleRows];
                localScheduleRows.push({
                  id: localScheduleRows.length + 1,
                  name: `form${localScheduleRows.length + 1}`,
                  days: [],
                  endTime: null,
                  startTime: null,
                });
                setScheduleRows(localScheduleRows);
              }}
              className="add"
            >
              Agregar otro horario
            </Button>
          </div>
        </div>
      </div>
      <Button className="btn-edit" onClick={() => saveScheduled()}>
        <img src={edit} alt="editar" />
      </Button>
    </div>
  );
};

export default AgentAvailability;
