import { Button, Card, Form, Input, message, Popover, Upload } from "antd";
import { bool, func } from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "../../../../../../../assets/icon/profile-inverse.svg";
import arrow from "../../../../../../../assets/images/arrow-green.svg";
import AgentActions from "../../../../../../../services/agent/actions";
import OnboardingActions from "../../../../../../../services/onboarding/actions";
import EditSvgW from "../../../../../../../assets/icon/icon-edit-white.svg";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const InformationEditing = ({ stopEditing, setEdited, saveInformation, setSaveInformation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [myOnSuccess, setMyOnSuccess] = useState();
  const [selectedOffice, setSelectedOffice] = useState();

  const onboarding = useSelector((state) => state.onboarding);
  const agent = useSelector((state) => state.agent);

  const [form] = Form.useForm();

  useEffect(() => {
    if (saveInformation) {
      setSaveInformation(false);
      form.submit();
    }
  }, [saveInformation, form, setSaveInformation]);

  useEffect(() => {
    if (onboarding.success.profile.offices) {
      if (onboarding.success.profile.offices.length === 1)
        setSelectedOffice(onboarding.success.profile.offices[0]);
      if (onboarding.success.profile.offices.length > 1) {
        const visible = onboarding.success.profile.offices.find(
          (office) => office.visible === "Active"
        );
        if (visible) setSelectedOffice(visible);
      }
    }
  }, [onboarding]);

  useEffect(() => {
    if (agent.success.mainInfo) {
      setEdited();
      message.success({
        content: "Información modificada con exito.",
        icon: (
          <svg
            className="icon-check-svg"
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.83877 5.6076L5.39204 9.16088L14.3405 0.212402L15.4703 1.34218L5.39204 11.4204L0.708984 6.73738L1.83877 5.6076Z"
              fill="#5D30DD"
            />
          </svg>
        ),
      });
      dispatch(AgentActions.cleanSaveMainInfoResponse());
      dispatch(OnboardingActions.getProfile(false));
      stopEditing();
    }
  }, [agent, dispatch, setEdited, stopEditing]);

  useEffect(() => {
    if (agent.success.setProfilePhoto && myOnSuccess) {
      myOnSuccess.onSuccess();
    }
  }, [agent, myOnSuccess]);

  const [image, setImage] = useState(undefined);

  const handleImageChange = (info) => {
    getBase64(info.file.originFileObj, (imageUrl) => {
      setImage(imageUrl);
    });
  };

  const uploadImage = async ({ file, onSuccess }) => {
    const data = { profile: file };
    setMyOnSuccess({ onSuccess });
    dispatch(AgentActions.setProfilePhoto(data));
  };

  if (onboarding.success.profile === undefined) return <></>;

  const getSocialNetwork = (socialKey) => {
    const { agentSocialNetworks } = onboarding.success.profile;
    return agentSocialNetworks?.find(
      (socialNetwork) => socialNetwork.socialNetwork.key === socialKey
    )?.value;
  };

  const onFinish = (values) => {
    dispatch(
      AgentActions.saveMainInfo(
        values.name,
        values.tagline,
        values.instagram,
        values.facebook,
        values.twitter,
        values.description,
        image,
        values.linkedin,
        selectedOffice?.id
      )
    );
  };

  const selectOffice = (office) => {
    if (selectedOffice?.id !== office.id) return setSelectedOffice(office);
    return setSelectedOffice(null);
  };

  const uploadButton = (
    <div className="edit-avatar">
      <img
        src={
          onboarding.success.profile?.profilePhoto === ""
            ? Avatar
            : onboarding.success.profile?.profilePhoto
        }
        alt="avatar"
      />
      <div className="edit-avatar__text">
        <p>{t("button.edit")}</p>
      </div>
    </div>
  );

  return (
    <section className="information-editing">
      <Card className="information-editing__cardProfile--desktop">
        <div className="information-editing__cardProfile--desktop__edit">
          <a
            href="./"
            className="btn-edit"
            onClick={(e) => {
              e.preventDefault();
              stopEditing();
            }}
          >
            <img src={EditSvgW} className="edit" alt="true" />
          </a>
          <a
            href="./"
            className="btn-back"
            onClick={(e) => {
              e.preventDefault();
              stopEditing();
            }}
          >
            <img src={arrow} className="arrow" alt="arrow" />
          </a>
        </div>
        <div className="information-editing__cardProfile--desktop__upload">
          <Upload
            name="profile"
            listType="picture-card"
            onChange={handleImageChange}
            customRequest={uploadImage}
          >
            {!image && uploadButton}
          </Upload>
        </div>
        <Form form={form} name="mainInfo" layout="vertical" onFinish={onFinish}>
          <div className="information-editing__cardProfile--desktop__form">
            <div className="information-editing__cardProfile--desktop__formInfo">
              <h4>{t("label.MyPersonalInformation")}</h4>
              <div className="form-item">
                <Form.Item
                  name="name"
                  initialValue={onboarding.success.profile?.user.person.name}
                  rules={[
                    {
                      message: t("error.name"),
                      required: true,
                    },
                    {
                      max: 50,
                      message: t("error.charactersNameMax"),
                    },
                  ]}
                >
                  <Input placeholder={t("placeholder.inputName")} className="input-form-generic" />
                </Form.Item>
              </div>
              <div className="form-item">
                <Form.Item
                  name="tagline"
                  initialValue={onboarding.success.profile?.tagline}
                  rules={[
                    {
                      message: t("error.name"),
                    },
                    {
                      max: 50,
                      message: t("error.charactersTaglineMax"),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("placeholder.inputTagLine")}
                    className="input-form-generic"
                  />
                </Form.Item>
              </div>
              <h4 className="bio">{t("label.Biography")}</h4>
              <div className="form-item">
                <Form.Item
                  name="description"
                  initialValue={onboarding.success.profile.description}
                  rules={[
                    {
                      message: t("error.name"),
                    },
                    {
                      max: 500,
                      message: t("error.charactersBiographyMax"),
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={5}
                    placeholder={t("placeholder.inputBiography")}
                    className="input-form-generic input-form-generic--textarea"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="information-editing__cardProfile--desktop__formSocial">
              <h4>{t("label.socialNetworksTitle")}</h4>
              <div className="form-item">
                <Form.Item
                  name="instagram"
                  initialValue={getSocialNetwork("instagram")}
                  rules={[
                    {
                      message: t("error.name"),
                    },
                    {
                      max: 256,
                      message: t("error.charactersNameMax"),
                    },
                  ]}
                >
                  <Input
                    placeholder="Instagram URL"
                    className="input-form-generic"
                    addonBefore="instagram.com/"
                    allowClear
                  />
                </Form.Item>
              </div>
              <div className="form-item">
                <Form.Item
                  name="facebook"
                  initialValue={getSocialNetwork("facebook")}
                  rules={[
                    {
                      message: t("error.name"),
                    },
                    {
                      max: 256,
                      message: t("error.charactersSocialNetowkrMax"),
                    },
                  ]}
                >
                  <Input
                    placeholder="Facebook URL"
                    className="input-form-generic"
                    addonBefore="facebook.com/"
                    allowClear
                  />
                </Form.Item>
              </div>
              <div className="form-item">
                <Form.Item
                  name="twitter"
                  initialValue={getSocialNetwork("twitter")}
                  rules={[
                    {
                      message: t("error.name"),
                    },
                    {
                      max: 256,
                      message: t("error.charactersSocialNetowkrMax"),
                    },
                  ]}
                >
                  <Input
                    placeholder="Twitter URL"
                    className="input-form-generic"
                    addonBefore="twitter.com/"
                    allowClear
                  />
                </Form.Item>
              </div>
              <h4 className="bio">{t("label.Addresses")}</h4>
              <div className="form-item">
                <div className="select-button-group">
                  {onboarding.success.profile.offices?.map((address, index) => (
                    <Popover
                      className="popover-Addresses"
                      placement="topLeft"
                      key={address.id}
                      content={address.address}
                    >
                      <Button
                        className={`button-radio ${
                          selectedOffice?.id === address.id && "button-radio--active"
                        } button-radio--${index}`}
                        onClick={() => selectOffice(address)}
                      >
                        <span>{address.name === "" ? address.address : address.name}</span>
                        <svg
                          className="check-svg"
                          width="16"
                          height="12"
                          viewBox="0 0 16 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.83877 5.6076L5.39204 9.16088L14.3405 0.212402L15.4703 1.34218L5.39204 11.4204L0.708984 6.73738L1.83877 5.6076Z"
                            fill="#5D30DD"
                          />
                        </svg>
                      </Button>
                    </Popover>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="information-editing__cardProfile--desktop__buttons">
            <button onClick={() => stopEditing()} className="button button--tertiary" type="button">
              {t("label.omitChanges")}
            </button>
            <button type="submit" className="button button--secundary">
              {t("label.saveChanges")}
            </button>
          </div>
        </Form>
      </Card>
    </section>
  );
};

InformationEditing.propTypes = {
  stopEditing: func.isRequired,
  setEdited: func.isRequired,
  setSaveInformation: func.isRequired,
  saveInformation: bool.isRequired,
};

export default InformationEditing;
