/* eslint-disable no-plusplus */
import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import Loader from "components/loader/index";
import { useSelector } from "react-redux";
import { func, objectOf } from "prop-types";

const DonutGraph = ({ isoptions, series, isListItem, setListItem }) => {
  const loading = useSelector((state) => state.policies.loading.filtersCommissions);

  useEffect(() => {
    if (isoptions?.labels?.length >= 1) {
      for (let index = 0; index < isoptions?.labels.length; index++) {
        isListItem.push({ title: isoptions?.labels[index], color: isoptions?.colors[index] });
      }
    }
    setListItem(isListItem);
  }, [isoptions, isListItem, setListItem]);

  return (
    <div className="DonutGraph">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="DonutGraph__chart">
            {isoptions && (
              <Chart
                options={isoptions}
                series={series}
                type="donut"
                height="880"
                className="grafica"
              />
            )}
          </div>
          <div className="list-items">
            {isListItem?.map((item) => (
              <div className="item" key={item.title}>
                <div className="circle" style={{ background: `${item.color}` }} />
                <p>{item.title}</p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
DonutGraph.propTypes = {
  isoptions: objectOf.isRequired,
  series: objectOf.isRequired,
  isListItem: objectOf.isRequired,
  setListItem: func.isRequired,
};
export default DonutGraph;
