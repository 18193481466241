/* eslint-disable no-else-return */
import moment from "moment";
import { handleActions } from "redux-actions";

export const INITIAL_STATE = {
  filters: {
    IdentificadorCuenta: [],
    Ramos: [0],
    Estado: [0],
    FechaInicial: [moment(new Date()).startOf("month").subtract(1, "y").format("YYYY-MM-DD")],
    FechaFinal: [moment(new Date()).endOf("month").format("YYYY-MM-DD")],
    NoPage: [1],
    PageSize: [30],
    Vencimiento: [0],
    Buscar: undefined,
  },
  filtersOferts: {
    Ramos: [0],
    Estado: [0],
    startDate: [],
    endDate: [],
    NoPage: 1,
    PageSize: [30],
    Vencimiento: [0],
    branch: ["autos"],
    minPremium: undefined,
    maxPremium: undefined,
    quantity: 30,
    order: "ASC",
  },
  filtersCommissions: {
    IdentificadorCuenta: [],
    Ramos: [0],
    FechaInicial: [moment(new Date()).startOf("month").subtract(1, "y").format("YYYY-MM-DD")],
    FechaFinal: [moment(new Date()).endOf("month").format("YYYY-MM-DD")],
  },
  filtersSummary: {
    FechaInicial: [moment(new Date()).startOf("month").subtract(1, "y").format("YYYY-MM-DD")],
    FechaFinal: [moment(new Date()).endOf("month").format("YYYY-MM-DD")],
  },
  filtersOpened: false,
  selectedPolicy: undefined,
  searchValue: "",
  loading: {
    accounts: false,
    nextRenovations: false,
    policies: false,
    lastPolicies: false,
    newAccount: false,
    listOffers: false,
    cover: false,
    deleteAccount: false,
    updateAccount: false,
    updateStatusPolicy: false,
    insurances: false,
    abstract: false,
    detail: false,
    insideLoader: false,
    closeToExpiration: false,
    events: false,
    coverUpload: false,
    filtersCommissions: false,
    commissions: false,
    summary: false,
    insuranceAccountStatus: false,
  },
  error: {
    commissions: false,
    accounts: undefined,
    listOffers: false,
    nextRenovations: undefined,
    policies: undefined,
    lastPolicies: undefined,
    cover: false,
    newAccount: false,
    deleteAccount: false,
    updateAccount: false,
    updateStatusPolicy: false,
    insurances: false,
    abstract: false,
    detail: false,
    closeToExpiration: false,
    events: false,
    coverUpload: false,
    summary: false,
    insuranceAccountStatus: false,
  },
  success: {
    accounts: false,
    nextRenovations: false,
    policies: false,
    lastPolicies: null,
    newAccount: false,
    cover: undefined,
    listOffers: false,
    commissions: false,
    deleteAccount: false,
    updateAccount: false,
    updateStatusPolicy: false,
    insurances: null,
    abstract: null,
    detail: null,
    actualSection: 0,
    morePages: -1,
    searched: false,
    closeToExpiration: false,
    events: null,
    coverUpload: false,
    summary: false,
    insuranceAccountStatus: null,
  },
  styleDark: false,
};

const reducer = handleActions(
  {
    POLICIES: {
      STYLE_DARK: (state) => ({
        ...state,
      }),
      STYLE_DARK_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            styleDark: payload.styleDark,
            error: INITIAL_STATE.error,
          };
        },
      },
      GET_OFFERS: (state) => ({
        ...state,
        loading: { ...state.loading, listOffers: true },
        error: { ...state.error, listOffers: undefined },
        success: { ...state.success, listOffers: undefined },
      }),
      GET_OFFERS_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, listOffers: false },
            success: {
              ...state.success,
              listOffers: payload?.offers?.data,
              appetites: payload?.offers?.appetites,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, listOffers: message },
            success: { ...state.success, listOffers: undefined },
            loading: { ...state.loading, listOffers: false },
          };
        },
      },

      GET_COMMISSIONS_SUMMARY: (state) => ({
        ...state,
        loading: { ...state.loading, summary: true },
        error: { ...state.error, summary: undefined },
        success: { ...state.success, summary: undefined },
      }),
      GET_COMMISSIONS_SUMMARY_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, summary: false },
            success: {
              ...state.success,
              summary: payload?.summary,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, summary: message },
            success: { ...state.success, summary: undefined },
            loading: { ...state.loading, summary: false },
          };
        },
      },

      SET_FILTERS_COMMISSIONS_SUMMARY: (state, { payload: { key, value, secondValue } }) => {
        if (key === "clean_fecha")
          return {
            ...state,
            filtersSummary: {
              ...state.filtersSummary,
              FechaInicial: [
                moment(new Date()).startOf("month").subtract(1, "year").format("YYYY-MM-01"),
              ],
              FechaFinal: [moment(new Date()).endOf("month").format("YYYY-MM-DD")],
            },
            loading: {
              ...state.loading,
              filtersSummary: true,
            },
          };
        if (key === "Fechas") {
          return {
            ...state,
            filtersSummary: {
              ...state.filtersSummary,
              FechaInicial: [value],
              FechaFinal: [secondValue],
            },
            loading: {
              ...state.loading,
              filtersSummary: true,
            },
          };
        }
        let actualFilters = state.filtersSummary[key];
        if (key === "NoPage") {
          actualFilters = [key === "NoPage" ? actualFilters[0] + 1 : value];
        } else {
          const filterIndex = actualFilters.findIndex((filter) => filter === value);
          if (filterIndex > -1) actualFilters.splice(filterIndex, 1);
          else actualFilters.push(value);
        }
        return {
          ...state,
          filtersSummary: {
            ...state.filtersSummary,
            [key]: actualFilters,
          },
          loading: { ...state.loading, filtersSummary: true },
        };
      },

      GET_ALL_ACCOUNTS: (state) => ({
        ...state,
        loading: { ...state.loading, accounts: true },
        error: { ...state.error, accounts: undefined },
      }),
      GET_ALL_ACCOUNTS_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, accounts: false },
            success: {
              ...state.success,
              accounts: payload.accounts,
              insurances: payload.insurances,
            },
            error: { ...state.error, accounts: false },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, accounts: message },
            success: { ...state.success, accounts: undefined },
            loading: { ...state.loading, accounts: false },
          };
        },
      },
      GET_COVER: (state) => ({
        ...state,
        loading: { ...state.loading, cover: true },
        error: { ...state.error, cover: undefined },
      }),
      GET_COVER_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, cover: false },
            success: {
              ...state.success,
              cover: payload.cover,
            },
            error: { ...state.error, cover: false },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, cover: message },
            success: { ...state.success, cover: undefined },
            loading: { ...state.loading, cover: false },
          };
        },
      },
      GET_NEXT_RENOVATIONS: (state) => ({
        ...state,
        loading: { ...state.loading, nextRenovations: true },
        error: { ...state.error, nextRenovations: undefined },
      }),
      GET_NEXT_RENOVATIONS_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, nextRenovations: false },
            success: {
              ...state.success,
              nextRenovations: payload.nextRenovations,
            },
            error: { ...state.error, nextRenovations: false },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, nextRenovations: message },
            success: { ...state.success, nextRenovations: undefined },
            loading: { ...state.loading, nextRenovations: false },
          };
        },
      },

      CLEAN_ALL_ACCOUNTS_RESPONSE: (state) => ({
        ...state,
        loading: { ...state.loading, accounts: true },
        error: { ...state.error, accounts: undefined },
      }),
      GET_ALL_POLICIES: (state) => ({
        ...state,
        loading: { ...state.loading, policies: true },
        error: { ...state.error, policies: undefined },
      }),
      GET_ALL_POLICIES_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, policies: false, insideLoader: false },
            success: {
              ...state.success,
              policies: payload.policies,
              morePages: payload.pages,
              lastPolicies: null,
              searched: !!state.filters.Buscar,
            },
            error: { ...state.error, policies: false },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, policies: message },
            success: { ...state.success, policies: undefined },
            loading: { ...state.loading, policies: false, insideLoader: false },
          };
        },
      },
      CLEAN_ALL_POLICIES_RESPONSE: (state) => ({
        ...state,
        loading: { ...state.loading, policies: false, insideLoader: false },
        error: { ...state.error, policies: undefined },
      }),

      GET_ALL_INSURANCES: (state) => ({
        ...state,
        loading: { ...state.loading, insurances: true },
        error: { ...state.error, insurances: undefined },
      }),
      GET_ALL_INSURANCES_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, insurances: false },
            success: { ...state.success, insurances: payload },
            error: { ...state.error, insurances: false },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, insurances: message },
            success: { ...state.success, insurances: undefined },
            loading: { ...state.loading, insurances: false },
          };
        },
      },
      GET_ALL_COMISSIONS: (state) => ({
        ...state,
        loading: { ...state.loading, comissionsTotal: true },
        error: { ...state.error, comissionsTotal: undefined },
      }),
      GET_ALL_COMISSIONS_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, comissionsTotal: false },
            success: { ...state.success, comissionsTotal: payload },
            error: { ...state.error, comissionsTotal: false },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, comissionsTotal: message },
            success: { ...state.success, comissionsTotal: undefined },
            loading: { ...state.loading, comissionsTotal: false },
          };
        },
      },
      CLEAN_ALL_INSURANCES_RESPONSE: (state) => ({
        ...state,
        loading: { ...state.loading, policies: true },
        error: { ...state.error, policies: undefined },
      }),

      GET_COMMISSIONS: (state) => ({
        ...state,
        loading: { ...state.loading, commissions: true },
        error: { ...state.error, commissions: undefined },
      }),
      GET_COMMISSIONS_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, commissions: false, filtersCommissions: false },
            success: { ...state.success, commissions: payload },
            error: { ...state.error, commissions: false },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, commissions: message },
            success: { ...state.success, commissions: undefined },
            loading: { ...state.loading, commissions: false, filtersCommissions: false },
          };
        },
      },
      CLEAR_COMMISSIONS_RESPONSE: (state) => ({
        ...state,
        loading: { ...state.loading, commissions: true },
        error: { ...state.error, commissions: undefined },
      }),

      GET_INSURANCE_ACCOUNT_STATUS: (state) => ({
        ...state,
        loading: { ...state.loading, insuranceAccountStatus: true },
        error: { ...state.error, insuranceAccountStatus: undefined },
      }),
      GET_INSURANCE_ACCOUNT_STATUS_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, insuranceAccountStatus: false },
            success: { ...state.success, insuranceAccountStatus: payload },
            error: { ...state.error, insuranceAccountStatus: false },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, insuranceAccountStatus: message },
            success: { ...state.success, insuranceAccountStatus: undefined },
            loading: { ...state.loading, insuranceAccountStatus: false },
          };
        },
      },
      CLEAR_INSURANCE_ACCOUNT_STATUS_RESPONSE: (state) => ({
        ...state,
        loading: { ...state.loading, insuranceAccountStatus: true },
        error: { ...state.error, insuranceAccountStatus: undefined },
      }),

      REGISTER_ACCOUNT: (state) => ({
        ...state,
        loading: { ...state.loading, newAccount: true },
        error: { ...state.error, newAccount: undefined },
      }),
      REGISTER_ACCOUNT_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, newAccount: false },
            success: { ...state.success, newAccount: true },
            error: { ...state.error, newAccount: false },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, newAccount: message },
            success: { ...state.success, newAccount: undefined },
            loading: { ...state.loading, newAccount: false },
          };
        },
      },
      CLEAN_REGISTER_ACCOUNT: (state) => ({
        ...state,
        success: { ...state.success, newAccount: false },
        loading: { ...state.loading, newAccount: false },
        error: { ...state.error, newAccount: undefined },
      }),

      UPDATE_ACCOUNT: (state) => ({
        ...state,
        loading: { ...state.loading, updateAccount: true },
        error: { ...state.error, updateAccount: undefined },
      }),
      UPDATE_ACCOUNT_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, updateAccount: false },
            success: { ...state.success, updateAccount: true },
            error: { ...state.error, updateAccount: false },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, updateAccount: message },
            success: { ...state.success, updateAccount: undefined },
            loading: { ...state.loading, updateAccount: false },
          };
        },
      },
      CLEAN_UPDATE_ACCOUNT: (state) => ({
        ...state,
        success: { ...state.success, updateAccount: false },
        loading: { ...state.loading, updateAccount: false },
        error: { ...state.error, updateAccount: undefined },
      }),

      UPDATE_STATUS_POLICY: (state) => ({
        ...state,
        loading: { ...state.loading, updateStatusPolicy: true },
        error: { ...state.error, updateStatusPolicy: undefined },
      }),
      UPDATE_STATUS_POLICY_RESPONSE: {
        next(state, { payload }) {
          if (state.success.detail) {
            const detailPolicy = [...state.success.detail.General];
            const indexPolicyUpdate = state.success.detail.General.findIndex(
              (policy) => policy.NumPolicy === payload.id
            );
            if (indexPolicyUpdate > -1) {
              detailPolicy[indexPolicyUpdate].StatusPolicy = payload.newStatus;
            }
          }
          if (state.success.policies) {
            const actualPolicies = [...state.success.policies];
            const indexPolicyUpdate = state.success.policies.findIndex(
              (policy) => policy.NumberPolicy === payload.id
            );
            if (indexPolicyUpdate > -1) {
              actualPolicies[indexPolicyUpdate].StatusPolicy = payload.newStatus;
            }
            return {
              ...state,
              loading: { ...state.loading, updateStatusPolicy: false },
              success: { ...state.success, updateStatusPolicy: true, policies: actualPolicies },
              error: { ...state.error, updateStatusPolicy: false },
            };
          }
          return state;
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, updateStatusPolicy: message },
            success: { ...state.success, updateStatusPolicy: undefined },
            loading: { ...state.loading, updateStatusPolicy: false },
          };
        },
      },
      CLEAN__STATUS_POLICY: (state) => ({
        ...state,
        success: { ...state.success, updateAccount: false },
        loading: { ...state.loading, updateAccount: false },
        error: { ...state.error, updateAccount: undefined },
      }),

      GET_ABSTRACT: (state) => ({
        ...state,
        loading: { ...state.loading, abstract: true },
        error: { ...state.error, abstract: undefined },
      }),
      GET_ABSTRACT_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, abstract: false },
            success: {
              ...state.success,
              abstract: payload,
            },
            error: { ...state.error, abstract: false },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, abstract: message },
            success: { ...state.success, abstract: undefined },
            loading: { ...state.loading, abstract: false },
          };
        },
      },
      CLEAN_ABSTRACT_RESPONSE: (state) => ({
        ...state,
        loading: { ...state.loading, abstract: true },
        error: { ...state.error, abstract: undefined },
      }),

      GET_LAST_POLICIES: (state) => ({
        ...state,
        loading: { ...state.loading, policies: true },
        error: { ...state.error, policies: undefined },
      }),
      CLEAN_LAST_POLICIES_RESPONSE: (state) => ({
        ...state,
        loading: { ...state.loading, lastPolicies: true },
        error: { ...state.error, lastPolicies: undefined },
      }),

      SET_FILTERS: (state, { payload: { key, value, secondValue } }) => {
        if (key === "Buscar")
          return {
            ...state,
            filters: {
              ...state.filters,
              Buscar: value,
            },
            loading: {
              ...state.loading,
              policies: true,
            },
          };
        if (key === "clean_Estado")
          return {
            ...state,
            filters: {
              ...state.filters,
              Estado: [0],
              NoPage: [1],
            },
            loading: {
              ...state.loading,
              policies: true,
            },
          };
        if (key === "clean_fecha")
          return {
            ...state,
            filters: {
              ...state.filters,
              FechaInicial: [
                moment(new Date()).startOf("month").subtract(1, "year").format("YYYY-MM-01"),
              ],
              FechaFinal: [moment(new Date()).endOf("month").format("YYYY-MM-DD")],
              NoPage: [1],
            },
            loading: {
              ...state.loading,
              policies: true,
            },
          };
        if (key === "FechasVencimiento")
          return {
            ...state,
            filters: {
              ...state.filters,
              FechaInicial: [value],
              FechaFinal: [secondValue],
              Vencimiento: [1],
              NoPage: [1],
            },
            loading: {
              ...state.loading,
              policies: true,
            },
          };
        if (key === "Fechas")
          return {
            ...state,
            filters: {
              ...state.filters,
              FechaInicial: [value],
              FechaFinal: [secondValue],
              Vencimiento: [0],
              NoPage: [1],
            },
            loading: {
              ...state.loading,
              policies: true,
            },
          };
        let actualFilters = state.filters[key];
        if (key === "NoPage") {
          actualFilters = [key === "NoPage" ? actualFilters[0] + 1 : value];
        } else {
          const filterIndex = actualFilters.findIndex((filter) => filter === value);
          if (filterIndex > -1) actualFilters.splice(filterIndex, 1);
          else actualFilters.push(value);
        }

        return {
          ...state,
          filters: {
            ...state.filters,
            [key]: actualFilters,
            NoPage: key !== "NoPage" ? [1] : actualFilters,
          },
          loading: { ...state.loading, policies: key !== "NoPage", insideLoader: key === "NoPage" },
        };
      },

      SET_FILTERS_COMMISSIONS: (state, { payload: { key, value, secondValue } }) => {
        if (key === "clean_fecha")
          return {
            ...state,
            filtersCommissions: {
              ...state.filtersCommissions,
              FechaInicial: [
                moment(new Date()).startOf("month").subtract(1, "year").format("YYYY-MM-01"),
              ],
              FechaFinal: [moment(new Date()).endOf("month").format("YYYY-MM-DD")],
            },
            loading: {
              ...state.loading,
              filtersCommissions: true,
            },
          };
        if (key === "Fechas") {
          return {
            ...state,
            filtersCommissions: {
              ...state.filtersCommissions,
              FechaInicial: [value],
              FechaFinal: [secondValue],
            },
            loading: {
              ...state.loading,
              filtersCommissions: true,
            },
          };
        }
        let actualFilters = state.filtersCommissions[key];
        if (key === "NoPage") {
          actualFilters = [key === "NoPage" ? actualFilters[0] + 1 : value];
        } else {
          const filterIndex = actualFilters.findIndex((filter) => filter === value);
          if (filterIndex > -1) actualFilters.splice(filterIndex, 1);
          else actualFilters.push(value);
        }
        return {
          ...state,
          filtersCommissions: {
            ...state.filtersCommissions,
            [key]: actualFilters,
          },
          loading: { ...state.loading, filtersCommissions: true },
        };
      },

      SET_SELECTED_POLICY: (state, { payload }) => ({
        ...state,
        selectedPolicy: payload,
      }),

      GET_DETAIL: (state) => ({
        ...state,
        loading: { ...state.loading, detail: true },
        error: { ...state.error, detail: undefined },
      }),
      GET_DETAIL_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, detail: false },
            success: {
              ...state.success,
              detail: payload,
            },
            error: { ...state.error, detail: false },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, detail: message },
            success: { ...state.success, detail: undefined },
            loading: { ...state.loading, detail: false },
          };
        },
      },
      CLEAN_DETAIL_RESPONSE: (state) => ({
        ...state,
        success: { ...state.success, detail: undefined },
        loading: { ...state.loading, detail: false },
        error: { ...state.error, detail: undefined },
      }),

      SEARCH_POLICIES_BY_KEY: (state) => ({
        ...state,
        loading: { ...state.loading, policies: true },
        error: { ...state.error, policies: undefined },
      }),
      SEARCH_POLICIES_BY_KEY_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, policies: false },
            success: {
              ...state.success,
              policies: payload,
              allPolicies: payload,
              actualSection: 1,
              lastPolicies: null,
              searched: true,
            },
            error: { ...state.error, policies: false },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, policies: message },
            success: { ...state.success, policies: undefined },
            loading: { ...state.loading, policies: false },
          };
        },
      },
      CLEAN_SEARCH_POLICIES_BY_KEY_RESPONSE: (state) => ({
        ...state,
        success: { ...state.success, searched: false },
        loading: { ...state.loading, policies: false },
        error: { ...state.error, policies: undefined },
      }),

      DELETE_ACCOUNT: (state) => ({
        ...state,
        loading: {
          ...state.loading,
          deleteAccount: true,
        },
      }),
      DELETE_ACCOUNT_RESPONSE: {
        next(state) {
          return {
            ...state,
            success: {
              ...state.success,
              deleteAccount: true,
            },
            loading: {
              ...state.loading,
              deleteAccount: false,
            },
          };
        },
        throw(state) {
          return {
            ...state,
            error: {
              ...state.error,
              deleteAccount: true,
            },
          };
        },
      },

      SHOW_MORE_POLICIES: (state) => {
        const actualPolicies = [
          ...state.success.policies,
          ...state.success.allPolicies.slice(
            state.success.actualSection * 10,
            (state.success.actualSection + 1) * 10
          ),
        ];

        return {
          ...state,
          success: {
            ...state.success,
            policies: actualPolicies,
            actualSection: state.success.actualSection + 1,
          },
        };
      },

      SET_SEARCH_VALUE: (state, { payload }) => {
        return {
          ...state,
          searchValue: payload,
          success: {
            ...state.success,
            searched: false,
          },
        };
      },
      MODIFY_FILTERS_OPENED: (state) => {
        return {
          ...state,
          filtersOpened: !state.filtersOpened,
        };
      },

      GET_CLOSE_TO_EXPIRATION: (state) => ({
        ...state,
        loading: { ...state.loading, closeToExpiration: true },
        error: { ...state.error, closeToExpiration: undefined },
      }),
      GET_CLOSE_TO_EXPIRATION_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, closeToExpiration: false },
            success: {
              ...state.success,
              closeToExpiration: payload,
            },
            error: { ...state.error, closeToExpiration: false },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, closeToExpiration: message },
            success: { ...state.success, closeToExpiration: undefined },
            loading: { ...state.loading, closeToExpiration: false },
          };
        },
      },

      GET_MONTH_EVENTS: (state) => ({
        ...state,
        loading: { ...state.loading, events: true },
        error: { ...state.error, events: undefined },
      }),
      GET_MONTH_EVENTS_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, events: false },
            success: {
              ...state.success,
              events: payload,
            },
            error: { ...state.error, events: false },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, events: message },
            success: { ...state.success, events: undefined },
            loading: { ...state.loading, events: false },
          };
        },
      },

      UPLOAD_COVER: (state) => ({
        ...state,
        loading: { ...state.loading, coverUpload: true },
        error: { ...state.error, coverUpload: undefined },
      }),
      UPLOAD_COVER_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, coverUpload: false },
            success: {
              ...state.success,
              coverUpload: true,
            },
            error: { ...state.error, coverUpload: false },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, coverUpload: message },
            success: { ...state.success, coverUpload: undefined },
            loading: { ...state.loading, coverUpload: false },
          };
        },
      },
      CLEAN_UPLOAD_COVER_RESPONSE: (state) => ({
        ...state,
        success: { ...state.success, coverUpload: false },
        loading: { ...state.loading, coverUpload: false },
        error: { ...state.error, coverUpload: undefined },
      }),
    },
  },
  INITIAL_STATE
);

export default reducer;
