/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import showTranslatedText from "common/utils/showTranslatedText";
import { func, instanceOf } from "prop-types";
import React from "react";
import { getI18n } from "react-i18next";

const ProductLineSelection = ({
  productLinesSelected,
  selectProductLine,
  inEditionAddress,
  newAddress,
}) => {
  const isProductLinesSelected = (productLine) => {
    const toValidate = inEditionAddress ? inEditionAddress.office : newAddress;
    return toValidate.productLines?.some(
      (productLineIterable) => productLineIterable.id === productLine.id
    );
  };

  return (
    <div className="offices-products__wrapper">
      {productLinesSelected?.map((product) => (
        <div
          key={product.id}
          onClick={() => selectProductLine(product)}
          className={`product${isProductLinesSelected(product) ? "--active" : "--disabled"}`}
        >
          {isProductLinesSelected(product) ? (
            <img src={product.iconWhite} alt={product.iconWhite} />
          ) : (
            <img src={product.icon} alt={product.icon} />
          )}
          <span>{showTranslatedText(product.title, getI18n().language)}</span>
        </div>
      ))}
    </div>
  );
};

ProductLineSelection.propTypes = {
  productLinesSelected: instanceOf(Object),
  selectProductLine: func.isRequired,
  inEditionAddress: instanceOf(Object),
  newAddress: instanceOf(Object),
};

ProductLineSelection.defaultProps = {
  productLinesSelected: null,
  inEditionAddress: null,
  newAddress: null,
};

export default ProductLineSelection;
