/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Card } from "antd";
import { func } from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import back from "../../../../../../../../../assets/icon/icon-back.svg";
import AgentActions from "../../../../../../../../../services/agent/actions";
import VerifyAddress from "../VerifyAddress/VerifyAddress";
import VerifyOfficeData from "../VerifyOfficeData/VerifyOfficeData";

const AddOfficeManually = ({ goBack, saveOffice }) => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("tab1");
  const [address, setAddress] = useState({});

  const save = (values) => {
    dispatch(AgentActions.createOffice({ ...address, ...values }));
    saveOffice({ ...values, ...address });
  };

  return (
    <section className="offices-editing">
      <Card className="offices-editing__card">
        <div className="offices-editing__cardHeader">
          <h4>Mi negocio</h4>
        </div>
        <div className="wrapper">
          <div className="offices-editing__cardBack">
            <a
              href="./"
              onClick={(e) => {
                e.preventDefault();
                goBack();
              }}
            >
              <img src={back} alt="back" />
            </a>
            <h6>Adicionar dirección manualmente</h6>
          </div>
          <div className="offices-editing__cardTabs">
            <div
              id="tab1"
              className="tab--active"
              onClick={() => {
                setActiveTab("tab1");
              }}
            />
            <div
              id="tab2"
              className={`tab${activeTab === "tab2" ? "--active" : "--disabled"}`}
              onClick={() => {
                setActiveTab("tab2");
              }}
            />
          </div>
          {activeTab === "tab1" && (
            <VerifyAddress
              setAddress={(addressProp) => {
                setAddress(addressProp);
                setActiveTab("tab2");
              }}
            />
          )}
          {activeTab === "tab2" && <VerifyOfficeData saveOffice={save} />}
        </div>
      </Card>
    </section>
  );
};

AddOfficeManually.propTypes = {
  goBack: func.isRequired,
  saveOffice: func.isRequired,
};

export default AddOfficeManually;
