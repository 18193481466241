/* eslint-disable no-console */
import { Select } from "antd";
import React from "react";
import car from "../../../assets/images/leaf-empty.svg";
import hoja from "../../../assets/icon/Hojaberry.svg";
import metricks from "../../../assets/icon/metricks.svg";
import TableLinePoint from "./TableLinePoint/TableLinePoint";

const { Option } = Select;

const Reports = () => {
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <div className="Reports">
      <div className="Reports-content">
        <div className="Reports-content__head">
          <div className="Reports-content__head--title">
            <div className="circle">
              <img src={metricks} alt="metricks" className="metricks" />
            </div>
            <h3 className="sub-title">Reportes</h3>
          </div>
          <div>
            <Select
              placeholder="Cambiar gráfico"
              style={{ width: 150 }}
              className="select"
              onChange={handleChange}
            >
              <Option value="jack">Jack</Option>
              <Option value="lucy">Lucy</Option>
              <Option value="Yiminghe">yiminghe</Option>
            </Select>
            <Select
              placeholder="Tiempo global: 1 Mes"
              style={{ width: 150 }}
              className="select"
              onChange={handleChange}
            >
              <Option value="jack">Jack</Option>
              <Option value="lucy">Lucy</Option>
              <Option value="Yiminghe">yiminghe</Option>
            </Select>
          </div>
        </div>
        <div className="table-metricks-medium">
          <div className="box-table">
            {/* <TableOne /> */}
            <div className="left">
              <h3 className="body-bold">Ventas cerradas</h3>
              <Select placeholder="Semana" className="select" onChange={handleChange}>
                <Option value="jack">Semana</Option>
                <Option value="lucy">Semana</Option>
                <Option value="Yiminghe">Semana</Option>
              </Select>
            </div>
            <TableLinePoint width="80%" height="180" />
          </div>
          <div className="box-number">
            <p className="body-bold">Ventas cerradas</p>
            <h2 className="number">0</h2>
          </div>
          <div className="box-number box-number-small">
            <p className="body-bold">Top ramo</p>
            <img src={car} alt="metricks" className="metricks" />
            <h3 className="sub-title">N/A</h3>
            <h4 className="body-regular">0%</h4>
          </div>
        </div>
        <div className="table-metricks-body">
          <div className="content-chart">
            <div className="item-select">
              <p className="body-bold">Ramo</p>
              <Select className="select" defaultValue="1" onChange={handleChange}>
                <Option value="1">1 año</Option>
                <Option value="2">2 años</Option>
                <Option value="3">3 años</Option>
              </Select>
            </div>
            {/* <TableCake /> */}
            <img src={hoja} alt="hoja" className="hoja" />
          </div>
          <div className="content-chart">
            <div className="item-select">
              <p className="body-bold">Pago</p>
              <Select className="select" defaultValue="1" onChange={handleChange}>
                <Option value="1">1 año</Option>
                <Option value="2">2 años</Option>
                <Option value="3">3 años</Option>
              </Select>
            </div>
            <img src={hoja} alt="hoja" className="hoja" />
            {/* <TableLinePoint width="90%" height="180" />
            <div className="tower">
              <p className="body-bold">Moneda</p>
              <div className="tower-table">
                <div className="up">
                  <p>MXP</p>
                  <span>85.5%</span>
                </div>
                <div className="down">
                  <p>USD</p>
                  <span>13.5%</span>
                </div>
              </div>
            </div> */}
          </div>
          <div className="content-chart">
            <div className="item-select">
              <p className="body-bold">Top 5 Aseguradoras en primas (MXP)</p>
              <Select className="select" defaultValue="1" onChange={handleChange}>
                <Option value="1">1 año</Option>
                <Option value="2">2 años</Option>
                <Option value="3">3 años</Option>
              </Select>
            </div>
            {/* <ChartTower /> */}
            <img src={hoja} alt="hoja" className="hoja" />
          </div>
          <div className="content-chart">
            <div className="item-select">
              <p className="body-bold">Aseguradoras</p>
              <Select className="select" defaultValue="1" onChange={handleChange}>
                <Option value="1">1 año</Option>
                <Option value="2">2 años</Option>
                <Option value="3">3 años</Option>
              </Select>
            </div>
            {/* <ChartBar /> */}
            <img src={hoja} alt="hoja" className="hoja" />
          </div>
          <div className="content-chart">
            <div className="item-select">
              <p className="body-bold">Leads generados</p>
              <Select className="select" defaultValue="1" onChange={handleChange}>
                <Option value="1">1 año</Option>
                <Option value="2">2 años</Option>
                <Option value="3">3 años</Option>
              </Select>
            </div>
            {/* <TableLinePoint width="96%" height="190" /> */}
            <img src={hoja} alt="hoja" className="hoja" />
          </div>
          <div className="content-chart">
            <div className="item-select">
              <p className="body-bold">Visitas a tu página web</p>
              <Select className="select" defaultValue="1" onChange={handleChange}>
                <Option value="1">1 año</Option>
                <Option value="2">2 años</Option>
                <Option value="3">3 años</Option>
              </Select>
            </div>
            <img src={hoja} alt="hoja" className="hoja" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
