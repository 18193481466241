import ClockPng from "../../assets/images/pia-brands/Espera.png";
import FailedSvg from "../../assets/images/pia-brands/failed.svg";
import LikePng from "../../assets/images/pia-brands/Listo.png";
import WarningGraySvg from "../../assets/images/pia-brands/warningGray.svg";

const statusList = [
  { id: 1, title: "En Vigor", status: "vigor", icon: ClockPng },
  { id: 2, title: "Cotizada", status: "cancelada", icon: WarningGraySvg },
  { id: 3, title: "Por cotizar", status: "vencida", icon: FailedSvg },
  { id: 4, title: "Renovada", status: "pagada", icon: LikePng },
];
export default statusList;
