/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import { ShoppingOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import showTranslatedText from "common/utils/showTranslatedText";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "react-alice-carousel/lib/alice-carousel.css";
import { getI18n, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MoneySvg from "../../../../assets/images/marketplace/money.svg";
import ReadSvg from "../../../../assets/images/marketplace/read.svg";
import SearchSvg from "../../../../assets/images/marketplace/search.svg";
import VisibilitySvg from "../../../../assets/images/marketplace/visibility.svg";
import CardProduct from "../../../../components/CardProduct/CardProduct";
import Card from "../../../../components/CardProduct/components/Card/Card";
import Loader from "../../../../components/loader";
import MarketplaceActions from "../../../../services/marketplace/actions";

const dataDumyHeader = [
  {
    id: "1",
    image: MoneySvg,
    text: "Cotíza el producto",
  },
  {
    id: "2",
    image: VisibilitySvg,
    text: "Visibilidad en tu perfíl",
  },
  {
    id: "3",
    image: ReadSvg,
    text: "Lee más del producto en la ficha técnica",
  },
  {
    id: "4",
    image: SearchSvg,
    text: "Busca por aseguradora y linea de negocio",
  },
];

const Home = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { specialProducts, products, loading, success } = useSelector((state) => state.marketplace);
  const [listProducts, setListProducts] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2.1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    if (success.setAgentProduct) {
      dispatch(MarketplaceActions.getSpecialProducts());
      dispatch(MarketplaceActions.clearAgentProductResponse());
      if (products.selected !== "all") {
        dispatch(MarketplaceActions.getProductsByInsuranceCarrier(products.selected));
      } else {
        dispatch(MarketplaceActions.getAllProducts());
      }
    }
  }, [success.setAgentProduct, products, dispatch]);

  useEffect(() => {
    if (products[products.selected]) setListProducts(products[products.selected]);
  }, [success, products]);

  useEffect(() => {
    if (products.selected) {
      const elements = document.getElementsByName("insurance");
      elements.forEach((item) => {
        if (item.id === products.selected) {
          item.className = "marketplace__aseguradoras__logo__selected";
        } else {
          item.className = "marketplace__aseguradoras__logo";
        }
      });
    }
  }, [products.selected]);

  return (
    <>
      <Modal
        className="modal-loader"
        visible={loading.setAgentProduct}
        centered
        maskStyle={{ background: "rgba(255, 255, 255, 0.9)" }}
      >
        <Loader />
      </Modal>
      <div className="marketplace">
        <div className="marketplace__header">
          <div className="marketplace__headerTitle">
            <div className="icon">
              <ShoppingOutlined />
            </div>
            <h3>Marketplace</h3>
          </div>
        </div>
        <div className="marketplace__know-more">
          <div className="marketplace__know-moreInfo">
            <p>{t("label.FunctionalitiesOfThe")} Marketplace</p>
            <span>{t("label.textHomeMarketplace")}</span>
          </div>
          <div className="marketplace__know-moreCards">
            {dataDumyHeader?.map((card) => (
              <div className="border-card" key={card.id}>
                <div className="marketplace__know-moreCards__card">
                  <img src={card.image} alt="card" />
                  <p>{card.text}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="marketplace__know-moreCards marketplace__know-moreCards--responsive">
            <Slider {...settings}>
              {dataDumyHeader?.map((card) => (
                <div className="border-card" key={card.id}>
                  <div className="marketplace__know-moreCards__card">
                    <img src={card.image} alt="card" />
                    <p>{card.text}</p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="marketplace__specials">
          <div className="marketplace__specials__header">
            <div className="marketplace__specials__headerTitle">
              <h3>{t("label.MostSelledProducts")}</h3>
            </div>
          </div>
          {specialProducts && (
            <CardProduct type="1/2" cards={specialProducts} dummyCardsRecommendation={[]} />
          )}
        </div>

        {!products.selected && loading.getProductsByInsuranceCarrier ? (
          <Loader width="200px" />
        ) : null}
        <div className="marketplace__list">
          {listProducts.map((category) => (
            <div className="marketplace__categories" key={category.id}>
              <div className="cards-category">
                {category.products?.map((product) => (
                  <div key={product.id}>
                    <div className="marketplace__categories__header">
                      <div className="marketplace__categories__headerTitle">
                        <i className="icon-health" aria-hidden="true" />
                        <h3>{showTranslatedText(category.title, getI18n().language)}</h3>
                      </div>
                    </div>
                    <Card
                      item={{
                        agency:
                          product.insuranceCarrierProducts &&
                          product.insuranceCarrierProducts[0]?.insuranceCarrier,
                        ...product,
                      }}
                      type="1/4"
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

Home.propTypes = {};

export default Home;
