/* eslint-disable react/no-array-index-key */
import React from "react";

const SectionCommissions = () => {
  const dummyTable = [
    { id: 1, title: "Recibo", text: "Concepto" },
    { id: 2, title: "Serie", text: "1234" },
    { id: 3, title: "Remesa", text: "Transferencia" },
    { id: 4, title: "CVE", text: "??" },
    { id: 5, title: "Concepto", text: "Lorem ipsum" },
  ];

  const DummyDataColum = [
    { id: 1, title: "Importe", text: "$9,300" },
    { id: 1, title: "Comisión", text: "$10,700" },
    { id: 1, title: "IVA pagado", text: "$2,500" },
    { id: 1, title: "ISR.R", text: "$2,500" },
    { id: 1, title: "IVA.R", text: "$2,500" },
    { id: 1, title: "Cargo", text: "$2,500" },
    { id: 1, title: "Abono", text: "$2,500" },
  ];

  return (
    <div className="SectionSummaryPia">
      <div className="SectionSummaryPia--table">
        <div className="SectionSummaryPia--table__column">
          <div className="content">
            {dummyTable?.map((item) => (
              <div key={item.id} className="item">
                <div className="item-title">
                  <p>{item.title}</p>
                </div>
                <div className="item-rta">
                  <h4>{item.text}</h4>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="SectionSummaryPia--table__column">
          <div className="content-lines">
            {DummyDataColum?.map((item, index) => (
              <div key={index} className="row">
                <p>{item.title}</p>
                <h4>{item.text}</h4>
              </div>
            ))}
          </div>
        </div>
        <div className="content-total">
          <div className="total">
            <p>Pago de prima total del Recibo 1/4</p>
            <h2 className="h2">$19,890</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionCommissions;
