const selectectablesProps = [
  {
    id: 1,
    name: "Nombre",
  },
  {
    id: 2,
    name: "Apellido",
  },
  {
    id: 3,
    name: "Fecha de nacimiento o de constitución de la empresa",
  },
  {
    id: 4,
    name: "C. postal",
  },
  {
    id: 5,
    name: "Tipo de persona",
  },
  {
    id: 6,
    name: "Correo",
  },
  {
    id: 7,
    name: "Correo secundario",
  },
  {
    id: 8,
    name: "Teléfono",
  },
  {
    id: 9,
    name: "Teléfono secundario",
  },
  {
    id: 10,
    name: "Estado",
  },
  {
    id: 11,
    name: "Póliza",
  },
  {
    id: 12,
    name: "Prima neta",
  },
  {
    id: 13,
    name: "Prima total",
  },
  {
    id: 14,
    name: "Fecha inicio",
  },
  {
    id: 15,
    name: "Fecha final",
  },
  {
    id: 16,
    name: "Producto",
  },
  {
    id: 17,
    name: "Línea de producto",
  },
  {
    id: 18,
    name: "Moneda",
  },
  {
    id: 20,
    name: "Aseguradora",
  },
  {
    id: 21,
    name: "Fecha aplicación",
  },
  {
    id: 22,
    name: "Estatus cobranza",
  },
  {
    id: 23,
    name: "RFC",
  },
];

export default selectectablesProps;
