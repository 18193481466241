import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import AgentActions from "../../../../../../../../../services/agent/actions";
import SearchPlace from "./components/SearchPlace/SearchPlace";
import VerifyData from "./components/VerifyData/VerifyData";

const AddOffice = ({ setAddManually, saveOffice, availableCountries }) => {
  const dispatch = useDispatch();

  const [detailedAddress, setDetailedAddress] = useState();
  const [step, setStep] = useState("search");

  const save = (values) => {
    dispatch(AgentActions.createOffice({ ...values, ...detailedAddress }));
    saveOffice({ ...values, ...detailedAddress });
  };

  const setVerifiedAddress = (values) => {
    setDetailedAddress({ ...detailedAddress, ...values });
  };

  return (
    <>
      {step === "search" && (
        <SearchPlace
          availableCountries={availableCountries}
          setAddManually={setAddManually}
          goToAddOffice={() => setStep("verify")}
          setDetailedAddress={setDetailedAddress}
        />
      )}
      {step === "verify" && (
        <VerifyData
          setVerifiedAddress={setVerifiedAddress}
          saveOffice={save}
          goBack={() => setStep("search")}
          detailedAddress={detailedAddress}
        />
      )}
    </>
  );
};

AddOffice.propTypes = {
  setAddManually: PropTypes.func.isRequired,
  saveOffice: PropTypes.func.isRequired,
  availableCountries: PropTypes.instanceOf(Array).isRequired,
};

export default AddOffice;
