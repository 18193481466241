import React from "react";
import { Button, Select } from "antd";
import back from "../../../../../assets/images/back.svg";
import down from "../../../../../assets/images/down.svg";

const { Option } = Select;

const HeaderReportsPia = () => {
  return (
    <div className="HeaderReportsPia">
      <Button className="button-transparent">
        <img className="back" src={back} alt="back" />
        <span className="btn-text">Regresar a Dashboard PIAA</span>
      </Button>
      <div className="content">
        <h2 className="h2">Generar Reportes</h2>
        <div className="content--left">
          <p className="small-detail">Criterio de búsqueda</p>
          <div className="content--left--seclet">
            <Select suffixIcon={<img src={down} alt="down" className="down" />} defaultValue="jack">
              <Option value="jack">Personal 1</Option>
              <Option value="lucy">Personal 2</Option>
              <Option value="Yiminghe">Personal 2</Option>
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderReportsPia;
