import { Col, Row } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AgentActions from "../../../../../services/agent/actions";
import Experience from "./components/experience/experience";
import ExperienceEditing from "./components/experienceEditing/experienceEditing";
import Information from "./components/information/information";
import InformationEditing from "./components/informationEditing/informationEditing";
import Offices from "./components/offices/offices";
import ProductsEditing from "./components/productsEditing/ProductsEditing";
import Ranking from "./components/ranking/ranking";
import EditMyPageActions from "./services/actions";

const MainAgentInfo = ({ setEdited }) => {
  const dispatch = useDispatch();

  const [saveInformation, setSaveInformation] = useState(false);
  const [saveExperience, setSaveExperience] = useState(false);
  const [saveProducts, setSaveProducts] = useState(false);
  const [saveOffice, setSaveOffice] = useState(false);

  const onboarding = useSelector((state) => state.onboarding);
  const agent = useSelector((state) => state.agent);
  const editPage = useSelector((state) => state.editPage);

  useEffect(() => {
    if (editPage.saveAll) {
      if (editPage.sectionInEdition === "myData" && !saveInformation) {
        setSaveInformation(true);
        dispatch(EditMyPageActions.saveAll(false));
      }
      if (editPage.sectionInEdition === "experience" && !saveExperience) {
        setSaveExperience(true);
        dispatch(EditMyPageActions.saveAll(false));
      }
      if (editPage.sectionInEdition === "products" && !saveProducts) {
        setSaveProducts(true);
        dispatch(EditMyPageActions.saveAll(false));
      }
      if (editPage.sectionInEdition === "office" && !saveOffice) {
        setSaveOffice(true);
        dispatch(EditMyPageActions.saveAll(false));
      }
    }
  }, [editPage, saveInformation, saveExperience, saveOffice, saveProducts, dispatch]);

  useEffect(() => {
    if (agent.autoInsurance) {
      if (!agent.success.insuranceCarriersOrdered)
        dispatch(
          AgentActions.setActualInsuranceCarriersOrdered(
            onboarding.success.profile.insuranceCarrierAgents
          )
        );
      dispatch(EditMyPageActions.changeEditStatus("experience"));
    }
  }, [agent, onboarding, dispatch]);

  return (
    <div className="mainAgentInfo">
      <Row className="container">
        <Col xs={24} sm={24} md={24} lg={17} xl={17} xxl={17}>
          {editPage.sectionVisible.mainInfo && (
            <>
              {editPage.sectionInEdition === "myData" ? (
                <InformationEditing
                  setSaveInformation={setSaveInformation}
                  saveInformation={saveInformation}
                  setEdited={setEdited}
                  stopEditing={() => dispatch(EditMyPageActions.changeEditStatus(undefined))}
                />
              ) : (
                <Information
                  startEditing={() => dispatch(EditMyPageActions.changeEditStatus("myData"))}
                />
              )}
            </>
          )}
          {editPage.sectionVisible.experience && (
            <>
              {editPage.sectionInEdition === "experience" ? (
                <ExperienceEditing
                  setSaveExperience={setSaveExperience}
                  saveExperience={saveExperience}
                  stopEditing={() => dispatch(EditMyPageActions.changeEditStatus(undefined))}
                />
              ) : (
                <Experience
                  startEditing={() => {
                    if (!agent.success.insuranceCarriersOrdered)
                      dispatch(
                        AgentActions.setActualInsuranceCarriersOrdered(
                          onboarding.success.profile.insuranceCarrierAgents
                        )
                      );
                    dispatch(EditMyPageActions.changeEditStatus("experience"));
                  }}
                />
              )}
            </>
          )}
          {editPage.sectionVisible.products && (
            <ProductsEditing saveProducts={saveProducts} setSaveProducts={setSaveProducts} />
          )}
          {editPage.sectionVisible.office && (
            <Offices saveOffice={saveOffice} setSaveOffice={setSaveOffice} />
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}>
          <Ranking />
        </Col>
      </Row>
    </div>
  );
};

MainAgentInfo.propTypes = {
  setEdited: PropTypes.func.isRequired,
};

export default MainAgentInfo;
