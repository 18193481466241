import { Divider } from "antd";
import PropTypes from "prop-types";
import React from "react";

const Tip = ({ tip, t, action }) => (
  <div>
    <div className="ranking-card__itemsItem">
      <h6>{tip.name}</h6>
      <a href="./" className="information-button" onClick={action}>
        {t("button.complete")}
      </a>
    </div>
    <Divider />
  </div>
);

Tip.propTypes = {
  tip: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
};

export default Tip;
