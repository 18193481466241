import { Tabs } from "antd";
import { instanceOf, string } from "prop-types";
import React from "react";
import PiaField from "../PiaField";

const { TabPane } = Tabs;

const SectionCollectionPia = ({ clock, searchedHighlight, policy, highlightDictionary }) => {
  return (
    <div className="SectionSummaryPia">
      {policy.TicketsList?.length < 1 && (
        <div className="empty">
          <span className="h2">Por el momento no tenemos registro de cobranza</span>
        </div>
      )}
      {policy.TicketsList.length > 0 && (
        <div className="SectionSummaryPia--table">
          <Tabs>
            {policy.TicketsList.map((ticket, index) => (
              <TabPane
                tab={
                  <div className="tab-head">
                    <h1>
                      Recibo {index + 1}/{policy.TicketsList.length}
                    </h1>
                    <img src={clock} alt="clock" />
                  </div>
                }
                key={ticket.NumberTicket}
              >
                <div className="SectionSummaryPia--table__column">
                  <div className="content">
                    <div className="item">
                      <div className="item-title">
                        <p
                          className={
                            highlightDictionary.agent.includes(searchedHighlight) ? "highlight" : ""
                          }
                        >
                          Agente
                        </p>
                      </div>
                      <div className="item-rta">
                        <PiaField text={ticket.agent || ""} type="" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="item-title">
                        <p
                          className={
                            highlightDictionary.office.includes(searchedHighlight)
                              ? "highlight"
                              : ""
                          }
                        >
                          Oficina / Promotoría
                        </p>
                      </div>
                      <div className="item-rta">
                        <PiaField text={ticket.office || ""} type="" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="item-title">
                        <p
                          className={
                            highlightDictionary.validity.includes(searchedHighlight)
                              ? "highlight"
                              : ""
                          }
                        >
                          Inicio de vigencia del recibo
                        </p>
                      </div>
                      <div className="item-rta">
                        <PiaField text={ticket.StartValidityDate || ""} type="date" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="item-title">
                        <p
                          className={
                            highlightDictionary.validity.includes(searchedHighlight)
                              ? "highlight"
                              : ""
                          }
                        >
                          Fin de vigencia del recibo
                        </p>
                      </div>
                      <div className="item-rta">
                        <PiaField text={ticket.EndValidityDate || ""} type="date" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="item-title">
                        <p
                          className={
                            highlightDictionary.serie.includes(searchedHighlight) ? "highlight" : ""
                          }
                        >
                          Serie
                        </p>
                      </div>
                      <div className="item-rta">
                        <PiaField text={ticket.Serie || ""} type="" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="item-title">
                        <p
                          className={
                            highlightDictionary.total.includes(searchedHighlight) ? "highlight" : ""
                          }
                        >
                          Importe total del recibo
                        </p>
                      </div>
                      <div className="item-rta">
                        <PiaField text={ticket.AmountTotal || ""} type="price" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="item-title">
                        <p
                          className={
                            highlightDictionary.paymentDeadline.includes(searchedHighlight)
                              ? "highlight"
                              : ""
                          }
                        >
                          Plazo de pago
                        </p>
                      </div>
                      <div className="item-rta">
                        <PiaField text={ticket.TermPayment || ""} type="" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="item-title">
                        <p
                          className={
                            highlightDictionary.paymentDate.includes(searchedHighlight)
                              ? "highlight"
                              : ""
                          }
                        >
                          Fecha de pago
                        </p>
                      </div>
                      <div className="item-rta">
                        <PiaField text={ticket.DatePayment || ""} type="date" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="item-title">
                        <p
                          className={
                            highlightDictionary.invoice.includes(searchedHighlight)
                              ? "highlight"
                              : ""
                          }
                        >
                          Folio / Remesa de aplicación
                        </p>
                      </div>
                      <div className="item-rta">
                        <PiaField text={ticket.Consignment || ""} type="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="SectionSummaryPia--table__column">
                  <div className="columns">
                    <div className="items">
                      <p
                        className={
                          highlightDictionary.link.includes(searchedHighlight) ? "highlight" : ""
                        }
                      >
                        Link para pago en linea
                      </p>
                      <PiaField text={ticket.LinkPaymentOnline || ""} type="" />
                    </div>
                    <div className="content--items">
                      <div className="items">
                        <p
                          className={
                            highlightDictionary.remesa.includes(searchedHighlight)
                              ? "highlight"
                              : ""
                          }
                        >
                          Remesa
                        </p>
                        <PiaField text={ticket.Consignment || ""} type="" />
                      </div>
                      <div className="items">
                        <p
                          className={
                            highlightDictionary.moveType.includes(searchedHighlight)
                              ? "highlight"
                              : ""
                          }
                        >
                          Tipo de movimiento
                        </p>
                        <PiaField text={ticket.TypeMovement || ""} type="" />
                      </div>
                      <div className="items">
                        <p
                          className={
                            highlightDictionary.endorsement.includes(searchedHighlight)
                              ? "highlight"
                              : ""
                          }
                        >
                          Endoso
                        </p>
                        <PiaField text={ticket.Endorsement || ""} type="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="SectionSummaryPia--table__column">
                  <div className="content-lines">
                    <div className="row">
                      <p
                        className={
                          highlightDictionary.prime.includes(searchedHighlight) ? "highlight" : ""
                        }
                      >
                        Importe de la prima
                      </p>
                      <PiaField text={ticket.AmountPremium || ""} type="price" />
                    </div>
                    <div className="row">
                      <p
                        className={
                          highlightDictionary.prime.includes(searchedHighlight) ? "highlight" : ""
                        }
                      >
                        Porcentaje de Comisión
                      </p>
                      <PiaField text={ticket.PercentComission || ""} type="percentage" />
                    </div>
                    <div className="row">
                      <p
                        className={
                          highlightDictionary.comission.includes(searchedHighlight)
                            ? "highlight"
                            : ""
                        }
                      >
                        Comisión
                      </p>
                      <PiaField text={ticket.Comission || ""} type="price" />
                    </div>
                  </div>
                </div>
                <div className="SectionSummaryPia--table__column">
                  <div className="content-lines">
                    <div className="row">
                      <p
                        className={
                          highlightDictionary.tax.includes(searchedHighlight) ? "highlight" : ""
                        }
                      >
                        IVA Pagado
                      </p>
                      <PiaField text={ticket.IvaPayment || ""} type="price" />
                    </div>
                    <div className="row">
                      <p
                        className={
                          highlightDictionary.tax.includes(searchedHighlight) ? "highlight" : ""
                        }
                      >
                        ISR Retenido
                      </p>
                      <PiaField text={ticket.IsrRet || ""} type="price" />
                    </div>
                    <div className="row">
                      <p
                        className={
                          highlightDictionary.tax.includes(searchedHighlight) ? "highlight" : ""
                        }
                      >
                        IVA Retenido
                      </p>
                      <PiaField text={ticket.IvaRet || ""} type="price" />
                    </div>
                    <div className="row">
                      <p
                        className={
                          [
                            ...highlightDictionary.comission,
                            ...highlightDictionary.total,
                            ...highlightDictionary.net,
                          ].includes(searchedHighlight)
                            ? "highlight"
                            : ""
                        }
                      >
                        Comisión Neta Abonos
                      </p>
                      <PiaField text={ticket.CommisionPremiumTotalTicket || ""} type="price" />
                    </div>
                  </div>
                </div>
                <div className="content-total">
                  <div className="total">
                    <p
                      className={
                        [...highlightDictionary.prime, ...highlightDictionary.total].includes(
                          searchedHighlight
                        )
                          ? "highlight"
                          : ""
                      }
                    >
                      Pago de prima total del Recibo {index + 1}/{policy.TicketsList.length}
                    </p>
                    <h2 className="h2">
                      <PiaField text={ticket.PaymentPremiumTotalTicket || ""} type="price" />
                    </h2>
                  </div>
                  <div className="total">
                    <p
                      className={
                        [...highlightDictionary.comission, ...highlightDictionary.total].includes(
                          searchedHighlight
                        )
                          ? "highlight"
                          : ""
                      }
                    >
                      Comisiones Totales del Recibo {index + 1}/{policy.TicketsList.length}
                    </p>
                    <h2 className="h2">
                      <PiaField text={ticket.CommisionPremiumTotalTicket || ""} type="price" />
                    </h2>
                  </div>
                </div>
              </TabPane>
            ))}
          </Tabs>
        </div>
      )}
    </div>
  );
};

SectionCollectionPia.propTypes = {
  clock: string.isRequired,
  searchedHighlight: string.isRequired,
  policy: instanceOf(Object).isRequired,
  highlightDictionary: instanceOf(Object).isRequired,
};

export default SectionCollectionPia;
