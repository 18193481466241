import { CLIENT_HOST } from "../config/config";
import * as Token from "../storage/Token";

const openMyPage = async (endPoint) => {
  const token = await Token.get();

  window.open(`${CLIENT_HOST}agente/preview/${endPoint}?token=${token}`);
};

export default openMyPage;
