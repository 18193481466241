/* eslint-disable react/jsx-props-no-spreading */
import { Button, Col, DatePicker, message, Row } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import IconMail from "assets/icon/icon-mail.svg";
import IconPhone from "assets/icon/icon-phone.svg";
import IconWs from "assets/icon/icon-wa.svg";
import calendar from "assets/images/calendar.png";
import LeadsActions from "services/leads/actions";

const mediums = [
  { name: "email", icon: IconMail },
  { name: "whatsapp", icon: IconWs },
  { name: "phone", icon: IconPhone },
];

function useButtons() {
  const [value, setState] = useState(null);

  const handleClick = (e) => {
    setState(e.currentTarget.id);
  };

  const inputProps = { onClick: handleClick };

  return [value, inputProps];
}

function Detail({ hidden, lead, activity, selectedDate, setSelectedDate }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [buttonValue, buttonProps] = useButtons(null);

  const saveActivity = () => {
    const textarea = document.getElementById("detail-activity");
    if (!textarea.value) return message.error("Ingrese el detalle de la actividad");
    if (!buttonValue) return message.error("Ingrese el medio de la actividad");
    if (!selectedDate) return message.error("Debe ingresar la fecha de la actividad");
    const note = document.getElementById("detail-note");

    let payload = {
      activity: textarea.value,
      medium: buttonValue,
      date: selectedDate,
      lead: +lead.id,
    };

    if (note.value) {
      payload = {
        ...payload,
        notes: [
          {
            value: note.value,
            interaction: 0,
          },
        ],
      };
    }
    hidden();
    dispatch(LeadsActions.createActivity(payload));
    return null;
  };

  const onChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <>
      <Row className="activity-detailsType">
        {mediums.map((medium) => (
          <Col span={8} key={medium.name}>
            <Button
              disabled={!!activity}
              className={`${medium.name} ${
                activity?.medium.key === medium.name || buttonValue === medium.name ? "active" : ""
              }`}
              id={medium.name}
              {...buttonProps}
            >
              <img src={medium.icon} alt="" />
            </Button>
          </Col>
        ))}
      </Row>
      <div className="cont-datepicker" style={{ marginTop: "8px", paddingLeft: "10px" }}>
        <DatePicker prefix={<img src={calendar} alt="calendar" />} onChange={onChange} />
      </div>
      <div className="activity-detailsMessage">
        <h4>Resumen</h4>
        <textarea
          defaultValue={activity ? activity.activity : ""}
          disabled={!!activity}
          id="detail-activity"
          placeholder="Detalle de la actividad"
        />
      </div>
      <div className="activity-buttons">
        {activity ? (
          <Button className="activity-buttons cancel" onClick={() => hidden()}>
            {t("button.close")}
          </Button>
        ) : (
          <>
            <Button className="activity-buttons cancel button" onClick={() => hidden()}>
              {t("button.RuleOut")}
            </Button>
            <Button onClick={() => saveActivity()} className="activity-buttons save button">
              {t("button.updateInsuranceCarriers")}
            </Button>
          </>
        )}
      </div>
    </>
  );
}

Detail.propTypes = {
  hidden: PropTypes.instanceOf(Object).isRequired,
  lead: PropTypes.instanceOf(Object).isRequired,
  activity: PropTypes.instanceOf(Object),
  selectedDate: PropTypes.instanceOf(Object),
  setSelectedDate: PropTypes.func.isRequired,
};

Detail.defaultProps = {
  activity: null,
  selectedDate: null,
};

export default Detail;
