/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { Modal } from "antd";
import Loader from "components/loader";
import leafPng from "assets/images/berry.png";
import PoliciesActions from "services/policies/actions";
import HeaderFilters from "./HeaderFilters/HeaderFilters";
import CardCommissions from "./CardCommissions/CardCommissions";
import CardTotals from "./CardTotals/CardTotals";
import CardBranch from "./CardBranch/CardBranch";

const Empty = (type) => (
  <div className="messaje-empty">
    <img alt="leaf" src={leafPng} className="leaf" />
    {type === "insurers" ? (
      <p>No se hallaron comisiones de Aseguradoras</p>
    ) : (
      <p>No se hallaron comisiones de Ramos</p>
    )}
  </div>
);

const Commissions = () => {
  const [TabActive, setActive] = useState(1);
  const [isBranchesPaid, setBranchesPaid] = useState([]);
  const [isBranchesQuantity, setBranchesQuantity] = useState([]);
  const [isDateCommissions, setDateCommissions] = useState();
  const [isDateBranches, setDateBranches] = useState();
  const styleDark = useSelector((state) => state.policies.styleDark);
  const summary = useSelector((state) => state.policies.success.summary);
  const loading = useSelector((state) => state.policies.loading.summary);
  const policies = useSelector((state) => state.policies);
  const dispatch = useDispatch();
  const [optionSelected, setoptionSelected] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    dispatch(PoliciesActions.getCommissionsSummary());
  }, [dispatch]);
  useEffect(() => {
    setDateCommissions({
      FechaFinal: policies?.filtersCommissions?.FechaFinal,
      FechaInicial: policies?.filtersCommissions?.FechaInicial,
    });
    setDateBranches({
      FechaFinal: policies?.filtersSummary?.FechaFinal,
      FechaInicial: policies?.filtersSummary?.FechaInicial,
    });
  }, [policies]);

  return (
    <div className={`page__commissions ${styleDark && "page__commissions--dark"}`}>
      <Modal
        className="modal-loader"
        visible={loading || policies.loading.insurances || policies?.loading?.commissions}
        centered
        maskStyle={{ backgroundColor: "#ffffff66" }}
      >
        <Loader />
      </Modal>

      <div className="container">
        <div className="column">
          <HeaderFilters
            TabActive={TabActive}
            setActive={setActive}
            optionSelected={optionSelected}
            setoptionSelected={setoptionSelected}
            setBranchesPaid={setBranchesPaid}
            setBranchesQuantity={setBranchesQuantity}
          />
          <div className="column_content">
            <CardTotals
              summary={summary}
              optionSelected={optionSelected}
              setoptionSelected={setoptionSelected}
              isDateCommissions={isDateCommissions}
              isDateBranches={isDateBranches}
            />
            <div className="content-grid">
              {summary?.insurers?.map((item) => (
                <CardCommissions data={item} key={item.key} />
              ))}
            </div>
          </div>
        </div>
        <div className="caroucel-res">
          {loading ? (
            <Loader />
          ) : (
            <>
              <Slider
                {...settings}
                className={`slide-${summary?.insurers?.length >= 1 && "active"}`}
              >
                {TabActive === 1
                  ? summary?.insurers?.map((item) => <CardCommissions data={item} key={item.key} />)
                  : summary?.branches?.map((item) => (
                      <CardCommissions data={item} key={item.branch} type="branch" />
                    ))}
              </Slider>
              {TabActive === 1
                ? summary?.insurers?.length <= 0 && <Empty type="insurers" />
                : summary?.branches?.length <= 0 && <Empty type="branches" />}
            </>
          )}
        </div>
        <div className="column column-rt">
          <CardBranch
            branches={summary?.branches}
            isBranchesPaid={isBranchesPaid}
            setBranchesPaid={setBranchesPaid}
            setBranchesQuantity={setBranchesQuantity}
            isBranchesQuantity={isBranchesQuantity}
            isDateBranches={isDateBranches}
          />
        </div>
      </div>
    </div>
  );
};

export default Commissions;
