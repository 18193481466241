/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { instanceOf } from "prop-types";
import React from "react";

const InsuranceCarrierSelection = ({
  insuranceCarriers,
  selectInsuranceCarrier,
  inEditionAddress,
  newAddress,
}) => {
  const isInsuranceCarrierSelected = (insuranceCarrier) => {
    const toValidate = inEditionAddress ? inEditionAddress.office : newAddress;

    return toValidate.insuranceCarriers?.some((insurance) => insurance.id === insuranceCarrier.id);
  };

  return (
    <div className="offices-products__wrapper">
      {insuranceCarriers?.map((insuranceCarrier) => (
        <div
          key={insuranceCarrier.id}
          onClick={() => selectInsuranceCarrier(insuranceCarrier)}
          className={`product${
            isInsuranceCarrierSelected(insuranceCarrier) ? "--active" : "--disabled"
          }`}
        >
          <img
            src={
              isInsuranceCarrierSelected(insuranceCarrier)
                ? insuranceCarrier.logoWhite
                : insuranceCarrier.logo
            }
            alt="insurance"
          />
        </div>
      ))}
    </div>
  );
};

InsuranceCarrierSelection.propTypes = {
  insuranceCarriers: instanceOf(Array),
  selectInsuranceCarrier: instanceOf(Object),
  inEditionAddress: instanceOf(Object),
  newAddress: instanceOf(Object),
};

InsuranceCarrierSelection.defaultProps = {
  insuranceCarriers: null,
  selectInsuranceCarrier: null,
  inEditionAddress: null,
  newAddress: null,
};

export default InsuranceCarrierSelection;
