/* eslint-disable jsx-a11y/label-has-associated-control */
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Form, InputNumber } from "antd";
import { func, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { GoogleLogin } from "react-google-login";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import store from "../../../../../../../../../assets/images/store.png";
import AgentActions from "../../../../../../../../../services/agent/actions";

const lengthInputs = 5;
const arrayInputs = Array.from(Array(lengthInputs).keys());
const inputs = {};

const SetGmbCode = ({ selectedOffice, setView }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [wasCompleted, setWasCompleted] = useState(false);
  const agent = useSelector((state) => state.agent);
  const { t } = useTranslation();

  const onChangeInput = (number, item) => {
    form.setFieldsValue({ [`number${item}`]: number.substr(number.length - 1) });
    if (item + 1 < lengthInputs && number !== "") inputs[`number${item + 1}`].input.focus();
  };

  useEffect(() => {
    if (agent.success.sendGmbVerificationCode) setWasCompleted(true);
  }, [agent]);

  const ref = React.createRef();

  const sendVerify = async (googleValues) => {
    const formValues = form.getFieldsValue();
    dispatch(
      AgentActions.sendGmbVerificationCode({
        selectedOffice,
        googleValues,
        formValues,
      })
    );
    form.setFieldsValue({
      code1: "",
      code2: "",
      code3: "",
      code4: "",
      code5: "",
    });
  };

  return (
    <section className="offices-editing">
      <Card className="offices-editing__card">
        <div className="wrapper">
          <h4>Mi negocio</h4>
          <Button className="goBack" onClick={setView}>
            <ArrowLeftOutlined />
          </Button>
          <div className="wrapper__cont">
            <div className="wrapper__card">
              <h3>{selectedOffice.name}</h3>
              <p>{t("label.PRIVATEUNION")} 10</p>
              <p>COL. AGRÍCOLA PANTITLÁN, IZTACALCO</p>
              <p>
                {selectedOffice.contact} {selectedOffice.city}, {selectedOffice.areaLevel}
              </p>
              <p>{selectedOffice.city}</p>
              <div className="state">
                <img alt="store" src={store} />
                {selectedOffice && selectedOffice.verified === "Processing" && (
                  <h5>{t("InProgress")}</h5>
                )}
              </div>
            </div>
            <Form name="horizontal_login" layout="vertical" form={form}>
              <div className="offices-editing__cardHeader">
                <h4>{t("label.EnterVerificationCode")}</h4>
              </div>
              <div className="form-inputs">
                {arrayInputs?.map((item) => (
                  <Form.Item
                    className="form-inputs__item"
                    name={`number${item}`}
                    ref={ref}
                    key={`number${item}`}
                    rules={[{ required: true, message: "*" }]}
                  >
                    <InputNumber
                      min={0}
                      max={9}
                      onChange={(number) => onChangeInput(`${number}`, item)}
                      ref={(c) => {
                        inputs[`number${item}`] = c;
                      }}
                      className="input-code"
                      placeholder=""
                    />
                  </Form.Item>
                ))}
              </div>

              {!wasCompleted ? (
                <Form.Item>
                  <GoogleLogin
                    render={(renderProp) => (
                      <Button onClick={renderProp.onClick} className="button--secondary">
                        {t("button.VerifyAndAuthorize")}
                      </Button>
                    )}
                    clientId="1028744019107-1vart3v5185at3cmqaba84mldm3ts2ng.apps.googleusercontent.com"
                    buttonText="Login"
                    scope="https://www.googleapis.com/auth/business.manage"
                    onSuccess={sendVerify}
                    onFailure={sendVerify}
                    cookiePolicy="single_host_origin"
                  />
                </Form.Item>
              ) : (
                <div>
                  <label>{t("label.gmbVerified")}</label>
                  <p>{t("label.gmbVerifiedDescription")}</p>
                  <button type="button">{t("button.understand")}</button>
                </div>
              )}
            </Form>
          </div>
        </div>
      </Card>
    </section>
  );
};

SetGmbCode.propTypes = {
  selectedOffice: string.isRequired,
  setView: func.isRequired,
};

export default SetGmbCode;
