/* eslint-disable consistent-return */
/* eslint-disable spaced-comment */
/* eslint-disable react/no-array-index-key */
import React from "react";
import settingsSvg from "assets/images/settingSvg.svg";
//import reloadGreenSvg from "assets/images/reload-green-svg.svg";
//import reloadPurpleSvg from "assets/images/reload-purple-svg.svg";
import editPurpleSvg from "assets/images/edit-purple-svg.svg";
import { Menu } from "antd";
import { Link } from "react-router-dom";

const textReturn = (state, insuranceName) => {
  if (state === "connected") return `Todo en orden con ${insuranceName}!`;
  if (state === "process") return `Conectando pólizas con ${insuranceName}!`;
  if (state === "invalid") return `Hubo una inconcistencia de datos con ${insuranceName}!`;
};
const urlIconInsurences = (insuranceName) => {
  return `https://stg-fileviewer.berrysafe.com/images/insurance-carrier/pia/${insuranceName}-icon.svg`;
};
const piaMenu = (cardsPolicies) => (
  <Menu className="header__dropdown-pia-menu__item" key="menu-footer">
    <div className="item-head">
      <p className="body-bold">Estatus PIAA</p>
      <Link className="button-txt" to="../account/pia">
        <img src={settingsSvg} className="settings" alt="settings" />
        Configurar
      </Link>
    </div>
    <div className="item-body">
      {cardsPolicies?.map((item, i) => (
        <div className="card-insurance" key={i}>
          <div className="lft">
            <div className="circle">
              <img src={urlIconInsurences(item.insurerName)} className="icon" alt="icon" />
            </div>
            <div className="section-info">
              <h4 className={`body-bold body-bold-gradient body-bold-gradient--${item.state}`}>
                {textReturn(item.state, item.insurerName)}
              </h4>
              <p className="small-detail">{item.totalPolicies} pólizas cargadas</p>
              {item?.accounts.length >= 1 && (
                <div className="items">
                  {item?.accounts?.map((account, index) => (
                    <div className={`account-circle account-circle--${account.state}`} key={index}>
                      <p className="type">U{index + 1}</p>
                      <div className="alret-hover">
                        <div className="alret-hover--left">
                          <div className="title">
                            <p className="body-bold">{account.username}</p>
                            <div className="status" />
                          </div>
                          <p className="small-detail">{account.value} pólizas cargadas</p>
                        </div>
                        {/* <Button className="button-transparent">
                          <img src={reloadGreenSvg} alt="reload" className="reload" />
                        </Button> */}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="rtg">
            {item?.totalPolicies === 0 && (
              // <Link to="../account/pia">
              //   <img src={reloadPurpleSvg} className="icon" alt="reload" />
              // </Link>
              <div />
            )}
            <Link to="../account/pia">
              <img src={editPurpleSvg} className="icon" alt="edit" />
            </Link>
          </div>
        </div>
      ))}
    </div>
  </Menu>
);

export default piaMenu;
