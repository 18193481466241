/* eslint-disable camelcase */
import { Modal } from "antd";
import { bool, element, func, string } from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { CLIENT_HOST } from "../../../common/config/config";

const ModalPublishment = ({
  button_succes,
  button_cancel,
  title,
  image,
  visible,
  setVisible,
  isPublish,
  textContent,
}) => {
  const {
    success: { profile },
  } = useSelector((state) => state.onboarding);

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <div className="modal-meneric-editable">
      <Modal
        className="modal-meneric-editable__modal"
        title="Basic Modal"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="box-left">
          <h1 className="title">{title}</h1>
          <div>
            <p className="text">{textContent}</p>
            {isPublish && (
              <>
                <input
                  id="link-input-modal"
                  className="link-text"
                  readOnly
                  value={`${CLIENT_HOST}${
                    profile?.agentSponsors && profile?.agentSponsors[0]
                      ? `${profile?.agentSponsors[0].sponsor.key}/`
                      : ""
                  }agente/${profile?.endPoint}`}
                  disabled
                />
                <a
                  href="./"
                  id="copy-button-modal"
                  className="copy"
                  onClick={async (e) => {
                    e.preventDefault();
                    if (navigator.clipboard) {
                      await navigator.clipboard.writeText(
                        `${CLIENT_HOST}${
                          profile?.agentSponsors && profile?.agentSponsors[0]
                            ? `${profile?.agentSponsors[0].sponsor.key}/`
                            : ""
                        }agente/${profile?.endPoint}`
                      );
                    }
                  }}
                >
                  <i className="icon-copy" aria-hidden="true" />
                </a>
              </>
            )}
          </div>
          <div className="cont-btn">
            {button_succes}
            {button_cancel}
          </div>
        </div>
        <div className="box-right">
          <img alt={image} src={image} />
        </div>
      </Modal>
    </div>
  );
};

ModalPublishment.propTypes = {
  button_succes: element,
  button_cancel: element,
  title: string.isRequired,
  image: string.isRequired,
  visible: bool.isRequired,
  setVisible: func.isRequired,
  isPublish: bool.isRequired,
  textContent: string.isRequired,
};

ModalPublishment.defaultProps = {
  button_succes: null,
  button_cancel: null,
};

export default ModalPublishment;
