import { handleActions } from "redux-actions";

export const INITIAL_STATE = {
  loading: {
    mainInfo: false,
    insuranceCarriers: false,
    updateInsuranceCarriers: false,
    layoutProducts: false,
    tips: false,
    setSelectedLayout: false,
    setProfilePhoto: false,
    createOffice: false,
    updateOffice: false,
    menu: false,
    officeSent: false,
    sendGmbVerificationCode: false,
    productLines: false,
    saveProductLines: false,
    changeShow: false,
    updateEmail: false,
    updatePhone: false,
    publish: false,
    deleteOffice: false,
  },
  error: {
    mainInfo: undefined,
    insuranceCarriers: undefined,
    updateInsuranceCarriers: undefined,
    layoutProducts: undefined,
    tips: undefined,
    setSelectedLayout: undefined,
    setProfilePhoto: undefined,
    createOffice: undefined,
    updateOffice: undefined,
    menu: undefined,
    officeSent: undefined,
    sendGmbVerificationCode: undefined,
    productLines: false,
    saveProductLines: false,
    changeShow: false,
    updateEmail: undefined,
    updatePhone: undefined,
    publish: undefined,
    deleteOffice: undefined,
  },
  success: {
    changeShow: false,
    mainInfo: undefined,
    insuranceCarriers: undefined,
    updateInsuranceCarriers: undefined,
    layoutProducts: undefined,
    tips: undefined,
    setSelectedLayout: undefined,
    setProfilePhoto: undefined,
    createOffice: undefined,
    updateOffice: undefined,
    menu: undefined,
    officeSent: undefined,
    sendGmbVerificationCode: undefined,
    productLines: false,
    saveProductLines: false,
    insuranceCarriersOrdered: undefined,
    updateEmail: undefined,
    updatePhone: undefined,
    publish: undefined,
    deleteOffice: undefined,
  },
  autoInsurance: null,
  autoPublish: false,
  publishData: null,
};

const reducer = handleActions(
  {
    AGENT: {
      SAVE_MAIN_INFO: (state) => ({
        ...state,
        loading: { ...state.loading, mainInfo: true },
        error: { ...state.error, mainInfo: undefined },
      }),
      SAVE_MAIN_INFO_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, mainInfo: false },
            success: { mainInfo: true },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, mainInfo: message },
            success: { ...state.success, mainInfo: undefined },
            loading: { ...state.loading, mainInfo: false },
          };
        },
      },

      GET_INSURANCE_CARRIERS: (state) => ({
        ...state,
        loading: { ...state.loading, insuranceCarriers: true },
        error: { ...state.error, insuranceCarriers: undefined },
      }),
      GET_INSURANCE_CARRIERS_RESPONSE: {
        next(state, { payload: { insuranceCarriers } }) {
          const fullOrderedInsuranceCarrires = [];
          const notAddedInsuranceCarriers = [];

          insuranceCarriers.map((insurance) => {
            const exists = state.success.insuranceCarriersOrdered.find(
              (insuranceToFound) => insuranceToFound.insuranceCarrier.id === insurance.id
            );
            if (exists)
              fullOrderedInsuranceCarrires.push({
                ...insurance,
                isSelected: false,
              });
            else
              notAddedInsuranceCarriers.push({
                ...insurance,
                isSelected: false,
              });
            return insurance;
          });

          fullOrderedInsuranceCarrires.sort((a, b) => (a.level < b.level ? -1 : 1));

          if (state.autoInsurance) {
            const foundInsurance = notAddedInsuranceCarriers.findIndex(
              (insurance) => insurance.key === state.autoInsurance
            );
            if (foundInsurance > -1) {
              fullOrderedInsuranceCarrires.unshift({
                ...notAddedInsuranceCarriers.find(
                  (insurance) => insurance.key === state.autoInsurance
                ),
                auto: true,
              });
              notAddedInsuranceCarriers.splice(foundInsurance, 1);
            }
          }
          return {
            ...state,
            loading: {
              ...state.loading,
              insuranceCarriers: false,
            },
            success: {
              ...state.success,
              insuranceCarriersOrdered: fullOrderedInsuranceCarrires,
              insuranceCarriers: notAddedInsuranceCarriers,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, insuranceCarriers: message },
            success: { ...state.success, insuranceCarriers: undefined },
            loading: { ...state.loading, insuranceCarriers: false },
          };
        },
      },

      GET_TIPS: (state) => ({
        ...state,
        loading: { ...state.loading, tips: true },
        error: { ...state.error, tips: undefined },
      }),
      GET_TIPS_RESPONSE: {
        next(state, { payload: { tips } }) {
          return {
            ...state,
            loading: {
              ...state.loading,
              tips: false,
            },
            success: {
              ...state.success,
              tips,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, tips: message },
            success: { ...state.success, tips: undefined },
            loading: { ...state.loading, tips: false },
          };
        },
      },

      SELECT_PRODUCT_LINE: (state, { payload }) => {
        const productLineIndex = state.success.productLines.findIndex(
          (product) => product.id === payload
        );

        if (productLineIndex < 0) return state;

        const newProductLines = state.success.productLines;
        newProductLines[productLineIndex].selected =
          !state.success.productLines[productLineIndex].selected;

        const actualIndex = newProductLines.findIndex((productLine) => productLine.id === payload);
        if (newProductLines[productLineIndex].selected) {
          const firstUnselected = newProductLines.findIndex((productLine) => !productLine.selected);

          if (firstUnselected < actualIndex) {
            const [removed] = newProductLines.splice(actualIndex, 1);
            newProductLines.splice(firstUnselected, 0, removed);
          }
        } else {
          let indexToReplace = newProductLines
            .slice()
            .reverse()
            .findIndex((productLine) => productLine.selected);
          indexToReplace =
            indexToReplace >= 0 ? newProductLines.length - 1 - indexToReplace : indexToReplace;
          if (productLineIndex < indexToReplace) {
            const [removed] = newProductLines.splice(productLineIndex, 1);
            newProductLines.splice(indexToReplace, 0, removed);
          }
        }

        return {
          ...state,
          success: {
            ...state.success,
            productLines: newProductLines,
          },
        };
      },

      ORDER_PRODUCT_LINES: (state, { payload: { source, destination } }) => {
        if (!destination) return state;
        const allProductLines = state.success.productLines;

        const realDestinyPosition = destination.droppableId.substring(4) * 6 + destination.index;

        const realSourcePosition = source.droppableId.substring(4) * 6 + source.index;

        const [removed] = allProductLines.splice(realSourcePosition, 1);

        allProductLines.splice(realDestinyPosition, 0, removed);

        if (removed.selected) {
          const actualIndex = allProductLines.findIndex(
            (productLine) => productLine.id === removed.id
          );
          if (actualIndex !== 0) {
            if (
              !allProductLines[actualIndex - 1]?.selected &&
              !allProductLines[actualIndex + 1]?.selected
            )
              allProductLines[actualIndex].selected = !allProductLines[actualIndex].selected;
          }
        } else {
          const actualIndex = allProductLines.findIndex(
            (productLine) => productLine.id === removed.id
          );
          if (allProductLines[actualIndex + 1]?.selected)
            allProductLines[actualIndex].selected = !allProductLines[actualIndex].selected;
        }
        const productLinesSelected = allProductLines.filter((productLine) => productLine.selected);

        return {
          ...state,
          success: {
            ...state.success,
            productLines: allProductLines,
            productLinesSelected,
          },
        };
      },

      MODIFY_ORDER: (state, { payload }) => {
        const insuranceExists = state.success.insuranceCarriersOrdered.findIndex(
          (insurance) => insurance.key === payload.key
        );
        const newOrderedProducts = [...state.success.insuranceCarriersOrdered];
        const newInsurances = [...state.success.insuranceCarriers];
        if (insuranceExists > -1) {
          const deleted = newOrderedProducts.splice(insuranceExists, 1);
          newInsurances.unshift(...deleted);
        } else {
          const insuranceToRemove = newInsurances.findIndex(
            (insurance) => insurance.key === payload.key
          );
          if (insuranceToRemove > -1) newInsurances.splice(insuranceToRemove, 1);

          newOrderedProducts.push(payload);
        }

        return {
          ...state,
          success: {
            ...state.success,
            insuranceCarriersOrdered: newOrderedProducts,
            insuranceCarriers: newInsurances,
          },
        };
      },

      GET_LAYOUT_PRODUCTS: (state) => ({
        ...state,
        loading: { ...state.loading, layoutProducts: true },
        error: { ...state.error, layoutProducts: undefined },
      }),
      GET_LAYOUT_PRODUCTS_RESPONSE: {
        next(state, { payload: { layoutProducts } }) {
          return {
            ...state,
            loading: {
              ...state.loading,
              layoutProducts: false,
            },
            success: {
              ...state.success,
              layoutProducts,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, layoutProducts: message },
            success: { ...state.success, layoutProducts: undefined },
            loading: { ...state.loading, layoutProducts: false },
          };
        },
      },

      SAVE_PRODUCT_LINES: (state) => ({
        ...state,
        loading: { ...state.loading, saveProductLines: true },
        error: { ...state.error, saveProductLines: undefined },
      }),
      SAVE_PRODUCT_LINES_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: {
              ...state.loading,
              saveProductLines: false,
            },
            success: {
              ...state.success,
              saveProductLines: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, saveProductLines: message },
            success: { ...state.success, saveProductLines: undefined },
            loading: { ...state.loading, saveProductLines: false },
          };
        },
      },

      CLEAR_PRODUCT_LINES_RESPONSE: (state) => ({
        ...state,
        success: { ...state.success, saveProductLines: false },
      }),

      SET_SELECTED_LAYOUT: (state) => ({
        ...state,
        loading: { ...state.loading, setSelectedLayout: true },
        error: { ...state.error, setSelectedLayout: undefined },
      }),
      SET_SELECTED_LAYOUT_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: {
              ...state.loading,
              setSelectedLayout: false,
            },
            success: {
              ...state.success,
              setSelectedLayout: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, setSelectedLayout: message },
            success: { ...state.success, setSelectedLayout: undefined },
            loading: { ...state.loading, setSelectedLayout: false },
          };
        },
      },
      CLEAR_RESPONSE_LAYOUT_PRODUCTS: (state) => ({
        ...state,
        loading: { ...state.loading, setSelectedLayout: false },
        error: { ...state.error, setSelectedLayout: undefined },
        success: { ...state.success, setSelectedLayout: undefined },
      }),

      ORDER_INSURANCE_CARRIERS: (state, { payload: { source, destination } }) => {
        if (source.droppableId === destination.droppableId) {
          const result = Array.from(state.success[source.droppableId]);
          const [removed] = result.splice(source.index, 1);
          result.splice(destination.index, 0, removed);

          return {
            ...state,
            success: {
              ...state.success,
              [source.droppableId]: result,
            },
          };
        }

        const sourceClone = Array.from(state.success[source.droppableId]);
        const destClone = Array.from(state.success[destination.droppableId]);
        const [removed] = sourceClone.splice(source.index, 1);

        destClone.splice(destination.index, 0, removed);

        const result = {};
        result[source.droppableId] = sourceClone;
        result[destination.droppableId] = destClone;

        return {
          ...state,
          success: {
            ...state.success,
            [source.droppableId]: sourceClone,
            [destination.droppableId]: destClone,
          },
        };
      },

      CLEAN_SAVE_MAIN_INFO_RESPONSE: (state) => ({
        ...state,
        success: { ...state.success, mainInfo: false },
      }),

      UPDATE_INSURANCE_CARRIERS: (state) => ({
        ...state,
        loading: { ...state.loading, updateInsuranceCarriers: true },
        error: { ...state.error, insuranceCarriers: undefined },
      }),
      UPDATE_INSURANCE_CARRIERS_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, updateInsuranceCarriers: false },
            success: {
              ...state.success,
              updateInsuranceCarriers: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, updateInsuranceCarriers: message },
            success: { ...state.success, updateInsuranceCarriers: undefined },
            loading: { ...state.loading, updateInsuranceCarriers: false },
          };
        },
      },

      SET_PROFILE_PHOTO: (state) => ({
        ...state,
        loading: { ...state.loading, setProfilePhoto: true },
        error: { ...state.error },
      }),
      SET_PROFILE_PHOTO_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, setProfilePhoto: false },
            success: {
              ...state.success,
              setProfilePhoto: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, setProfilePhoto: message },
            success: { ...state.success, setProfilePhoto: undefined },
            loading: { ...state.loading, setProfilePhoto: false },
          };
        },
      },

      SET_ACTUAL_INSURANCE_CARRIERS_ORDERED: (state, { payload }) => {
        return {
          ...state,
          success: {
            ...state.success,
            insuranceCarriersOrdered: payload,
          },
        };
      },

      CREATE_OFFICE: (state) => ({
        ...state,
        loading: { ...state.loading, createOffice: true },
        error: { ...state.error, createOffice: undefined },
      }),
      CREATE_OFFICE_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, createOffice: false },
            success: {
              ...state.success,
              createOffice: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, createOffice: message },
            success: { ...state.success, createOffice: undefined },
            loading: { ...state.loading, createOffice: false },
          };
        },
      },

      CLEAR_CREATE_OFFICE: (state) => ({
        ...state,
        loading: { ...state.loading, createOffice: false },
        error: { ...state.error, createOffice: undefined },
        success: { ...state.success, createOffice: undefined },
      }),

      UPDATE_OFFICE: (state) => ({
        ...state,
        loading: { ...state.loading, updateOffice: true },
        error: { ...state.error, updateOffice: undefined },
      }),
      UPDATE_OFFICE_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, updateOffice: false },
            success: {
              ...state.success,
              updateOffice: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, updateOffice: message },
            success: { ...state.success, updateOffice: undefined },
            loading: { ...state.loading, updateOffice: false },
          };
        },
      },

      CLEAR_UPDATE_OFFICE: (state) => ({
        ...state,
        loading: { ...state.loading, updateOffice: false },
        error: { ...state.error, updateOffice: undefined },
        success: { ...state.success, updateOffice: undefined },
      }),

      CHANGE_SHOW: (state) => ({
        ...state,
        loading: { ...state.loading, changeShow: true },
        error: { ...state.error, changeShow: undefined },
      }),
      CHANGE_SHOW_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, changeShow: false },
            success: {
              ...state.success,
              changeShow: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, updateOffice: message },
            success: { ...state.success, updateOffice: undefined },
            loading: { ...state.loading, updateOffice: false },
          };
        },
      },

      CLEAR_CHANGE_SHOW: (state) => ({
        ...state,
        loading: { ...state.loading, changeShow: false },
        error: { ...state.error, changeShow: undefined },
        success: { ...state.success, changeShow: undefined },
      }),

      GET_MENU: (state) => ({
        ...state,
        loading: { ...state.loading, menu: true },
        error: { ...state.error, menu: undefined },
      }),
      GET_MENU_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { menu: false },
            success: {
              ...state.success,
              menu: payload,
            },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            loading: { menu: false },
            error: { ...state.error, menu: message },
          };
        },
      },

      SEND_GOOGLE_LOCATION: (state) => ({
        ...state,
        loading: { ...state.loading, officeSent: true },
        error: { ...state.error, officeSent: undefined },
      }),
      SEND_GOOGLE_LOCATION_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { officeSent: false },
            success: {
              ...state.success,
              officeSent: true,
            },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            loading: { officeSent: false },
            error: { ...state.error, officeSent: message },
          };
        },
      },
      CLEAR_GOOGLE_LOCATION: (state) => ({
        ...state,
        success: { ...state.loading, officeSent: undefined },
        error: { ...state.error, officeSent: undefined },
      }),

      SEND_GMB_VERIFICATION_CODE: (state) => ({
        ...state,
        loading: { ...state.loading, sendGmbVerificationCode: true },
        error: { ...state.error, sendGmbVerificationCode: undefined },
      }),
      SEND_GMB_VERIFICATION_CODE_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { sendGmbVerificationCode: false },
            success: {
              ...state.success,
              sendGmbVerificationCode: true,
            },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            loading: { sendGmbVerificationCode: false },
            error: { ...state.error, sendGmbVerificationCode: message },
          };
        },
      },

      GET_PRODUCT_LINES: (state) => ({
        ...state,
        loading: { ...state.loading, productLines: true },
        error: { ...state.error, productLines: undefined },
      }),
      GET_PRODUCT_LINES_RESPONSE: {
        next(state, { payload: { productLinesLists } }) {
          return {
            ...state,
            loading: { ...state.loading, productLines: false },
            success: {
              ...state.success,
              productLines: productLinesLists,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, productLines: message },
            success: { ...state.success, productLines: undefined },
            loading: { ...state.loading, productLines: false },
          };
        },
      },
      CLEAR_GET_PRODUCT_LINES_RESPONSE: (state) => ({
        ...state,
        loading: { ...state.loading, productLines: false },
        error: { ...state.error, productLines: false },
        success: {
          ...state.success,
          productLines: undefined,
        },
      }),
      CLEAR_INSURANCE_CARRIER_UPDATED: (state) => ({
        ...state,
        success: {
          ...state.success,
          updateInsuranceCarriers: undefined,
        },
      }),

      UPDATE_EMAIL: (state) => ({
        ...state,
        loading: { ...state.loading, updateEmail: true },
        error: { ...state.error, updateEmail: undefined },
      }),
      UPDATE_EMAIL_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, updateEmail: false },
            success: {
              ...state.success,
              updateEmail: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, updateEmail: message },
            success: { ...state.success, updateEmail: undefined },
            loading: { ...state.loading, updateEmail: false },
          };
        },
      },
      CLEAR_UPDATE_EMAIL: (state) => ({
        ...state,
        success: {
          ...state.success,
          updateEmail: undefined,
        },
        error: {
          ...state.error,
          updateEmail: undefined,
        },
      }),

      UPDATE_PHONE: (state) => ({
        ...state,
        loading: { ...state.loading, updatePhone: true },
        error: { ...state.error, updatePhone: undefined },
      }),
      UPDATE_PHONE_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, updatePhone: false },
            success: {
              ...state.success,
              updatePhone: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, updatePhone: message },
            success: { ...state.success, updatePhone: undefined },
            loading: { ...state.loading, updatePhone: false },
          };
        },
      },
      CLEAR_UPDATE_PHONE: (state) => ({
        ...state,
        success: {
          ...state.success,
          updatePhone: undefined,
        },
        error: {
          ...state.error,
          updatePhone: undefined,
        },
      }),

      PUBLISH: (state) => ({
        ...state,
        loading: { ...state.loading, publish: true },
        error: { ...state.error, publish: undefined },
      }),
      PUBLISH_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, publish: false },
            success: {
              ...state.success,
              publish: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, publish: message },
            success: { ...state.success, publish: undefined },
            loading: { ...state.loading, publish: false },
          };
        },
      },
      CLEAR_PUBLISH: (state) => ({
        ...state,
        success: {
          ...state.success,
          publish: undefined,
        },
        error: {
          ...state.error,
          publish: undefined,
        },
      }),

      DELETE_OFFICE: (state) => ({
        ...state,
        loading: { ...state.loading, deleteOffice: true },
        error: { ...state.error, deleteOffice: undefined },
      }),
      DELETE_OFFICE_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, deleteOffice: false },
            success: {
              ...state.success,
              deleteOffice: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, deleteOffice: message },
            success: { ...state.success, deleteOffice: undefined },
            loading: { ...state.loading, deleteOffice: false },
          };
        },
      },
      CLEAR_DELETE_OFFICE: (state) => ({
        ...state,
        success: {
          ...state.success,
          deleteOffice: undefined,
        },
        error: {
          ...state.error,
          deleteOffice: undefined,
        },
      }),

      SET_AUTO_INSURANCE: (state, { payload }) => ({
        ...state,
        autoInsurance: payload,
      }),

      SET_AUTO_PUBLISH: (state) => ({
        ...state,
        autoPublish: !state.autoPublish,
      }),

      SET_PUBLISH_DATA: (state, { payload }) => ({
        ...state,
        publishData: payload,
      }),
    },
  },
  INITIAL_STATE
);

export default reducer;
