/* eslint-disable react/no-unescaped-entities */
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import PropTypes, { string } from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BerryLinkSvg from "../../../assets/images/berry-link.png";
import help from "../../../assets/images/help-btn.png";

const ModalLinkTree = ({ insuranceCarriers, endpoint }) => {
  const [visible, setVisible] = useState(false);
  const [video, setVideo] = useState(false);
  const { t } = useTranslation();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <div>
      {insuranceCarriers?.filter((insurance) => insurance.urlHelp).length > 0 && (
        <Button
          id="quote-button-modal"
          className="button--secondary button-card-secundary"
          onClick={showModal}
        >
          Cotizar
        </Button>
      )}
      <Modal
        className="ModalLinkTree"
        title="Basic Modal"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <img
          src={BerryLinkSvg}
          alt="berry_link"
          className={`berry_link ${video && "berry_link--active"}`}
        />
        <div className={`buts ${video && "buts--active"}`}>
          {insuranceCarriers?.length >= 2 && (
            <>
              <div className="text">
                ¿{t("label.howToQuote")} <span>{t("label.insurance")}</span>?
              </div>
              <p>
                ¿Sabes cómo cotizar? Si tienes dudas escríbenos al correo info@berrysafe.com
                estaremos atentos a responderte y guiarte para la cotización de tu seguro.
              </p>
            </>
          )}
          {video && (
            <div className="cont-video">
              <Button onClick={() => setVideo(!video)} className="back">
                <ArrowLeftOutlined />
              </Button>
              <iframe
                title="tutorial"
                className="video"
                src="https://www.youtube.com/embed/-idsefXc5_4"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <Button className="btn-help">
                <img alt="./" src={help} />
              </Button>
            </div>
          )}
          {insuranceCarriers
            ?.filter((insurance) => insurance.urlHelp)
            .map((insurance) => (
              <div key={insurance.id} className="buts__content">
                <Button
                  key={insurance.id}
                  className="button--linktree"
                  onClick={() => window.open(insurance.urlHelp)}
                  style={{ fontSize: "15px", padding: 10 }}
                >
                  <div className="cont-img">
                    <div className="white">
                      <img
                        alt="insurance"
                        className="icon-insurance"
                        src={insurance.insuranceCarrier.icon}
                      />
                    </div>
                  </div>
                  <span>{insurance.insuranceCarrier.name}</span>
                </Button>
                {insuranceCarriers?.length <= 1 && (
                  <>
                    <div className="text">
                      ¿{t("label.howToQuote")} <span>{insurance.insuranceCarrier.name}</span>?
                    </div>
                    {endpoint !== "huracan" ? (
                      <p>
                        ¿Sabes cómo cotizar? Si tienes dudas escríbenos al correo info@berrysafe.com
                        estaremos atentos a responderte y guiarte para la cotización de tu seguro.
                      </p>
                    ) : (
                      <p>
                        "Este seguro requiere ser cotizado en el área de Suscripción de la
                        aseguradora. Favor de solicitarlo al correo electrónico
                        berrystorm@berrysafe.com, indicando el código postal de la ubicación del
                        inmueble."
                      </p>
                    )}
                  </>
                )}
              </div>
            ))}
        </div>
      </Modal>
    </div>
  );
};

ModalLinkTree.propTypes = {
  insuranceCarriers: PropTypes.instanceOf(Array),
  endpoint: string.isRequired,
};

ModalLinkTree.defaultProps = {
  insuranceCarriers: [],
};

export default ModalLinkTree;
