/* eslint-disable react/jsx-props-no-spreading */
import { Button } from "antd";
import { instanceOf } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import AgentActions from "services/agent/actions";

const SliderInsuranceCarriers = ({ insurances }) => {
  const { success } = useSelector((state) => state.agent);
  const [actualInsurances, setActualInsurances] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (success.insuranceCarriersOrdered && insurances) {
      const newActualInsurances = [
        ...success.insuranceCarriersOrdered.map((insurance) => ({
          ...insurance,
          isSelected: true,
        })),
      ];
      newActualInsurances.push(...insurances);
      setActualInsurances(newActualInsurances);
    }
  }, [success.insuranceCarriersOrdered, insurances]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    rows: 5,
  };

  return (
    <div className="SliderInsuranceCarriers">
      <Slider {...settings}>
        {actualInsurances?.map((insuranceCarrier) => (
          <Button
            className={`btn ${insuranceCarrier.isSelected && "btn--active"}`}
            key={insuranceCarrier.id}
            onClick={() => dispatch(AgentActions.modifyOrder(insuranceCarrier))}
          >
            <img
              alt={insuranceCarrier.key}
              src={insuranceCarrier.isSelected ? insuranceCarrier.logoWhite : insuranceCarrier.logo}
            />
          </Button>
        ))}
      </Slider>
    </div>
  );
};

SliderInsuranceCarriers.propTypes = {
  insurances: instanceOf(Object).isRequired,
};

export default SliderInsuranceCarriers;
