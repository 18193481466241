/* eslint-disable import/no-cycle */
import { all, put, takeLatest } from "redux-saga/effects";
import ApiConnection from "../../common/api/Api";
import parseError from "../../common/api/ErrorParser";
import ChartsActions from "./actions";

function* getMinimalMetrics() {
  const url = "/report/minimal";
  const response = yield ApiConnection.get(url);

  if (response.success) return yield put(ChartsActions.getMinimalMetricsResponse(response.detail));

  return yield put(ChartsActions.getMinimalMetricsResponse(parseError(response), response));
}

function* ActionWatcher() {
  yield takeLatest(ChartsActions.getMinimalMetrics, getMinimalMetrics);
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
