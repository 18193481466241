import { Card, Col, Dropdown, Menu, Tooltip } from "antd";
import AlertSvg from "assets/icon/alert.svg";
import ClockSvg from "assets/icon/clock.svg";
import EditSvgW from "assets/icon/icon-edit-white.svg";
import iconLocation from "assets/icon/icon-location-purple.svg";
import Verified from "assets/icon/icon-verified.svg";
import ProfileSvg from "assets/icon/profile-inverse.svg";
import PreviewSvg from "assets/images/preview.svg";
import { changeRoute } from "common/utils/changeRoute";
import openMyPage from "common/utils/openMyPage";
import ModalPublishment from "components/Modals/Publishment/ModalPublishment";
import { func } from "prop-types";
import React, { useState } from "react";
import { CircleSlider } from "react-circle-slider";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import AgentActions from "services/agent/actions";
import SocialNetworks from "./components/SocialNetworks";
import Tips from "./components/Tips";

const Information = ({ startEditing }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [seeMore, setSeeMore] = useState(false);
  const [publishModalData, setPublishModalData] = useState(undefined);
  const { t } = useTranslation();
  const agent = useSelector((state) => state.agent);
  const cardVerified = (
    <Menu>
      <Menu.Item className="card-alert">
        <h1 className="title">{t("label.Verified")}</h1>
        <span className="description">{t("label.descriptionInformation")}. </span>
      </Menu.Item>
    </Menu>
  );
  const cardBerrysafe = (
    <Menu>
      <Menu.Item className="card-alert card-alert--berry">
        <div className="card-alert-content">
          <h1 className="title">¿Qué es BerrySmart?</h1>
          <span className="description">{t("label.descriptionMenuInformation")}</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  const onboarding = useSelector((state) => state.onboarding);

  const publishMyPage = () => {
    const tipState =
      agent.success.tips?.filter((tip) => !onboarding.success.profile[tip.column]).length > 0;

    let publishData;

    switch (onboarding.success.profile?.user.userType) {
      case "migrated":
        if (tipState) {
          publishData = {
            title: t("label.YourPageNeedsUpdated"),
            textContent: t("label.textMigrate"),
            button: (
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setPublishModalData(null);
                }}
                className="btn btn--succes link-dash"
              >
                {t("button.continue")}
              </a>
            ),
            image: AlertSvg,
          };
        } else {
          dispatch(AgentActions.publish());
        }
        return setPublishModalData(publishData);

      case "regular":
        if (onboarding.success.profile?.publishPermission !== "Active") {
          publishData = {
            title: t("label.titleRegular"),
            textContent: t("label.textRegular"),
            button: (
              <a
                href="/."
                onClick={(e) => {
                  e.preventDefault();
                  setPublishModalData(false);
                }}
                className="btn btn--succes link-dash"
              >
                {t("button.continue")}
              </a>
            ),
            image: ClockSvg,
          };
          return setPublishModalData(publishData);
        }
        if (tipState) {
          publishData = {
            title: t("label.titleRegular2"),
            textContent: t("label.textRegular2"),
            button: (
              <a
                href="/"
                onClick={() => {
                  setPublishModalData(null);
                }}
                className="btn btn--succes link-dash"
              >
                {t("label.GoToDashboard")}
              </a>
            ),
            image: AlertSvg,
          };
          return setPublishModalData(publishData);
        }
        return dispatch(AgentActions.publish());

      default:
        break;
    }
    return null;
  };

  return (
    <section className="information">
      <div className="information-page-actions">
        {(onboarding.success.profile?.onBoarding || onboarding.omitForNow) && (
          <>
            {!onboarding.success.profile?.onBoarding ||
            ((!onboarding.success.profile?.agentProducts ||
              onboarding.success.profile?.agentProducts?.length) < 1 &&
              (!onboarding.success.profile?.agentProductLines ||
                onboarding.success.profile?.agentProductLines?.length < 1)) ? (
              <Tooltip placement="topLeft" title={t("error.publishError")}>
                <button
                  type="button"
                  id="edit-page-button-header"
                  onClick={() => {}}
                  className="btn-custom btn-custom--preview btn-custom--preview--desisable"
                  style={{ color: "#5d30dd" }}
                >
                  <span>{t("label.previewMyPage")}</span>
                  <img src={PreviewSvg} className="preview-header" alt="preview" />
                </button>
              </Tooltip>
            ) : (
              <button
                type="button"
                id="preview-button-header"
                onClick={() => openMyPage(onboarding.success.profile?.endPoint)}
                className="btn-custom btn-custom--preview"
              >
                <img src={PreviewSvg} className="preview-header" alt="preview" />
                <span>{t("label.previewMyPage")}</span>
              </button>
            )}
          </>
        )}
        {(onboarding.success.profile?.onBoarding || onboarding.omitForNow) && (
          <>
            <button
              type="button"
              id="publishMyPage-button-header"
              onClick={() => publishMyPage()}
              className="btn-custom btn-custom--secundary"
            >
              {t("label.publishMyPage")}
            </button>
          </>
        )}
      </div>
      <Card className="information__cardProfile--desktop">
        <div className="information__cardProfile--desktop__content">
          <Col className="image">
            <div className="responsive-cont">
              <div className="image--circle">
                <img
                  alt="Avatar"
                  className="Avatar"
                  src={
                    onboarding.success.profile?.profilePhoto === ""
                      ? ProfileSvg
                      : onboarding.success.profile?.profilePhoto
                  }
                />
                <div className="circle">
                  <CircleSlider
                    value={70}
                    size={118}
                    knobRadius={0}
                    progressWidth={5}
                    circleWidth={6}
                    circleColor="#ffffff"
                    tooltipColor="#4B3A7C"
                    gradientColorFrom="#7B3BF9"
                    gradientColorTo="#00DBD0"
                    showTooltip
                    tooltipSize={33}
                    disabled
                  />
                </div>
              </div>
              {onboarding.success.profile?.agentLevels &&
                onboarding.success.profile?.agentLevels.length > 0 && (
                  <div className="content-image">
                    <div className="image-agentLevel">
                      <Dropdown overlay={cardBerrysafe}>
                        <img
                          alt="checked"
                          className="checked"
                          src={onboarding.success.profile?.agentLevels[0].level.icon}
                        />
                      </Dropdown>
                      <p>{onboarding.success.profile?.agentLevels[0].level.name}</p>
                    </div>
                    <div className="detail-level">
                      <p>93 de 100</p>
                      <span className="span">Próximo nivel: BerryMaster</span>
                    </div>
                  </div>
                )}
            </div>
            {agent.success.tips?.filter((tip) => !onboarding.success.profile[tip.column]).length >
            0 ? (
              <Tips
                text={
                  agent.success.tips && (
                    <>
                      {
                        agent.success.tips.find((tip) => !onboarding.success.profile[tip.column])
                          ?.name
                      }
                    </>
                  )
                }
                action={() => changeRoute("marketplace", history, dispatch)}
              />
            ) : (
              <>
                {onboarding.success.profile?.agentProducts?.length < 1 ? (
                  <Tips
                    text="Selecciona al menos un producto del Marketplace Berrysafe"
                    action={() => changeRoute("marketplace", history, dispatch)}
                  />
                ) : (
                  <>
                    {onboarding.success.profile?.agentProductLines?.length < 1 && (
                      <Tips
                        text="Selecciona al menos un ramo para mostrar en tu perfil"
                        action={() =>
                          window.scroll({
                            top: 100,
                            left: 100,
                            behavior: "smooth",
                          })
                        }
                      />
                    )}
                  </>
                )}
              </>
            )}
          </Col>
          <Col className="informationAgent">
            <Col className="informationAgent-name">
              <h1>{onboarding.success.profile?.user.person.name}</h1>
              <Dropdown overlay={cardVerified}>
                <img alt="Verified" src={Verified} />
              </Dropdown>
            </Col>
            <Col className="informationAgent-data">
              <p>{onboarding.success.profile?.tagline}</p>
              {onboarding.success.profile && onboarding.success.profile.offices.length > 0 && (
                <div className="informationAgent-data__address">
                  <img src={iconLocation} className="icon-location" alt="location" />
                  <p>
                    {
                      onboarding.success.profile.offices.find(
                        (address) => address.visible === "Active"
                      )?.address
                    }{" "}
                  </p>
                </div>
              )}
            </Col>
            {onboarding.success.profile && (
              <div className="desktop-networks">
                <SocialNetworks onboarding={onboarding} />
              </div>
            )}
            <Col
              className={`informationAgent-summary ${
                seeMore && "informationAgent-summary--active"
              }`}
            >
              {onboarding.success.profile?.description.length >= 1 ? (
                <>
                  <p>{onboarding.success.profile?.description}</p>
                  {onboarding.success.profile?.description.length > 150 && (
                    <Link to="./" onClick={() => setSeeMore(!seeMore)}>
                      {!seeMore ? (
                        <span>{t("label.readMore")}</span>
                      ) : (
                        <span>{t("label.readLess")}</span>
                      )}
                    </Link>
                  )}
                </>
              ) : (
                <p className="message--empty">{t("label.messageEmpty")}</p>
              )}
            </Col>
            {onboarding.success.profile && (
              <div className="responsive-networks">
                <SocialNetworks onboarding={onboarding} />
              </div>
            )}
          </Col>
          <Col className="edit">
            <a
              href="./"
              onClick={(e) => {
                e.preventDefault();
                startEditing();
              }}
            >
              <img src={EditSvgW} className="edit-icon" alt="true" />
              <span>{t("button.EditSection")}</span>
            </a>
          </Col>
        </div>
      </Card>
      {publishModalData && (
        <ModalPublishment
          image={publishModalData.image}
          setVisible={() => setPublishModalData(null)}
          visible
          title={publishModalData.title}
          textContent={publishModalData.textContent}
          button_succes={publishModalData.button}
          isPublish={publishModalData.isPublish || false}
        />
      )}
    </section>
  );
};

Information.propTypes = {
  startEditing: func.isRequired,
};

export default Information;
