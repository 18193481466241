const initialHighlightDictionary = {
  insurance: ["aseguradora", "asegurada"],
  branch: ["rama", "ramo", "ramos", "ramas"],
  subBranch: ["subramo", "subramos", "subramas", "subrama"],
  policyType: ["tipo", "poliza"],
  validity: ["vigencia"],
  emission: ["emision", "emisión"],
  beneficiary: ["beneficiario", "asegurado"],
  agent: ["agente", "agentes"],
  office: ["oficina", "oficinas", "promotoría", "promotoria"],
  prime: ["prima", "Prima neta"],
  net: ["neta"],
  rate: ["tasa", "tarifa", "Tasa Financiada"],
  expenses: ["gastos", "gasto", "Gastos expedición", "Gastos medicos"],
  tax: ["iva"],
  total: ["total", "Importe total", "Robo total"],
  subtotal: ["subtotal"],
  discount: ["descuento", "descuentos"],
  serie: ["serie", "Número de serie", "Numero de serie"],
  motor: ["motor", "Número de motor", "Numero de motor"],
  description: ["description"],
  service: ["servicio", "servicios"],
  password: ["clave", "Clave de vehículo", "claves"],
  use: ["uso"],
  endorsement: ["endosos", "endoso"],
  sum: ["suma"],
  coverage: ["cobertura", "coberturas"],
  risk: ["riesgos"],
  deductible: ["deducible"],
  coInsurance: ["coaseguro"],
  currency: ["moneda"],
  paymentMethod: ["pago", "forma de pago", "formas de pago", "Plazo de pago"],
  paymentDeadline: ["plazo"],
  paymentDate: ["fecha de pago"],
  happenedDate: ["fecha de ocurrido"],
  reportHour: ["hora de reporte", "reporte"],
  reportNumber: ["número de reporte", "reporte"],
  sinisterNumber: ["número de siniestro", "siniestro"],
  driver: ["conducto"],
  vehicle: ["vehículo", "modelo", "descripción", "vehiculo"],
  location: ["ubicación"],
  cause: ["causa"],
  estimate: ["estimación", "estimaciones"],
  subSection: ["inciso"],
  invoice: ["folio"],
  link: ["link", "url"],
  remesa: ["remesa"],
  moveType: ["tipo de movimiento"],
  comission: ["comision", "comisión", "comisiones"],
  plate: ["placa", "placas", "matricula", "matriculas", "Número de Placa", "Numero de Placa"],

  PolicyType: ["Tipo de póliza", "póliza", "poliza", "Tipos de pólizas"],
  BeginningValidity: ["Inicio de Vigencia", "Vigencia", "Vigencia"],
  EndValidity: ["fin de Vigencia", "Vigencia", "Vigencia"],
  DateIssue: ["Fecha de emisión", "Fecha de emision", "emision", "emisión"],
  Vigor: ["vigor", "en vigor"],
  Number: [
    "numero",
    "número",
    "Número de Placa",
    "Numero de Placa",
    "Numero de motor",
    "Numero de serie",
  ],
  Damage: ["Daño materiales", "Daño"],
  receipt: ["Recibo", "Serie del recibo"],
  PremiumAmount: ["Importe de la prima"],
  Percentage: ["Porcentaje de Comisión"],
  ISR: ["ISR", "ISR Retenido"],
  detained: ["iva Retenido", "ISR Retenido"],
  Fertilizers: ["Abonos", "Abono"],
};

export default initialHighlightDictionary;
