import { createActions } from "redux-actions";

const { onboarding } = createActions({
  ONBOARDING: {
    GET_PROFILE: (ignoreLoad) => ignoreLoad,
    GET_PROFILE_RESPONSE: (profile) => profile,

    GET_LICENSES: () => ({}),
    GET_LICENSES_RESPONSE: (licenses) => licenses,

    SET_LICENSE: (id, isSelected, licenseNumber) => ({ id, isSelected, licenseNumber }),

    SET_IS_SPONSOR: (sponsor) => ({ sponsor }),

    GET_INSURANCE_CARRIERS: () => ({}),
    GET_INSURANCE_CARRIERS_RESPONSE: (insuranceCarriers) => insuranceCarriers,

    SET_INSURANCE_CARRIER: (id, isSelected) => ({ id, isSelected }),

    GET_PRODUCT_LINES: () => ({}),
    GET_PRODUCT_LINES_RESPONSE: (productLines) => productLines,

    SET_LAYOUT_PRODUCTS: (layoutProducts) => layoutProducts,

    ORDER_PRODUCT_LINES: (source, destination) => ({ source, destination }),

    SELECT_PRODUCT_LINE: (productLine) => productLine.id,

    SELECT_OFFICE_PRODUCT_LINE: (productLine) => productLine.id,

    SAVE_ONBOARDING: () => ({}),
    SAVE_ONBOARDING_RESPONSE: (response) => ({ response }),

    SET_PROFILE_PHOTO: (data) => data,

    UPDATE_OFFICE_TO_PROFILE: (office) => office,

    ADD_OFFICE_TO_SAVE: (office) => office,

    REMOVE_OFFICE_TO_SAVE: (index) => index,

    UPDATE_OFFICE_TO_SAVE: (office) => office,

    SET_OMIT_FOR_NOW: (omit) => omit,

    COMPLETE_OFFICE: (office) => office,

    SET_SHOW_TUTORIAL: (state) => state,

    SET_ALL_PROFILE_OFFICES: (offices) => offices,

    SET_VERIFIED_OFFICE_STATUS: (id, status) => ({ id, status }),

    REMOVE_OFFICE: (id) => id,

    SET_STEP: (index) => index,
  },
});

export default onboarding;
