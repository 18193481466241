/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import NoOrderSvg from "assets/icon/no-order.svg";
import SortSvg from "assets/icon/sort_down.svg";
import moment from "moment";
import React from "react";

const columns = (openDetail, columnOrder, sort, setShowLeadDetail) => {
  const renderEmailContact = (record) => {
    const emailContact = record.person.contacts.find((contact) => contact.type === "Email");

    return emailContact ? emailContact.value : "";
  };

  const renderSort = (column) => {
    if (columnOrder[column].value === "ASC") {
      return (
        <div
          onClick={(e) => {
            e.preventDefault();
            sort(column);
          }}
        >
          <img alt="Sort" src={SortSvg} />
        </div>
      );
    }

    if (columnOrder[column].value === "DESC")
      return (
        <div
          onClick={(e) => {
            e.preventDefault();
            sort(column);
          }}
        >
          <img
            alt="Sort"
            src={SortSvg}
            style={{
              transform: "rotate(180deg)",
            }}
          />
        </div>
      );

    return (
      <div
        onClick={(e) => {
          e.preventDefault();
          sort(column);
        }}
      >
        <img alt="Sort" src={NoOrderSvg} />
      </div>
    );
  };

  return [
    {
      title: () => <div> Línea{renderSort("title")} </div>,
      dataIndex: "line",
      key: "line",
      width: 70,
      fixed: "left",
      render: (text, record) => (
        <div className="table-cell table-cell--line">
          <img src={record.productLine?.iconWhite} alt="" />
        </div>
      ),
    },
    {
      title: () => (
        <div>
          Nombre
          {renderSort("name")}
        </div>
      ),
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div className="table-cell table-cell--name">{record.person.name}</div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => (
        <div className="table-cell table-cell--email">{renderEmailContact(record)}</div>
      ),
    },
    {
      title: "Estado",
      dataIndex: "state",
      key: "state",
      render: (text, record) => <p>{record.status[0].status.name}</p>,
    },
    {
      title: () => (
        <div>
          Fecha
          {renderSort("requestDate")}
        </div>
      ),
      dataIndex: "date",
      key: "date",
      render: (text, record) => (
        <p>{moment(record.requestDate).format("MMM DD, YYYY - hh:mm a", "es")}</p>
      ),
    },
    {
      title: () => (
        <div>
          Origen
          {renderSort("origin")}
        </div>
      ),
      dataIndex: "origin",
      key: "origin",
      render: (text, record) => <p>{record.origin.name}</p>,
    },
    {
      key: "action",
      render: (lead) => (
        <div className="leads-detail">
          <button
            type="button"
            onClick={() => {
              setShowLeadDetail(true);
              openDetail(lead);
            }}
            className="leads-detail__button"
          >
            Detalle
          </button>
        </div>
      ),
    },
  ];
};

export default columns;
