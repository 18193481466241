import React from "react";
import icono14 from "assets/images/pia-brands/image4.png";
import AigSvg from "assets/images/pia-brands/insures/AIG.svg";
import icono3 from "assets/images/pia-brands/insures/Group 1319.svg";
import icono4 from "assets/images/pia-brands/insures/icono (1).svg";
import icono5 from "assets/images/pia-brands/insures/icono (2).svg";
import icono6 from "assets/images/pia-brands/insures/icono (3).svg";
import icono7 from "assets/images/pia-brands/insures/icono (4).svg";
import icono8 from "assets/images/pia-brands/insures/icono (5).svg";
import icono9 from "assets/images/pia-brands/insures/icono (6).svg";
import icono10 from "assets/images/pia-brands/insures/icono (7).svg";
import icono11 from "assets/images/pia-brands/insures/icono (8).svg";
import icono12 from "assets/images/pia-brands/insures/icono.svg";
import icono13 from "assets/images/pia-brands/insures/Original.svg";

const AdditionalInsurances = () => (
  <div className="msj-empty">
    <div className="text">
      <h1>Estamos trabajando para ofrecerte más funcionalidades</h1>
    </div>
    <div className="insures">
      <div className="line line-1">
        <div className="icon icon--1">
          <img src={AigSvg} alt="icon" className="img" />
        </div>
        <div className="icon icon--2">
          <img src={icono3} alt="icon" className="img" />
        </div>
        <div className="icon icon--3">
          <img src={icono4} alt="icon" className="img" />
        </div>
      </div>
      <div className="line line-2">
        <div className="icon icon--2">
          <img src={icono5} alt="icon" className="img" />
        </div>
        <div className="icon icon--1">
          <img src={icono6} alt="icon" className="img" />
        </div>
      </div>
      <div className="line line-3">
        <div className="icon icon--3">
          <img src={icono7} alt="icon" className="img" />
        </div>
        <div className="icon icon--2">
          <img src={icono8} alt="icon" className="img" />
        </div>
        <div className="icon icon--1">
          <img src={icono9} alt="icon" className="img" />
        </div>
      </div>
      <div className="line line-4">
        <div className="icon icon--2">
          <img src={icono10} alt="icon" className="img" />
        </div>
        <div className="icon icon--3">
          <img src={icono11} alt="icon" className="img" />
        </div>
      </div>
      <div className="line line-5">
        <div className="icon icon--1">
          <img src={icono12} alt="icon" className="img" />
        </div>
        <div className="icon icon--3">
          <img src={icono13} alt="icon" className="img" />
        </div>
        <div className="icon icon--2">
          <img src={icono14} alt="icon" className="img" />
        </div>
      </div>
    </div>
  </div>
);

export default AdditionalInsurances;
