import * as firebase from "firebase/app";
import "firebase/auth";

export const initGoogleAuthProvider = () => {
  return new firebase.auth.GoogleAuthProvider();
};

export const initFacebookAuthProvider = () => {
  return new firebase.auth.FacebookAuthProvider();
};

export const initTwitterAuthProvider = () => {
  return new firebase.auth.TwitterAuthProvider();
};

export const getSocialInfo = (data, type) => {
  const accountData = {
    socialAccountId: "",
    socialNetwork: "",
    name: "",
    email: data.email,
  };

  switch (type) {
    case "Google":
      accountData.name = data.name;
      accountData.socialAccountId = data.id;
      accountData.socialNetwork = "google";
      break;

    case "Facebook":
      accountData.name = data.name;
      accountData.socialAccountId = data.id;
      accountData.socialNetwork = "facebook";
      break;

    case "Twitter":
      accountData.name = data.name;
      accountData.socialAccountId = `${data.id}`;
      accountData.socialNetwork = "twitter";
      break;
    default:
      break;
  }

  return accountData;
};
