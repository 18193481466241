/* eslint-disable react/jsx-props-no-spreading */
import { Button, Card, Form, Input, List, message } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import logo from "../../../../../../../../../../../assets/icon/logo-google.svg";
import {
  GOOGLE_PLACES_KEY_ADMINISTRATIVE_AREA_LEVEL_1,
  GOOGLE_PLACES_KEY_COUNTRY,
  GOOGLE_PLACES_KEY_LOCALITY,
  GOOGLE_PLACES_KEY_POSTAL_CODE,
} from "../../../../../../../../../../../common/config/config";

const SearchPlace = ({ goToAddOffice, setAddManually, setDetailedAddress, availableCountries }) => {
  const [form] = Form.useForm();

  const { t } = useTranslation();
  const [address, setAddress] = useState("");

  const handleChange = (newAddress) => {
    setAddress(newAddress);
  };

  const handleSelect = async (newAddress) => {
    try {
      form.setFieldsValue({
        address: newAddress,
      });
      setAddress(newAddress);
      const geocode = await geocodeByAddress(newAddress);
      const country = geocode[0].address_components.find((component) =>
        component.types.find((type) => type === GOOGLE_PLACES_KEY_COUNTRY)
      );
      const areaLevel = geocode[0].address_components.find((component) =>
        component.types.find((type) => type === GOOGLE_PLACES_KEY_ADMINISTRATIVE_AREA_LEVEL_1)
      );
      const city = geocode[0].address_components.find((component) =>
        component.types.find((type) => type === GOOGLE_PLACES_KEY_LOCALITY)
      );
      const postalCode = geocode[0].address_components.find((component) =>
        component.types.find((type) => type === GOOGLE_PLACES_KEY_POSTAL_CODE)
      );
      const longitude = geocode[0].geometry.location.lng();
      const latitude = geocode[0].geometry.location.lat();

      const isCountryAllowed = availableCountries.some(
        (countryAllowed) =>
          countryAllowed.key ===
          country.long_name
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLocaleLowerCase()
      );

      if (!isCountryAllowed) throw new Error(`No se permiten oficinas en ${country.long_name}`);

      setDetailedAddress({
        address: geocode[0].formatted_address,
        country: country?.long_name,
        areaLevel: areaLevel?.long_name,
        city: city?.long_name,
        postalCode: postalCode?.long_name,
        longitude,
        latitude,
      });
      goToAddOffice();
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <section className="offices">
      <Card className="offices__card">
        <div className="offices__cardHeader">
          <h4>{t("label.myBusiness")}</h4>
        </div>
        <div className="offices__cardWrapper">
          <div className="offices__cardWrapper__content">
            <div className="offices__cardWrapper__contentSearch">
              <h6>{t("label.searchYourAddress")}</h6>

              <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                  <>
                    <Form form={form}>
                      <Form.Item
                        name="address"
                        rules={[
                          {
                            required: true,
                            message: "Ingresa tu dirección por favor",
                          },
                        ]}
                      >
                        <Input
                          prefix={<i className="icon-search" alt="Search" aria-hidden="true" />}
                          {...getInputProps({
                            placeholder: "Ingresa tu dirección ...",
                            className: "location-search-input",
                          })}
                        />
                      </Form.Item>
                    </Form>
                    <List
                      dataSource={suggestions}
                      renderItem={(suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";

                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };

                        return (
                          <List.Item
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            {suggestion.description}
                          </List.Item>
                        );
                      }}
                    />
                  </>
                )}
              </PlacesAutocomplete>

              <div className="offices__cardWrapper__contentSearch__logo">
                <img src={logo} alt="google" />
              </div>
            </div>
            <div className="offices__cardWrapper__contentAdd">
              <p>{t("label.addressIsNotInYourList")}</p>
              <h5>{t("label.addAddressManually")}</h5>
              <Button onClick={setAddManually}>{t("button.add")}</Button>
            </div>
          </div>
        </div>
      </Card>
    </section>
  );
};

SearchPlace.propTypes = {
  goToAddOffice: PropTypes.func.isRequired,
  setAddManually: PropTypes.func.isRequired,
  setDetailedAddress: PropTypes.func.isRequired,
  availableCountries: PropTypes.instanceOf(Array).isRequired,
};

export default SearchPlace;
