import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import * as TokenStorage from "../../../common/storage/Token";
import AuthActions from "../../../services/auth/actions";

const TokenValidation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const { token } = useParams();
  const auth = useSelector((state) => state.auth);

  const setLogged = useCallback(
    async (tokenProp) => {
      history.push("");
      await TokenStorage.save(tokenProp);
      dispatch(
        AuthActions.loginResponse({
          token: tokenProp,
          profile: await TokenStorage.decodeToken(),
        })
      );
    },
    [dispatch, history]
  );

  useEffect(() => {
    if (token && !auth.error.setToken && !auth.loading.setToken)
      dispatch(AuthActions.setToken(token));

    if (auth.error.setToken || auth.success.setToken) setLogged(token);
  }, [token, auth, dispatch, setLogged]);

  return <div>{t("label.validatingInformation")}</div>;
};

export default TokenValidation;
