/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import { Button, message } from "antd";
import { messaging, onMessageListener } from "common/firebase";
import { changeRoute } from "common/utils/changeRoute";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import AgentActions from "services/agent/actions";
import CloseSVG from "../../assets/images/close-purple.svg";
import NotificationSVG from "../../assets/images/notification-purple.svg";

const Notify = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState("");
  const [myToken, setToken] = useState("");
  const [firebaseInstanced, setFirebaseInstanced] = useState(false);

  onMessageListener()
    .then((payload) => {
      const actualMessage = [
        ...messages,
        {
          id: messages.length,
          title: payload.data.title,
          text: payload.data.text,
          type: payload.data.type,
          link: payload.data.link,
        },
      ];
      setMessages(actualMessage);
    })
    .catch((err) => setError("Error al recibir notitifación"));

  useEffect(() => {
    const initFirebase = async () => {
      const token = await messaging.getToken(messaging, {
        vapidKey: "AIzaSyD-c7IS5BmOerrV1ZFl7xQNdswMLzdIla4",
      });
      dispatch(AgentActions.updateFirebaseToken(token));
      setToken(token);
    };
    if (firebaseInstanced) return;
    initFirebase();
    setFirebaseInstanced(true);
  }, [messages, firebaseInstanced, dispatch]);

  return (
    <>
      {messages.map((messageItem, i) => (
        <div
          className="alert-notification"
          key={message.id}
          style={{
            top: 51 + 150 * i,
          }}
        >
          <div className="head">
            <div
              onClick={() => changeRoute(messageItem.link, history, dispatch)}
              className="head-box"
            >
              <img src={NotificationSVG} className="notificationSVG" alt="notificationSVG" />
              <h4>{messageItem.title}</h4>
            </div>
            <Button
              className="btn-transparent"
              onClick={() => {
                const actualMessages = [...messages];
                actualMessages.splice(i, 1);
                setMessages(actualMessages);
              }}
            >
              <img src={CloseSVG} className="closeSVG" alt="closeSVG" />
            </Button>
          </div>
          <p
            onClick={() => changeRoute(messageItem.link, history, dispatch)}
            className="body-regular"
          >
            {messageItem.text}
          </p>
        </div>
      ))}
    </>
  );
};

export default Notify;
