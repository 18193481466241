import { Button, Popover } from "antd";
import capitalize from "common/utils/capitalize";
import { number, oneOfType, string } from "prop-types";
import React from "react";
import TooltipSvg from "../../../../../../assets/images/tooltip.svg";

const content = (
  <>
    <p>No hay información para este campo</p>
  </>
);

const PiaField = ({ text, type }) => {
  try {
    if (text === undefined || text === null) {
      return (
        <span className="body-bold">
          -{" "}
          {
            <Popover placement="topLeft" content={content}>
              <Button className="btn-tooltip">
                <img src={TooltipSvg} className="tooltip" alt="empty" />
              </Button>
            </Popover>
          }
        </span>
      );
    }

    if (type === "date") return <span className="body-bold">{text}</span>;

    if (type === "price" && !Number.isNaN(+text))
      return (
        <span className="body-bold">
          {new Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
          }).format(text)}
        </span>
      );

    if (type === "percentage" && Number.isNaN(+text))
      return (
        <span className="body-bold">
          {Intl.NumberFormat("es-MX", {
            style: "percent",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(text)}
        </span>
      );

    if (text && typeof text === "string")
      return <span className="body-bold">{capitalize(text)}</span>;
    return <span className="body-bold">{text}</span>;
  } catch (error) {
    return <span className="body-bold">{text}</span>;
  }
};

PiaField.propTypes = {
  text: oneOfType([string, number]).isRequired,
  type: string.isRequired,
};

export default PiaField;
