import Slider from "react-slick";
import { Button, Card, Divider } from "antd";
import { func } from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import EditSvgW from "../../../../../../../assets/icon/icon-edit-white.svg";

const Experience = ({ startEditing }) => {
  const { t } = useTranslation();
  const onboarding = useSelector((state) => state.onboarding);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 804,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  return (
    <section className="experience">
      <Card className="experience-card">
        <div className="experience-card__edit">
          <a
            href="./"
            onClick={(e) => {
              e.preventDefault();
              startEditing();
            }}
          >
            <img src={EditSvgW} className="edit" alt="true" />
          </a>
        </div>
        <div className="experience-card__header">
          <div className="experience-card__headerTitles">
            <h4>Aseguradoras</h4>
            <Divider />
          </div>
        </div>
        {onboarding.success.profile?.insuranceCarrierAgents.length >= 1 ? (
          <div className="experience-card__sponsor">
            {onboarding.success.profile?.insuranceCarrierAgents?.length <= 5 ? (
              <>
                <div className="items">
                  {onboarding.success.profile?.insuranceCarrierAgents?.map((insuranceCarrier) => (
                    <img
                      key={insuranceCarrier.id}
                      alt={insuranceCarrier.insuranceCarrier.name}
                      src={insuranceCarrier.insuranceCarrier.logo}
                    />
                  ))}
                </div>
              </>
            ) : (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Slider {...settings}>
                {onboarding.success.profile?.insuranceCarrierAgents?.map((insuranceCarrier) => (
                  <div key={insuranceCarrier.id} className="item">
                    <img
                      alt={insuranceCarrier.insuranceCarrier.name}
                      src={insuranceCarrier.insuranceCarrier.logo}
                    />
                  </div>
                ))}
              </Slider>
            )}
          </div>
        ) : (
          <p className="message--empty">{t("label.editSection")}</p>
        )}
        <div className="experience-card__edit--res">
          <Button
            onClick={(e) => {
              e.preventDefault();
              startEditing();
            }}
          >
            <img src={EditSvgW} className="EditSvgW" alt="edit" />
            {t("button.EditSection")}
          </Button>
        </div>
      </Card>
    </section>
  );
};

Experience.propTypes = {
  startEditing: func.isRequired,
};

export default Experience;
