import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/messaging";
import {
  AUTH_DOMAIN,
  DATABASE_URL,
  GOOGLE_API_KEY,
  GOOGLE_APP_ID,
  MEASUREMENT_ID,
  MESSAGING_SENDER_ID,
  PROJECT_ID,
  STORAGE_BUCKET,
} from "../config/config";

const app = firebase.initializeApp({
  apiKey: GOOGLE_API_KEY,
  authDomain: AUTH_DOMAIN,
  databaseURL: DATABASE_URL,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSAGING_SENDER_ID,
  appId: GOOGLE_APP_ID,
  measurementId: MEASUREMENT_ID,
});

export const messaging = firebase.messaging.isSupported() && app.messaging();

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

export default app;
