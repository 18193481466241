import { Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import EmailMigrated from "../../../assets/icon/emailMigrated.svg";
import AuthActions from "../../../services/auth/actions";
import "./MigratedGreetings.scss";

const MigratedGreentings = () => {
  const auth = useSelector((state) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleOk = () => {
    dispatch(AuthActions.clearMigrated());
    history.replace("/");
  };

  const handleCancel = () => {
    dispatch(AuthActions.clearMigrated());
    history.replace("/");
  };

  return (
    <div className="migrated-greetings">
      <Modal
        className="migrated-greetings__modal"
        title="Basic Modal"
        mask={false}
        visible
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="box-left">
          <h1 className="title">
            Bienvenido/a {auth.success.migratedInfo?.person?.name.split(" ")[0]}
          </h1>
          <div>
            <p className="text">{t("label.text1MigratedGreentings")}. </p>
            <p className="text text--two">
              {t("label.text2MigratedGreentings")}.{" "}
              <a href="mailto:info@berrysafe.com"> info@berrysafe.com</a>
            </p>
          </div>
        </div>
        <div className="box-right">
          <img alt="email" src={EmailMigrated} />
        </div>
      </Modal>
    </div>
  );
};

export default MigratedGreentings;
