const piaFormLabels = {
  qualitas: {
    username: "Cuenta",
    usernameTwo: "Clave de agente",
    password: "Contraseña",
  },
  gnp: {
    username: "Correo electrónico",
    usernameTwo: "Código de acceso",
    password: "Contraseña",
  },
  mapfre: {
    username: "Usuario",
    password: "Contraseña",
  },
  "ana-seguros": {
    usernameTwo: "Usuario",
    password: "Contraseña",
  },
  hdi: {
    username: "Usuario",
    password: "Contraseña",
  },
  axa: {
    username: "Usuario",
    password: "Contraseña",
  },
  chubb: {
    username: "Usuario",
    password: "Contraseña",
  },
  gmx: {
    username: "Usuario",
    password: "Contraseña",
  },
  ana: {
    username: "Usuario",
    password: "Contraseña",
  },
};

export default piaFormLabels;
