/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import Auth from "./auth/Auth";
import CheckEmail from "./checkEmail";
import EmailVerification from "./emailVerification";
import MigratedGreetings from "./MigratedGreetings/MigratedGreetings";
import TokenValidation from "./tokenValidation/TokenValidation";

const Public = () => {
  const NoMatch = ({ location }) => {
    const history = useHistory();
    useEffect(() => {
      history.replace("/");
    }, [history]);

    return (
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    );
  };

  NoMatch.propTypes = {
    location: PropTypes.instanceOf(Object).isRequired,
  };

  return (
    <Router>
      <Switch>
        <Route exact path="/" render={(props) => <Auth {...props} signup={false} />} />
        <Route exact path="/registro" render={(props) => <Auth {...props} signup />} />
        <Route
          exact
          path="/recuperar-contrasena"
          render={(props) => <Auth {...props} resetPassword />}
        />
        <Route path="/checkEmail" component={CheckEmail} />
        <Route path="/emailVerification" component={EmailVerification} />
        <Route path="/migrated-greetings" component={MigratedGreetings} />
        <Route exact path="/sso/:token" component={TokenValidation} />
        <Route component={NoMatch} />
      </Switch>
    </Router>
  );
};

export default Public;
