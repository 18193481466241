import { Card } from "antd";
import { func, instanceOf } from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import back from "../../../../../../../../../../../assets/icon/icon-back.svg";
import VerifyAddress from "./components/VerifyAddress/VerifyAddress";
import VerifyOfficeData from "./components/VerifyOfficeData/VerifyOfficeData";

const VerifyData = ({ detailedAddress, goBack, saveOffice, setVerifiedAddress }) => {
  const [activeTab, setActiveTab] = useState("tab1");
  const { t } = useTranslation();
  return (
    <section className="offices-editing">
      <Card className="offices-editing__card">
        <div className="offices-editing__cardHeader">
          <h4>{t("label.MyBusiness")}</h4>
        </div>
        <div className="wrapper">
          <div className="offices-editing__cardBack">
            <a
              href="./"
              onClick={(e) => {
                e.preventDefault();
                if (activeTab === "tab1") return goBack();
                return setActiveTab("tab1");
              }}
            >
              <img src={back} alt="back" />
            </a>
            <h6>{t("label.AddAddress")}</h6>
          </div>
          <div className="offices-editing__cardTabs">
            <div id="tab1" className="tab--active" />
            <div id="tab2" className={`tab${activeTab === "tab2" ? "--active" : "--disabled"}`} />
          </div>
          {activeTab === "tab1" && (
            <VerifyAddress
              goBack={goBack}
              detailedAddress={detailedAddress}
              goNext={() => setActiveTab("tab2")}
              setVerifiedAddress={setVerifiedAddress}
            />
          )}
          {activeTab === "tab2" && <VerifyOfficeData saveOffice={saveOffice} />}
        </div>
      </Card>
    </section>
  );
};

VerifyData.propTypes = {
  detailedAddress: instanceOf(Object),
  goBack: func.isRequired,
  saveOffice: func.isRequired,
  setVerifiedAddress: func.isRequired,
};

VerifyData.defaultProps = {
  detailedAddress: null,
};

export default VerifyData;
