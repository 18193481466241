import { Col, Form, Input, Row } from "antd";
import { func, instanceOf } from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const AddressForm = ({ setAddressForm, inEditionAddress, newAddress }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  useEffect(() => {
    if (inEditionAddress) return form.setFieldsValue(inEditionAddress.office);
    if (newAddress) return form.setFieldsValue(newAddress);
    return form.setFieldsValue({
      areaLevel: "",
      city: "",
      zipcode: "",
      address: "",
    });
  }, [inEditionAddress, form, newAddress]);

  useEffect(() => setAddressForm(form), [form, setAddressForm]);

  return (
    <div className="add-offices__form">
      <Form form={form} name="horizontal_login" layout="vertical">
        <Row>
          <Col xs={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t("error.YouEnterState"),
                },
                {
                  pattern: new RegExp(/^[a-zA-Záéíóú @~`!@#$%^&*()_=+\\\\';:"\\/?>.<,-]+$/i),
                  message: t("error.NotNumbers"),
                },
              ]}
              label={t("label.state")}
              name="areaLevel"
            >
              <Input type="text" placeholder="Guadalajara" />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t("error.YouEnterCity"),
                },
                {
                  pattern: new RegExp(/^[a-zA-Záéíóú @~`!@#$%^&*()_=+\\\\';:"\\/?>.<,-]+$/i),
                  message: t("error.NotNumbers"),
                },
              ]}
              label={t("label.City")}
              name="city"
            >
              <Input type="text" placeholder={t("label.Capital")} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t("error.YouEnterCodePostal"),
                },
              ]}
              label={t("label.PostalCode")}
              name="zipcode"
            >
              <Input type="text" placeholder="00000" />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t("error.YouEnterCountry"),
                },
                {
                  pattern: new RegExp(/^[a-zA-Záéíóú @~`!@#$%^&*()_=+\\\\';:"\\/?>.<,-]+$/i),
                  message: t("error.NotNumbers"),
                },
              ]}
              label={t("label.Country")}
              name="country"
              initialValue="Mexico"
            >
              <Input disabled type="text" placeholder="Mexico" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          rules={[
            {
              required: true,
              message: t("error.YouEnterAddress"),
            },
          ]}
          label={t("label.address")}
          name="address"
        >
          <Input style={{ width: "95%" }} type="text" placeholder="1230 Av. tepec" />
        </Form.Item>
      </Form>
    </div>
  );
};

AddressForm.propTypes = {
  setAddressForm: func.isRequired,
  inEditionAddress: instanceOf(Object),
  newAddress: instanceOf(Object),
};

AddressForm.defaultProps = {
  inEditionAddress: null,
  newAddress: null,
};

export default AddressForm;
