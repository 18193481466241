import { Button } from "antd";
import ModalLinkTree from "components/Modals/ModalLinkTree/ModalLinkTree";
import React from "react";
import PropTypes from "prop-types";

const RecommendedProducts = ({ suggestedProducts, leadDetail, readMore, t, hasSponsor }) => (
  <>
    <div className="container-modal__recommended">
      <div className="container-modal__recommendedHeader">
        <h3>
          {leadDetail.lead?.desiredProduct
            ? "Recomendaciones para el lead"
            : "Productos relacionados"}
        </h3>
      </div>
      <div className="container-modal__recommendedProduct">
        {suggestedProducts?.map((productIterable) => (
          <div className="cont-card" key={productIterable.id}>
            <div
              className="category"
              style={
                hasSponsor
                  ? { backgroundImage: `url(${productIterable.background}` }
                  : {
                      backgroundImage: `linear-gradient(0deg, rgba(75, 58, 124, 0.55), rgba(75, 58, 124, 0.55)), url(${productIterable.background})`,
                    }
              }
            >
              <div>
                <i className={productIterable.productLine?.shortIcon} />
                <img className="image-logo" src={productIterable.icon} alt="Producto" />
              </div>
              <div className="icons">
                {productIterable.insuranceCarrierProducts &&
                  productIterable.insuranceCarrierProducts.map((insuranceCarrier) => (
                    <div key={insuranceCarrier.id} className="circle">
                      <img src={insuranceCarrier.insuranceCarrier.icon} alt="icon" />
                    </div>
                  ))}
              </div>
            </div>
            <div className="buttons">
              <Button onClick={() => readMore(productIterable)} type="link" className="link">
                {t("label.readMore")}
              </Button>
              <ModalLinkTree
                items={productIterable.insuranceCarrierProducts}
                endpoint={productIterable.endPoint}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  </>
);
RecommendedProducts.propTypes = {
  suggestedProducts: PropTypes.instanceOf(Array).isRequired,
  leadDetail: PropTypes.instanceOf(Object).isRequired,
  readMore: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  hasSponsor: PropTypes.bool.isRequired,
};

export default RecommendedProducts;
