/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
import { Button } from "antd";
import { changeRoute } from "common/utils/changeRoute";
import { func, instanceOf } from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import banner from "../../../../../assets/images/banner-analisis.png";
import close from "../../../../../assets/images/close-m.svg";
import i from "../../../../../assets/images/i.svg";
import more from "../../../../../assets/images/more.svg";

const FormAnalisisConnection = ({ selectedInsurance, setSelectedInsurance, setSection }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div className="ConnectionsPIA__form">
      <div className="form form--analisis">
        <div className="form-head">
          <div className="form-head--left">
            <p className="body-bold">Autorizar acceso a</p>
            <img src={selectedInsurance.image} alt="logo" className="image" />
          </div>
          <Button onClick={() => setSelectedInsurance(false)} className="button-transparent">
            <img src={close} alt="close" className="close" />
          </Button>
        </div>
        <img src={banner} alt="banner" className="banner" />
        {selectedInsurance.key === "gnp" ? (
          <h1 className="h1">
            Tus datos se han enviado correctamente, te enviaremos una notificación cuando el proceso
            esté completo.
          </h1>
        ) : (
          <h1 className="h1">
            Ya estamos analizando la información para tu nueva conexión con PIA!
          </h1>
        )}
        <Button
          onClick={() => changeRoute("pia/", history, dispatch)}
          className="button button-primary"
          type="primary"
          htmlType="submit"
        >
          Ir a dashboard P.I.A
        </Button>
        <Button
          className="button button-tertiary"
          type="primary"
          htmlType="submit"
          onClick={() => setSection("FormConnect")}
        >
          <div>
            <img src={i} alt="i" className="i" />
            <span>Adicionar otra cuenta </span>
            <img src={selectedInsurance.image} alt="logo" className="logo" />
          </div>
          <img src={more} alt="more" className="more" />
        </Button>
      </div>
    </div>
  );
};

FormAnalisisConnection.propTypes = {
  selectedInsurance: instanceOf(Object).isRequired,
  setSelectedInsurance: func.isRequired,
  setSection: func.isRequired,
};

export default FormAnalisisConnection;
