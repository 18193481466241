/* eslint-disable no-restricted-syntax */
import { handleActions } from "redux-actions";
import { chunk } from "../../common/utils/chunk";

export const INITIAL_STATE = {
  loading: {
    profile: false,
    license: false,
    insuranceCarrier: false,
    productLines: false,
    complete: false,
  },
  error: {
    profile: false,
    license: undefined,
    insuranceCarrier: undefined,
    productLines: undefined,
    complete: undefined,
  },
  success: {
    profile: undefined,
    licenseTypes: undefined,
    sponsor: undefined,
    insuranceCarriers: undefined,
    productLines: undefined,
    productLinesSelected: undefined,
    complete: undefined,
    insuranceCarriersSelected: undefined,
  },
  officesToSave: [],
  omitForNow: false,
  showTutorial: false,
  step: -1,
};

const reducer = handleActions(
  {
    ONBOARDING: {
      GET_PROFILE: (state, { payload }) => ({
        ...state,
        success: {
          ...state.success,
          complete: undefined,
          productLines: undefined,
          productLinesSelected: undefined,
        },
        loading: { ...state.loading, profile: payload !== undefined ? payload : true },
        error: { ...state.error, profile: undefined },
      }),
      GET_PROFILE_RESPONSE: {
        next(state, { payload: { profile } }) {
          return {
            ...state,
            loading: { ...state.loading, profile: false },
            success: {
              ...state.success,
              profile,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, profile: message },
            success: { ...state.success, profile: undefined },
            loading: { ...state.loading, profile: false },
          };
        },
      },

      GET_LICENSES: (state) => ({
        ...state,
        loading: { ...state.loading, license: true },
        error: { ...state.error, license: undefined },
      }),
      GET_LICENSES_RESPONSE: {
        next(state, { payload: { licenses } }) {
          return {
            ...state,
            loading: { ...state.loading, license: false },
            success: {
              ...state.success,
              licenseTypes: licenses.map((license) => ({
                ...license,
                isSelected: false,
              })),
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, license: message },
            success: { ...state.success, licenseTypes: undefined },
            loading: { ...state.loading, license: false },
          };
        },
      },

      SET_LICENSE: (state, { payload: { id, isSelected, licenseNumber } }) => {
        const newLicenseTypesState = state.success.licenseTypes;
        const licenseTypeIndex = state.success.licenseTypes.findIndex(
          (license) => license.id === id
        );
        if (newLicenseTypesState && licenseTypeIndex > -1) {
          newLicenseTypesState[licenseTypeIndex].isSelected = isSelected;
          newLicenseTypesState[licenseTypeIndex].licenseNumber = licenseNumber;
        }

        return {
          ...state,
          success: {
            ...state.success,
            licenseTypes: newLicenseTypesState,
          },
        };
      },

      SET_IS_SPONSOR: (state, { payload: { sponsor } }) => {
        return {
          ...state,
          success: {
            ...state.success,
            sponsor,
          },
        };
      },

      GET_INSURANCE_CARRIERS: (state) => ({
        ...state,
        loading: { ...state.loading, insuranceCarrier: true },
        error: { ...state.error, insuranceCarrier: undefined },
      }),
      GET_INSURANCE_CARRIERS_RESPONSE: {
        next(state, { payload: { insuranceCarriers } }) {
          const insurances = insuranceCarriers.map((insuranceCarrier) => ({
            ...insuranceCarrier,
            isSelected: false,
          }));
          return {
            ...state,
            loading: { ...state.loading, insuranceCarrier: false },
            success: {
              ...state.success,
              insuranceCarriers: insurances,
              insuranceCarriersSelected: insurances,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, insuranceCarrier: message },
            success: { ...state.success, insuranceCarriers: undefined },
            loading: { ...state.loading, insuranceCarrier: false },
          };
        },
      },

      SET_INSURANCE_CARRIER: (state, { payload: { id, isSelected } }) => {
        const newInsuranceCarriers = state.success.insuranceCarriers;
        const insuranceIndex = state.success.insuranceCarriers.findIndex(
          (insurance) => insurance.id === id
        );

        if (insuranceIndex !== -1) newInsuranceCarriers[insuranceIndex].isSelected = isSelected;

        return {
          ...state,
          success: {
            ...state.success,
            insuranceCarriers: newInsuranceCarriers,
            insuranceCarriersSelected: newInsuranceCarriers.filter(
              (insurance) => insurance.isSelected
            ),
          },
        };
      },

      GET_PRODUCT_LINES: (state) => ({
        ...state,
        loading: { ...state.loading, productLines: true },
        error: { ...state.error, productLines: undefined },
      }),
      GET_PRODUCT_LINES_RESPONSE: {
        next(state, { payload: { productLinesLists } }) {
          const productLinesSelected = [];
          Object.keys(productLinesLists).map((keyList) =>
            productLinesLists[keyList].map((productLine) => {
              return productLinesSelected.push(productLine);
            })
          );
          return {
            ...state,
            loading: { ...state.loading, productLines: false },
            success: {
              ...state.success,
              productLines: productLinesLists,
              productLinesSelected,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, productLines: message },
            success: { ...state.success, productLines: undefined },
            loading: { ...state.loading, productLines: false },
          };
        },
      },

      SET_PRODUCT: (state, { payload: { id, isSelected } }) => {
        const newProductLines = state.success.productLines.map((productLine) => {
          const actualProductLine = { ...productLine };
          const productIndex = productLine.products.findIndex((product) => product.id === id);
          if (productIndex !== -1) actualProductLine.products[productIndex].isSelected = isSelected;

          return actualProductLine;
        });

        return {
          ...state,
          success: {
            ...state.success,
            productLines: newProductLines,
          },
        };
      },

      SET_LAYOUT_PRODUCTS: (state, { payload }) => {
        return {
          ...state,
          success: {
            ...state.success,
            profile: {
              ...state.success.profile,
              layoutProducts: payload,
            },
          },
        };
      },

      SELECT_PRODUCT_LINE: (state, { payload }) => {
        const { productLines: productLinesOriginal } = state.success;

        const allProductLines = [
          Object.keys(productLinesOriginal).map((productList) => [
            ...productLinesOriginal[productList],
          ]),
        ].flat(2);

        const productLineIndex = allProductLines.findIndex(
          (productLine) => productLine.id === payload
        );

        if (productLineIndex > -1)
          allProductLines[productLineIndex] = {
            ...allProductLines[productLineIndex],
            selected: !allProductLines[productLineIndex].selected,
          };
        let indexToReplace;
        if (!allProductLines[productLineIndex].selected) {
          indexToReplace = allProductLines
            .slice()
            .reverse()
            .findIndex((productLine) => productLine.selected);

          indexToReplace =
            indexToReplace >= 0 ? allProductLines.length - 1 - indexToReplace : indexToReplace;

          if (productLineIndex < indexToReplace) {
            const [removed] = allProductLines.splice(productLineIndex, 1);
            allProductLines.splice(indexToReplace, 0, removed);
          }
        } else {
          indexToReplace = allProductLines.findIndex((productLine) => !productLine.selected);

          if (indexToReplace > -1 && indexToReplace < productLineIndex) {
            const [removed] = allProductLines.splice(productLineIndex, 1);
            allProductLines.splice(indexToReplace, 0, removed);
          }
        }

        const productLines = chunk(allProductLines, 6);
        const productLinesLists = {};
        for (const i in productLines) {
          if (productLines[i]) productLinesLists[`list${i}`] = productLines[i];
        }

        const productLinesSelected = allProductLines.filter((productLine) => productLine.selected);

        return {
          ...state,
          success: {
            ...state.success,
            productLines: productLinesLists,
            productLinesSelected,
          },
        };
      },

      SELECT_OFFICE_PRODUCT_LINE: (state) => ({
        ...state,
        success: {
          ...state.success,
        },
      }),
      ORDER_PRODUCT_LINES: (state, { payload: { source, destination } }) => {
        const { productLines: productLinesOriginal } = state.success;
        if (!destination) return state;
        const allProductLines = [
          Object.keys(productLinesOriginal).map((productList) => [
            ...productLinesOriginal[productList],
          ]),
        ].flat(2);

        const realDestinyPosition = destination.droppableId.substring(4) * 6 + destination.index;

        const realSourcePosition = source.droppableId.substring(4) * 6 + source.index;

        const [removed] = allProductLines.splice(realSourcePosition, 1);

        allProductLines.splice(realDestinyPosition, 0, removed);

        if (removed.selected) {
          const actualIndex = allProductLines.findIndex(
            (productLine) => productLine.id === removed.id
          );
          if (actualIndex !== 0) {
            if (
              !allProductLines[actualIndex - 1]?.selected &&
              !allProductLines[actualIndex + 1]?.selected
            )
              allProductLines[actualIndex].selected = !allProductLines[actualIndex].selected;
          }
        } else {
          const actualIndex = allProductLines.findIndex(
            (productLine) => productLine.id === removed.id
          );
          if (allProductLines[actualIndex + 1]?.selected)
            allProductLines[actualIndex].selected = !allProductLines[actualIndex].selected;
        }
        const productLinesSelected = allProductLines.filter((productLine) => productLine.selected);

        const productLines = chunk(allProductLines, 6);
        const productLinesLists = {};
        for (const i in productLines)
          if (productLines[i]) productLinesLists[`list${i}`] = productLines[i];

        return {
          ...state,
          success: {
            ...state.success,
            productLines: productLinesLists,
            productLinesSelected,
          },
        };
      },

      SAVE_ONBOARDING: (state) => ({
        ...state,
        loading: { ...state.loading, complete: true },
        error: { ...state.error, complete: undefined },
      }),
      SAVE_ONBOARDING_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: {
              ...state.loading,
              complete: false,
            },
            success: {
              ...state.success,
              complete: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, complete: message },
            success: { ...state.success, complete: undefined },
            loading: { ...state.loading, complete: false },
          };
        },
      },

      UPDATE_OFFICE_TO_PROFILE: (state, { payload }) => {
        const officeIndex = state.success.profile.offices.findIndex(
          (office) => office.id === payload.id
        );
        const allOffices = [...state.success.profile.offices];
        allOffices.splice(officeIndex, 1, payload);
        return {
          ...state,
          success: {
            ...state.success,
            profile: {
              ...state.success.profile,
              offices: allOffices,
            },
          },
        };
      },

      ADD_OFFICE_TO_SAVE: (state, { payload }) => {
        const { officesToSave } = state;
        officesToSave.push(payload);
        return {
          ...state,
          officesToSave,
        };
      },

      COMPLETE_OFFICE: (state, { payload }) => {
        const { officesToSave } = state;
        officesToSave[officesToSave.length - 1] = payload;

        return {
          ...state,
          officesToSave,
        };
      },

      REMOVE_OFFICE_TO_SAVE: (state, { payload }) => {
        const { officesToSave } = state;
        officesToSave.splice(payload, 1);
        return {
          ...state,
          officesToSave,
        };
      },

      UPDATE_OFFICE_TO_SAVE: (state, { payload }) => {
        const { inEditionAddress, newAddress } = payload;
        const { officesToSave } = state;
        officesToSave.splice(inEditionAddress.index, 1, newAddress);
        return {
          ...state,
          officesToSave,
        };
      },

      SET_OMIT_FOR_NOW: (state, { payload }) => ({
        ...state,
        omitForNow: payload,
      }),

      SET_SHOW_TUTORIAL: (state, { payload }) => ({
        ...state,
        showTutorial: payload,
      }),

      SET_ALL_PROFILE_OFFICES: (state, { payload }) => ({
        ...state,
        success: {
          ...state.success,
          profile: { ...state.success.profile, offices: payload },
        },
      }),

      SET_VERIFIED_OFFICE_STATUS: (state, { payload }) => {
        const newOffices = state.success.profile.offices.map((office) => {
          if (office.id === payload.id) return { ...office, verified: payload.status };
          return office;
        });

        return {
          ...state,
          success: {
            ...state.success,
            profile: { ...state.success.profile, offices: newOffices },
          },
        };
      },

      SET_PROFILE_PHOTO: (state, { payload }) => {
        return {
          ...state,
          success: {
            ...state.success,
            profile: { ...state.success.profile, profilePhoto: payload },
          },
        };
      },

      REMOVE_OFFICE: (state, { payload: id }) => {
        const { offices } = state.success.profile;
        const officeIndex = offices.findIndex((office) => office.id === id);
        if (officeIndex > -1) offices.splice(officeIndex, 1);

        return state;
      },

      SET_STEP: (state, { payload }) => {
        return {
          ...state,
          step: payload,
        };
      },
    },
  },
  INITIAL_STATE
);

export default reducer;
