/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
import { Button, Modal } from "antd";
import editSvg from "assets/images/edit-pensil.svg";
import Loader from "components/loader";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import AgentActions from "services/agent/actions";
import OnboardingActions from "services/onboarding/actions";
import PiaDashboard from "./components/PiaDashboard/PiaDashboard";

const Home = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const onboarding = useSelector((state) => state.onboarding);
  const profile = useSelector((state) => state.auth);
  const leads = useSelector((state) => state.leads);
  const marketplace = useSelector((state) => state.marketplace);
  const report = useSelector((state) => state.reports);
  const agent = useSelector((state) => state.agent);
  const history = useHistory();

  useEffect(() => {
    if (!agent.success.tips && !agent.error.tips && !agent.loading.tips)
      dispatch(AgentActions.getTips());
  }, [agent, dispatch, t]);

  useEffect(() => {
    if (!onboarding.loading.profile && !onboarding.success.profile && !onboarding.error.profile) {
      dispatch(OnboardingActions.getProfile());
    }
  }, [dispatch, onboarding]);
  return (
    <div className="container">
      <Modal
        className="modal-loader"
        visible={
          leads.loading.recentLeads ||
          report.loading.minimalMetrics ||
          marketplace.loading.minimalMarketplace ||
          onboarding.loading.profile ||
          agent.loading.publish
        }
        centered
        maskStyle={{ backgroundColor: "#ffffff66" }}
      >
        <Loader />
      </Modal>
      <div className="home">
        <div className="home__nav">
          <a href="./pia/" className="link">
            Mi cartera
          </a>
          <a href="./pia/commissions" className="link">
            Comisiones
          </a>
        </div>
        {profile?.profile?.agent?.onBoarding === "Inactive" && (
          <div className="home__head-completed">
            <p>
              Falta poco para que tengas acceso a todos nuestros beneficios, ¡Completa tu perfil!
            </p>
            <Button onClick={() => history.push("/editPage/")}>
              <img src={editSvg} className="edit" alt="edit" />
              Completa tu página web
            </Button>
          </div>
        )}
        <PiaDashboard />
      </div>
    </div>
  );
};

export default Home;
