/* eslint-disable prefer-template */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable import/no-cycle */
import { message } from "antd";
import { FILE_VIEWER_HOST } from "common/config/config";
import parseNumberThousand from "common/utils/parseThousandNumber";
import availableInsurances from "common/utils/piaActiveInsurances";
import moment from "moment";
import { all, put, select, takeLatest } from "redux-saga/effects";
import Api from "../../common/api/Api";
import ApiPiaConnection from "../../common/api/ApiPia";
import * as Token from "../../common/storage/Token";
import PoliciesActions from "./actions";

function* getAllAccounts() {
  let {
    success: { insurances },
  } = yield select((state) => state.policies);

  const url = `/pia/accounts`;

  const response = yield Api.get(url);
  if (response.success)
    insurances = insurances.map((insurance) => {
      const foundAccount = response.detail.filter(
        (account) => account.insurance.key === insurance.key
      );

      if (foundAccount.find((account) => account.status === "invalid"))
        return {
          ...insurance,
          state: "vencida",
          order: 3,
        };

      if (foundAccount.find((account) => account.status === "process"))
        return {
          ...insurance,
          state: "proceso",
          order: 2,
        };

      if (
        foundAccount.length > 0 &&
        foundAccount.every((account) => account.status === "connected")
      )
        return {
          ...insurance,
          state: "conectado",
          order: 1,
        };

      return {
        ...insurance,
        state: "desconectado",
        order: 4,
      };
    });

  insurances.sort((a, b) => {
    if (a.order > b.order) return 1;
    if (a.order < b.order) return -1;
    return 0;
  });

  if (response.success)
    return yield put(PoliciesActions.getAllAccountsResponse(response.detail, insurances));

  let err;
  if (response.error) err = new TypeError(response.error);
  else err = new TypeError("ERROR_GETTING_ACCOUNTS");

  return yield put(PoliciesActions.getAllAccountsResponse(err, response));
}

function* registerAccount({ payload }) {
  const urlAccount = `/pia/account`;
  let userName = payload.cuenta;
  if (payload.claveAgente) userName = `${payload.cuenta}/${payload.claveAgente}`;

  const responseAccount = yield Api.post(urlAccount, {
    userName,
    password: payload.contrasena,
    insurance: payload.identificadorAseguradora,
  });

  if (responseAccount.success) {
    yield put(PoliciesActions.getAllAccounts());
    return yield put(PoliciesActions.registerAccountResponse(responseAccount.data));
  }

  let err;
  if (responseAccount.error) err = new TypeError("Error al guardar tu cuenta");
  else err = new TypeError("ERROR_GETTING_ACCOUNTS");

  return yield put(PoliciesActions.registerAccountResponse(err, responseAccount));
}

function* updateAccount({ payload }) {
  const urlAccount = `/pia/account`;

  const responseAccount = yield Api.put(urlAccount, {
    reference: payload.reference,
    userName: payload.cuenta,
    password: payload.contrasena,
    insurance: payload.identificadorAseguradora,
  });

  if (responseAccount.success) {
    yield put(PoliciesActions.getAllAccounts());
    return yield put(PoliciesActions.updateAccountResponse(responseAccount.data));
  }

  let err;
  if (responseAccount.error) err = new TypeError(responseAccount.error);
  else err = new TypeError("ERROR_UPDATING_ACCOUNT");

  return yield put(PoliciesActions.updateAccountResponse(err, responseAccount));
}

function* updateStatusPolicy({ payload }) {
  const url = `/policy-quotation-status`;
  const response = yield Api.post(url, payload);

  if (response.success) {
    message.success({
      content: "Cambio del estatus con Éxito.",
      className: "status-message",
    });
    return yield put(PoliciesActions.updateStatusPolicyResponse(payload.policyId, payload.status));
  }

  let err;
  if (response.error) err = new TypeError(response.error);
  else err = new TypeError("ERROR_UPDATING_STATUS_POLICY");
  message.error({
    content: "Hubo un error al intentar cambiar el estatus.",
    className: "status-message",
  });

  return yield put(PoliciesActions.updateStatusPolicyResponse(err, response));
}

function* deleteAccount({ payload }) {
  const urlDeleteAccount = `/pia/account/${payload}`;

  const deleteAccountResponse = yield Api.delete(urlDeleteAccount);

  if (!deleteAccountResponse.success) {
    let err;
    if (deleteAccountResponse.error) err = new TypeError(deleteAccountResponse.error);
    else err = new TypeError("ERROR_DELETING_ACCOUNT");

    return yield put(PoliciesActions.deleteAccountResponse(err, deleteAccountResponse));
  }

  yield put(PoliciesActions.getAllAccounts());
  return yield put(PoliciesActions.deleteAccountResponse());
}

function* getAllPolicies({ payload }) {
  const url = `get-all-policies-by-account/${payload}`;
  const response = yield ApiPiaConnection.get(url);
  if (response.message === "Transacción completada satisfactoriamente.")
    return yield put(PoliciesActions.getAllPoliciesResponse(response.data, -1));

  let err;
  if (response.error) err = new TypeError(response.error);
  else err = new TypeError("ERROR_GETTING_ACCOUNTS");

  return yield put(PoliciesActions.getAllPoliciesResponse(err, response));
}

function* getCover({ payload }) {
  const url = "/pia/cover/";
  const response = yield Api.get(url + payload.cover);
  if (response.success) {
    return yield put(PoliciesActions.getCoverResponse(response.detail));
  }

  const err = new TypeError(response.message);

  return yield put(PoliciesActions.getCoverResponse(err));
}

function* getNextRenovations() {
  const url = "/pia/summary";
  const filters = yield select((state) => state.policies.filters);

  const filtersParsed = {};
  Object.keys(filters).map((key) => {
    if (key === "Buscar") {
      filtersParsed[key] = filters[key];
      return key;
    }
    if (filters[key].filter((value) => `${value}` !== "0").join(","))
      filtersParsed[key] = filters[key].filter((value) => `${value}` !== "0").join(",");
    return key;
  });

  if (!filtersParsed.Buscar) delete filtersParsed.Buscar;

  const params = {};

  if (filtersParsed.Ramos) params.Ramos = filtersParsed.Ramos;

  const response = yield Api.get(url, params);

  if (response.success) {
    response.detail.insurances = response.detail.insurances
      .map((insurance) => ({
        ...insurance,
        image: `${FILE_VIEWER_HOST}/images/insurance-carrier/pia/${insurance.key}-white.svg`,
      }))
      .filter((insurance) => insurance.quantityOfExpirations !== 0);
    return yield put(PoliciesActions.getNextRenovationsResponse(response.detail?.insurances));
  }

  return yield put(PoliciesActions.getNextRenovationsResponse([]));
}

function* getAllComissions() {
  const url = `/pia/total-values`;
  const response = yield Api.get(url);
  if (response.success) {
    return yield put(PoliciesActions.getAllComissionsResponse(response));
  }
  let err;
  if (response.error) err = new TypeError(response.error);
  else err = new TypeError("ERROR_GETTING_ACCOUNTS");
  return yield put(PoliciesActions.getAllComissionsResponse(err, response));
}

function* getAllInsurances() {
  const url = `/insurance-carrier`;

  const response = yield Api.get(url);
  if (response.status === "SUCCESS") {
    response.payload = response.payload
      .filter((insurance) =>
        availableInsurances.find((availableInsurance) => availableInsurance === insurance.key)
      )
      .map((insurance) => ({
        ...insurance,
        SlugName: insurance.key,
        image: `${FILE_VIEWER_HOST}/images/insurance-carrier/pia/${insurance.key}.svg`,
        grayImage: `${FILE_VIEWER_HOST}/images/insurance-carrier/pia/${insurance.key}-gray.svg`,
        whiteImage: `${FILE_VIEWER_HOST}/images/insurance-carrier/pia/${insurance.key}-white.svg`,
        icon: `${FILE_VIEWER_HOST}/images/insurance-carrier/pia/${insurance.key}-icon.svg`,
        state: "desconectado",
      }))
      .sort((a, b) => {
        if (a.SlugName > b.SlugName) return 1;
        if (a.SlugName < b.SlugName) return -1;

        return 0;
      });
    return yield put(PoliciesActions.getAllInsurancesResponse(response.payload));
  }
  let err;
  if (response.error) err = new TypeError(response.error);
  else err = new TypeError("ERROR_GETTING_ACCOUNTS");

  return yield put(PoliciesActions.getAllInsurancesResponse(err, response));
}

function* getLastPolicies() {
  const filters = yield select((state) => state.policies.filters);

  const piaPolicies = yield Api.get(`/pia`, {
    FechaInicial: filters.FechaInicial[0],
    FechaFinal: filters.FechaFinal[0],
    page: 1,
    quantity: filters.PageSize[0],
  });
  if (piaPolicies.success) {
    piaPolicies.detail = {
      ...piaPolicies.detail,
      Commissions: {
        Count: parseNumberThousand(piaPolicies.detail.commissionAbstract?.total),
        Value: parseNumberThousand(piaPolicies.detail.commissionAbstract?.value, "price"),
      },
      PoliciesReceivable: {
        Count: parseNumberThousand(piaPolicies.detail.policyAbstract?.total),
        Value: parseNumberThousand(piaPolicies.detail.policyAbstract?.value, "price"),
      },
    };
    yield put(
      PoliciesActions.getAllPoliciesResponse(
        piaPolicies.detail?.data,
        piaPolicies.detail.totalPages - filters.NoPage[0],
        piaPolicies.detail
      )
    );
    return yield put(PoliciesActions.getAbstractResponse(piaPolicies.detail));
  }

  let err;
  if (piaPolicies.message) err = new TypeError(piaPolicies.message);
  else err = new TypeError("ERROR_GETTING_ACCOUNTS");

  return yield put(PoliciesActions.getAllPoliciesResponse(err, piaPolicies));
}

function* getFilteredPolicies() {
  const filters = yield select((state) => state.policies.filters);
  if (filters.Buscar) {
    const piaPolicies = yield Api.get(`/pia/search`, { q: filters.Buscar });
    if (!!piaPolicies.success)
      return yield put(
        PoliciesActions.getAllPoliciesResponse(
          piaPolicies.detail?.data,
          piaPolicies.detail.totalPages - filters.NoPage[0]
        )
      );

    return yield put(PoliciesActions.getAllPoliciesResponse([], 0));
  }
  const policies = (yield select((state) => state.policies.success.policies)) || [];

  const filtersParsed = {};
  Object.keys(filters).map((key) => {
    if (key === "Buscar") {
      filtersParsed[key] = filters[key];
      return key;
    }
    if (filters[key].filter((value) => `${value}` !== "0").join(","))
      filtersParsed[key] = filters[key].filter((value) => `${value}` !== "0").join(",");
    return key;
  });

  if (!filtersParsed.Buscar) delete filtersParsed.Buscar;

  const params = {
    FechaInicial: filters.FechaInicial[0],
    FechaFinal: filters.FechaFinal[0],
    page: filters.NoPage,
    quantity: filters.PageSize,
    accounts: filtersParsed.IdentificadorCuenta || "",
  };

  if (params.accounts === "") delete params.accounts;

  if (filtersParsed.Ramos) params.Ramos = filtersParsed.Ramos;
  if (filtersParsed.Estado) params.Estado = filtersParsed.Estado;
  if (filtersParsed.Vencimiento) params.Vencimiento = filtersParsed.Vencimiento;

  const piaPolicies = yield Api.get(`/pia`, params);

  if (piaPolicies.success) {
    if (filters.NoPage[0] > 1) {
      const initialPolicies = [...policies, ...piaPolicies.detail?.data];

      yield put(
        PoliciesActions.getAllPoliciesResponse(
          initialPolicies,
          piaPolicies.totalPages - filters.NoPage[0]
        )
      );
      const abstractData = {
        ...piaPolicies.detail,
        Commissions: {
          Count: parseNumberThousand(piaPolicies.detail.commissionAbstract?.total),
          Value: parseNumberThousand(piaPolicies.detail.commissionAbstract?.value, "price"),
        },
        PoliciesReceivable: {
          Count: parseNumberThousand(piaPolicies.detail.policyAbstract?.total),
          Value: parseNumberThousand(piaPolicies.detail.policyAbstract?.value, "price"),
        },
      };
      return yield put(PoliciesActions.getAbstractResponse(abstractData));
    }
    const abstractData = {
      ...piaPolicies.detail,
      Commissions: {
        Count: parseNumberThousand(piaPolicies.detail.commissionAbstract?.total),
        Value: parseNumberThousand(piaPolicies.detail.commissionAbstract?.value, "price"),
      },
      PoliciesReceivable: {
        Count: parseNumberThousand(piaPolicies.detail.policyAbstract?.total),
        Value: parseNumberThousand(piaPolicies.detail.policyAbstract?.value, "price"),
      },
    };
    yield put(
      PoliciesActions.getAllPoliciesResponse(
        piaPolicies.detail?.data,
        piaPolicies.detail.totalPages - filters.NoPage[0]
      )
    );

    return yield put(PoliciesActions.getAbstractResponse(abstractData));
  }

  let err;
  if (piaPolicies.message) err = new TypeError(piaPolicies.message);
  else err = new TypeError("ERROR_GETTING_ACCOUNTS");

  return yield put(PoliciesActions.getAllPoliciesResponse(err, piaPolicies));
}

function* getCloseToExpiration() {
  const axaPolicies = yield Api.get(`/pia`, {
    FechaInicial: moment(new Date()).startOf("month").subtract(1, "y").format("YYYY-MM-01"),
    FechaFinal: moment(new Date()).startOf("month").format("YYYY-MM-01"),
  });

  if (!axaPolicies.success || !axaPolicies.detail.data) return false;

  axaPolicies.detail.data = axaPolicies.detail?.data.map((policy) => ({
    ...policy,
    diffDate: moment(policy.DateExpired).diff(moment(), "day"),
  }));

  axaPolicies.detail.data.sort((a, b) => {
    if (a.diffDate > b.diffDate) return 1;
    if (a.diffDate < b.diffDate) return -1;

    return 0;
  });

  const firstTargetBranch = axaPolicies.detail.data?.length > 0 && {
    Branch: axaPolicies.detail.data[0].Branch,
    diffDate: axaPolicies.detail.data[0].diffDate,
  };
  const secondTargetBranch = axaPolicies.detail.data.find(
    (policy) => policy.Branch !== firstTargetBranch.Branch
  );

  if (firstTargetBranch)
    firstTargetBranch.value = axaPolicies.detail.data.filter(
      (policy) =>
        policy.Branch === firstTargetBranch.Branch && policy.diffDate === firstTargetBranch.diffDate
    ).length;

  if (secondTargetBranch)
    secondTargetBranch.value = axaPolicies.detail.data.filter(
      (policy) =>
        policy.Branch === secondTargetBranch.Branch &&
        policy.diffDate === secondTargetBranch.diffDate
    ).length;

  return yield put(
    PoliciesActions.getCloseToExpirationResponse(firstTargetBranch, secondTargetBranch)
  );
}

function* getDetail({ payload }) {
  const secondResponse = yield Api.get(`/pia/${payload}`);

  if (secondResponse.success) {
    yield put(PoliciesActions.getCover(secondResponse.detail?.General[0]?.Cover));
    return yield put(PoliciesActions.getDetailResponse(secondResponse.detail));
  }

  let err;
  if (secondResponse.error) err = new TypeError(secondResponse.error);
  else err = new TypeError("ERROR_GETTING_DETAIL");

  return yield put(PoliciesActions.getDetailResponse(err, secondResponse));
}

function* styleDark() {
  const stateDark = yield select((state) => state.policies.styleDark);
  return yield put(PoliciesActions.styleDarkResponse(!stateDark));
}

function* getCommissionsSummary() {
  const filters = yield select((state) => state.policies.filtersSummary);
  const filtersParsed = {};
  Object.keys(filters).map((key) => {
    if (filters[key].filter((value) => `${value}` !== "0").join(","))
      filtersParsed[key] = filters[key].filter((value) => `${value}` !== "0").join(",");
    return key;
  });
  const params = {
    FechaInicial: filters.FechaInicial[0],
    FechaFinal: filters.FechaFinal[0],
  };
  const response = yield Api.get(`/pia/commission/summary`, params);
  if (response.success) {
    return yield put(PoliciesActions.getCommissionsSummaryResponse(response.detail));
  }

  let err;
  if (response.error) err = new TypeError(response.error);
  else err = new TypeError("ERROR_GETTING_DETAIL");

  return yield put(PoliciesActions.getCommissionsSummaryResponse(err, response));
}

function* getCommissions() {
  const filters = yield select((state) => state.policies.filtersCommissions);

  const filtersParsed = {};
  Object.keys(filters).map((key) => {
    if (key === "Buscar") {
      filtersParsed[key] = filters[key];
      return key;
    }
    if (filters[key].filter((value) => `${value}` !== "0").join(","))
      filtersParsed[key] = filters[key].filter((value) => `${value}` !== "0").join(",");
    return key;
  });

  const params = {
    FechaInicial: filters.FechaInicial[0],
    FechaFinal: filters.FechaFinal[0],
    accounts: filtersParsed.IdentificadorCuenta || "",
  };
  if (filtersParsed.Ramos) params.Ramos = filtersParsed.Ramos;
  const response = yield Api.get(`/pia/commission/chart`, params);
  if (response.success) {
    return yield put(PoliciesActions.getCommissionsResponse(response.detail));
  }

  let err;
  if (response.error) err = new TypeError(response.error);
  else err = new TypeError("ERROR_GETTING_DETAIL");

  return yield put(PoliciesActions.getCommissionsResponse(err, response));
}

function* getMonthEvents({ payload }) {
  const token = yield Token.decodeToken();
  if (!token.agent?.policiesAccount)
    return yield put(
      PoliciesActions.getMonthEventsResponse(new TypeError("El agente no está registrado"))
    );

  const url = `calendar-policies/${token?.agent?.policiesAccount}`;
  const response = yield ApiPiaConnection.get(url, { Year: payload.year, Month: payload.month });
  if (response.message === "Transacción completada satisfactoriamente.")
    return yield put(PoliciesActions.getMonthEventsResponse(response.data));

  let err;
  if (response.error) err = new TypeError(response.error);
  else err = new TypeError("ERROR_SEARCHING_POLICIES");

  return yield put(PoliciesActions.getMonthEventsResponse(err, response));
}

function* uploadCover({ payload }) {
  const token = yield Token.decodeToken();
  if (!token.agent?.policiesAccount)
    return yield put(
      PoliciesActions.uploadCoverResponse(new TypeError("El agente no está registrado"))
    );

  const url = `upload-cover/${payload.policyId}`;
  const response = yield ApiPiaConnection.put(url, { type: "base64", document: payload.image });
  if (response.message === "Transacción exitosa.")
    return yield put(PoliciesActions.uploadCoverResponse());

  let err;
  if (response.error) err = new TypeError(response.error);
  else err = new TypeError("ERROR_UPLOADING_COVER");

  return yield put(PoliciesActions.uploadCoverResponse(err, response));
}

function* getOffers() {
  const filtersOferts = yield select((state) => state.policies?.filtersOferts);
  const response = yield Api.get("/pia/offer/policies", {
    page: 1,
    order: filtersOferts.order,
  });
  if (response.success) {
    return yield put(PoliciesActions.getOffersResponse(response.detail));
  }

  let err;
  if (response.success) err = new TypeError(response.detail);
  else err = new TypeError(response.detail);

  return yield put(PoliciesActions.getOffersResponse(err, response));
}

function* getInsuranceAccountStatus() {
  const url = `/pia/status/policies`;
  const response = yield Api.get(url);
  if (response.success) {
    return yield put(PoliciesActions.getInsuranceAccountStatusResponse(response.detail.data));
  }
  let err;
  if (response.success) err = new TypeError(response.detail);
  else err = new TypeError(response.detail);
  return yield put(PoliciesActions.getInsuranceAccountStatusResponse(err, response));
}

function* ActionWatcher() {
  yield takeLatest(PoliciesActions.getOffers, getOffers);
  yield takeLatest(PoliciesActions.styleDark, styleDark);
  yield takeLatest(PoliciesActions.getCommissionsSummary, getCommissionsSummary);
  yield takeLatest(PoliciesActions.getAllInsurances, getAllInsurances);
  yield takeLatest(PoliciesActions.getAllAccounts, getAllAccounts);
  yield takeLatest(PoliciesActions.updateAccount, updateAccount);
  yield takeLatest(PoliciesActions.registerAccount, registerAccount);
  yield takeLatest(PoliciesActions.deleteAccount, deleteAccount);
  yield takeLatest(PoliciesActions.getAllPolicies, getAllPolicies);
  yield takeLatest(PoliciesActions.getLastPolicies, getLastPolicies);
  yield takeLatest(PoliciesActions.setFilters, getFilteredPolicies);
  yield takeLatest(PoliciesActions.setFiltersCommissions, getCommissions);
  yield takeLatest(PoliciesActions.setFiltersCommissionsSummary, getCommissionsSummary);
  yield takeLatest(PoliciesActions.getCloseToExpiration, getCloseToExpiration);
  yield takeLatest(PoliciesActions.getMonthEvents, getMonthEvents);
  yield takeLatest(PoliciesActions.uploadCover, uploadCover);
  yield takeLatest(PoliciesActions.getNextRenovations, getNextRenovations);
  yield takeLatest(PoliciesActions.getCover, getCover);
  yield takeLatest(PoliciesActions.getDetail, getDetail);
  yield takeLatest(PoliciesActions.updateStatusPolicy, updateStatusPolicy);
  yield takeLatest(PoliciesActions.getCommissions, getCommissions);
  yield takeLatest(PoliciesActions.getInsuranceAccountStatus, getInsuranceAccountStatus);
  yield takeLatest(PoliciesActions.getAllComissions, getAllComissions);
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
