import { Button, Input, message, Modal } from "antd";
import { string } from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import edit from "../../../../../assets/images/edit.png";
import Loader from "../../../../../components/loader";
import accountActions from "../../../../../services/account/actions";
import OnboardingActions from "../../../../../services/onboarding/actions";

const PhoneUpdate = ({ phone }) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [newPhone, setNewPhone] = useState("");

  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);

  useEffect(() => {
    if (account.success.updatePhone) {
      message.success("Teléfono actualizado");
      dispatch(OnboardingActions.getProfile());
    }

    if (account.error.updatePhone) message.error(t(`error.${account.error.updatePhone}`));

    if (account.error.updatePhone || account.success.updatePhone)
      dispatch(accountActions.clearUpdatePhone());
  }, [account, dispatch, t]);

  const updatePhone = () => {
    if (newPhone === phone) return message.error("El teléfono ingresado es igual al actual");
    if (newPhone === "") return message.error("El teléfono no puede estar vacío");
    dispatch(accountActions.updatePhone(newPhone));
    return setIsEditing(false);
  };

  return (
    <div className="my-account__content--section">
      <Modal
        className="modal-loader"
        visible={account.loading.updatePhone}
        centered
        maskStyle={{ backgroundColor: "#ffffff66" }}
      >
        <Loader />
      </Modal>

      <h4>{t("label.WhatsAppNumber")}</h4>
      <div className="center">
        <div className="redes">
          <Input
            className="input-wahtsaap"
            disabled={!isEditing}
            placeholder={phone}
            type="number"
            onChange={(e) => setNewPhone(e.target.value)}
            suffix={
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.208 9.06686C10.0325 8.9805 9.17808 8.57511 9.01839 8.51725C8.8587 8.46195 8.74277 8.43303 8.62639 8.60361C8.51264 8.76993 8.17752 9.15066 8.07514 9.26168C7.97277 9.37271 7.87214 9.38122 7.69933 9.30592C7.52389 9.21957 6.96345 9.04218 6.29802 8.46195C5.77827 8.01232 5.4322 7.45846 5.32939 7.28788C5.22702 7.11943 5.31802 7.02414 5.40464 6.93991C5.48427 6.86249 5.58008 6.74509 5.66889 6.64299C5.75333 6.5409 5.78045 6.47241 5.84214 6.36181C5.89902 6.24228 5.8697 6.14912 5.82639 6.06489C5.78308 5.98066 5.43439 5.14562 5.2887 4.81297C5.14958 4.48287 5.00389 4.52498 4.8967 4.52498C4.79652 4.51605 4.68014 4.51605 4.5642 4.51605C4.44827 4.51605 4.25883 4.55816 4.09914 4.71981C3.93945 4.89039 3.48839 5.29791 3.48839 6.12232C3.48839 6.94885 4.1127 7.74858 4.19933 7.86811C4.28814 7.97871 5.42739 9.68027 7.1752 10.4115C7.59214 10.5821 7.91589 10.6842 8.16877 10.7684C8.5857 10.8969 8.96633 10.879 9.26689 10.8369C9.59939 10.7837 10.2968 10.4268 10.4429 10.0282C10.5912 9.62709 10.5912 9.29486 10.5479 9.21957C10.5046 9.14215 10.3908 9.10004 10.2154 9.02474L10.208 9.06686ZM7.03827 13.2454H7.02908C5.99439 13.2454 4.97152 12.9728 4.07814 12.4632L3.86858 12.3415L1.68108 12.8954L2.26908 10.8258L2.12777 10.6131C1.55003 9.71998 1.24353 8.6863 1.24358 7.63117C1.24358 4.54285 3.84364 2.0237 7.04264 2.0237C8.59227 2.0237 10.0461 2.61073 11.1398 3.67421C11.6794 4.19258 12.1074 4.81024 12.3988 5.49115C12.6903 6.17206 12.8393 6.90258 12.8373 7.6401C12.833 10.7263 10.2351 13.2454 7.04045 13.2454H7.03827ZM11.9715 2.86554C10.6406 1.61575 8.89064 0.90918 7.02908 0.90918C3.18958 0.90918 0.0632031 3.93582 0.0610156 7.65542C0.0610156 8.8431 0.379953 10.0019 0.990703 11.0275L0.00195312 14.5216L3.69795 13.5845C4.72184 14.1212 5.8663 14.4035 7.02952 14.4063H7.0317C10.8734 14.4063 13.9998 11.3797 14.002 7.65754C14.002 5.85645 13.2796 4.16128 11.9628 2.88724L11.9715 2.86554Z"
                  fill="#B2B2B2"
                />
              </svg>
            }
          />
          {isEditing ? (
            <div className="btns-section-account">
              <Button onClick={() => setIsEditing(false)} className="button button--tertiary">
                {t("button.cancel")}
              </Button>
              <Button
                onClick={() => {
                  updatePhone();
                }}
                className="button button--secundary"
              >
                {t("button.updateInsuranceCarriers")}
              </Button>
            </div>
          ) : (
            <Button
              onClick={() => {
                if (!isEditing) return setIsEditing(true);
                return updatePhone();
              }}
              className="btn-edit"
            >
              <img src={edit} alt="editar" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

PhoneUpdate.propTypes = {
  phone: string.isRequired,
};

export default PhoneUpdate;
