/* eslint-disable no-param-reassign */
import { func } from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../../../components/loader";
import OnboardingActions from "../../../../../../../services/onboarding/actions";
import "./insuranceCarrier.scss";

const InsuranceCarrier = ({ activeGoNext }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onboarding = useSelector((state) => state.onboarding);

  useEffect(() => {
    if (
      !onboarding.loading.insuranceCarrier &&
      !onboarding.success.insuranceCarriers &&
      !onboarding.error.insuranceCarrier
    ) {
      dispatch(OnboardingActions.getInsuranceCarriers());
    }
  }, [dispatch, onboarding]);

  const setStyleSvg = (i, isSelected) => {
    let svg = document.querySelector(`#insurancei`);
    if (!svg) return;
    svg = svg.contentDocument.querySelector("svg");
    const paths = svg.querySelectorAll("path");
    paths.forEach((element) => {
      element.style.fill = isSelected ? "white" : "#A8A8A8";
    });
  };

  const setInsuranceCarrier = (insurance, i) => {
    setStyleSvg(i, !insurance.isSelected);
    dispatch(OnboardingActions.setInsuranceCarrier(insurance.id, !insurance.isSelected));
    activeGoNext();
  };

  return (
    <div className="insuranceCarrier">
      <div className="insuranceCarrier__text">
        <h4>{t("label.selectInsuranceCarriers")}</h4>
        <p>{t("label.selectOneOrMore")}</p>
      </div>
      <div className="insuranceCarrier__wrapper">
        {onboarding.loading.insuranceCarrier && <Loader />}
        <div className="insuranceCarrier__wrapperButtons">
          {onboarding.success.insuranceCarriers &&
            onboarding.success.insuranceCarriers.map((insuranceCarrier, i) => (
              <button
                className={`insuranceCarrier${
                  insuranceCarrier.isSelected ? "--active" : "--disabled"
                }`}
                key={insuranceCarrier.id}
                onClick={() => setInsuranceCarrier(insuranceCarrier, i)}
                type="button"
              >
                <img
                  alt={insuranceCarrier.key}
                  src={
                    insuranceCarrier.isSelected ? insuranceCarrier.logoWhite : insuranceCarrier.logo
                  }
                />
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};

InsuranceCarrier.propTypes = {
  activeGoNext: func.isRequired,
};

export default InsuranceCarrier;
