import { Button, Col, Form, Input, Row } from "antd";
import { postcodeValidator } from "postcode-validator";
import { func, instanceOf } from "prop-types";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

const VerifyAddress = ({ office, setAddress }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const setOffice = useCallback(() => {
    form.setFieldsValue({
      country: "México",
      areaLevel: office?.areaLevel,
      city: office?.city,
      address: office?.address,
      zipcode: office?.zipcode,
    });
  }, [form, office]);

  useEffect(() => {
    if (office) setOffice();
  }, [office, setOffice]);

  const onFinish = async (values) => {
    try {
      const isValid = postcodeValidator(values.zipcode, "MX");
      if (!isValid) throw new Error();
    } catch (err) {
      return form.setFields([
        {
          name: "zipcode",
          errors: ["Debe ser un código postal de México"],
        },
      ]);
    }
    return setAddress({ ...values });
  };

  return (
    <div className="offices-editing__cardForms">
      <h3>{t("label.VerifyAddress")}</h3>
      <Form form={form} name="horizontal_login" layout="vertical" onFinish={onFinish}>
        <Row>
          <Col xs={12}>
            <Form.Item
              label={t("label.Country")}
              name="country"
              rules={[
                {
                  required: true,
                  message: t("error.YouEnterCountry"),
                },
              ]}
              initialValue="Mexico"
            >
              <Input type="text" placeholder="Mexico" />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label={t("label.state")}
              name="areaLevel"
              rules={[
                {
                  required: true,
                  message: t("error.YouEnterState"),
                },
                {
                  pattern: new RegExp(/^[a-zA-Záéíóú @~`!@#$%^&*()_=+\\\\';:"\\/?>.<,-]+$/i),
                  message: t("error.NotNumbers"),
                },
              ]}
            >
              <Input type="text" placeholder="Guadalajara" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              label={t("label.City")}
              name="city"
              rules={[
                {
                  required: true,
                  message: t("error.YouEnterCity"),
                },
                {
                  pattern: new RegExp(/^[a-zA-Záéíóú @~`!@#$%^&*()_=+\\\\';:"\\/?>.<,-]+$/i),
                  message: t("error.NotNumbers"),
                },
              ]}
            >
              <Input type="text" placeholder="Capital" />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label={t("label.PostalCode")}
              name="zipcode"
              rules={[
                {
                  required: true,
                  message: t("error.YouEnterCodePostal"),
                },
              ]}
            >
              <Input type="text" placeholder="00000" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={t("label.address")}
          name="address"
          rules={[
            {
              required: true,
              message: t("error.YouEnterAddress"),
            },
          ]}
        >
          <Input style={{ width: "95%" }} type="text" placeholder="1230 Av. tepec" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("label.nextStep")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

VerifyAddress.propTypes = {
  office: instanceOf(Object).isRequired,
  setAddress: func.isRequired,
};

export default VerifyAddress;
