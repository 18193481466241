/* eslint-disable import/no-cycle */
import { all, put, takeLatest } from "redux-saga/effects";
import ApiConnection from "../../common/api/Api";
import parseError from "../../common/api/ErrorParser";
import MarketplaceActions from "./actions";
import OnboardingActions from "../onboarding/actions";

function* getMinimalMarketplace({ payload }) {
  const url = "/product/marketplace-dashboard";
  const response = yield ApiConnection.get(url, payload);
  if (response.success)
    return yield put(
      MarketplaceActions.getMinimalMarketplaceResponse({
        marketplace: response.detail,
      })
    );

  return yield put(
    MarketplaceActions.getMinimalMarketplaceResponse(parseError(response), response)
  );
}

function* setProductStatus() {
  return yield put();
}

function* getInsuranceCarriers() {
  const url = "/insurance-carrier";
  const response = yield ApiConnection.get(url);
  if (response.status === "SUCCESS")
    return yield put(MarketplaceActions.getInsuranceCarriersResponse(response.payload));

  return yield put(MarketplaceActions.getInsuranceCarriersResponse(parseError(response), response));
}

function* getSpecialProducts() {
  const url = "/product/specials";
  const response = yield ApiConnection.get(url);
  if (response.success) {
    return yield put(MarketplaceActions.getSpecialProductsResponse(response.detail));
  }
  return yield put(MarketplaceActions.getSpecialProductsResponse(parseError(response), response));
}

function* getAllProducts() {
  const url = "/product/filter-insurance-carrier";
  const response = yield ApiConnection.get(url);
  if (response.success) {
    return yield put(MarketplaceActions.getAllProductsResponse(response.detail));
  }
  return yield put(MarketplaceActions.getAllProductsResponse(parseError(response), response));
}

function* getProductsByInsuranceCarrier({ payload: { insuranceCarrier } }) {
  const url = "/product/filter-insurance-carrier";
  const response = yield ApiConnection.get(url, { insuranceCarrier });
  if (response.success || !response.error) {
    return yield put(
      MarketplaceActions.getProductsByInsuranceCarrierResponse(insuranceCarrier, response.detail)
    );
  }
  return yield put(
    MarketplaceActions.getProductsByInsuranceCarrierResponse(parseError(response), response)
  );
}

function* setAgentProduct({ payload: { product } }) {
  const url = "/agent/set-agent-product";
  const response = yield ApiConnection.post(url, { product });
  if (response.success) {
    yield put(OnboardingActions.getProfile());
    return yield put(MarketplaceActions.setAgentProductResponse(response));
  }

  return yield put(MarketplaceActions.setAgentProductResponse(parseError(response), response));
}

function* getInsuranceLines() {
  const url = "/product-line";
  const response = yield ApiConnection.get(url);
  if (response.status === "SUCCESS") {
    const lines = response.payload.map((item) => {
      return {
        id: item.id,
        title: item.title,
        icon: item.icon,
        iconWhite: item.iconWhite,
      };
    });
    return yield put(MarketplaceActions.getInsuranceLinesResponse(lines));
  }
  return yield put(MarketplaceActions.getInsuranceLinesResponse(parseError(response), response));
}

function* getProductsByInsuranceLine({ payload: { insuranceLine } }) {
  const url = "/product/filter-product-line";
  const response = yield ApiConnection.get(url, { productLine: insuranceLine });
  if (response.success) {
    return yield put(
      MarketplaceActions.getProductsByInsuranceLineResponse(insuranceLine, response.detail)
    );
  }
  return yield put(
    MarketplaceActions.getProductsByInsuranceLineResponse(parseError(response), response)
  );
}

function* getProduct({ payload: { product } }) {
  const url = "/product/detail";
  const response = yield ApiConnection.get(url, { product });
  if (response.success) {
    return yield put(MarketplaceActions.getProductResponse(response.detail[0]));
  }
  return yield put(MarketplaceActions.getProductResponse(parseError(response), response));
}

function* requestHelp({ payload: { product } }) {
  const url = "/product/request-help";
  const response = yield ApiConnection.get(url, { product });
  if (response.success) {
    return yield put(MarketplaceActions.requestHelpWithInsuranceCarrierResponse());
  }
  return yield put(
    MarketplaceActions.requestHelpWithInsuranceCarrierResponse(parseError(response), response)
  );
}

function* ActionWatcher() {
  yield takeLatest(MarketplaceActions.getMinimalMarketplace, getMinimalMarketplace);
  yield takeLatest(MarketplaceActions.setProductStatus, setProductStatus);
  yield takeLatest(MarketplaceActions.requestHelpWithInsuranceCarrier, requestHelp);
  yield takeLatest(MarketplaceActions.getInsuranceCarriers, getInsuranceCarriers);
  yield takeLatest(MarketplaceActions.getSpecialProducts, getSpecialProducts);
  yield takeLatest(MarketplaceActions.getProductsByInsuranceCarrier, getProductsByInsuranceCarrier);
  yield takeLatest(MarketplaceActions.setAgentProduct, setAgentProduct);
  yield takeLatest(MarketplaceActions.getInsuranceLines, getInsuranceLines);
  yield takeLatest(MarketplaceActions.getProductsByInsuranceLine, getProductsByInsuranceLine);
  yield takeLatest(MarketplaceActions.getAllProducts, getAllProducts);
  yield takeLatest(MarketplaceActions.getProduct, getProduct);
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
