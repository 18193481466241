/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { Button } from "antd";
import CarGreenSvg from "assets/images/car-green.svg";
import demoSvg from "assets/images/demo.svg";
import PetsSvg from "assets/images/pets.svg";
import DamageGreenSvg from "assets/images/pia-brands/damage-green.svg";
import GmmGreenSvg from "assets/images/pia-brands/gmm-icon-green.svg";
import PiaSvg from "assets/images/Pia.png";
import VidaGreenSvg from "assets/images/vida-green.svg";
import { changeRoute } from "common/utils/changeRoute";
import Loader from "components/loader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Slider from "react-slick";
import PoliciesActions from "services/policies/actions";

const branchImages = {
  gmm: GmmGreenSvg,
  autos: CarGreenSvg,
  daños: DamageGreenSvg,
  vida: VidaGreenSvg,
};

const PiaDashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const policiesStore = useSelector((state) => state.policies);

  const [actualInsurances, setActualInsurances] = useState([]);
  useEffect(() => {
    if (
      !policiesStore.loading.insurances &&
      !policiesStore.success.insurances &&
      !policiesStore.error.insurances
    )
      dispatch(PoliciesActions.getAllInsurances());
  }, [policiesStore, dispatch]);

  useEffect(() => {
    if (
      !policiesStore.loading.accounts &&
      !policiesStore.success.accounts &&
      !policiesStore.error.accounts &&
      policiesStore.success.insurances
    )
      dispatch(PoliciesActions.getAllAccounts());

    if (
      !policiesStore.loading.closeToExpiration &&
      !policiesStore.success.closeToExpiration &&
      !policiesStore.error.closeToExpiration
    )
      dispatch(PoliciesActions.getCloseToExpiration());
  }, [policiesStore, dispatch]);

  useEffect(() => {
    if (policiesStore?.success?.insurances && policiesStore?.success.accounts) {
      const newActualInsurances = [...policiesStore.success.insurances]
        .filter(
          (insurance) =>
            policiesStore?.success.accounts?.filter(
              (account) => account.insurance.key === insurance.key
            ).length > 0
        )
        .sort((insurance) => {
          if (
            policiesStore?.success.accounts?.filter(
              (account) => account.insurance.key === insurance.key
            ).length > 0
          )
            return 1;

          if (
            policiesStore?.success.accounts?.filter(
              (account) => account.insurance.key === insurance.key
            ).length > 0
          )
            return -1;

          return 0;
        });

      if (newActualInsurances.length > 4) newActualInsurances.length = 4;

      setActualInsurances(newActualInsurances);
    }
  }, [policiesStore]);

  useEffect(() => {
    if (
      policiesStore?.success?.accounts &&
      policiesStore.success.accounts?.some((account) => account.status === "connected")
    ) {
      history.push("/pia");
    }
  }, [policiesStore, history]);

  const dummyProducts = [
    { id: 1, image: CarGreenSvg, name: "Autos (2)", time: "Dentro de 5 días" },
    { id: 2, image: PetsSvg, name: "Mascotas (5)", time: "Dentro de 20 días" },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1.1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "10px",
  };
  return (
    <div className="PiaDashboard">
      {policiesStore?.loading.abstract || policiesStore?.loading?.accounts ? (
        <Loader className="loader-ligth" />
      ) : policiesStore?.success.abstract ? (
        <div className="PiaDashboard--data">
          <div className="PiaDashboard--card PiaDashboard--card--title">
            <img src={PiaSvg} alt="imgPia" className="img-pia" />
            <div className="box">
              <h1>P.I.A</h1>
              <p>Portal Inteligente de Agente</p>
            </div>
          </div>
          <div className="pia-content-dashboard">
            <div className="PiaDashboard--card PiaDashboard--card--big">
              <h3 className="body-bold">Conexiones</h3>
              <div className="card-pia card-pia--big">
                <div className="card-pia--brand">
                  {actualInsurances.map((insurance) => (
                    <div className="brand" key={insurance.key}>
                      <div className="content-brand">
                        <div className={`brand--circle brand--circle--${insurance.state}`} />
                        <div className="brand--cont">
                          <img src={insurance.icon} className="image" alt="brand" />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="line" />
                <div className="section-state">
                  <div className="state">
                    <p>Conectada</p>
                    <div className="circle circle-active" />
                  </div>
                  <div className="state">
                    <p>Revisar</p>
                    <div className="circle circle-inactive" />
                  </div>
                  <div className="state">
                    <p>En proceso</p>
                    <div className="circle circle-medium" />
                  </div>
                </div>
              </div>
              <Button
                className="button button-primary"
                onClick={() => changeRoute("account/pia", history, dispatch)}
              >
                Ir a conexiones
              </Button>
            </div>
            <div className="PiaDashboard--card PiaDashboard--card--min">
              <h3 className="body-bold">Próximos vencimientos</h3>
              <div className="card-pia card-pia-null">
                <div className="card-pia--box">
                  {policiesStore.success.closeToExpiration.firstBranch ? (
                    <>
                      <div className="bar">
                        <div className="bar-left">
                          <img
                            src={
                              branchImages[
                                policiesStore.success.closeToExpiration.firstBranch?.Branch?.toLowerCase()
                              ]
                            }
                            className="image"
                            alt="brand"
                          />
                          <p className="small-detail">
                            {`${policiesStore.success.closeToExpiration.firstBranch?.Branch?.toLowerCase()} (${
                              policiesStore.success.closeToExpiration.firstBranch?.value
                            })`}
                          </p>
                        </div>
                        <p className="small-detail small-detail--yellow">
                          {`Dentro de ${
                            policiesStore.success.closeToExpiration.firstBranch?.diffDate
                          } ${
                            policiesStore.success.closeToExpiration.firstBranch?.diffDate === 1
                              ? "día"
                              : "días"
                          }`}
                        </p>
                      </div>
                    </>
                  ) : (
                    <Loader className="loader-small" />
                  )}
                  {policiesStore.success.closeToExpiration.secondBranch && (
                    <>
                      <div className="bar">
                        <div className="bar-left">
                          <img
                            src={
                              branchImages[
                                policiesStore.success.closeToExpiration.secondBranch?.Branch?.toLowerCase()
                              ]
                            }
                            className="image"
                            alt="brand"
                          />
                          <p className="small-detail">
                            {policiesStore.success.closeToExpiration.secondBranch?.Branch?.toLowerCase()}
                          </p>
                        </div>
                        <p className="small-detail small-detail--yellow">
                          {`Dentro de ${
                            policiesStore.success.closeToExpiration.secondBranch?.value
                          } ${
                            policiesStore.success.closeToExpiration.secondBranch?.value === 1
                              ? "día"
                              : "días"
                          }`}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <Button
                onClick={() => changeRoute("pia", history, dispatch)}
                className="button button-primary"
              >
                Ver Pólizas
              </Button>
            </div>
            <div className="PiaDashboard--card">
              <h3 className="body-bold">
                Comisiones
                <span>(1 año)</span>
              </h3>
              <div className="card-pia card-pia--mx">
                <p className="small-detail">MXP</p>
                <p className="text">{policiesStore.success?.abstract?.Commissions.Value}</p>
              </div>
              <Button
                onClick={() => changeRoute("pia/comisiones", history, dispatch)}
                className="button button-primary"
              >
                Ver Reportes
              </Button>
            </div>
          </div>
          <div className="content-res">
            <Slider {...settings}>
              <div className="PiaDashboard--card">
                <h3 className="body-bold">Conexiones</h3>
                <div className="card-pia">
                  <div className="card-pia--brand">
                    {actualInsurances.map((insurance) => (
                      <div className="brand" key={insurance.key}>
                        <div className="content-brand">
                          <div
                            className={`brand--circle brand--circle--${
                              insurance.state === "conectado" ? "active" : "inactive"
                            }`}
                          />
                          <div className="brand--cont">
                            <img src={insurance.icon} className="image" alt="brand" />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="line" />
                  <div className="section-state">
                    <div className="state">
                      <p>Conectada</p>
                      <div className="circle circle-active" />
                    </div>
                    <div className="state">
                      <p>Revisar</p>
                      <div className="circle circle-inactive" />
                    </div>
                    <div className="state">
                      <p>En proceso</p>
                      <div className="circle circle-medium" />
                    </div>
                  </div>
                </div>
                <Button
                  className="button button-primary"
                  onClick={() => changeRoute("account/pia", history, dispatch)}
                >
                  Ir a conexiones
                </Button>
              </div>
              <div className="PiaDashboard--card PiaDashboard--card--min">
                <h3 className="body-bold">Próximos vencimientos</h3>
                <div className="card-pia card-pia-null">
                  <div className="card-pia--box">
                    {dummyProducts?.map((item) => (
                      <div className="bar" key={item.id}>
                        <div className="bar-left">
                          <img src={item.image} className="image" alt="brand" />
                          <p className="small-detail">{item.name}</p>
                        </div>
                        <p className="small-detail small-detail--yellow">{item.time}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <Button
                  onClick={() => changeRoute("pia", history, dispatch)}
                  className="button button-primary"
                >
                  Ver Pólizas
                </Button>
              </div>
              <div className="PiaDashboard--card">
                <h3 className="body-bold">
                  Comisiones
                  <span>(1 año)</span>
                </h3>
                <div className="card-pia card-pia--mx">
                  <p className="small-detail">MXP</p>
                  <p className="text">{policiesStore.success?.abstract?.Commissions.Value}</p>
                </div>
                <Button
                  onClick={() => changeRoute("pia/comisiones", history, dispatch)}
                  className="button button-primary"
                >
                  Ver reportes
                </Button>
              </div>
            </Slider>
          </div>
        </div>
      ) : (
        <div className="PiaDashboard--empty">
          <div className="PiaDashboard--card PiaDashboard--card--title">
            <img src={PiaSvg} alt="imgPia" className="img-pia" />
            <div className="box">
              <h1>PIAA</h1>
              <p>
                Portal Inteligente <br /> de Agente & Aseguradoras
              </p>
            </div>
          </div>
          <div className="PiaDashboard--empty__content">
            <p>
              Prepárate para vivir una experiencia digital simplificando y concentrando tu
              información en un sólo lugar con nuestro Portal Inteligente de Agentes & Aseguradoras
              (PIAA){" "}
            </p>
            <div className="buttons">
              <Button
                onClick={() => changeRoute("account/pia", history, dispatch)}
                className="see-demo"
              >
                Ver demo
                <img src={demoSvg} className="play" alt="play" />
              </Button>
              <Button
                onClick={() => changeRoute("account/pia", history, dispatch)}
                className="button button-primary"
              >
                Conecta tus portales aquí
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default PiaDashboard;
