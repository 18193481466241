import { Button } from "antd";
import { changeRoute } from "common/utils/changeRoute";
import { func, instanceOf } from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ClockPng from "../../../../../assets/images/clock-big.png";
import CloseSvg from "../../../../../assets/images/close-m.svg";
import CloseRedSvg from "../../../../../assets/images/close-red.svg";
import EditSvg from "../../../../../assets/images/edit.svg";
import GeneralWarningSvg from "../../../../../assets/images/general_warning.svg";
import i from "../../../../../assets/images/i.svg";
import ModalLikePng from "../../../../../assets/images/modal-like.png";
import MoreSvg from "../../../../../assets/images/more.svg";

const ConnectionStatus = ({
  selectedInsunrace,
  setSelectedInsurance,
  setSection,
  accounts,
  setEdit,
  deleteAccount,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleUpdateAcount = (item) => {
    setEdit(item);
    setSection("FormConnect");
  };
  const handleClose = () => {
    setEdit();
    setSection("");
    setSelectedInsurance(false);
  };

  return (
    <div className="ConnectionsPIA__form">
      <div className="form">
        <div className="form-head">
          <div className="form-head--left">
            <p className="body-bold">Autorizar acceso a</p>
            <img src={selectedInsunrace.image} alt="logo" className="image" />
          </div>
          <Button onClick={() => handleClose()} className="button-transparent">
            <img src={CloseSvg} alt="close" className="close" />
          </Button>
        </div>
        <div className="content-form">
          <div className="cont-img">
            {!accounts?.some((account) => account.status === "invalid") &&
              accounts?.some((account) => account.status === "process") && (
                <img src={ClockPng} alt="analisis" className="image" />
              )}

            {accounts?.some((account) => account.status === "invalid") && (
              <img src={GeneralWarningSvg} alt="analisis" className="image" />
            )}

            {accounts.length > 0 &&
              accounts?.every((account) => account.status === "connected") && (
                <img src={ModalLikePng} className="image" alt="Todo bien" />
              )}
          </div>

          {accounts?.some((account) => account.status === "invalid") && (
            <h1 className="h1 h1--red">Tienes una cuenta con la clave vencida o errónea</h1>
          )}

          {accounts?.length > 0 && accounts?.every((account) => account.status === "completed") && (
            <h1 className="h1">
              <span>Todo en orden con {selectedInsunrace.CommercialName}</span>
            </h1>
          )}

          {!accounts?.some((account) => account.status === "invalid") &&
            accounts?.some((account) => account.status === "process") && (
              <h1 className="h1 h1--yellow">Tienes una cuenta en proceso</h1>
            )}
          <div className="body">
            {accounts?.map((account) => (
              <div
                className={`account 
                  ${account.status === "invalid" && "account--red"}
                  ${account.status === "process" && "account--yellow"}
                `}
                key={account.uuid}
              >
                <div className="account__box">
                  <p className="body-bold">Cuenta</p>
                  <p className="account__box__account">{account.username}</p>
                </div>
                <div className="account__box">
                  <Button
                    onClick={() => handleUpdateAcount(account)}
                    className="button-transparent"
                  >
                    <img src={EditSvg} className="edit" alt="edit" />
                  </Button>
                  <Button
                    onClick={() => deleteAccount(account.reference)}
                    className="button-transparent"
                  >
                    <img src={CloseRedSvg} className="closeRed" alt="closeRed" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <Button
            className="button button-primary"
            type="primary"
            htmlType="submit"
            onClick={() => changeRoute("pia/", history, dispatch)}
          >
            Ir a dashboard P.I.A
          </Button>

          <Button
            className="button button-tertiary"
            type="primary"
            htmlType="submit"
            onClick={() => {
              setEdit();
              setSection("FormConnect");
            }}
          >
            <div className="flex">
              <div>
                <img src={i} alt="i" className="i" />
                <span>Adicionar otra cuenta </span>
              </div>
              <img src={selectedInsunrace.image} alt="logo" className="logo" />
            </div>
            <img src={MoreSvg} alt="more" className="more" />
          </Button>
        </div>
      </div>
    </div>
  );
};

ConnectionStatus.propTypes = {
  selectedInsunrace: instanceOf(Object).isRequired,
  setSelectedInsurance: func.isRequired,
  setSection: func.isRequired,
  accounts: instanceOf(Array).isRequired,
  setEdit: func.isRequired,
  deleteAccount: func.isRequired,
};

export default ConnectionStatus;
