/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Alert, Col, Form, Input, message, Row } from "antd";
import { func } from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import pass from "../../../../../assets/icon/icon-password-gray.svg";
import user from "../../../../../assets/icon/icon-user-gray.svg";
import google from "../../../../../assets/icon/icon-google-purple.svg";
import app from "../../../../../common/firebase";
import { getSocialInfo, initGoogleAuthProvider } from "../../../../../common/firebase/auth";
import Loader from "../../../../../components/loader";
import AuthActions from "../../../../../services/auth/actions";

const layout = {
  wrapperCol: {
    span: 24,
  },
};

const Login = ({ changeToForgotPassword, setWhoIsActive, setOAuthData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const [oauthError, setOauthError] = useState(false);
  const [lastOauthData, setLastOauthData] = useState();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.error.login) {
      if (auth.error.login === "USER_NOT_FOUND_NETWORK_SOCIAL") setOAuthData(lastOauthData);
      else message.error(t(`error.${auth.error.login}`));
      dispatch(AuthActions.clearLoginProcess());
    }
  }, [auth, t, dispatch, setOAuthData, lastOauthData]);

  useEffect(() => {
    if (auth.success.isMigrated) history.push("/migrated-greetings");

    if (auth.success.passwordRequired) changeToForgotPassword();
  }, [auth, history, changeToForgotPassword]);

  const onFinish = (values) => dispatch(AuthActions.login(values));

  const socialLogin = async (provider, type) => {
    await app
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        const loginData = getSocialInfo(result.additionalUserInfo.profile, type);
        setLastOauthData(loginData);
        onFinish(loginData);
      })
      .catch(() => {
        setOauthError(`oauth${type}`);
      });
  };

  return (
    <div className="login">
      {auth.loading.login || auth.loading.signup ? (
        <div className="Loader">
          <Loader />
        </div>
      ) : (
        <div className="login-content">
          <h1 className="login__redesTitle">{t("label.loginTitle")}</h1>
          <br />
          <div className="card-login">
            <Row className="container">
              <figure className="login__redes">
                <div className="images">
                  <div
                    className="red"
                    id="googlr-button-login"
                    onClick={() => socialLogin(initGoogleAuthProvider(), "Google")}
                  >
                    <img className="icon_google" src={google} alt="google" />
                  </div>
                </div>
                <div className="login__redesSeparator">{t("label.or")}</div>
              </figure>
            </Row>
            <div className="login__form">
              <Form {...layout} name="login" onFinish={onFinish}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t("error.email"),
                      type: "email",
                    },
                  ]}
                >
                  <Input
                    id="email-input-login"
                    prefix={<img src={user} alt="user" />}
                    placeholder={t("placeholder.inputEmail")}
                    className="input-custom"
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t("error.password"),
                    },
                  ]}
                >
                  <Input.Password
                    id="password-input-login"
                    prefix={<img src={pass} alt="password" />}
                    placeholder={t("placeholder.inputPass")}
                    className="input-custom"
                  />
                </Form.Item>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <a
                      href="/"
                      id="forgot-pass-button-login"
                      onClick={(e) => {
                        e.preventDefault();
                        changeToForgotPassword();
                      }}
                      className="login__link-forgot-pass"
                    >
                      {t("button.forgetPassword")}
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col lg={24}>
                    <button
                      id="login-button-login"
                      className="btn-custom btn-custom--secundary btn--login"
                      disabled={auth.loading.login}
                      type="submit"
                    >
                      {t("button.login")}
                    </button>
                    <button
                      id="signup-button-login"
                      className="btn-custom btn-custom--tertiary btn--login-tertiary"
                      onClick={() => setWhoIsActive("signup")}
                      type="button"
                    >
                      {t("button.goSignUp")}
                    </button>
                  </Col>
                </Row>
              </Form>
              {auth.success && auth.success.login && (
                <div className="alert">
                  <Alert
                    message={t("alert.LoginSuccessful")}
                    type="success"
                    showIcon
                    icon={
                      <svg
                        className="icon-check-svg"
                        width="16"
                        height="12"
                        viewBox="0 0 16 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.83877 5.6076L5.39204 9.16088L14.3405 0.212402L15.4703 1.34218L5.39204 11.4204L0.708984 6.73738L1.83877 5.6076Z"
                          fill="#5D30DD"
                        />
                      </svg>
                    }
                  />
                </div>
              )}
              {oauthError && (
                <div className="alert">
                  <Alert message={t(`error.${oauthError}`)} type="error" showIcon />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Login.propTypes = {
  changeToForgotPassword: func.isRequired,
  setWhoIsActive: func.isRequired,
  setOAuthData: func.isRequired,
};

export default Login;
