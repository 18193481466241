import moment from "moment";
import { instanceOf } from "prop-types";
import React from "react";

const Policy = ({ contract }) => (
  <div className="Policy__content">
    <div className="Policy__content--column-left box">
      <p className="sub-title">Persona</p>
      <div className="item">
        <p className="body-bold">Tipo de persona</p>
        <p className="body-regular">{contract.contractor.personType.name}</p>
      </div>
      <div className="item">
        <p className="body-bold">RFC</p>
        <p className="body-regular">{contract.contractor.rfc}</p>
      </div>
      {contract.contractor.birthDate && (
        <div className="item">
          <p className="body-bold">Fecha de Nacimiento</p>
          <p className="body-regular">{contract.contractor.birthDate}</p>
        </div>
      )}
      <div className="item">
        <p className="body-bold">Código postal</p>
        <p className="body-regular">{contract.contractor.postalCode}</p>
      </div>
      {contract.contractor.secondaryEmail && (
        <div className="item">
          <p className="body-bold">Correo Electrónico adicional</p>
          <p className="body-regular">{contract.contractor.secondaryEmail}</p>
        </div>
      )}
      {contract.contractor.secondaryPhone && (
        <div className="item">
          <p className="body-bold">Teléfono adicional</p>
          <p className="body-regular">{contract.contractor.secondaryPhone}</p>
        </div>
      )}
    </div>
    <div className="Policy__content--column-right box">
      <p className="sub-title">Póliza</p>
      <div className="item">
        <div className="column">
          <p className="body-bold">Linea de producto</p>
          <p className="body-regular">{contract.policy.productLine}</p>
        </div>
        <div className="column">
          <p className="body-bold">Producto</p>
          <p className="body-regular">{contract.policy.product}</p>
        </div>
        <div className="column">
          <p className="body-bold">Aseguradora</p>
          <img src={contract.policy.insuranceCarrier.logoColor} alt="Aseguradora" />
        </div>
      </div>
      <div className="item">
        <div className="column">
          <p className="body-bold">Fecha de aplicación</p>
          <p className="body-regular">
            {contract.policy.applicationDate &&
              moment(contract.policy.applicationDate).format("DD/MM/YYYY")}
          </p>
        </div>
        <div className="column">
          <p className="body-bold">Fecha de inicio</p>
          <p className="body-regular">
            {contract.policy.startDate && moment(contract.policy.startDate).format("DD/MM/YYYY")}
          </p>
        </div>
        <div className="column">
          <p className="body-bold">Fecha final</p>
          <p className="body-regular">
            {contract.policy.endDate && moment(contract.policy.endDate).format("DD/MM/YYYY")}
          </p>
        </div>
      </div>
      <div className="item">
        <div className="column">
          <p className="body-bold">Moneda</p>
          <p className="body-regular">{contract.policy.currency.name}</p>
        </div>
        <div className="column">
          <p className="body-bold">Prima neta</p>
          <p className="body-regular">${contract.policy.bonusNet}</p>
        </div>
        <div className="column">
          <p className="body-bold">Prima total</p>
          <p className="body-regular">${contract.policy.bonusTotal}</p>
        </div>
      </div>
    </div>
  </div>
);

Policy.propTypes = {
  contract: instanceOf(Object).isRequired,
};

export default Policy;
