const showTranslatedText = (text, language) => {
  try {
    if (!language || !text) return "";

    const parsedText = JSON.parse(text);
    if (language) if (parsedText[language.split("-")[0]]) return parsedText[language.split("-")[0]];

    return parsedText.es;
  } catch (ex) {
    return text;
  }
};

export default showTranslatedText;
