import { createActions } from "redux-actions";

const { editMyPage } = createActions({
  EDIT_MY_PAGE: {
    CHANGE_EDIT_STATUS: (section) => ({
      section,
    }),

    MAKE_SECTION_VISIBLE: (section) => ({
      section,
    }),

    MAKE_ALL_SECTIONS_VISIBLE: () => ({}),

    SAVE_ALL: (state) => state,
  },
});

export default editMyPage;
