import { createActions } from "redux-actions";

const { routes } = createActions({
  ROUTES: {
    GET_ROUTES: (page, quantity, order) => ({
      page,
      quantity,
      order,
    }),
    GET_ROUTES_RESPONSE: (users) => users,

    SET_ACTUAL_ROUTE: (actualPosition) => ({
      actualPosition,
    }),
  },
});

export default routes;
