import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import arrow from "assets/images/arrow-green.svg";
import { changeRoute } from "../../../common/utils/changeRoute";

const Notifications = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const dummyNotify = [];
  return (
    <div className="section-notifications">
      <h1>{t("label.notifications")}</h1>
      <div className="section-notifications--content">
        {dummyNotify?.map((item) => (
          <Button key={item.id} className="button-arrow notify">
            <div className="notify-text">
              <h4 className="body-bold">{item.name}</h4>
              <h5 className="span-opacity">{item.date}</h5>
              <h6 className="body-regular">{item.description}</h6>
            </div>
            <img src={arrow} alt="arrow-green" className="arrow-green" />
          </Button>
        ))}
        {dummyNotify.length <= 0 && <h3>No tienes notificaciones sin leer</h3>}
        <Button
          onClick={() => changeRoute("", history, dispatch)}
          className="btn-custom btn-custom--secundary"
        >
          {t("label.GoToDashboard")}
        </Button>
      </div>
    </div>
  );
};

export default Notifications;
