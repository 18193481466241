import { Alert, Button, Card, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import AuthActions from "../../../services/auth/actions";

const useQuery = () => new URLSearchParams(useLocation().search);

const EmailVerification = () => {
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [verified, setIsVerified] = useState(false);

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    const email = query.get("email");
    if (!verified) {
      dispatch(
        AuthActions.verifyEmail({
          email,
        })
      );
      setIsVerified(true);
    }

    if (!email) history.replace("");
  }, [dispatch, query, history, verified]);

  return (
    <div className="change-password">
      <Card className="change-password__card" title={t("label.verifyinEmail")}>
        <Spin spinning={auth.loading.emailVerification} />
        {auth.error && auth.error.emailVerification && (
          <div className="alert">
            <Alert message={t(`error.${auth.error.emailVerification}`)} type="error" showIcon />
          </div>
        )}
        {auth.success && auth.success.emailVerification && (
          <div className="alert">
            <Alert
              message={t("label.congratEmailActivated")}
              type="success"
              icon={
                <svg
                  className="icon-check-svg"
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.83877 5.6076L5.39204 9.16088L14.3405 0.212402L15.4703 1.34218L5.39204 11.4204L0.708984 6.73738L1.83877 5.6076Z"
                    fill="#5D30DD"
                  />
                </svg>
              }
              showIcon
            />
            <Button
              onClick={() => {
                history.replace("/");
              }}
            >
              Ir al login
            </Button>
          </div>
        )}
      </Card>
    </div>
  );
};

export default EmailVerification;
