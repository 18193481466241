import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import piaSvg from "assets/images/Pia-svg.svg";
import { Dropdown } from "antd";
import PoliciesActions from "services/policies/actions";
import piaMenu from "./PiaMenuConection";

const NavigationMenu = () => {
  const policiesStore = useSelector((state) => state.policies);
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      !policiesStore.loading.insuranceAccountStatus &&
      !policiesStore.success.insuranceAccountStatus &&
      !policiesStore.error.insuranceAccountStatus
    )
      dispatch(PoliciesActions.getInsuranceAccountStatus());
  }, [policiesStore, dispatch]);

  return (
    <div className="header--item">
      <a className="link pia" href="/pia">
        Mi Cartera
      </a>
      <a className="link reports" href="/pia/commissions">
        Comisiones
      </a>
      <Dropdown
        overlay={piaMenu(policiesStore?.success?.insuranceAccountStatus)}
        trigger={["click"]}
        placement="bottomRight"
        overlayClassName="header__dropdown-pia-menu"
      >
        <a
          href="./"
          id="menu-button-header"
          className="custom-trigger-btn"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <div className="alert" />
          <img src={piaSvg} className="piaSvg" alt="pia" />
        </a>
      </Dropdown>
    </div>
  );
};

export default NavigationMenu;
