import { Button } from "antd";
import { FILE_VIEWER_HOST } from "common/config/config";
import { bool, func, instanceOf } from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ArrowRightSvg from "../../../../../../../../../assets/icon/arrow-right.svg";
import ArrowSvg from "../../../../../../../../../assets/icon/arrow.svg";
import deleteSvg from "../../../../../../../../../assets/icon/offices-delete.svg";
import editSvg from "../../../../../../../../../assets/icon/offices-edit.svg";
import OnboardingActions from "../../../../../../../../../services/onboarding/actions";

const AddedOffices = ({ offices, sideBarCollapsed, setInEditionAddress, canEdit }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  return (
    <div className="added-offices">
      <div className="added-offices__header">
        <h5>
          {t("label.offices")} ({offices.length})
        </h5>
        <img
          style={{ marginRight: sideBarCollapsed ? 0 : 10 }}
          src={sideBarCollapsed ? ArrowSvg : ArrowRightSvg}
          alt="arrow"
        />
      </div>
      <div className="added-offices__wrapper">
        {offices.map((office, i) => (
          <div key={office.address} className="added-offices__info">
            <h6 className="address">{t("label.address")}:</h6>
            <h6>{office.address}</h6>
            <h6>{office.state}</h6>
            <h6>
              {office.zipcode}, {office.city}
            </h6>
            <h6>{office.country}</h6>
            {office.productLines?.length > 0 && (
              <>
                <h6 className="text-lines">{t("label.lines")}:</h6>
                <div className="lines">
                  {office.productLines?.map((productLine) => (
                    <div key={productLine.id} className="lines__wrapper">
                      <img src={productLine.iconWhite} alt="lines" />
                    </div>
                  ))}
                </div>
              </>
            )}
            {office.insuranceCarriers?.length > 0 && (
              <>
                <h6>{t("label.insuranceCarriers")}:</h6>
                <div className="insurances">
                  {office.insuranceCarriers.map((insuranceCarrier) => (
                    <img
                      key={insuranceCarrier.id}
                      src={`${FILE_VIEWER_HOST}/images/insurance-carrier/icon/${insuranceCarrier.key}.svg`}
                      alt="insurances"
                    />
                  ))}
                </div>
              </>
            )}
            {canEdit && (
              <div className="added-offices__buttons">
                <Button
                  onClick={() => {
                    dispatch(OnboardingActions.removeOfficeToSave(i));
                  }}
                  disabled={!canEdit}
                >
                  <img src={deleteSvg} alt="delete" />
                  {t("button.delete")}
                </Button>
                <Button
                  disabled={!canEdit}
                  onClick={() => setInEditionAddress({ index: i, office })}
                >
                  <img src={editSvg} alt="edit" />
                  {t("button.edit")}
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

AddedOffices.propTypes = {
  offices: instanceOf(Array).isRequired,
  sideBarCollapsed: bool.isRequired,
  setInEditionAddress: func.isRequired,
  canEdit: bool.isRequired,
};

export default AddedOffices;
