import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import PiaPng from "assets/images/Pia.png";
import dowloadSvg from "assets/images/dowload.svg";
import lupSvg from "assets/images/lup.svg";
import solSvg from "assets/images/pia-brands/sol.svg";
import lunaSvg from "assets/images/luna.svg";
import arrowRtSvg from "assets/images/arrow-rigth-green-line.svg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import PropTypes, { bool } from "prop-types";
import Loader from "components/loader";
import PoliciesActions from "services/policies/actions";

const HeaderFeedBack = ({ styleDark }) => {
  const [actualInsurances, setActualInsurances] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const policiesStore = useSelector((state) => state.policies);
  const onboarding = useSelector((state) => state.onboarding);
  useEffect(() => {
    if (
      !policiesStore.loading.insurances &&
      !policiesStore.success.insurances &&
      !policiesStore.error.insurances
    ) {
      dispatch(PoliciesActions.getAllInsurances());
    }
  }, [policiesStore, dispatch]);

  const changeStyleDark = () => {
    dispatch(PoliciesActions.styleDark());
  };

  useEffect(() => {
    if (policiesStore?.success?.insurances && policiesStore?.success.accounts) {
      const newActualInsurances = [...policiesStore.success.insurances]
        .filter(
          (insurance) =>
            policiesStore?.success.accounts?.filter(
              (account) => account.insurance.key === insurance.key
            ).length > 0
        )
        .sort((insurance) => {
          if (
            policiesStore?.success.accounts?.filter(
              (account) => account.insurance.key === insurance.key
            ).length > 0
          )
            return 1;

          if (
            policiesStore?.success.accounts?.filter(
              (account) => account.insurance.key === insurance.key
            ).length > 0
          )
            return -1;

          return 0;
        });

      if (newActualInsurances.length > 4) newActualInsurances.length = 4;
      setActualInsurances(newActualInsurances);
    }
  }, [policiesStore, dispatch]);
  return (
    <div className="HeaderFeedBack">
      <div className="box">
        <img src={PiaPng} className="piaImage" alt="pia" />
        <h1 className="title">
          Hola
          <br />
          {onboarding?.success?.profile?.user?.person?.name}
        </h1>
      </div>
      <div className="box box-two">
        <div className="box-item">
          <div className="card-policies">
            {actualInsurances?.length <= 0 ? (
              <Loader className="loader-small" />
            ) : (
              <div className="card-policies__head">
                {actualInsurances?.map((insurance) => (
                  <div className="item" key={insurance.key}>
                    <div className={`circle circle--${insurance.state}`} />
                    <div className="item-image">
                      <img src={insurance.icon} className="brand" alt="aseguradora" />
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="card-policies__link">
              <Button className="btn" onClick={() => history.push("/account/pia")}>
                Gestionar conexiones
                <img src={arrowRtSvg} className="arrow" alt="arrow" />
              </Button>
            </div>
          </div>
        </div>
        <div className="box-item box-item-two">
          <Input
            size="large"
            placeholder="Busca por póliza, asegurado, serie, etc"
            prefix={<img src={lupSvg} className="lup" alt="lup" />}
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.target.value.length >= 3)
                return dispatch(PoliciesActions.setFilters("Buscar", e.target.value));
              if (e.key === "Enter" && e.target.value.length === 0)
                return dispatch(PoliciesActions.getLastPolicies());
              return null;
            }}
            onChange={(e) =>
              e.target.value.length === 0 && dispatch(PoliciesActions.getLastPolicies())
            }
          />
          <Button className="button-transparent">
            <img src={dowloadSvg} className="dowloadSvg" alt="dowloadSvg" />
          </Button>
          <Button className="button-transparent" onClick={() => changeStyleDark()}>
            {styleDark ? (
              <img src={solSvg} className="luna" alt="luna" />
            ) : (
              <img src={lunaSvg} className="luna" alt="luna" />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

HeaderFeedBack.propTypes = {
  styleDark: PropTypes.instanceOf(bool).isRequired,
};
export default HeaderFeedBack;
