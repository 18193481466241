import { Button, Card } from "antd";
import { func, string } from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import marker from "../../../../../../../../../assets/images/marker.png";

const OfficeSaved = ({ finish, action }) => {
  const { t } = useTranslation();

  return (
    <section className="offices-editing">
      <Card className="offices-editing__card">
        <div className="offices-editing__cardHeader">
          <h4>Mi negocio</h4>
        </div>
        <div className="wrapper">
          <div className="offices-editing__cardFinished">
            <div>
              <h1>{action === "create" ? t("label.addressSaved") : t("label.addressUpdated")}</h1>
              <p>
                {action === "create"
                  ? t("alert.congratsOfficeSaved1")
                  : t("alert.congratsOfficeUpdated1")}
                <a
                  href="./"
                  onClick={(e) => {
                    e.preventDefault();
                    finish();
                  }}
                >
                  {t("button.makeClickOfficeSaved")}
                </a>
                {t("alert.congratsOfficeSaved2")}
              </p>
              <Button onClick={finish}>{t("button.finish")}</Button>
            </div>
            <div>
              <img src={marker} alt="marker" className="marker" />
            </div>
          </div>
        </div>
      </Card>
    </section>
  );
};

OfficeSaved.propTypes = { finish: func.isRequired, action: string.isRequired };

export default OfficeSaved;
