import { handleActions } from "redux-actions";

const actualRoute = window?.location.pathname.split("/").filter((route) => route !== "");

export const INITIAL_STATE = {
  routes: [],
  actualPosition: actualRoute[0] === "" && actualRoute.length > 2 ? actualRoute[1] : actualRoute[0],
  error: undefined,
};

const reducer = handleActions(
  {
    ROUTES: {
      GET_ROUTES: (state) => ({
        ...state,
      }),
      GET_ROUTES_RESPONSE: {
        next(state, { payload: { routes } }) {
          return {
            ...state,
            routes,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: message,
          };
        },
      },

      SET_ACTUAL_ROUTE: (state, { payload: { actualPosition } }) => ({
        ...state,
        actualPosition,
      }),
    },
  },
  INITIAL_STATE
);

export default reducer;
