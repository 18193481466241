import { Col, Form, Input, Row } from "antd";
import { func, instanceOf } from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const OfficeForm = ({ setOfficeForm, inEditionAddress, inAdditionAddress }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    if (inEditionAddress) form.setFieldsValue(inEditionAddress.office);
  }, [inEditionAddress, form]);

  useEffect(() => setOfficeForm(form), [form, setOfficeForm]);

  useEffect(() => {
    if (inAdditionAddress) {
      form.setFieldsValue({
        name: inAdditionAddress.name,
        contact: inAdditionAddress.contact,
      });
    }
  }, [inAdditionAddress, form]);

  return (
    <div className="add-offices__form">
      <Form form={form} name="horizontal_login" layout="vertical">
        <Form.Item
          label={t("label.OfficeName")}
          name="name"
          rules={[
            {
              required: true,
              message: t("error.YouEnterOfficeName"),
            },
          ]}
        >
          <Input style={{ width: "95%" }} type="text" placeholder={t("label.ZoneInsurance")} />
        </Form.Item>
        <Row>
          <Col xs={12}>
            <Form.Item
              label={t("label.ContactNumber")}
              className="input-type-number"
              name="contact"
              rules={[
                {
                  required: true,
                  message: t("error.YouEnterNumberContact"),
                },
                {
                  max: 15,
                  message: t("error.charactersContactMax"),
                  whitespace: true,
                },
                {
                  min: 10,
                  message: t("error.charactersContactMin"),
                  whitespace: true,
                },
              ]}
            >
              <Input type="tel" placeholder="123-123-1234" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

OfficeForm.propTypes = {
  setOfficeForm: func.isRequired,
  inEditionAddress: instanceOf(Object),
  inAdditionAddress: instanceOf(Object),
};

OfficeForm.defaultProps = {
  inEditionAddress: null,
  inAdditionAddress: null,
};

export default OfficeForm;
