const label = {
  stepReady: "Listo",
  gmbVerified: "¡Listo, ya estás verificado!",
  gmbVerifiedDescription:
    "Tu dirección ha sido validada con Google Mi Negocio. En las próximas horas recibirás una notificación por correo eletrónico indicando el estado de la validación.",
  gmbCodeTitle: "Lo sentimos, hubo un error",
  gmbCodeDescription:
    "Parece que el código que ingresaste es incorrecto pero no te preocupes, revisa el código que recibiste de Google, he intenta ingresarlo de nuevo.",
  addressIsVisible: "Esta dirección es visible en tu perfil",
  addressNotIsVisible: "Esta dirección no es visible en tu perfil",
  completed: "Completado",
  saveAndContinue: "Guardar y seguir",
  loginTitle: "Inicia sesión",
  editOffice: "Editar oficina",
  createAccountWith: "Crea una cuenta con",
  email: "Correo electrónico",
  password: "Contraseña",
  hello: "¡Hola!",
  dontHaveAnAccount: "¿No tienes una cuenta?",
  alreadyHaveAnAccount: "¿Ya tienes una cuenta?",
  or: "o",
  name: "Nombre",
  lastname: "Apellido",
  phone: "Teléfono",
  forgotPasswordText:
    "Ingresa tu correo electrónico y te enviaremos un link para que puedas cambiar tu contraseña",
  newPassword: "Nueva Contraseña",
  onlineStatus: "Estado:",
  editProfile: "Editar mi perfil",
  tips: "Tips",
  leads: "Leads",
  logOut: "Cerrar sesión",
  verifyinEmail: "Verificando email",
  congratEmailActivated: "Email verificado con éxito",
  confirmPassword: "Confirmar contraseña",
  completeData:
    "Completa tus datos como agente Berrysafe, comienza tu carrera de seguros en la era digital y obten beneficios.",
  selectLicenseTypes: "Selecciona el tipo de cédula que tienes",
  selectOneOrMores: "Selecciona una o varias",
  license: "Cédula de agente",
  isAmasfac: "¿Eres asociado AMASFAC?",
  yes: "Si",
  not: "No",
  selectInsuranceCarriers: "Selecciona las aseguradoras a las que perteneces",
  marketplace: "Marketplace",
  marketplaceAutoPilot: "Encender piloto automático",
  nextStep: "Siguiente",
  congratsOnboarding: "Muchas gracias por completar la información!",
  continueToProfile: "Puedes continuar personalizando tu perfil aquí",
  productLine: "Línea de seguros",
  priority: "Prioridades",
  superAdminLogin: "Super admin log in",
  checkYourEmail: "Revisa tu correo",
  checkYourEmailParagraph:
    "Hemos enviado la contraseña de acceso a tu correo electrónico, la necesitarás para tu próximo inicio de sesión",
  myAccount: "Mi cuenta",
  notifications: "Notificaciones",
  privacy: "Privacidad",
  forgotPasswordTitle: "¿Olvidaste tu contraseña?",
  forgotPasswordDescrip: "Ingresa el correo electrónico que asociaste a tu cuenta ",
  forgotPasswordWelcomeBerry:
    "Al actualizar tu contraseña, ya podrás tener acceso a tu cuenta Berrysafe y difrutar de las nuevas funcionalidades de nuestra plataforma.",
  forgotPasswordWelcomeFortis:
    "Al actualizar tu contraseña, ya podrás tener acceso a tu cuenta Fortis y difrutar de las nuevas funcionalidades de nuestra plataforma.",
  welcome: "Bienvenido",
  welcomeBody:
    "Completa tus datos como agente Berrysafe, comienza tu carrera de seguros en la era digital y obten beneficios.",
  agentCardType: "Cédula de agente",
  sponsor: "Sponsor",
  productLines: "Líneas de productos",
  validateCodeTitle: "Ingresa el código",
  checkEmailCode: "Comprueba si has recibido un código de 6 dígitos en tu correo electrónico",
  changeForgotPasswordTitle: "Elige una nueva contraseña",
  changeForgotPasswordSubtitle: "Crea una contraseña nueva de 8 caracteres como mínimo",
  viewMyWebPage: "Ver tu página web",
  EditYourWebsite: "Editar tu página web",
  leadsTitle: "Mis leads",
  socialNetworksTitle: "Redes sociales",
  insuranceCarriers: "Aseguradoras",
  yourSelection: "Tu selección",
  selectAndDrag: "Selecciona y arrastra",
  selectOneOrMore: "Selecciona una o más",
  selectBusinessLines: "Selecciona las líneas de producto que vendes",
  selectOneOrMultiples: "Selecciona una o múltiples",
  businessLines: "Líneas de negocio",
  productsLayout: "Layout de productos",
  selectAnSquare: "Seleccione un cuadro para previzualizar",
  myBerryRanking: "Mi Berry ranking",
  completeYourProfile: "Completa tu perfil",
  complete: "Completar",
  products: "Productos",
  branchs: "Ramos",
  viewOptions: "Opciones vista",
  layoutProductsSelectionText:
    "*El layout definira como se mostraran los productos seleccionados en tu perfil",
  agentsActive: "Activos",
  agentsProcess: "En proceso",
  agentsPending: "Pendientes",
  agentsDenied: "Cerrados",
  sold: "Clientes",
  omitChanges: "Omitir cambios",
  omitForNow: "Omitir por ahora",
  selectOneOrMultples: "Selecciona una o varias",
  saveChanges: "Guardar cambios",
  email_table: "Email",
  line: "Línea",
  date: "Fecha",
  origin: "Origen",
  selectYourLines: "Selecciona las líneas de productos que usted vende",
  productVisible: "Este producto es visible en tu perfil",
  readMore: "Leer más",
  readLess: "Leer menos",
  titleProductLines: "Selecciona y ordena las líneas de productos que manejas.",
  subtitleProductLine:
    "Ordena arrastrando las líneas por prioridad de mas importante a menos importante",
  previewMyPage: "Previsualizar",
  publishMyPage: "Publicar página",
  myBusiness: "Mi negocio",
  searchYourAddress: "Busca tu dirección",
  addressIsNotInYourList: "¿No está en la lista?",
  addAddressManually: "Agrega tu dirección manualmente",
  addressSaved: "Dirección agregada!",
  addressUpdated: "Dirección actualizada!",
  titleAddYourOffices: "Agrega tu(s) despacho(s)",
  titleEditYourOffices: "Edita tu despacho",
  importantInformationOffice: "Esta información es indispensable para configurar tu localización",
  assignProductLines: "Asigna las líneas de productos para este despacho.",
  subtitleAddressOffices: "A. Ingresa la dirección",
  subtitleEditAddressOffices: "A. Edita la dirección",
  subtitleInformationOffices: "B. Información del despacho",
  subtitleEditInformationOffices: "B. Edita la información del despacho",
  assignInsurance: "Asígna las aseguradoras que el despacho maneja",
  offices: "Despachos",
  address: "Dirección",
  lines: "Ramos",
  previewProducts: "Previsualización",
  ParticipatingInsurers: "Aseguradoras participantes",
  ThisProductProfile: "Este producto es visible en tu perfil",
  inLine: "En linea",
  SetUp: "Configurar",
  NotNotification: "No tienes ningúna notificación",
  GoToDashboard: "Ir al Dashboard",
  YourPageNeedsUpdated: "¡Tu página debe actualizarse!",
  textMigrate:
    "Por favor sigue las instrucciones en tu perfil, actualiza la información de tu página y publica nuevamente tu página de agente, así activaremos pauta digital para que recibas más prospectos.",
  titleRegular: "Tu información está siendo verificada",
  textRegular: "Una vez confirmados, podrás publicar tu página",
  titleRegular2: "Tu página no puede publicarse aún",
  textRegular2: "Revisa que todos los campos esten debidamente diligenciados.",
  Home: "Home",
  textLoader: "Estamos haciendo algo Berry importante para ti",
  GeneralView: "Vista general",
  YourInformation: "Tu información",
  YourExperience: "Tu experiencia",
  GoogleMyBusiness: "Google Mi Negocio",
  howToQuote: "Sabes como cotizar tus",
  insurance: "Seguros",
  videoTutorial: "Clíc aquí y ve un video tutorial",
  skipTutorial: "Estás seguro que desea omitir el tutorial por ahora",
  Induction: "Inducción",
  Tutorial: "Tutorial",
  URLHandle: "URL/Handle",
  textWolcome:
    "¡Queremos hacerte mas fácil el proceso! Haz clic en el siguiente enlace y verás un video tutorial, en el que de manera sencilla te mostramos el paso a paso de registro para que puedas rápidamente convertirte en un agente activo Berrysafe.",
  ChangePassword: "Cambiar contraseña",
  WhatsAppNumber: "Número de Whatsapp",
  InsurersYouWork: "Aseguradoras con las que trabajas",
  editSection: "Edita esta sección y muestra tu experiencia con aseguradoras",
  descriptionInformation:
    "Este agente ha sido validado por el equipo de profesionales de Berrysafe. Ya inició su carrera de agentes en la era digital y te brindará la ayuda necesaria para comprar el seguro que necesitas",
  WhatIsBerrysafe: "¿Qué es Berrysafe?",
  descriptionMenuInformation:
    "Somos una plataforma digital especializada en ayudar a conectar clientes que buscan comprar un seguro en línea, con una red de agentes expertos que los asisten en todo el proceso. Apoyándonos en multiples estrategias de promoción digital, logramos direccionar los clientes hacia aquellos agentes profesionales y certificados que están más cerca de su zona de influencia, de esta manera el usuario recibe atención especializada de manera inmediata y por el canal de su preferencia. Colaboramos en el proceso de transformación digital de los agentes generándoles visibilidad en internet y facilitándoles el acceso a varias herramientas y recursos que les permitirán acceder a más prospectos y así crecer su cartera.",
  Verified: "Verificado",
  ReadyVerified: "Listo, ya está verificado",
  messageEmpty: "Edita esta sección para que tus prospectos te conozcan!",
  MyPersonalInformation: "Mi info personal",
  Biography: "Biografía",
  Addresses: "Direcciones",
  deleteOffice: "Estás seguro de eliminar esta oficina",
  VerifyAddress: "Verifica tu dirección",
  VerifyInformation: "Verifica tu información",
  Country: "País",
  City: "Ciudad",
  state: "Estado",
  PostalCode: "Código postal",
  OfficeName: "Nombre de despacho",
  ContactNumber: "Número de contacto",
  WebsiteURL: "Página web URL",
  MyBusiness: "Mi negocio",
  AddAddress: "Adicionar dirección",
  AddressInformation:
    "Tu dirección ha sido validada con Google Mi Negocio. En las próximas horas recibirá una notificación por correo eletrónico indicando el estado de la valicación",
  PRIVATEUNION: "PRIVADA UNIÓN ",
  InProgress: "En proceso",
  EnterVerificationCode: "Ingresa tu código de verificación",
  sellingInsurance: "+10 Años vendiendo seguros de Autos",
  Credits: "Creditos",
  enableLines: "Debes habilitar una o más líneas para poder previsualizar",
  YourCurrentLevel: "Tu nivel actual",
  NextLevel: "Próximo nivel",
  AgentLevels: "Niveles de Agente",
  CompleteTheSteps:
    "Completa los pasos para el registro de tu perfil y comienza tu proceso activo de ventas para mejorar tu nivel de agente Berrysafe y obtener beneficios.",
  LessImportant: "Menos importante",
  Capital: "Capital",
  ZoneInsurance: "Zona 0 seguros",
  License: "Licencia",
  Sponsor: "Sponsor",
  Insurers: "Aseguradoras",
  ProductLines: "Lineas de producto",
  ContinueYourProfile: "Continúa a tu perfil",
  StartHere: "Comienza aquí",
  StartWizard: "Comenzar wizard",
  OmitirWizard: "Omitir wizard por ahora",
  VisitsToYourWebsite: "Visitas a tu página web",
  LeadsGenerated: "Leads generados",
  SalesClosed: "Ventas cerradas",
  titleMaterialNetwork: "Material para Redes sociales",
  textMaterialNetwork:
    "En esta sección encuentras contenidos Berrysafe que podrás tomar para publicar en tus redes sociales, personales o corporativas. Recuerda que estar activo en redes te hará más visible en los buscadores en linea.",
  YourWebsite: "Tu página web",
  textHome:
    "Como agente tienes una página de perfil personalizada para generar oportunidades al instante a través de tu Whatsapp , sin formularios ni esperas.",
  Activity: "Actividad",
  ActivityNew: "Nueva actividad",
  notes: "Notas",
  DeleteHistory: "Estas seguro de eliminar esta Historial de contacto",
  historyContact: "Historial de contacto",
  textHistory:
    "Recuerda agregar tus comentarios, recordatorios y cualquier nota para recordar información importante de tu cliente.",
  Message: "Mensaje",
  Requested: "Solicitado",
  SureOf: "Seguro de",
  ReplyByWhatsapp: "Responder por Whatsapp",
  ReplyByEmail: "Responder por Correo",
  HospitalGroup: "Grupo hospitalario de tu cliente",
  Hospital: "Hospitales",
  LearnMore: "Conocer más",
  ProductDetail: "Detalle de producto",
  Details: "Detalle de producto",
  Record: "Historial",
  Request: "Solicitud",
  Policy: "Póliza",
  filter: "Filtrar",
  see: "Ver",
  titleCategory: "¿Deseas adicionar esta aseguradora a tu perfíl?",
  FunctionalitiesOfThe: "Funcionalidades del",
  textHomeMarketplace:
    "A continuación encontrarás las funcionalidades que tiene nuestro Marketplace. Podrás completar tu experiencia como agente y tener todos los datos de tus leads.",
  MostSelledProducts: "Productos más vendidos",
  SearchForInsurers: "Busca por Aseguradoras",
  textWeAreWorking: "Estamos trabajando para ofrecerte más funcionalidades",
  messageNotifications:
    "pronto encontrarás acá más opciones para personalizar tu experiencia en Berrysafe",
  sectionUnderConstruction:
    "¡Esta sección esta en construcción, muy pronto podrás ver tus reportes en detalle!",
  loginTextWelcomeBerry:
    "Bienvenido/a al nuevo portal de Berrysafe. Si ya eres un agente Berrysafe, por favor verifica tu correo y sigue las indicaciones.",
  loginTextWelcomeFortis:
    "Bienvenido/a al nuevo portal de Fortis. Si ya eres un agente Fortis, por favor verifica tu correo y sigue las indicaciones.",
  validatingInformation: "Estamos validando tu información",
  text1MigratedGreentings:
    "Bienvenido/a al nuevo portal de Berrysafe. Para iniciar sesión debes actualizar tu contraseña y para ello, te hemos enviado un correo electrónico que te explica cómo hacerlo de manera fácil",
  text2MigratedGreentings: "Si no recibiste el correo, por favor contáctanos en",
};

const button = {
  AddNote: "Adicionar nota",
  QuoteProduct: "Cotizar producto",
  SeeTechnicalSheet: "Ver ficha técnica",
  close: "Cerrar",
  RuleOut: "Descartar",
  SeeMoreReports: "Ver más reportes",
  SkipStep: "Omitir paso",
  updateOffice: "Editar despacho",
  sendAgain: "Ingresar otra vez",
  understand: "Entendido",
  delete: "Eliminar",
  edit: "Editar",
  Erase: "Borrar",
  goLogin: "Iniciar sesión",
  registerAddress: "Registrar dirección",
  updateAddress: "Actualizar dirección",
  finish: "Finalizar",
  login: "Iniciar sesión",
  goSignUp: "Registrarme",
  register: "Registrarme",
  logout: "Cerrar sesión",
  haveAnAccessCode: "Tienes un código de acceso?",
  forgetPassword: "¿Olvidaste tu contraseña?",
  forgotPassword: "Olvidé mi contraseña",
  loginFacebook: "Iniciar sesión con Facebook",
  loginGoogle: "Iniciar sesión con Google",
  loginTwitter: "Iniciar sesión con Twitter",
  signUpFacebook: "Registrate con Facebook",
  signUpGoogle: "Registrate con Google",
  signUpTwitter: "Registrate con Twitter",
  send: "Enviar",
  validate: "Validar",
  update: "Actualizar",
  detail: "Detalle",
  goToProfile: "Continuar a tu perfil",
  goToBerrysafe: "Continuar a Berrysafe",
  cancel: "Cancelar",
  continue: "Continuar",
  updateInsuranceCarriers: "Guardar",
  visualize: "Previsualización",
  saveChanges: "Guardar cambios",
  saveAndContinue: "Guardar y continuar",
  addOtherOffice: "Agregar otro despacho",
  completeYourProfile: "Completa tu perfil",
  watchTutorial: "Ver tutorial",
  more: "Ver más",
  addOffices: "Agregar despachos",
  add: "Agregar",
  makeClickOfficeSaved: " haz click aquí ",
  nextStep: "Siguiente",
  complete: "Completar",
  AddProfile: "Agregar a mi perfil",
  editProfile: "Editar perfil",
  ReturnMainMenu: "Regresar al Menú principal",
  BusinessLines: "Líneas de negocio",
  insuranceSection: "Sección de aseguradoras",
  goToMyProfile: "Ir a mi perfil",
  EditSection: "Editar sección",
  VerifyAndAuthorize: "Verificar y autorizar",
  howToTseTheArticle: "Mira como usarlo en este artículo",
  acceptTheTyC: "Acepto los T&C, y Politicas de privacidad",
};

const placeholder = {
  inputEmail: "Correo electrónico",
  inputPhone: "Teléfono",
  inputName: "Nombre",
  inputNameAndLastname: "Nombres y apellidos",
  inputBiography: "Describe tu trayectoria",
  inputTagLine: "Tagline",
  inputPass: "Contraseña",
  inputPassNew: "Contraseña Nueva",
  code: "Código",
  socialNetwork: "https://",
  inputAssociationCode: "######",
  LookFor: "Buscar",
};

const error = {
  email: "Por favor ingresa un email válido",
  ADDRESS_NOT_FOUND: "Dirección no encontrada",
  charactersEmail: "El correo electrónico no puede contener más de 200 caracteres",
  password: "Por favor ingresa tu contraseña",
  charactersPassword: "La contraseña debe estar entre 8 y 20 caracteres",
  name: "El nombre no debe ir vacío",
  charactersNameMax: "El nombre no puede contener más de 50 carácteres",
  charactersBiographyMax: "La biografía no puede contener más de 50 carácteres",
  charactersTaglineMax: "El tagline no puede contener más de 50 carácteres",
  charactersSocialNetowkrMax: "El valor no puede contener más de 256 carácteres",
  charactersNameMin: "El nombre debe tener mínimo 5 carácteres",
  charactersContactMin: "El contacto debe tener mínimo 10 carácteres",
  charactersContactMax: "El contacto debe tener máximo 15 carácteres",
  lastname: "Por favor ingresa tu apellido",
  charactersLastname: "El apellido no puede contener más de 50 caracteres",
  phone: "Por favor ingresa tu número de teléfono mexicano",
  charactersPhone: "El teléfono debe estar entre 10 y 15 caracteres.",
  confirmPassword: "Por favor confirma tu contraseña",
  EMAIL_BAD_FORMAT: "El email es incorrecto",
  UNDEFINED_ERROR: "No se pudo establecer conexión",
  USER_NOT_EXIST: "No existe ningún usuario asociado a este email",
  USER_NOT_FOUND_NETWORK_SOCIAL: "No existe ningún usuario asociado a esta red social",
  USER_NOT_FOUND: "El correo electronico o la contraseña no son válidos.",
  forgetPasswordCode: "Debes ingresar el código de verificación",
  FORGOT_PASSWORD_CODE_ERROR: "El código ingresado es incorrecto",
  CANT_UPDATE_INSURANCE_CARRIER: "No se pudieron actualizar las aseguradoras",
  "Failed to fetch": "No se pudo establecer conexión",
  INVALID_ASSOCIATION_CODE: "El código de la asociación es inválido",
  BAD_PHONE_LENGTH: "El teléfono debe estar entre 10 y 15 caracteres.",
  oauthGoogle: "La autenticación mediante Google no se pudo completar.",
  oauthFacebook: "La autenticación mediante Facebook no se pudo completar.",
  oauthTwitter: "La autenticación mediante Twitter no se pudo completar.",
  SERVICE_UNAVALIABLE: "No se pudo establecer conexión",
  publishError: "Debes completar los datos básicos y obtener aprobación de un administrador",
  EMAIL_IN_USE: "El correo está en uso",
  BAD_NAME_LENGTH: "El nombre no puede estar vacío y debe ser menor a 50",
  EMAIL_NOT_UPDATED: "El correo electrónico no sé pudo actualizar",
  USER_NOT_VALID: "El usuario es incorrecto",
  CONTACT_NOT_UPDATED: "No se pudo actualizar el teléfono",
  CONTACT_NOT_SAVED: "No se pudo guardar el teléfono",
  END_POINT_IN_USE: "La url está en uso",
  END_POINT_NOT_UPDATED: "No se pudo actualizar la dirección",
  YourPageNotPublished: "No se ha podido publicar tu página",
  text404:
    "La página que estás buscando no exíste, pero no te preocupes, puedes seguir navegando en Berrysafe!",
  YouEnterCountry: "Debe ingresar el país",
  YouEnterState: "Debe ingresar el estado",
  YouEnterCity: "Debe ingresar la ciudad",
  YouEnterCodePostal: "Debe ingresar el código postal",
  YouEnterAddress: "Debe ingresar la dirección",
  YouEnterOfficeName: "Debe ingresar el nombre del despacho",
  NotNumbers: "No puedes ingresar números",
  YouEnterNumberContact: "Debe ingresar el número de contato",
  emptyExcel: "El documento debe contener información",
  ERROR_UPLOADING_COVER: "No se pudo cargar la carátula",
  AGENT_NOT_PRODUCT_LINES: "No tienes líneas de seguros",
  AGENT_NOT_PRODUCTS: "No puedes publicar sin productos",
  AGENT_NOT_PRODUCTS_AND_PRODUCT_LINES: "No puedes publicar sin productos ni ramos",
};

const alert = {
  success: "Registro exitoso",
  success_password: "Contraseña actualizada existosamente",
  sure_to_delete: "Seguro que quieres eliminar esta linea",
  signupSuccess: "Registro exitoso",
  forgotPasswordRequested: "Código generado exitosamente",
  insuranceCarriersUpdated: "Aseguradoras actualizadas exitosamente",
  layoutProductsEdited: "Productos actualizados",
  congratsOfficeSaved1:
    "Tu dirección ha sido agregada a Berrysafe. Si quieres autenticarla con Google Mi Negocio",
  congratsOfficeUpdated1:
    "Tu dirección ha sido actualizada. Si quieres autenticarla con Google Mi Negocio",
  congratsOfficeSaved2:
    "de lo contrario berrysafe se encargará de autenticarla con Google Mi negocio por ti.",
  validationCode: "Te hemos enviado un código de validación a tu correo electrónico",
  LoginSuccessful: "Inicio de sesión exitoso",
  acceptTermsAndConditions: "Debes aceptar los términos y condiciones",
  PasswordResetSuccessfully: "La contraseña se ha restablecido con éxito",
};

export { label, button, error, alert, placeholder };
