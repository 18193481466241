/* eslint-disable import/no-cycle */
import ApiConnection from "common/api/Api";
import parseError from "common/api/ErrorParser";
import { all, put, takeLatest } from "redux-saga/effects";
import OfficeActions from "./actions";

function* getCountries() {
  const url = "/country";

  const response = yield ApiConnection.get(url);
  if (response.success) return yield put(OfficeActions.getCountriesResponse(response.detail));

  return yield put(OfficeActions.getCountriesResponse(parseError(response.payload), response));
}

function* ActionWatcher() {
  yield takeLatest(OfficeActions.getCountries, getCountries);
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
