/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Breadcrumb, Layout, Modal } from "antd";
import Notify from "components/Notify/Notify";
import firebase from "firebase";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "react-day-picker/lib/style.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Zendesk from "react-zendesk";
import MyMenu from "../../common/menu";
import { changeRoute } from "../../common/utils/changeRoute";
import AuthActions from "../../services/auth/actions";
import OnboardinActions from "../../services/onboarding/actions";
import Header from "../header/Header";
import Tutorial from "../tutorial/Tutorial";
import FooterNavBar from "./components/FooterNavBar";

const ZENDESK_KEY = "5985d50d-aac5-472d-a391-8e3e370277cf";

const setting = {
  color: {
    theme: "#000",
  },
  launcher: {
    chatLabel: {
      "es-419": "Need Help",
    },
  },
  contactForm: {
    fields: [{ id: "description", prefill: { "*": "My pre-filled description" } }],
  },
  locale: "es",
};

const { Content } = Layout;

const LayoutPrivate = ({ children }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [showTutorial, setShowTutorial] = useState(false);
  const routes = useSelector((state) => state.routes);
  const onboarding = useSelector((state) => state.onboarding);
  const { profile } = useSelector((state) => state.auth);
  const { width } = window.screen;
  const [breadcrumbList, setBreadcrumbList] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [actualMenuLink, setActualMenuLink] = useState(undefined);
  const dispatch = useDispatch();
  const logout = () => dispatch(AuthActions.logout());
  const [collapsedByMobile, setCollapsedByMobile] = useState(false);
  const [notificationRef, setNotificationRef] = useState(null);
  const [notifiactionInitialized, setNotificationInitialized] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [newMessage, setNewMessage] = useState();
  const [allNotifications, setAllNotification] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      document.querySelectorAll("iframe").forEach((iframe) => {
        if (!iframe.title.includes("Botón para iniciar la ventana de mensajería")) return;
        iframe.setAttribute("id", "zendesk-button-iframe");
        const buttons = iframe.contentWindow.document.getElementsByTagName("button");
        if (!buttons || !buttons[0]) return;
        buttons[0].addEventListener("click", () => {
          setTimeout(() => {
            document.querySelectorAll("iframe").forEach((textIframe) => {
              if (!textIframe.id.includes("zendesk-iframe")) return;
              if (!textIframe.title.includes("Ventana de mensajería")) return;
              const labelsCollection =
                textIframe.contentWindow.document.getElementsByTagName("label");
              const labels = Array.prototype.slice.call(labelsCollection);

              const nameLabel = labels.find((label) => label.textContent === "Name");
              nameLabel.textContent = "Nombre";
            });
          }, 500);
        });
      });
    }, 2000);
  }, []);

  useEffect(() => {
    if (!routes.actualPosition) {
      if (history.location.pathname.split("/").filter((route) => route !== "").length === 0) {
        setActualMenuLink("/");
      }
      return;
    }

    if (history.location.pathname.split("/").some((path) => path === "sso")) {
      dispatch(AuthActions.logout());
    }

    const selectedMenu = MyMenu.find(
      (menuActual) => menuActual.key === routes.actualPosition.split("/")[0]
    );

    if (!selectedMenu) {
      return;
    }

    const endPoints = history.location.pathname.split("/");

    if (endPoints.filter((endPoint) => !!endPoint).length === 0) {
      setBreadcrumbList([selectedMenu.name]);
      return;
    }

    const breads = [
      ...endPoints
        .map((endPoint) => {
          const isThere = selectedMenu.items.map((item) => item.key).indexOf(endPoint);

          if (selectedMenu.key === endPoint) return selectedMenu.name;

          if (isThere > -1) return selectedMenu.items.find((sub) => sub.key === endPoint).name;
          return endPoint;
        })
        .filter((bread) => bread !== ""),
    ];
    setBreadcrumbList(breads);
    setActualMenuLink(selectedMenu?.path);
  }, [routes, history, dispatch]);

  useEffect(() => {
    if (!collapsedByMobile) setCollapsedByMobile(true);

    if (width <= 768 && !collapsed && !collapsedByMobile) {
      setCollapsed(true);
    }
  }, [routes, collapsedByMobile, collapsed, width]);

  useEffect(() => {
    if (
      (profile.agent?.tutorialShown === "Inactive" && routes.actualPosition === "") ||
      onboarding.showTutorial
    )
      setShowTutorial(true);
  }, [onboarding, routes, profile]);

  useEffect(() => {
    if (!notificationRef && profile)
      setNotificationRef(firebase.database().ref(`/notifications/agent-${profile.agent.id}`));
  }, [profile, notificationRef]);

  useEffect(() => {
    if (!notificationRef || notifiactionInitialized) return;
    const initFirestore = () => {
      notificationRef.once("value", (snapshot) => {
        const actualNotifications = [...unreadNotifications];
        snapshot.forEach((childSnapshot) => {
          const data = childSnapshot.val();
          actualNotifications.push({
            key: childSnapshot.key,
            title: data.title,
            text: data.text,
            read: data.read,
          });
        });
        setAllNotification(actualNotifications);
        setUnreadNotifications(
          actualNotifications.filter((notificationIterable) => !notificationIterable.read)
        );
      });

      notificationRef.on("child_added", (snapshot) => {
        setNewMessage({ key: snapshot.key, ...snapshot.val() });
      });
    };
    initFirestore();
    setNotificationInitialized(true);
  }, [notificationRef, unreadNotifications, notifiactionInitialized]);

  useEffect(() => {
    if (newMessage) {
      setUnreadNotifications([...unreadNotifications, newMessage]);
      setNewMessage(null);
    }
  }, [newMessage, unreadNotifications]);

  if (actualMenuLink === undefined)
    return (
      <>
        <Layout className="section-dashboard">
          {/* <Layout className="site-layout">
            <Content className="site-layout-background">{children}</Content>
          </Layout> */}
        </Layout>
      </>
    );

  return (
    <>
      <Notify />
      <Modal
        className="tutorial__modal"
        onCancel={() => {
          dispatch(OnboardinActions.setShowTutorial(false));
          setShowTutorial(false);
        }}
        centered
        maskStyle={{ background: "#ffffff", backdropFilter: "10px" }}
        width="800px"
        visible={showTutorial}
      >
        <Tutorial
          setShowTutorial={() => {
            setShowTutorial(false);
            dispatch(OnboardinActions.setShowTutorial(false));
          }}
          profile={onboarding.success.profile}
          goEditPage={() => changeRoute("editPage")}
        />
      </Modal>

      <Zendesk defer zendeskKey={ZENDESK_KEY} {...setting} />

      {routes.actualPosition === "editPage" ? (
        <Header
          allNotifications={allNotifications}
          notificationRef={notificationRef}
          setUnreadNotifications={setUnreadNotifications}
          unreadNotifications={unreadNotifications}
        />
      ) : (
        <Header
          allNotifications={allNotifications}
          notificationRef={notificationRef}
          setUnreadNotifications={setUnreadNotifications}
          unreadNotifications={unreadNotifications}
        />
      )}

      <Breadcrumb className="breadcrumb">
        {breadcrumbList.map((bread) => (
          <Breadcrumb.Item key={bread}>{bread}</Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <Layout className="section-dashboard">
        <FooterNavBar
          logout={logout}
          dispatch={dispatch}
          history={history}
          onboarding={onboarding}
          allNotifications={allNotifications}
          notificationRef={notificationRef}
          setUnreadNotifications={setUnreadNotifications}
          unreadNotifications={unreadNotifications}
          t={t}
        />

        <Layout className="site-layout">
          <Content className="site-layout-background">{children}</Content>
        </Layout>
      </Layout>
    </>
  );
};

LayoutPrivate.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LayoutPrivate;
