import { Col, message, Row } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { CLIENT_HOST } from "common/config/config";
import BackLogin from "../../../assets/images/back-login.png";
import logo from "../../../assets/images/logo-berry-purple.svg";
import AuthActions from "../../../services/auth/actions";
import ChangeForgotPassword from "./components/forgotPassword/components/changeForgotPassword/ChangeForgotPassword";
import ValidateCode from "./components/forgotPassword/components/validateCode/ValidateCode";
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import Login from "./components/login/Login";
import Signup from "./components/signup/Signup";

const forgotPasswordSteps = ["forgotPassword", "validateCode", "changePassword"];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Auth = ({ signup, resetPassword = false }) => {
  const query = useQuery();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [whoIsActive, setWhoIsActive] = useState("login");
  const auth = useSelector((state) => state.auth);
  const [oAuthData, setOAuthData] = useState();

  useEffect(() => {
    if (signup) setWhoIsActive("signup");
  }, [signup]);

  useEffect(() => {
    if (resetPassword) setWhoIsActive("forgotPassword");
  }, [resetPassword]);

  useEffect(() => {
    if (
      query.get("forgotPasswordCode") !== null &&
      !auth.success.codeVerification &&
      !auth.success.changeForgotPassword
    ) {
      setWhoIsActive("validateCode");
    }

    if (query.get("sponsor")) document.body.classList.add(query.get("sponsor"));
  }, [query, auth]);

  const changeToForgotPassword = () => setWhoIsActive("forgotPassword");

  const changeToValidateCode = () => {
    message.success({
      content: t("alert.validationCode"),
      icon: (
        <svg
          className="icon-check-svg"
          width="16"
          height="12"
          viewBox="0 0 16 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.83877 5.6076L5.39204 9.16088L14.3405 0.212402L15.4703 1.34218L5.39204 11.4204L0.708984 6.73738L1.83877 5.6076Z"
            fill="#5D30DD"
          />
        </svg>
      ),
    });
    setWhoIsActive("login");
  };

  const changeToChangePassword = () => setWhoIsActive("changePassword");

  const changeToLogin = () => {
    dispatch(AuthActions.cancelForgotPassword());
    setWhoIsActive("login");
  };

  return (
    <Row className="full-height">
      <Col className="cont-img" xs={12} sm={12} md={12} lg={12}>
        <div className="col-login">
          <a href={`${CLIENT_HOST}`}>
            <img alt="berry" src={logo} className="logo-berry" />
          </a>
          <div className="col-login__welcome" style={{ backgroundImage: `url(${BackLogin})` }}>
            {forgotPasswordSteps.some((step) => step === whoIsActive) ? (
              <>
                <h1 className="step-pass">¡Estás a un paso!</h1>
                <h1 className="col-login__welcomeForgotPassword welcomeForgotPassword--berry">
                  {t("label.forgotPasswordWelcomeBerry")}
                </h1>
                <h1 className="col-login__welcomeForgotPassword welcomeForgotPassword--fortis">
                  {t("label.forgotPasswordWelcomeFortis")}
                </h1>
              </>
            ) : (
              <h1 className="col-login__welcomeTitle">{t("label.hello")}</h1>
            )}

            {whoIsActive === "signup" && (
              <h3 className="col-login__welcomeSubtitle">{t("label.alreadyHaveAnAccount")}</h3>
            )}
            {whoIsActive === "login" && (
              <h3 className="col-login__welcomeSubtitle">{t("label.dontHaveAnAccount")}</h3>
            )}

            {whoIsActive === "signup" && (
              <button
                type="button"
                id="login-button-auth"
                onClick={() => setWhoIsActive("login")}
                className="btn-custom btn-custom--primary"
              >
                {t("button.goLogin")}
              </button>
            )}
            {whoIsActive === "login" && (
              <button
                type="button"
                id="signup-button-auth"
                onClick={() => setWhoIsActive("signup")}
                className="btn-custom btn-custom--primary"
              >
                {t("button.goSignUp")}
              </button>
            )}
          </div>
        </div>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} className="col-signup">
        {whoIsActive === "login" && (
          <Login
            setOAuthData={(data) => {
              setOAuthData(data);
              setWhoIsActive("signup");
            }}
            setWhoIsActive={setWhoIsActive}
            changeToForgotPassword={changeToForgotPassword}
          />
        )}

        {whoIsActive === "signup" && (
          <Signup oAuthData={oAuthData} setWhoIsActive={setWhoIsActive} />
        )}

        {whoIsActive === "forgotPassword" && (
          <ForgotPassword
            cancelForgotPassword={changeToLogin}
            changeToValidateCode={changeToValidateCode}
          />
        )}

        {whoIsActive === "validateCode" && (
          <ValidateCode
            urlCode={query.get("forgotPasswordCode")}
            changeToChangePassword={changeToChangePassword}
            cancelForgotPassword={changeToLogin}
          />
        )}

        {whoIsActive === "changePassword" && <ChangeForgotPassword changeToLogin={changeToLogin} />}
      </Col>
    </Row>
  );
};

Auth.propTypes = {
  signup: PropTypes.bool.isRequired,
  resetPassword: PropTypes.bool,
};

Auth.defaultProps = {
  resetPassword: false,
};

export default Auth;
