import { Button } from "antd";
import PropType from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import warnig from "assets/images/modal-warnig.png";
import { CLIENT_HOST } from "common/config/config";
import { changeRoute } from "common/utils/changeRoute";
import MarketplaceActions from "services/marketplace/actions";
import AgentActions from "services/agent/actions";
import ModalGenericEditable from "../../../Modals/ModalGenericEditable/ModalGenericEditable";
import ModalLinkTree from "../../../Modals/ModalLinkTree/ModalLinkTree";
import ShareButton from "../../../ShareButton/ShareButton";

const Card = ({ item, type }) => {
  const onboarding = useSelector((state) => state.onboarding);
  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation();

  return (
    <div className="cont-card">
      <div className="card">
        {onboarding.success?.profile && (
          <ShareButton email={onboarding?.success?.profile?.user?.email} endpoint={item.endPoint} />
        )}
        <img alt="fondo" className="card__background" src={item.background} />
        <div className="content-transparent" />
        <div className="card__content">
          <div className="card__contentHeader">
            {type === "1/2" && <img alt="BerryAUTO" className="BerryAUTO" src={item.icon} />}
            {type === "1/4" && <img alt="BerryAUTO" className="BerryAUTO" src={item.icon} />}
          </div>
          <div
            className={`card__contentBody ${
              item.mine ? "card__contentBody--active" : "card__contentBody--disabled"
            }`}
          >
            <div
              className={`card__contentBody ${
                item.mine
                  ? "card__contentBody--active__insurances"
                  : "card__contentBody--disabled__insurances"
              }`}
            >
              {item.agency && item.endPoint !== "pelotea" && (
                <>
                  <p>{t("label.ParticipatingInsurers")}</p>
                  {item.insuranceCarrierProducts.map((insurance) => (
                    <div key={insurance.id} className="insurance">
                      <img alt="insurance" src={insurance.insuranceCarrier.icon} />
                    </div>
                  ))}
                </>
              )}
            </div>
            <div
              className={`card__contentBody ${
                item.mine
                  ? "card__contentBody--active__visibility"
                  : "card__contentBody--disabled__visibility"
              }`}
            >
              <p>{t("label.ThisProductProfile")}</p>
              <ModalGenericEditable
                title="¿Deseas adicionar esta aseguradora a tu perfíl?"
                button_succes={
                  <Button
                    onClick={() => {
                      changeRoute("editPage", history, dispatch);
                      dispatch(MarketplaceActions.requestHelpWithInsuranceCarrier(item.id));
                      dispatch(
                        AgentActions.setAutoInsurance(
                          item.insuranceCarrierProducts[0].insuranceCarrier.key
                        )
                      );
                      dispatch(AgentActions.setActualInsuranceCarriersOrdered());
                      dispatch(AgentActions.getInsuranceCarriers());
                    }}
                    className="btn btn--succes"
                  >
                    {t("button.AddProfile")}
                  </Button>
                }
                button_cancel={
                  <Button onClick={() => history.push("/")} className="btn btn--cancel">
                    {t("button.cancel")}
                  </Button>
                }
                product={item}
                image={warnig}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="btns">
        <a
          href={`${CLIENT_HOST}${
            onboarding.success.profile?.agentSponsors &&
            onboarding.success.profile.agentSponsors.length > 0
              ? `${onboarding.success.profile.agentSponsors[0].sponsor.endPoint}/`
              : ""
          }producto/${item.endPoint}?agente=${onboarding.success.profile?.endPoint}`}
          target="_blank"
          rel="noopener noreferrer"
          className="see-more"
        >
          {t("label.readMore")}
        </a>
        {item.endPoint !== "huracan" && item.endPoint !== "pelotea" && (
          <ModalLinkTree
            insuranceCarriers={item.insuranceCarrierProducts}
            endpoint={item.endPoint}
          />
        )}
      </div>
    </div>
  );
};

Card.propTypes = {
  item: PropType.instanceOf(Object).isRequired,
  type: PropType.string.isRequired,
};

export default Card;
