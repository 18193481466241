import React from "react";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

const ActiveLeadsFilter = ({ filters, activeFilters, setFilter }) => (
  <div className="leadsSelect">
    {activeFilters?.map((item) => (
      <div key={item.id} className="button--select">
        <Button className="button--select-item">
          {item.name}{" "}
          <CloseOutlined
            onClick={() => {
              const newFilters = filters.filter((filt) => filt.id !== item.id);
              setFilter(newFilters);
            }}
          />
        </Button>
      </div>
    ))}
  </div>
);

ActiveLeadsFilter.propTypes = {
  filters: PropTypes.instanceOf(Array).isRequired,
  activeFilters: PropTypes.instanceOf(Array).isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default ActiveLeadsFilter;
