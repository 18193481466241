import moment from "moment";
import "moment/locale/es"; // without this line it didn't work
import { instanceOf } from "prop-types";
import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "../i18n/i18n";
import AuthActions from "../services/auth/actions";
import MixPanelActions from "../services/mixpanel/actions";
import Private from "./Private/Private";
import Public from "./Public/Public";

moment.locale("es");

const App = ({ mixpanel }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const instance = useSelector((state) => state.mixpanel.instance);

  useEffect(() => {
    if (mixpanel && !instance) dispatch(MixPanelActions.init(mixpanel));
  }, [mixpanel, instance, dispatch]);

  useEffect(() => {
    i18n.changeLanguage("es");
    dispatch(AuthActions.isLogged());
  }, [dispatch, i18n]);

  if (auth.isLogged) {
    TagManager.dataLayer({
      dataLayer: { userId: auth.profile.id },
      dataLayerName: "PageDataLayer",
    });
  }

  return auth.isLogged ? <Private /> : <Public />;
};

App.propTypes = {
  mixpanel: instanceOf(Object).isRequired,
};

export default App;
