import { Tabs } from "antd";
import { instanceOf, string } from "prop-types";
import React from "react";
import PiaField from "../PiaField";

const { TabPane } = Tabs;

const SectionSinisterPia = ({ policy, highlightDictionary, searchedHighlight }) => {
  return (
    <div className="SectionSummaryPia">
      {policy.InsuranceClaimList.length > 0 ? (
        <div className="SectionSummaryPia--table">
          <Tabs defaultActiveKey="1">
            {policy.InsuranceClaimList.map((sinister) => (
              <TabPane
                tab={
                  <div className="tab-head">
                    <h1>{sinister.ClaimNumber}</h1>
                  </div>
                }
                key={sinister.ClaimNumber}
              >
                <div className="SectionSummaryPia--table__column">
                  <div className="content-gray">
                    <div className="content-gray--item">
                      <p
                        className={
                          highlightDictionary.happenedDate.includes(searchedHighlight)
                            ? "highlight"
                            : ""
                        }
                      >
                        Fecha ocurrido
                      </p>
                      <PiaField text={sinister.EventDay} type="date" />
                    </div>
                    <div className="content-gray--item">
                      <p
                        className={
                          highlightDictionary.reportHour.includes(searchedHighlight)
                            ? "highlight"
                            : ""
                        }
                      >
                        Hora reporte
                      </p>
                      <h4>10:10am</h4>
                    </div>
                    <div className="content-gray--item">
                      <p
                        className={
                          highlightDictionary.reportNumber.includes(searchedHighlight)
                            ? "highlight"
                            : ""
                        }
                      >
                        Número de Reporte
                      </p>
                      <PiaField text={sinister.ReportNumber} type="" />
                    </div>
                    <div className="content-gray--item">
                      <p
                        className={
                          highlightDictionary.sinisterNumber.includes(searchedHighlight)
                            ? "highlight"
                            : ""
                        }
                      >
                        Número de Siniestro
                      </p>
                      <PiaField text={sinister.ClaimNumber} type="" />
                    </div>
                  </div>
                  <div className="content">
                    <div className="item">
                      <p
                        className={
                          highlightDictionary.driver.includes(searchedHighlight) ? "highlight" : ""
                        }
                      >
                        Conductor
                      </p>
                      <PiaField text={sinister.DriverName} type="" />
                    </div>
                    <div className="item">
                      <p
                        className={
                          highlightDictionary.driver.includes(searchedHighlight) ? "highlight" : ""
                        }
                      >
                        Nombre y telefono de contacto
                      </p>
                      <PiaField text={sinister.DriverName} type="" />
                      <br />
                      <PiaField text={sinister.Phone} type="" />
                    </div>
                  </div>
                </div>
                <div className="SectionSummaryPia--table__column">
                  <div className="columns">
                    <p
                      className={
                        highlightDictionary.vehicle.includes(searchedHighlight)
                          ? "small-detail highlight"
                          : "small-detail"
                      }
                    >
                      Modelo y descripción del vehículo
                    </p>
                    <h6 className="body-regular">{sinister.DetailVehicle}</h6>
                  </div>
                  <div className="columns">
                    <p
                      className={
                        highlightDictionary.location.includes(searchedHighlight)
                          ? "small-detail highlight"
                          : "small-detail"
                      }
                    >
                      Ubicación
                    </p>
                    <h6 className="body-regular">{sinister.Location}</h6>
                  </div>

                  <div className="columns">
                    <p
                      className={
                        highlightDictionary.cause.includes(searchedHighlight)
                          ? "small-detail highlight"
                          : "small-detail"
                      }
                    >
                      Causa
                    </p>
                    <h6 className="body-regular">{sinister.DetailCause}</h6>
                  </div>
                </div>
                <div className="SectionSummaryPia--table__column">
                  <div className="content--items">
                    <div className="items">
                      <p
                        className={
                          highlightDictionary.estimate.includes(searchedHighlight)
                            ? "small-detail highlight"
                            : "small-detail"
                        }
                      >
                        Estimación
                      </p>
                      <PiaField text={sinister.Estimate} type="price" />
                    </div>
                    <div className="items">
                      <p
                        className={
                          highlightDictionary.subSection.includes(searchedHighlight)
                            ? "highlight"
                            : ""
                        }
                      >
                        Inciso
                      </p>
                      <PiaField text={sinister.SubSection} type="" />
                    </div>
                  </div>
                </div>
              </TabPane>
            ))}
          </Tabs>
        </div>
      ) : (
        <div className="empty">
          <span className="h2">Por el momento no tenemos registro de siniestros</span>
        </div>
      )}
    </div>
  );
};

SectionSinisterPia.propTypes = {
  policy: instanceOf(Object).isRequired,
  highlightDictionary: instanceOf(Object).isRequired,
  searchedHighlight: string.isRequired,
};

export default SectionSinisterPia;
