/* eslint-disable react/jsx-props-no-spreading */
import { Button, Col, Row } from "antd";
import useWindowSize from "common/utils/useWindowResize";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import OnboardingActions from "services/onboarding/actions";
import BelongAssociation from "./belongsAssociation";
import Completed from "./completed";
import InsuranceCarrier from "./insuranceCarriers";
import License from "./license";
import ProductLines from "./productLines";

const OnboardingSteps = () => {
  const dispatch = useDispatch();
  const size = useWindowSize();
  const sliderTutorial = useRef();
  const [selectedSponsor, setSelectedSponsor] = useState("");
  const [isSlideIndex, setSlideIndex] = useState(0);
  const { step } = useSelector((state) => state.onboarding);
  const [canGoNext, setCanGoNext] = useState(false);
  const { t } = useTranslation();

  const settings = {
    dots: true,
    className: "center",
    centerMode: true,
    centerPadding: "30px",
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setSlideIndex(next),
  };

  const dummyButtons = [
    { id: 0, text: "Sponsor", name: "button-sponsor" },
    { id: 1, text: "Licencia", name: "button-license" },
    { id: 2, text: "Aseguradoras", name: "button-insurance" },
    { id: 3, text: "Lineas de producto", name: "button-lines" },
    { id: 4, text: "Continúa a tu perfil", name: "button-finish" },
  ];

  useEffect(() => {
    sliderTutorial.current.slickGoTo(step);
  }, [step]);

  useEffect(() => {
    dispatch(OnboardingActions.setStep(isSlideIndex));
  }, [isSlideIndex, dispatch]);

  const setStep = (n) => {
    dispatch(OnboardingActions.setStep(n));
  };

  const activeGoNext = () => setCanGoNext(true);

  const setNextActive = () => {
    activeGoNext();
  };

  return (
    <div className="onBoarding-responsive__steps">
      <div className="onBoarding-responsive__stepsStep">
        <div className="line-purple" />
        <div className="onBoarding-responsive__stepsStep__scroll" id="containerScroll">
          <Slider
            {...settings}
            ref={(slider) => {
              sliderTutorial.current = slider;
            }}
          >
            {dummyButtons?.map((item) => (
              <Button
                key={item.id}
                id={item.name}
                className={
                  step === item.id
                    ? "onBoarding-responsive__stepsStep--active"
                    : "onBoarding-responsive__stepsStep--disabled"
                }
                onClick={() => setStep(item.id)}
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
              >
                <p>{item.text}</p>
              </Button>
            ))}
          </Slider>
        </div>
      </div>
      <Row>
        <Col xs={24} lg={14} xl={14} className="onBoarding-responsive__stepsWrapper">
          <div className="onBoarding-responsive__stepsWrapper__step">
            {step === 0 && (
              <BelongAssociation
                activeGoNext={() => setNextActive(0)}
                selectedSponsor={selectedSponsor}
                setSelectedSponsor={setSelectedSponsor}
              />
            )}

            {step === 1 && (
              <License activeGoNext={() => setNextActive(0)} selectedSponsor={selectedSponsor} />
            )}

            {step === 2 && <InsuranceCarrier />}

            {step === 3 && <ProductLines />}

            {step === 4 && <Completed />}
          </div>
          <div className="onBoarding-responsive__stepsWrapper__nextStep">
            {step !== 4 && (
              <Button disabled={!canGoNext} onClick={() => setStep(step + 1)}>
                {t("label.nextStep")}
              </Button>
            )}
          </div>
          <div className="onBoarding-responsive__stepsWrapper__omitStep">
            {size.width > 1024 && (
              <Button type="link">
                <p>{t("button.SkipStep")}</p>
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OnboardingSteps;
