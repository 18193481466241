/* eslint-disable import/no-cycle */
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import mixpanel from "mixpanel-browser";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { MixpanelConsumer, MixpanelProvider } from "react-mixpanel";
import { Provider } from "react-redux";
import React from "react";
import { ENV } from "./common/config/config";
import "./sass/main.scss";
import App from "./scenes/App";
import * as serviceWorker from "./serviceWorker";
import initStore from "./store";

mixpanel.init("f4f64c4b0dc23f0219b5bdc268261600");

const history = createBrowserHistory();
const store = initStore(history);
export default store;

const tagManagerArgs = {
  gtmId: "GTM-MVTBJCP",
  dataLayerName: "PageDataLayer",
};

if (ENV === "production") TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MixpanelProvider mixpanel={mixpanel}>
        <MixpanelConsumer>
          {(mixpanelInstance) => <App mixpanel={mixpanelInstance} history={history} />}
        </MixpanelConsumer>
      </MixpanelProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();
