import { Button, Modal, Tabs } from "antd";
import initialColumnOrders from "common/utils/initialColumnOrder";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import LeadsActions from "services/leads/actions";
import { v4 as uuidv4 } from "uuid";
import Loader from "../../../components/loader/index";
import LeadDetail from "../leadDetail/LeadDetail";
import LeadTable from "./tables/leadTable";
import phone from "../../../assets/icon/ws-berry.svg";
import email from "../../../assets/images/email-purple.svg";
import arrow from "../../../assets/images/arrow-green.svg";
import menu from "../../../assets/images/menu.svg";
import share from "../../../assets/images/share.svg";
import flecha from "../../../assets/images/flecha.svg";

const { TabPane } = Tabs;

const Leads = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const leads = useSelector((state) => state.leads);
  const [page, setPage] = useState(1);
  const [typeList, setTypeList] = useState("process");
  const [isSearching, setIsSearching] = useState(false);
  const [quantity, setQuantity] = useState(10);
  const [filter, setFilter] = useState([]);
  const [orderColumns, setOrderColumns] = useState(initialColumnOrders);
  const [activeOrderColumns, setActiveOrderColumns] = useState("requestDate");
  const [showLeadDetail, setShowLeadDetail] = useState(false);
  const [leadCurrent, setLeadCurrent] = useState(null);

  const openDetail = (lead) => setLeadCurrent(lead);

  const sort = (columnName) => {
    const newOrder = orderColumns;

    Object.keys(newOrder).map((columnkey) => {
      if (columnkey !== columnName) {
        newOrder[columnkey].value = undefined;
        return columnkey;
      }

      if (newOrder[columnkey].value === "ASC") {
        newOrder[columnkey].value = "DESC";
        return columnkey;
      }

      if (newOrder[columnkey].value === "DESC") {
        newOrder[columnkey].value = undefined;
        return columnkey;
      }
      newOrder[columnkey].value = "ASC";
      return columnkey;
    });

    setOrderColumns(newOrder);
    setActiveOrderColumns(columnName);
  };

  useEffect(() => {
    if (!leads.loading.productLines && !leads.success.productLines && !leads.error.productLines)
      dispatch(LeadsActions.getProductLines());
  }, [leads, dispatch]);

  useEffect(() => {
    if (!leads.loading.origins && !leads.success.origins && !leads.error.origins)
      dispatch(LeadsActions.getOrigins());
  }, [leads, dispatch]);

  const search = () => {
    if (!isSearching) return setIsSearching(true);

    const newFilters = filter.filter((filt) => filt.column === "name");
    const searchValue = document.querySelector("#inputSearch").value;
    newFilters.push({
      id: uuidv4(),
      column: "person.name",
      value: searchValue,
      type: "varchar",
      name: searchValue,
    });
    LeadsActions.getMyLeads(1, quantity, newFilters, orderColumns[activeOrderColumns], typeList);
    setPage(1);
    setFilter(newFilters);
    return null;
  };

  useEffect(() => {
    dispatch(
      LeadsActions.getMyLeads(
        page,
        quantity,
        filter.map((filt) => ({ column: filt.column, value: filt.value, type: filt.type })),
        orderColumns[activeOrderColumns],
        typeList
      )
    );
  }, [dispatch, quantity, activeOrderColumns, filter, orderColumns, page, typeList]);

  const renderLeadTable = () => {
    if (!leads.success.leads || !leads.success.productLines || !leads.success.origins) return <></>;

    return (
      <LeadTable
        data={leads.success.leads}
        productLines={leads.success.productLines}
        total={leads.success.total}
        openDetail={openDetail}
        setPage={setPage}
        setQuantity={setQuantity}
        quantity={quantity}
        search={search}
        isSearching={isSearching}
        setFilter={(newFilters) => {
          setPage(1);
          setFilter(newFilters);
        }}
        filter={filter}
        sort={sort}
        columnOrder={orderColumns}
        setShowLeadDetail={setShowLeadDetail}
        origins={leads.success.origins}
        page={page}
      />
    );
  };

  return (
    <div className="container">
      <div className="leads">
        {showLeadDetail && (
          <Modal
            className="leads-details__modal"
            onCancel={() => setShowLeadDetail(false)}
            mask={false}
            style={{
              height: "calc(100% - 50px)",
              top: "50px",
              left: "50px",
              margin: "0px",
              paddingBottom: "0px",
            }}
            title="Detalle de Lead"
            width="calc(100% - 50px)"
            visible={showLeadDetail}
          >
            <Button
              id="open-modal-button-leads"
              className="btn-close-modal"
              onClick={() => setShowLeadDetail(false)}
            >
              <img src={flecha} className="arrow" alt="arrow" />
            </Button>
            <LeadDetail setShowLeadDetail={setShowLeadDetail} lead={leadCurrent} />
          </Modal>
        )}
        <section className="leads__table">
          <aside className="leads__table--content-title">
            <h1 className="leads__tableTitle">
              <div className="icon-b">
                <i className="icon-user" aria-hidden="true" />
              </div>
              {t("label.leadsTitle")}
            </h1>
            <div className="section-responsive">
              <Button className="btn-transarent">
                <img src={share} alt="icon" className="image" />
              </Button>
              <Button className="btn-transarent">
                <img src={menu} alt="icon" className="image" />
              </Button>
            </div>
            {/* <ModalUpBookClient /> */}
          </aside>
          {leads.loading.leads && (
            <Modal
              className="modal-loader"
              visible
              centered
              maskStyle={{ backgroundColor: "#ffffff66" }}
            >
              <Loader />
            </Modal>
          )}
          <div className="leads--responsive">
            <p className="body-small">1 de {leads?.success?.leads?.length}</p>
            {leads?.success?.leads?.map((item) => (
              <div className="card-lead" key={item.id}>
                <div className="card-lead--head">
                  <div className="cont-icon">
                    <img src={item.productLine.icon} alt="icon" className="icon" />
                  </div>
                  <div className="title">
                    <h4>{item.person.name}</h4>
                    <div className="title-footer">
                      <div className="box">
                        <p className="body-small">Fecha de ingreso:</p>
                        <p className="body-small">
                          {moment(item.requestDate).format("MM/DD/YYYY")}
                        </p>
                      </div>
                      <div className="box-two">
                        <p className="body-small">Origen:</p>
                        <p className="body-small">Página de producto</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contacts">
                  {item.person.contacts.map((data) => (
                    <div className="item" key={data.id}>
                      <img
                        src={data.type === "Phone" ? phone : email}
                        alt="icon"
                        className="icon"
                      />
                      <p className="body-regular">{data.value}</p>
                    </div>
                  ))}
                </div>
                <div className="divider" />
                <Button
                  onClick={() => {
                    setShowLeadDetail(true);
                    openDetail(item);
                  }}
                  className="see-detail"
                >
                  <span>Ver detalle</span>
                  <img src={arrow} className="btn-detail" alt="see-detail" />
                </Button>
              </div>
            ))}
          </div>
          <div className="tabs-leads">
            <Tabs
              defaultActiveKey="process"
              onChange={(key) => {
                setPage(1);
                setTypeList(key);
                setQuantity(10);
              }}
            >
              <TabPane tab={t("label.agentsProcess")} key="process">
                {renderLeadTable()}
              </TabPane>
              <TabPane tab={t("label.agentsActive")} key="active">
                {renderLeadTable()}
              </TabPane>
              <TabPane tab={t("label.agentsPending")} key="pending">
                {renderLeadTable()}
              </TabPane>
              <TabPane tab={t("label.agentsDenied")} key="closed">
                {renderLeadTable()}
              </TabPane>
              <TabPane tab={t("label.sold")} key="sold">
                {renderLeadTable()}
              </TabPane>
            </Tabs>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Leads;
