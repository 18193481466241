import { Button, message } from "antd";
import Loader from "components/loader";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import close from "../../../assets/icon/icon-close.svg";
import LeadsActions from "../../../services/leads/actions";
import History from "./components/history/History";
import Policy from "./components/policy/Policy";
import Products from "./components/products/Products";
import SidebarLead from "./components/sidebar/sidebar";

const getComponents = (lead, contract, setShowLeadDetail) => {
  if (contract)
    return {
      products: <Products lead={lead} />,
      history: <History lead={lead} />,
      policy: <Policy contract={contract} />,
    };
  return {
    products: <Products lead={lead} />,
    history: <History lead={lead} setShowLeadDetail={setShowLeadDetail} />,
  };
};

const LeadDetail = ({ setShowLeadDetail, lead }) => {
  const dispatch = useDispatch();

  const leadStore = useSelector((state) => state.leads);

  const [pageCurrent, setPageCurrent] = useState("products");

  const initialComponents = () =>
    getComponents(lead, leadStore.success.leadDetail?.contract, setShowLeadDetail);
  const [components, setComponents] = useState(initialComponents);

  useEffect(() => {
    dispatch(LeadsActions.getSuggestedProducts(+lead.id));
    dispatch(LeadsActions.getLeadDetail(+lead.id));
  }, [dispatch, lead]);

  useEffect(() => {
    if (leadStore.success.changeStatus) {
      message.success("Has actualizado el estado correctamente");
      dispatch(LeadsActions.clearChangeStatus());
    }
  }, [leadStore, dispatch]);

  useEffect(() => {
    setComponents(getComponents(lead, leadStore.success.leadDetail?.contract));
  }, [lead, leadStore.success.leadDetail]);

  if (leadStore.loading.leadDetail || !leadStore.success.leadDetail)
    return <Loader className="loader--full" />;

  return (
    <div className="leads-details">
      <div className="leads-details__header">
        <Button type="link" onClick={() => setShowLeadDetail(false)}>
          <img src={close} alt="close" />
        </Button>
      </div>
      <div className="leads-details__wrapper">
        <div className="leads-details__wrapperSidebar">
          <SidebarLead
            detail={leadStore.success.leadDetail}
            lead={lead}
            setShowLeadDetail={setShowLeadDetail}
            pageCurrent={pageCurrent}
            setPageCurrent={setPageCurrent}
          />
          <Button
            className="button-history"
            onClick={() => setPageCurrent("history")}
            style={{ borderRadius: "0 0 20px 20px" }}
            block
          >
            Ver historial
          </Button>
        </div>
        <div className="leads-details__wrapperContainer">
          {components[pageCurrent] || components.products}
        </div>
      </div>
    </div>
  );
};

LeadDetail.propTypes = {
  setShowLeadDetail: PropTypes.func.isRequired,
  lead: PropTypes.instanceOf(Object).isRequired,
};

export default LeadDetail;
