import { Button, Input, message, Modal } from "antd";
import { string } from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import edit from "../../../../../assets/images/edit.png";
import Loader from "../../../../../components/loader";
import AccountActions from "../../../../../services/account/actions";
import OnboardingActions from "../../../../../services/onboarding/actions";

const EmailUpdate = ({ email }) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [newEmail, setNewEmail] = useState("");

  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);

  useEffect(() => {
    if (account.success.updateEmail) {
      message.success("Correo electrónico actualizado");
      dispatch(OnboardingActions.getProfile());
    }

    if (account.error.updateEmail) message.error(t(`error.${account.error.updateEmail}`));

    if (account.error.updateEmail || account.success.updateEmail)
      dispatch(AccountActions.clearUpdateEmail());
  }, [account, dispatch, t]);

  const updateEmail = () => {
    if (newEmail === email)
      return message.error("El correo electrónico ingresado es igual al actual");
    if (newEmail === "") return message.error("El correo electrónico no puede estar vacío");

    dispatch(AccountActions.updateEmail(newEmail));
    return setIsEditing(false);
  };

  return (
    <div className="my-account__content--section">
      <Modal
        className="modal-loader"
        visible={account.loading.updateEmail}
        centered
        maskStyle={{ backgroundColor: "#ffffff66" }}
      >
        <Loader />
      </Modal>

      <h4>{t("label.email")}</h4>
      {isEditing ? (
        <div className="center center-row">
          <Input
            className="input-wahtsaap"
            type="email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
          />
          <div className="btns-section-account">
            <Button onClick={() => setIsEditing(false)} className="button button--tertiary">
              {t("button.cancel")}
            </Button>
            <Button
              onClick={() => {
                updateEmail();
              }}
              className="button button--secundary"
            >
              {t("button.updateInsuranceCarriers")}
            </Button>
          </div>
        </div>
      ) : (
        <div className="center center-row">
          <h6>{email}</h6>
          <Button onClick={() => setIsEditing(true)} className="btn-edit">
            <img src={edit} alt="editar" />
          </Button>
        </div>
      )}
    </div>
  );
};

EmailUpdate.propTypes = {
  email: string.isRequired,
};

export default EmailUpdate;
