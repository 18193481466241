/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
import { Button, Form, TimePicker } from "antd";
import moment from "moment";
import { func, instanceOf, number } from "prop-types";
import React, { useEffect, useState } from "react";

const FormSchedule = ({ scheduleId, setScheduleRows, scheduleRows }) => {
  const [selectedDays, setSelectedDays] = useState([]);
  const format = "HH:mm";

  const [form] = Form.useForm();

  useEffect(() => {
    if (scheduleRows) {
      const selectedRow = scheduleRows.find((scheduleRow) => scheduleRow.id === scheduleId);

      const actualSelectedDays = selectedRow.days.map((selectedDay) => ({ id: selectedDay }));
      setSelectedDays(actualSelectedDays);
      form.setFieldsValue({
        startTime: moment(
          selectedRow.startTime > 1000
            ? `${selectedRow.startTime / 100}:${selectedRow.startTime % 100}`
            : `0${selectedRow.startTime / 100}:${selectedRow.startTime % 100}`,
          format
        ),
        endTime: moment(
          selectedRow.endTime > 1000
            ? `${selectedRow.endTime / 100}:${selectedRow.endTime % 100}`
            : `0${selectedRow.endTime / 100}:${selectedRow.endTime % 100}`,
          format
        ),
      });
    }
  }, [scheduleRows, form, scheduleId]);

  const onFinishForm = (day) => {
    const values = form.getFieldsValue();
    const scheduleIndex = scheduleRows.findIndex((schedule) => schedule.id === scheduleId);
    if (scheduleIndex < 0) throw new Error("Error al asignar horario");

    const scheduleRowsCopy = [...scheduleRows];
    const dayIndex = scheduleRowsCopy[scheduleIndex].days.findIndex(
      (selectedDay) => day.id === selectedDay
    );
    if (dayIndex > -1) {
      scheduleRowsCopy[scheduleIndex].days.splice(dayIndex, 1);
    } else {
      scheduleRowsCopy[scheduleIndex] = {
        ...scheduleRows[scheduleIndex],
        days: [...scheduleRows[scheduleIndex].days, day.id],
        startTime: moment(values.startTime).format("HHmm"),
        endTime: moment(values.endTime).format("HHmm"),
      };
    }
    setScheduleRows(scheduleRowsCopy);
    setSelectedDays([
      ...scheduleRows[scheduleIndex].days.map((selectedDay) => ({ id: selectedDay })),
      day,
    ]);
  };

  const days = [
    { id: 1, name: "Monday", title: "L" },
    { id: 2, name: "Tuesday", title: "M" },
    { id: 3, name: "Wednesday", title: "M" },
    { id: 4, name: "Thursday", title: "J" },
    { id: 5, name: "Friday", title: "V" },
  ];

  return (
    <div className="FormSchedule">
      <Form form={form} className="form" name={`form${scheduleId}`} autoComplete="off">
        <div className="form-inputs">
          <Form.Item
            name="startTime"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <TimePicker placeholder="9:00" format={format} />
          </Form.Item>
          <Form.Item
            name="endTime"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <TimePicker placeholder="10:00" format={format} />
          </Form.Item>
        </div>
        <div className="buttons">
          {days?.map((day) => (
            <Button
              className={`btn ${
                selectedDays.some((selectedDay) => day.id === selectedDay.id) ? " btn--active" : ""
              }`}
              key={day.id}
              onClick={() => onFinishForm(day)}
            >
              {day.title}
            </Button>
          ))}
        </div>
      </Form>
    </div>
  );
};

FormSchedule.propTypes = {
  scheduleId: number.isRequired,
  setScheduleRows: func.isRequired,
  scheduleRows: instanceOf(Array).isRequired,
};

export default FormSchedule;
