/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Card } from "antd";
import { func, instanceOf } from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import back from "../../../../../../../../../assets/icon/icon-back.svg";
import AgentActions from "../../../../../../../../../services/agent/actions";
import VerifyAddress from "../VerifyAddress/VerifyAddress";
import VerifyOfficeData from "../VerifyOfficeData/VerifyOfficeData";

const EditOffice = ({ setSelectedOffice, selectedOffice, goBack }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("tab1");
  const [office, setOffice] = useState({});

  useEffect(() => {
    if (selectedOffice) setOffice(selectedOffice);
  }, [selectedOffice]);

  const save = (values) => {
    setSelectedOffice({ ...office, ...values });
    dispatch(AgentActions.updateOffice({ ...office, ...values }));
  };

  return (
    <section className="offices-editing">
      <Card className="offices-editing__card">
        <div className="offices-editing__cardHeader">
          <h4>{t("label.MyBusiness")}</h4>
        </div>
        <div className="wrapper">
          <div className="offices-editing__cardBack">
            <a
              href="./"
              onClick={(e) => {
                e.preventDefault();
                if (activeTab === "tab1") return goBack();
                return setActiveTab("tab1");
              }}
            >
              <img src={back} alt="back" />
            </a>
            <h6>{t("label.editOffice")}</h6>
          </div>
          <div className="offices-editing__cardTabs">
            <div
              id="tab1"
              className="tab--active"
              onClick={() => {
                setActiveTab("tab1");
              }}
            />
            <div
              id="tab2"
              className={`tab${activeTab === "tab2" ? "--active" : "--disabled"}`}
              onClick={() => {
                setActiveTab("tab2");
              }}
            />
          </div>
          {activeTab === "tab1" && (
            <VerifyAddress
              office={office}
              setAddress={(address) => {
                setOffice({ ...office, ...address });
                setActiveTab("tab2");
              }}
            />
          )}
          {activeTab === "tab2" && <VerifyOfficeData office={office} saveOffice={save} />}
        </div>
      </Card>
    </section>
  );
};

EditOffice.propTypes = {
  setSelectedOffice: func.isRequired,
  selectedOffice: instanceOf(Object),
  goBack: func.isRequired,
};

EditOffice.defaultProps = {
  selectedOffice: null,
};

export default EditOffice;
