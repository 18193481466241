import { createActions } from "redux-actions";

const { charts } = createActions({
  CHARTS: {
    GET_MINIMAL_METRICS: () => {},
    GET_MINIMAL_METRICS_RESPONSE: (metrics) => ({ metrics }),
  },
});

export default charts;
