/* eslint-disable react/no-unescaped-entities */
import { Button, Input, DatePicker } from "antd";
import React, { useState } from "react";
import dowSvg from "assets/images/dow-purple.svg";
import dowGreenSvg from "assets/images/dowload.svg";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PoliciesActions from "services/policies/actions";
import { func } from "prop-types";

const HeaderFilters = ({ setoptionSelected, setBranchesPaid, setBranchesQuantity }) => {
  const [isFilterRangeDate, setFilterRangeDate] = useState();
  const styleDark = useSelector((state) => state.policies.styleDark);
  const dispatch = useDispatch();
  const onChangeRange = (date, dateString) => {
    setBranchesPaid([]);
    setBranchesQuantity([]);
    dispatch(PoliciesActions.setFiltersCommissionsSummary("Fechas", dateString[0], dateString[1]));
    setFilterRangeDate(4);
  };

  const changeFiltreCommissiones = (cantDays) => {
    setBranchesPaid([]);
    setBranchesQuantity([]);
    if (cantDays === 24) {
      setFilterRangeDate(24);
      setoptionSelected(1);
    }
    if (cantDays === 12) {
      setFilterRangeDate(12);
      setoptionSelected(2);
    }
    if (cantDays === 6) {
      setFilterRangeDate(6);
      setoptionSelected(3);
    }
    dispatch(
      PoliciesActions.setFiltersCommissionsSummary(
        "Fechas",
        moment().subtract(cantDays, "month").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      )
    );

    dispatch(
      PoliciesActions.setFiltersCommissions(
        "Fechas",
        moment().subtract(cantDays, "month").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      )
    );
  };
  return (
    <div className="HeaderFilters">
      <div className="row row--head">
        <div className="section-title">
          <h2 className="h2">Comisiones</h2>
          <Button className="tabs-tab tabs-tab--down">
            {styleDark ? (
              <img src={dowGreenSvg} className="dowSvg" alt="dow" />
            ) : (
              <img src={dowSvg} className="dowSvg" alt="dow" />
            )}
          </Button>
        </div>
        <div className="filters">
          <Button
            className={`tab ${isFilterRangeDate === 24 && "tab--active"}`}
            onClick={() => changeFiltreCommissiones(24)}
          >
            24M
          </Button>
          <Button
            className={`tab ${isFilterRangeDate === 12 && "tab--active"}`}
            onClick={() => changeFiltreCommissiones(12)}
          >
            12M
          </Button>
          <Button
            className={`tab ${isFilterRangeDate === 6 && "tab--active"}`}
            onClick={() => changeFiltreCommissiones(6)}
          >
            6M
          </Button>
          <Input.Group
            compact
            className={`range-date ${isFilterRangeDate === 4 && "range-date-active"}`}
          >
            <DatePicker.RangePicker
              onChange={onChangeRange}
              placeholder={["Fecha inicial", "Fecha final"]}
              style={{ width: "100%" }}
            />
          </Input.Group>
        </div>
      </div>
    </div>
  );
};
HeaderFilters.propTypes = {
  setoptionSelected: func.isRequired,
  setBranchesPaid: func.isRequired,
  setBranchesQuantity: func.isRequired,
};
export default HeaderFilters;
