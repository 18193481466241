import { handleActions } from "redux-actions";

export const INITIAL_STATE = {
  sectionInEdition: undefined,
  sectionVisible: {
    mainInfo: true,
    experience: true,
    products: true,
    office: true,
  },
  saveAll: false,
};

const reducer = handleActions(
  {
    EDIT_MY_PAGE: {
      CHANGE_EDIT_STATUS: (state, { payload: { section } }) => ({
        ...state,
        sectionInEdition: section,
      }),

      MAKE_SECTION_VISIBLE: (state, { payload: { section } }) => {
        const newSectionVisible = {
          mainInfo: false,
          experience: false,
          products: false,
          office: false,
        };

        newSectionVisible[section] = !newSectionVisible[section];
        return { ...state, sectionVisible: newSectionVisible };
      },

      MAKE_ALL_SECTIONS_VISIBLE: (state) => ({
        ...state,
        sectionVisible: INITIAL_STATE.sectionVisible,
      }),

      SAVE_ALL: (state, { payload }) => ({
        ...state,
        saveAll: payload,
      }),
    },
  },
  INITIAL_STATE
);

export default reducer;
