/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button } from "antd";
import CloseGreenSvg from "assets/images/close-green.svg";
import moment from "moment";
import { func, instanceOf, string } from "prop-types";
import React from "react";
import PoliciesActions from "services/policies/actions";

const ExpirationFilter = ({
  expirationFilter,
  setSelectedExpiration,
  selectedExpiration,
  dispatch,
}) => {
  return (
    <div className="box-big box-big-before">
      <div className="box-big__head">
        <p className="sub-title">Vencimientos</p>
      </div>
      <div className="content-buttons">
        {expirationFilter?.map((item) => (
          <div className="content-icon" key={item.key}>
            {item.key === selectedExpiration && (
              <img
                src={CloseGreenSvg}
                className="icon"
                alt="icon"
                onClick={() => {
                  setSelectedExpiration("0");
                  dispatch(
                    PoliciesActions.setFilters(
                      "Fechas",
                      moment(new Date()).startOf("month").subtract(1, "year").format("YYYY-MM-01"),
                      moment(new Date()).endOf("month").format("YYYY-MM-DD")
                    )
                  );
                }}
              />
            )}
            <Button
              key={item.key}
              className={`button-list ${selectedExpiration === item.key && "button-list--active"}`}
              onClick={() => {
                setSelectedExpiration(item.key);
                dispatch(
                  PoliciesActions.setFilters(
                    "FechasVencimiento",
                    moment().format("YYYY-MM-DD"),
                    moment(item.endDate).format("YYYY-MM-DD")
                  )
                );
              }}
            >
              {item.title}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

ExpirationFilter.propTypes = {
  expirationFilter: instanceOf(Array).isRequired,
  setSelectedExpiration: func.isRequired,
  selectedExpiration: string.isRequired,
  dispatch: func.isRequired,
};

export default ExpirationFilter;
