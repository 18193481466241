import { createActions } from "redux-actions";

const { clientBookActions } = createActions({
  CLIENT_BOOK_ACTIONS: {
    SET_COLUMNS: (rows) => rows,

    SET_RELATION: (property, columnId) => ({ property, columnId }),

    UPLOAD_BOOK: () => {},
    UPLOAD_BOOK_RESPONSE: () => ({}),
    CLEAR_UPLOAD_BOOK: () => ({}),

    CLEAR_CHANGE_SHOW: () => {},
  },
});

export default clientBookActions;
