import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, message, Modal, Row } from "antd";
import capitalize from "common/utils/capitalize";
import showTranslatedText from "common/utils/showTranslatedText";
import moment from "moment";
import { func, instanceOf } from "prop-types";
import React, { useEffect, useState } from "react";
import DayPicker from "react-day-picker";
import { getI18n, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import IconSubmitNotes from "../../../../../../assets/icon/submit-notes.svg";
import back from "../../../../../../assets/images/flecha.svg";
import LeadsActions from "../../../../../../services/leads/actions";

function Notes({ activity, hidden, setSelectedDate, selectedDate }) {
  const dispatch = useDispatch();
  const { success } = useSelector((state) => state.leads);
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const createNote = () => {
    const note = document.getElementById("detail-note");
    if (!note.value) return message.error("Agrega detalle a la nota");
    dispatch(
      LeadsActions.createNote({
        interaction: +activity.id,
        value: note.value,
      })
    );
    note.value = "";
    return null;
  };

  const deleteNote = (note) => {
    dispatch(LeadsActions.deleteNote(+note.id));
  };
  useEffect(() => {
    if (activity) dispatch(LeadsActions.getNotes(+activity?.id));
  }, [activity, dispatch]);

  const handleCancel = () => setVisible(false);
  const handleOk = () => setVisible(true);

  const renderDay = (day) => {
    if (moment(selectedDate).isSame(day, "day"))
      return <div className="DayPicker-Day-calendar DayPicker--active">{day.getDate()}</div>;
    if (moment(new Date()).isSame(day, "day"))
      return <div className="DayPicker-Day-calendar DayPicker--today">{day.getDate()}</div>;
    return <div>{day.getDate()}</div>;
  };

  return (
    <Row style={{ flexDirection: "column", height: "100%" }}>
      <Col style={{ flex: "1" }} className="activity-notes">
        <div>
          <h3 className="activity-title">{t("label.notes")}</h3>
          {success.notes?.map((note) => (
            <div>
              <span className="activity-notesDate">
                {moment(note.requestDate).format("YYYY-MM-DD", "es")}
              </span>
              <div className="note">
                <Button className="close" onClick={() => handleOk()}>
                  <CloseOutlined />
                </Button>
                <Modal
                  title="Basic Modal"
                  className="modal-warnning-delete"
                  visible={visible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <p>{t("label.DeleteHistory")}</p>
                  <div className="buttons-modal">
                    <Button className="no" onClick={handleCancel}>
                      {t("label.not")}
                    </Button>
                    <Button
                      className="yes"
                      onClick={() => {
                        deleteNote(note);
                        handleCancel();
                      }}
                    >
                      {t("label.yes")}
                    </Button>
                  </div>
                </Modal>
                <p>{note.value}</p>
              </div>
            </div>
          ))}
        </div>
      </Col>
      <Col>
        <div className="activity-notesMessage">
          <textarea id="detail-note" className="detail-note" placeholder="Detalle de la nota" />
          <Button onClick={() => createNote()} className="activity-notesButton">
            <img src={IconSubmitNotes} alt="" />
          </Button>
        </div>
        <div className="title-new-interaction">
          <Button onClick={() => hidden()} className="back">
            <img src={back} alt="back" />
          </Button>
          <h2>Nueva interacción</h2>
        </div>
        <div className="head">
          <div className="circle">
            <img
              src="https://res.cloudinary.com/arepa-tec/image/upload/v1652889440/Blanco_iouvy0.svg"
              alt="icon"
              className="icon"
            />
          </div>
          <div className="text">
            <h6>
              Solicitud RFQ Seguro{" "}
              {showTranslatedText(
                success.leadDetail?.lead?.desiredProduct?.title,
                getI18n().language
              )}
            </h6>
            <h5>Recibida: {capitalize(success.leadDetail?.lead?.requestDate)}</h5>
          </div>
        </div>
        <DayPicker
          className="DayPicker"
          initialMonth={new Date()}
          month={moment(new Date()).subtract(0, "month").toDate()}
          renderDay={renderDay}
          onDayClick={(day) => setSelectedDate(day)}
        />
      </Col>
    </Row>
  );
}

Notes.propTypes = {
  activity: instanceOf(Object),
  hidden: instanceOf(Object).isRequired,
  setSelectedDate: func.isRequired,
  selectedDate: instanceOf(Object),
};

Notes.defaultProps = {
  activity: null,
  selectedDate: null,
};

export default Notes;
