/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import AutoSvg from "assets/images/pia-brands/auto.svg";
import DamageSvg from "assets/images/pia-brands/damage.svg";
import GmmSvg from "assets/images/pia-brands/gmm-icon.svg";
import VidaSvg from "assets/images/vida.svg";
import PoliciesActions from "services/policies/actions";
import HeaderFeedBack from "./components/HeaderFeedBack/HeaderFeedBack";
import SectionCommissions from "./components/SectionCommissions/SectionCommissions";
import SectionFeedBack from "./components/SectionFeedBack/SectionFeedBack";
import SectionPolicy from "./components/SectionPolicy/SectionPolicy";

const branchImages = {
  gmm: GmmSvg,
  autos: AutoSvg,
  daños: DamageSvg,
  vida: VidaSvg,
};

const PiaDashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [policiesCard, setPoliciesCard] = useState();
  const [insurances, setInsurances] = useState();
  const policiesStore = useSelector((state) => state.policies);
  const styleDark = useSelector((state) => state.policies.styleDark);

  useEffect(() => {
    history.listen((location, action) => {
      if (action === "POP")
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 25);
    });
  }, [history]);

  useEffect(() => {
    if (policiesStore.success.accounts)
      if (policiesStore.success.accounts.length === 0) history.replace("/account/pia");
  }, [policiesStore, history]);

  useEffect(() => {
    if (
      !policiesStore.loading.insurances &&
      !policiesStore.success.insurances &&
      !policiesStore.error.insurances
    )
      dispatch(PoliciesActions.getAllInsurances());
  }, [policiesStore, dispatch]);

  useEffect(() => {
    if (
      !policiesStore.loading.policies &&
      !policiesStore.success.policies &&
      !policiesStore.error.policies
    )
      dispatch(PoliciesActions.getLastPolicies());
  }, [policiesStore, dispatch]);

  useEffect(() => {
    if (
      !policiesStore.loading.accounts &&
      !policiesStore.success.accounts &&
      !policiesStore.error.accounts &&
      policiesStore.success.insurances
    )
      dispatch(PoliciesActions.getAllAccounts());

    if (policiesStore.success.accounts && policiesStore.success.insurances && !insurances) {
      const filteredInsurances = policiesStore.success.insurances
        .filter(
          (insurance) =>
            policiesStore.success.accounts.filter(
              (account) => account.insurance.key === insurance.key
            ).length > 0
        )
        .map((insurance) => ({
          ...insurance,
          available:
            policiesStore.success.accounts.filter(
              (account) => account.insurance.key === insurance.key && account.status === "connected"
            ).length > 0,
        }));
      setInsurances(filteredInsurances);
    }

    if (policiesStore.success.policies && policiesStore.success.insurances) {
      const mappedPolicies = policiesStore.success.policies?.map((policy) => ({
        id: policy.IdentifierPolicy,
        title: policy?.BenefeciaryName || "",
        number: policy.NumberPolicy,
        icon: branchImages[policy.Branch?.toLowerCase()],
        image: policiesStore.success.insurances.find(
          (insurance) => insurance.key === policy.SlugNameInsured
        )?.image,
        whiteImage: policiesStore.success.insurances.find(
          (insurance) => insurance.key === policy.SlugNameInsured
        )?.whiteImage,
        ramo: policy.Branch?.toLowerCase(),
        pago: policy.WayPayment,
        status: policy.StatusPolicy,
        expiration: policy.expiration,
        insurersOffering: policy.insurersOffering,
        fecha: policy.DateEmission,
        fecha_limite: policy.DateLimitPayment,
        fecha_vencimiento: policy.DateExpired,
        HasDetail: policy.HasDetail,
        commissionValue: policy.commissionValue,
      }));
      setPoliciesCard(mappedPolicies);
    }
    if (policiesStore.success.lastPolicies) {
      const mappedPolicies = policiesStore.success.lastPolicies?.map((policy) => ({
        id: policy.IdentifierPolicy,
        title: policy?.BenefeciaryName || "",
        number: policy.NumberPolicy,
        icon: branchImages[policy.Branch?.toLowerCase()],
        image: policiesStore.success.insurances.find(
          (insurance) => insurance.key === policy.SlugNameInsured
        )?.image,
        whiteImage: policiesStore.success.insurances.find(
          (insurance) => insurance.key === policy.SlugNameInsured
        )?.whiteImage,
        ramo: policy.Branch?.toLowerCase(),
        pago: policy.WayPayment,
        insurersOffering: policy.insurersOffering,
        status: policy.StatusPolicy,
        expiration: policy.expiration,
        fecha: policy.DateEmission,
        fecha_limite: policy.DateLimitPayment,
        fecha_vencimiento: policy.DateExpired,
        HasDetail: policy.HasDetail,
        commissionValue: policy.commissionValue,
      }));
      setPoliciesCard(mappedPolicies);
    }
  }, [policiesStore, dispatch, insurances]);
  return (
    <section className={`FeedBack ${styleDark && "FeedBack--dark"}`}>
      <div className="container">
        <HeaderFeedBack styleDark={styleDark} />
        <div className="FeedBack__content">
          <div className="column column-left">
            <SectionFeedBack />
            <SectionCommissions styleDark={styleDark} />
          </div>
          <div className="column">
            <SectionPolicy
              policiesCard={policiesCard}
              policiesStore={policiesStore}
              styleDark={styleDark}
              insurances={insurances}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PiaDashboard;
