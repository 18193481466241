/* eslint-disable no-param-reassign */
import { Button, Col, Modal, Row } from "antd";
import { changeRoute } from "common/utils/changeRoute";
import showTranslatedText from "common/utils/showTranslatedText";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import { getI18n, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import warnig from "assets/images/modal-warnig.png";
import { CLIENT_HOST } from "common/config/config";
import Loader from "components/loader";
import ModalGenericEditable from "components/Modals/ModalGenericEditable/ModalGenericEditable";
import ModalLinkTree from "components/Modals/ModalLinkTree/ModalLinkTree";
import AgentActions from "services/agent/actions";
import MarketplaceActions from "services/marketplace/actions";

const Category = ({ category }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    loading,
    insuranceLines,
    success,
    products: { selected },
  } = useSelector((state) => state.marketplace);
  const [line, setLine] = useState(null);
  const [listProducts, setListProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const {
    success: { profile },
    loading: loadingOboarding,
  } = useSelector((state) => state.onboarding);

  useEffect(() => {
    if (success.setAgentProduct) {
      dispatch(MarketplaceActions.getProductsByInsuranceLine(category));
    }
  }, [success.setAgentProduct, category, dispatch]);

  useEffect(() => {
    if (!insuranceLines.list) dispatch(MarketplaceActions.getInsuranceLines());
    if (!insuranceLines[category])
      dispatch(MarketplaceActions.getProductsByInsuranceLine(category));
  }, [category, dispatch, insuranceLines]);

  useEffect(() => {
    if (insuranceLines.list) {
      setLine(insuranceLines.list.find((item) => item.id === category));
    }
  }, [category, insuranceLines.list]);

  useEffect(() => {
    if (insuranceLines[category]) {
      if (selected === "all") {
        let all = insuranceLines[category];
        all = all.map((insurance) => insurance.products).flat(1);

        all = all.filter(
          (thing, index, self) => index === self.findIndex((i) => i.id === thing.id)
        );

        setAllProducts(all);
      } else {
        const aux = insuranceLines[category].filter((item) => item.id === selected);
        setListProducts(aux);
      }
    }
  }, [category, insuranceLines, selected]);

  useEffect(() => {
    if (selected) {
      const elements = document.getElementsByName("insurance");
      elements.forEach((item) => {
        if (item.id === selected) {
          item.className = "marketplace__aseguradoras__logo__selected";
        } else {
          item.className = "marketplace__aseguradoras__logo";
        }
      });
    }
  }, [selected]);

  const renderProducts = (products, insuranceCarrier) =>
    products.map((product) => (
      <Col className="marketplace__products__card" key={product.id}>
        <div className="marketplace__products__card__header">
          <img
            src={product.background}
            className="marketplace__products__card__headerBackground"
            alt=""
          />
          <div className="marketplace__products__card__headerCategorie">
            <img src={line?.iconWhite} alt="line" />
            <h4>{showTranslatedText(product.title, getI18n().language)}</h4>
          </div>
          <div
            className={`marketplace__products__card__headerSponsor ${
              product.mine ? "" : "marketplace__products__card__headerSponsor--mod"
            }`}
          >
            {insuranceCarrier ? (
              <div className={`icon-line ${product.mine && "marging"}`}>
                <img src={insuranceCarrier.icon} alt="" />
              </div>
            ) : (
              product.insuranceCarrierProducts.map((insurance) => (
                <div key={insurance.id} className={`icon-line ${product.mine && "marging"}`}>
                  <img src={insurance.insuranceCarrier.icon} alt="" />
                </div>
              ))
            )}
          </div>
          {product.mine && (
            <div className="visible-eye">
              <span>{t("label.productVisible")}</span>
            </div>
          )}
          <ModalGenericEditable
            title={t("label.titleCategory")}
            button_succes={
              <Button
                onClick={() => {
                  changeRoute("editPage", history, dispatch);
                  dispatch(
                    AgentActions.setAutoInsurance(
                      product.insuranceCarrierProducts[0].insuranceCarrier.key
                    )
                  );
                  dispatch(MarketplaceActions.requestHelpWithInsuranceCarrier(product.id));
                }}
                className="btn btn--succes"
              >
                {t("button.AddProfile")}
              </Button>
            }
            button_cancel={
              <Button onClick={() => history.push("/")} className="btn btn--cancel">
                {t("button.cancel")}
              </Button>
            }
            product={product}
            image={warnig}
          />
        </div>
        <div className="marketplace__products__card__body">
          <p className="marketplace__products__card__bodyTitle">{product.shortDescription}</p>
          <p className="marketplace__products__card__bodyDescrip">{product.description}</p>
          <div className="footer-card">
            <a
              href={`${CLIENT_HOST}producto/${product.endPoint}?agente=${profile?.endPoint}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("label.readMore")}
            </a>
            <ModalLinkTree items={product.insuranceCarrierProducts} endpoint={product.endPoint} />
          </div>
        </div>
      </Col>
    ));

  const renderList = (type, list) => {
    if (type === "insurance")
      return list?.map((insuranceCarrier) => (
        <div className="marketplace__products" key={insuranceCarrier.key}>
          <Row className="caroucel-market-two-section">
            {renderProducts(insuranceCarrier.products, insuranceCarrier)}
          </Row>
        </div>
      ));

    return renderProducts(list);
  };

  return (
    <>
      <Modal
        className="modal-loader"
        visible={loading.setAgentProduct || loadingOboarding.profile}
        centered
        maskStyle={{ backgroundColor: "#ffffff66" }}
      >
        <Loader />
      </Modal>
      <div className="marketplace">
        <div className="marketplace__header">
          <div className="marketplace__headerTitle">
            <div className="line-icon">
              <img src={line?.iconWhite} alt="line" />
            </div>
            <h3>{showTranslatedText(line?.title, getI18n().language)}</h3>
          </div>
        </div>
        <div className="flex-center">
          {selected !== "all"
            ? renderList("insurance", listProducts)
            : renderList("products", allProducts)}
        </div>
      </div>
    </>
  );
};

Category.propTypes = {
  category: PropTypes.instanceOf(Object).isRequired,
};

export default Category;
