/* eslint-disable import/no-cycle */
import ApiConnection from "common/api/Api";
import parseError from "common/api/ErrorParser";
import { all, put, select, takeLatest } from "redux-saga/effects";
import ClientBookActions from "./actions";

function* uploadBook() {
  const {
    data,
    error: { dataErrors },
  } = yield select((state) => state.clientBook);
  if (dataErrors && dataErrors.length > 0)
    return yield put(
      ClientBookActions.uploadBookResponse(
        parseError({ message: "Debes resolver todos los hallazgos primero" })
      )
    );
  const url = "/contract/read-excel";
  const response = yield ApiConnection.post(url, { data });
  if (response.success) return yield put(ClientBookActions.uploadBookResponse());
  return yield put(ClientBookActions.uploadBookResponse(parseError(response), response));
}

function* ActionWatcher() {
  yield takeLatest(ClientBookActions.uploadBook, uploadBook);
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
