import { handleActions } from "redux-actions";

export const INITIAL_STATE = {
  loading: {
    countries: false,
  },
  error: {
    countries: undefined,
  },
  success: {
    countries: undefined,
  },
};

const reducer = handleActions(
  {
    OFFICE: {
      GET_COUNTRIES: (state) => ({
        ...state,
        loading: { ...state.loading, countries: true },
        error: { ...state.error, countries: undefined },
      }),
      GET_COUNTRIES_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, countries: false },
            success: { countries: payload },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, countries: message },
            success: { ...state.success, countries: undefined },
            loading: { ...state.loading, countries: false },
          };
        },
      },
    },
  },
  INITIAL_STATE
);

export default reducer;
