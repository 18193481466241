import { Modal } from "antd";
import Loader from "components/loader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import PoliciesActions from "services/policies/actions";
import HeaderSummaryPolicy from "./components/HeaderSummarPoliza/HeaderSummarPoliza";
import PolicySummaryPia from "./components/PolicySummaryPia/PolicySummaryPia";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SummaryPia = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDarkMode, setDarkMode] = useState(false);
  const [actualId, setActualId] = useState(null);
  const [searchedHighlight, setSearchedHighlight] = useState("");
  const {
    success: { detail },
    loading,
    error,
  } = useSelector((state) => state.policies);

  const query = useQuery();

  useEffect(() => {
    if (actualId) dispatch(PoliciesActions.getDetail(actualId));
  }, [actualId, dispatch]);

  useEffect(() => {
    const queryValue = query.get("id");
    if (actualId !== queryValue && queryValue) setActualId(queryValue);
  }, [actualId, query]);

  useEffect(() => {
    if (error.detail) {
      // history.replace("/pia");
      dispatch(PoliciesActions.cleanDetailResponse());
    }
  }, [error, dispatch, history]);

  if (loading.detail)
    return (
      <Modal
        className="modal-loader"
        visible={loading.detail}
        centered
        maskStyle={{ backgroundColor: "#ffffff66" }}
      >
        <Loader />
      </Modal>
    );
  if (detail)
    return (
      <div className="container--SummaryPia">
        <div className="SummaryPia">
          <HeaderSummaryPolicy
            setSearchedHighlight={setSearchedHighlight}
            policy={detail}
            isDarkMode={isDarkMode}
            setDarkMode={setDarkMode}
          />
          <PolicySummaryPia
            searchedHighlight={searchedHighlight}
            policy={detail}
            isDarkMode={isDarkMode}
          />
        </div>
      </div>
    );

  return <></>;
};

export default SummaryPia;
