import { Button } from "antd";
import useWindowSize from "common/utils/useWindowResize";
import { instanceOf } from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import OnboardingActions from "../../../services/onboarding/actions";

const OmitSection = ({ onboarding }) => {
  const size = useWindowSize();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      {size.width < 1024 &&
        onboarding.step > -1 &&
        onboarding.step < 4 &&
        !onboarding.success.profile.onBoarding &&
        !onboarding.omitForNow && (
          <Button
            onClick={() => {
              dispatch(OnboardingActions.setStep(onboarding.step + 1));
            }}
            className="skip-step"
            type="link"
          >
            <p>{t("button.SkipStep")}</p>
          </Button>
        )}
    </>
  );
};

OmitSection.propTypes = {
  onboarding: instanceOf(Object).isRequired,
};

export default OmitSection;
