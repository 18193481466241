/* eslint-disable react/jsx-no-bind */
import { Button, Checkbox } from "antd";
import p1 from "assets/images/pia-brands/p1.svg";
import p2 from "assets/images/pia-brands/p2.svg";
import p3 from "assets/images/pia-brands/p3.svg";
import p4 from "assets/images/pia-brands/rc.svg";
import { func } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PoliciesActions from "services/policies/actions";
import Policies from "../../../pia/components/Policies/Policies";

const CustomizeReport = ({ setTabActive }) => {
  const [isActive, setAvtive] = useState(1);
  const [isCheckAll, setCheckAll] = useState(false);
  const [isCheckNone, setCheckNone] = useState(false);
  const [selectedExpiration, setSelectedExpiration] = useState("");
  const [selectedProductLines, setSelectedProductLines] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [insurances, setInsurances] = useState();

  const dispatch = useDispatch();

  const policiesStore = useSelector((state) => state.policies);

  useEffect(() => {
    if (
      !policiesStore.loading.insurances &&
      !policiesStore.success.insurances &&
      !policiesStore.error.insurances
    )
      dispatch(PoliciesActions.getAllInsurances());
  }, [policiesStore, dispatch]);

  useEffect(() => {
    if (
      !policiesStore.loading.accounts &&
      !policiesStore.success.accounts &&
      !policiesStore.error.accounts &&
      policiesStore.success.insurances
    )
      dispatch(PoliciesActions.getAllAccounts());

    if (policiesStore.success.accounts && policiesStore.success.insurances && !insurances) {
      const filteredInsurances = policiesStore.success.insurances
        .filter(
          (insurance) =>
            policiesStore.success.accounts.filter(
              (account) => account.insurance.key === insurance.key
            ).length > 0
        )
        .map((insurance) => ({
          ...insurance,
          available:
            policiesStore.success.accounts.filter(
              (account) => account.insurance.key === insurance.key && account.StatusLogin === 1
            ).length > 0,
        }));
      setInsurances(filteredInsurances);
    }
  }, [policiesStore, dispatch, insurances]);

  function onChange(checkedValues) {
    const { value } = checkedValues.target;
    if (value === "all") {
      setCheckAll(true);
      setCheckNone(false);
    } else {
      setCheckAll(false);
      setCheckNone(true);
    }
  }

  const dummyBtns = [
    { id: 1, name: "Cobranza" },
    { id: 2, name: "Ingresos" },
    { id: 3, name: "Emisión" },
    { id: 4, name: "Siniestro" },
  ];

  const dummyProduct = [
    { id: 2, title: "Mascotas", icon: p1 },
    { id: 1, title: "Autos", icon: p2 },
    { id: 3, title: "RC MED", icon: p4 },
    { id: 4, title: "Salud", icon: p3 },
    { id: 5, title: "Salud", icon: p3 },
  ];

  return (
    <div className="CustomizeReport">
      <h3 className="h3">Selecciona el tipo de reporte</h3>
      <div className="CustomizeReport--checks">
        <Checkbox value="all" checked={isCheckAll} onChange={onChange}>
          Seleccionar todas
        </Checkbox>
        <Checkbox value="none" checked={isCheckNone} onChange={onChange}>
          Deseleccionar todas
        </Checkbox>
      </div>
      <div
        className={`CustomizeReport--btns ${isCheckAll && "CustomizeReport--btns--check-all"} ${
          isCheckNone && "CustomizeReport--btns--check-none"
        }`}
      >
        {dummyBtns?.map((item) => (
          <Button
            key={item.id}
            className={`btn ${isActive === item.id && "btn--active"}`}
            onClick={() => setAvtive(item.id)}
          >
            {item.name}
          </Button>
        ))}
      </div>
      <h3 className="h3">Selecciona los filtros</h3>
      {insurances && (
        <Policies
          insurances={insurances}
          accounts={policiesStore.success.accounts}
          dummyProduct={dummyProduct}
          selectedExpiration={selectedExpiration}
          setSelectedExpiration={setSelectedExpiration}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          selectedProductLines={selectedProductLines}
          setSelectedProductLines={setSelectedProductLines}
        />
      )}
      <div className="generate-report">
        <Button className="button button-primary" onClick={() => setTabActive("2")}>
          Generar Reporte
        </Button>
      </div>
    </div>
  );
};

CustomizeReport.propTypes = { setTabActive: func.isRequired };

export default CustomizeReport;
