/* eslint-disable camelcase */
import { createActions } from "redux-actions";

const { policies: PoliciesActions } = createActions({
  POLICIES: {
    GET_ALL_ACCOUNTS: () => ({}),
    GET_ALL_ACCOUNTS_RESPONSE: (accounts, insurances) => ({ accounts, insurances }),
    CLEAN_ALL_ACCOUNTS_RESPONSE: () => ({}),

    GET_NEXT_RENOVATIONS: () => ({}),
    GET_NEXT_RENOVATIONS_RESPONSE: (nextRenovations) => ({ nextRenovations }),

    GET_COVER: (cover) => ({ cover }),
    GET_COVER_RESPONSE: (cover) => ({ cover }),

    REGISTER_ACCOUNT: (
      identificadorAseguradora,
      contrasena,
      contrasena_2,
      claveAgente,
      cuenta
    ) => ({ identificadorAseguradora, contrasena, contrasena_2, claveAgente, cuenta }),
    REGISTER_ACCOUNT_RESPONSE: () => ({}),
    CLEAN_REGISTER_ACCOUNT: () => ({}),

    UPDATE_ACCOUNT: (
      identificadorAseguradora,
      contrasena,
      contrasena_2,
      claveAgente,
      cuenta,
      reference
    ) => ({
      identificadorAseguradora,
      contrasena,
      contrasena_2,
      claveAgente,
      cuenta,
      reference,
    }),
    UPDATE_ACCOUNT_RESPONSE: () => ({}),
    CLEAN_UPDATE_ACCOUNT: () => ({}),

    UPDATE_STATUS_POLICY: (policyId, status) => ({
      policyId,
      status,
    }),
    UPDATE_STATUS_POLICY_RESPONSE: (id, newStatus) => ({ id, newStatus }),
    CLEAN__STATUS_POLICY: () => ({}),

    GET_ALL_POLICIES: (account) => account,
    GET_ALL_POLICIES_RESPONSE: (policies, pages) => ({ policies, pages }),
    CLEAN_ALL_POLICIES_RESPONSE: () => ({}),

    GET_ALL_INSURANCES: () => ({}),
    GET_ALL_INSURANCES_RESPONSE: (insurances) => insurances,

    GET_ALL_COMISSIONS: () => ({}),
    GET_ALL_COMISSIONS_RESPONSE: (comossionsTotal) => comossionsTotal,

    CLEAR_ALL_INSURANCES_RESPONSE: () => ({}),

    GET_COMMISSIONS: () => ({}),
    GET_COMMISSIONS_RESPONSE: (commissions) => commissions,

    CLEAR_COMMISSIONS_RESPONSE: () => ({}),

    GET_INSURANCE_ACCOUNT_STATUS: () => ({}),
    GET_INSURANCE_ACCOUNT_STATUS_RESPONSE: (insuranceAccountStatus) => insuranceAccountStatus,
    CLEAR_INSURANCE_ACCOUNT_STATUS_RESPONSE: () => ({}),

    GET_ABSTRACT: (startDate, endDate) => ({ startDate, endDate }),
    GET_ABSTRACT_RESPONSE: (abstract) => abstract,
    CLEAN_ABSTRACT_RESPONSE: () => ({}),

    GET_LAST_POLICIES: () => ({}),
    GET_LAST_POLICIES_RESPONSE: (policies) => policies,
    CLEAN_LAST_POLICIES_RESPONSE: () => ({}),

    SET_FILTERS: (key, value, secondValue) => ({ key, value, secondValue }),

    SET_FILTERS_COMMISSIONS: (key, value, secondValue) => ({ key, value, secondValue }),
    SET_FILTERS_COMMISSIONS_SUMMARY: (key, value, secondValue) => ({ key, value, secondValue }),

    SET_SELECTED_POLICY: (key) => key,

    GET_DETAIL: (id) => id,
    GET_DETAIL_RESPONSE: (detail) => detail,
    CLEAN_DETAIL_RESPONSE: () => ({}),

    SEARCH_POLICIES_BY_KEY: (keyword) => keyword,
    SEARCH_POLICIES_BY_KEY_RESPONSE: (detail) => detail,
    CLEAN_SEARCH_POLICIES_BY_KEY_RESPONSE: () => ({}),

    DELETE_ACCOUNT: (id) => id,
    DELETE_ACCOUNT_RESPONSE: (detail) => detail,

    SHOW_MORE_POLICIES: () => ({}),

    SET_SEARCH_VALUE: (value) => value,

    MODIFY_FILTERS_OPENED: () => ({}),

    GET_CLOSE_TO_EXPIRATION: () => ({}),
    GET_CLOSE_TO_EXPIRATION_RESPONSE: (firstBranch, secondBranch) => ({
      firstBranch,
      secondBranch,
    }),

    GET_MONTH_EVENTS: (year, month) => ({ year, month }),
    GET_MONTH_EVENTS_RESPONSE: (events) => events,

    UPLOAD_COVER: (image, policyId) => ({ image, policyId }),
    UPLOAD_COVER_RESPONSE: (detail) => detail,
    CLEAN_UPLOAD_COVER_RESPONSE: () => ({}),

    GET_OFFERS: () => {},
    GET_OFFERS_RESPONSE: (offers) => ({ offers }),

    GET_COMMISSIONS_SUMMARY: () => {},
    GET_COMMISSIONS_SUMMARY_RESPONSE: (summary) => ({ summary }),

    STYLE_DARK: () => {},
    STYLE_DARK_RESPONSE: (styleDark) => ({ styleDark }),
  },
});
export default PoliciesActions;
