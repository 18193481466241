import React from "react";
import { Button, Tooltip } from "antd";
import { bool, func, instanceOf } from "prop-types";
import { useDispatch } from "react-redux";
import PoliciesActions from "services/policies/actions";
import ButtonSelect from "./ButtonSelect/ButtonSelect";

const InsurersFilter = ({ insurances, accounts, styleMode, filteredAccounts, getLast10 }) => {
  const dispatch = useDispatch();
  return (
    <section className="InsurersFilter">
      <p className="sub-title">Filtra por Aseguradoras</p>
      <div className="box">
        {insurances
          ?.sort((insurance) => {
            const actualAccounts = accounts.filter(
              (account) => account.insurance.key === insurance.SlugName
            );

            if (
              actualAccounts.find((account) =>
                filteredAccounts.find(
                  (identificador) => account.IdentificadorCuenta === identificador
                )
              )
            )
              return -1;

            return 1;
          })
          ?.map((insurance) => (
            <div className="slick-slider-content" key={insurance.SlugName}>
              {insurance.available ? (
                <>
                  {accounts.filter((account) => account.insurance.key === insurance.SlugName)
                    .length > 1 ? (
                    <ButtonSelect
                      styleMode={styleMode}
                      insurance={insurance}
                      accounts={accounts.filter(
                        (account) => account.insurance.key === insurance.SlugName
                      )}
                      getLast10={getLast10}
                    />
                  ) : (
                    <Button
                      className={`btn-select-caroucel ${
                        filteredAccounts.some((filteredAccount) =>
                          accounts.find(
                            (account) =>
                              account.insurance.key === insurance.SlugName &&
                              filteredAccount === account.id
                          )
                        )
                          ? "btn-select-caroucel--active"
                          : ""
                      }`}
                      onClick={() =>
                        dispatch(
                          PoliciesActions.setFilters(
                            "IdentificadorCuenta",
                            accounts
                              .filter((account) => account.insurance.key === insurance.SlugName)
                              .map((account) => account.id)
                              .join(",")
                          )
                        )
                      }
                    >
                      <img
                        src={
                          styleMode ||
                          filteredAccounts.some((filteredAccount) =>
                            accounts.find(
                              (account) =>
                                account.insurance.key === insurance.SlugName &&
                                filteredAccount === account.id
                            )
                          )
                            ? insurance.whiteImage
                            : insurance.image
                        }
                        alt="aseguradora"
                        className="icon"
                      />
                    </Button>
                  )}
                </>
              ) : (
                <Tooltip title="Ninguna cuenta de esta aseguradora ha sido verificada">
                  <Button className="btn-select-caroucel" disabled>
                    <img
                      src={
                        filteredAccounts.some(
                          (filteredAccount) =>
                            `${filteredAccount}` ===
                            `${
                              accounts.filter(
                                (account) => account.insurance.key === insurance.SlugName
                              )[0].IdentificadorCuenta
                            }`
                        )
                          ? insurance.whiteImage
                          : insurance.image
                      }
                      alt="aseguradora"
                      className="icon"
                    />
                  </Button>
                </Tooltip>
              )}
            </div>
          ))}
      </div>
    </section>
  );
};

InsurersFilter.propTypes = {
  insurances: instanceOf(Array).isRequired,
  accounts: instanceOf(Array).isRequired,
  styleMode: bool.isRequired,
  getLast10: func.isRequired,
  filteredAccounts: instanceOf(Array).isRequired,
};

export default InsurersFilter;
