/* eslint-disable react/forbid-prop-types */
import React from "react";
import { Button } from "antd";
import PropTypes, { object, string, func } from "prop-types";
import { useDispatch } from "react-redux";
import PoliciesActions from "services/policies/actions";
import moment from "moment";

const ButtonListFilters = ({
  item,
  type,
  setSelectedProductLines,
  setSelectedExpiration,
  selectedProductLines,
}) => {
  const dispatch = useDispatch();
  const handleDeleteFilter = (value) => {
    if (value === "state") {
      dispatch(PoliciesActions.setFilters("clean_Estado", []));
      setSelectedProductLines([]);
    }
    if (value === "expiration") {
      setSelectedExpiration("0");
      dispatch(
        PoliciesActions.setFilters(
          "Fechas",
          moment(new Date()).startOf("month").subtract(1, "year").format("YYYY-MM-01"),
          moment(new Date()).endOf("month").format("YYYY-MM-DD")
        )
      );
    }
    if (value === "branch") {
      const newProductLines = [...selectedProductLines];
      const productLineIndex = newProductLines.findIndex((productLine) => productLine === item);
      if (productLineIndex > -1) newProductLines.splice(productLineIndex, 1);
      else newProductLines.push(item);
      setSelectedProductLines(newProductLines);
      dispatch(PoliciesActions.setFilters("Ramos", item));
    }
    if (value === "account") {
      dispatch(PoliciesActions.setFilters("IdentificadorCuenta", item.id));
    }
  };
  return (
    <>
      {item === 0 ? (
        <></>
      ) : (
        <Button className="button-filter" onClick={() => handleDeleteFilter(type)}>
          <span>{type === "account" ? item.insurance.key : item}</span>
          <svg
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.99991 5.3631L10.6277 0.744816C10.7022 0.670082 10.791 0.610619 10.8885 0.570168C10.986 0.529716 11.0906 0.508894 11.1962 0.508894C11.3017 0.508894 11.4063 0.529716 11.5038 0.570168C11.6013 0.61062 11.69 0.669996 11.7646 0.744728C12.0698 1.04996 12.0698 1.56702 11.7646 1.87225L7.13681 6.5L11.7646 11.1278C11.8392 11.2024 11.8984 11.291 11.9388 11.3885C11.9792 11.4861 12 11.5906 12 11.6962C12 11.8017 11.9792 11.9062 11.9388 12.0038C11.8984 12.1013 11.8392 12.1899 11.7646 12.2646C11.6899 12.3392 11.6013 12.3984 11.5038 12.4388C11.4062 12.4792 11.3017 12.5 11.1962 12.5C11.0906 12.5 10.9861 12.4792 10.8885 12.4388C10.791 12.3984 10.7024 12.3392 10.6278 12.2646L6 7.63681L1.37225 12.2646C1.29761 12.3392 1.20899 12.3984 1.11147 12.4388C1.01394 12.4792 0.909409 12.5 0.803847 12.5C0.698284 12.5 0.593755 12.4792 0.496228 12.4388C0.398702 12.3984 0.310086 12.3392 0.235442 12.2646C0.160798 12.1899 0.101587 12.1013 0.0611904 12.0038C0.0207934 11.9062 0 11.8017 0 11.6962C0 11.5906 0.0207933 11.4861 0.0611904 11.3885C0.101588 11.291 0.160798 11.2024 0.235442 11.1278L4.86319 6.5L0.235442 1.87225C0.160798 1.79761 0.101587 1.70899 0.0611904 1.61146C0.0207934 1.51394 0 1.40941 0 1.30385C0 1.19828 0.0207934 1.09375 0.0611904 0.996227C0.101587 0.8987 0.160798 0.810085 0.235442 0.735441C0.310086 0.660797 0.398701 0.601586 0.496228 0.561189C0.593755 0.520792 0.698284 0.5 0.803847 0.5C0.90941 0.5 1.01394 0.520792 1.11147 0.561189C1.20899 0.601586 1.29761 0.660797 1.37225 0.735441L5.99991 5.3631Z"
              fill="#5D30DD"
            />
          </svg>
        </Button>
      )}
    </>
  );
};
ButtonListFilters.propTypes = {
  item: string.isRequired,
  type: string.isRequired,
  setSelectedProductLines: func.isRequired,
  setSelectedExpiration: func.isRequired,
  selectedProductLines: PropTypes.instanceOf(object).isRequired,
};
export default ButtonListFilters;
